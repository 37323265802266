import { createSlice } from "@reduxjs/toolkit"

export const initialData = {
  isShowCloseBtn : false ,
  isOpenOrder: false,
  isOpenRedeem: false,
  isOpenUserInformation: false,
  isOpenUserEvent: false,
  isOpenAvatarList: false,
  isOpenEventListing: false,
  isOpenPayment: false,
  isOpenAddAttendee: false,
  isOpenMissionList : false,
  isOpenActivity : false,
  isOpenPurchaseInfo: false,
  isOpenAttachment: false,
  isOpenCarReport: false,
  isOpenPaymentSuccess: false,
  isOpenPostPreview: false
}

const modalSlice = createSlice({
  name: "modal",
  initialState: initialData,
  reducers: {
    closeActivityModal: (state, action) => {
      state.isOpenActivity =  false
    },
    openActivityModal: (state, action) => {
      state.isOpenActivity =  true
      state.isShowCloseBtn =  true
    },
    openModalMissionList: (state,action) => {
      state.isOpenMissionList =  true
    },
    openModalAddAttendee: (state,action) => {
      state.isOpenAddAttendee =  true
    },
    openModalOrderHistory: (state,action) => {
      state.isOpenOrder =  true
      state.isShowCloseBtn =  true
    },
    openModalRedeemHistory: (state,action) => {
      state.isOpenRedeem =  true
      state.isShowCloseBtn =  true

    },
    openModalUserInformation: (state,action) => {
      state.isOpenUserInformation =  true
      state.isShowCloseBtn =  true

    },
    openModalEvent: (state,action) => {
      state.isOpenUserEvent =  true
      state.isShowCloseBtn =  true
    },
    openModalAvatarList: (state,action) => {
      state.isOpenAvatarList =  true
      state.isShowCloseBtn =  true

    },
       openModalEventListing: (state,action) => {
      state.isOpenEventListing =  true
      state.isShowCloseBtn =  true

    },
    openModalPurchase: (state,action) => {
      state.isOpenPayment =  true

    },
    openModalPurchaseInfo: (state,action) => {
      state.isOpenPurchaseInfo =  true
    },
    openModalAttachment: (state,action) => {
      state.isOpenAttachment =  true
    },
    openModalCarReport: (state,action) => {
      state.isOpenCarReport =  true
    },
    openModalPaymentSuccess: (state,action) => {
      state.isOpenPaymentSuccess =  true
    },
    openModalPostPreview: (state,action) => {
      state.isOpenPostPreview =  true
    },
    closeModalPurchaseInfo: (state,action) => {
      state.isOpenPurchaseInfo =  false
    },
    closeModalOrderHistory: (state,action) => {
      state.isOpenOrder =  false
    },
    closeModalRedeemHistory: (state,action) => {
      state.isOpenRedeem =  false
    },
    closeModalUserInformation: (state,action) => {
      state.isOpenUserInformation =  false
    },
    closeModalEvent: (state,action) => {
      state.isOpenUserEvent =  false
    },
    closeModalAvatarList: (state,action) => {
      state.isOpenAvatarList =  false
    },
      closeModalEventListing: (state,action) => {
      state.isOpenEventListing =  false
    },
    closeModalPurchase: (state,action) => {
      state.isOpenPayment =  false
    },
    closeModalAddAttendee: (state,action) => {
      state.isOpenAddAttendee =  false
    },
    closeModalMissionList: (state,action) => {
      state.isOpenMissionList =  false
    },
    closeModalAttachment: (state,action) => {
      state.isOpenAttachment =  false
    },
    closeModalCarReport: (state,action) => {
      state.isOpenCarReport =  false
    },
    closeModalPaymentSuccess: (state,action) => {
      state.isOpenPaymentSuccess =  false
    },
    closeModalPostPreview: (state,action) => {
      state.isOpenPostPreview =  false
    },
    closeAllModal: (state,action) => {
      state.isOpenOrder =  false
      state.isOpenRedeem =  false
      state.isOpenUserInformation =  false
      state.isOpenUserEvent =  false
      state.isOpenAvatarList = false
      state.isShowCloseBtn =  false
      state.isOpenEventListing =  false
      state.isOpenPayment =  false
      state.isOpenAddAttendee =  false
      state.isOpenMissionList =  false
      state.isOpenActivity =  false
      state.isOpenPurchaseInfo =  false
      state.isOpenAttachment =  false
      state.isOpenCarReport =  false
      state.isOpenPaymentSuccess =  false
      state.isOpenPostPreview =  false
    },
  },
  extraReducers: {
  },
})

export const {openActivityModal, closeActivityModal ,openModalAvatarList , closeModalAvatarList ,openModalOrderHistory , closeModalOrderHistory , openModalRedeemHistory , closeModalRedeemHistory ,closeAllModal , openModalEvent , openModalUserInformation , closeModalEvent ,closeModalUserInformation , openModalEventListing ,closeModalEventListing , openModalPurchase , closeModalPurchase , openModalAddAttendee , closeModalAddAttendee , openModalMissionList ,closeModalMissionList , openModalPurchaseInfo , closeModalPurchaseInfo, openModalAttachment, closeModalAttachment, openModalCarReport, closeModalCarReport, openModalPaymentSuccess, closeModalPaymentSuccess, openModalPostPreview, closeModalPostPreview}  = modalSlice.actions;
const { reducer } = modalSlice;
export default reducer;
