import { useEffect, useRef, useState } from "react";

//lib
import _ from "lodash";
import { Formik } from "formik";
import Lottie from "lottie-react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { Skeleton } from "primereact/skeleton";
import { QuantityPicker } from "react-qty-picker";
import { useNavigate, useParams } from "react-router";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";

//redux
import { useDispatch, useSelector } from "react-redux";
import {
  getProductInfo,
  getUserOrderDetails,
  setProductInfo,
  setShowPurchaseModal,
} from "../../../redux/reducer/stockReducer";
import {
  setSelectedLogin,
  setShareModal,
  setShowLoginModal,
} from "../../../redux/reducer/settingReducer";
import {
  addToCart,
  getCartData,
  setActiveTab,
  setAddCartQuantity,
} from "../../../redux/reducer/cartReducer";

//assets
import ImageLoading from "./../../../assets/json/image-loading.json";

//hook
import useWindowDimensions from "../../../components/common/window-dimension";

//component
import Login from "../../Login";
import PurchaseModal from "./purchase.modal";
import ImageSlider from "../../../components/element/slider";
import ShareModal from "../../../components/modal/share.modal";
import { setShareUrl } from "../../../redux/reducer/forumReducer";
import { Link } from "react-router-dom";

export default function Product() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const firstDivRef = useRef(null);
  const secondDivRef = useRef(null);
  const { id } = useParams();

  const params = useParams();
  const [quantity, setQuantity] = useState(1);
  const [firstShow, setFirstShow] = useState(false);
  const [secondShow, setSecondShow] = useState(false);
  const [firstShowMore, setFirstShowMore] = useState(false);
  const [secondShowMore, setSecondShowMore] = useState(false);

  const {
    showPurchaseModal,
    productInfo,
    productInfo_loading,
    payment_loading,
  } = useSelector((state) => state.stock);
  const { add_cart_loading } = useSelector((state) => state.cart);
  const { showLoginModal, shareModal } = useSelector((state) => state.setting);

  const token = localStorage.getItem("token");

  useEffect(() => {
    dispatch(
      getProductInfo({
        id: id,
      })
    );
  }, []);

  useEffect(() => {
    if (payment_loading && productInfo.payment_type === "currency") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [payment_loading, productInfo]);

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const referenceNo = params.get("reference_no");

    if (referenceNo) {
      dispatch(setShowPurchaseModal(true));
      dispatch(
        getUserOrderDetails({
          reference_no: referenceNo,
        })
      );
    }
  }, []);

  useEffect(() => {
    const divHeight = firstDivRef?.current?.offsetHeight;
    const maxHeight = 58; // 5em = 5 * 16px (assuming 1em = 16px)
    setFirstShowMore(divHeight > maxHeight);
    setFirstShow(true);
    if (divHeight > maxHeight) {
    }
  }, [productInfo, firstDivRef?.current]);

  useEffect(() => {
    const divHeight = secondDivRef?.current?.offsetHeight;
    const maxHeight = 58; // 5em = 5 * 16px (assuming 1em = 16px)
    setSecondShowMore(divHeight > maxHeight);
    setSecondShow(true);
    if (divHeight > maxHeight) {
    }
  }, [productInfo, secondDivRef?.current]);

  const shareProduct = `Hey friends, check out this cool product I found! It's the ${productInfo.name} from ${productInfo.brand_name} that I think you'll be interested in. You can find more details about it by visiting this link: ${window.location.href}`;

  const autoOpenModal = {
    purchase: setShowPurchaseModal(true),
    share: setShareModal(true),
  };

  useEffect(() => {
    if (params.state) {
      dispatch(autoOpenModal[params.state]);
    }
  }, []);

  useEffect(() => {
    if (payment_loading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [payment_loading]);

  return (
    //Store
    <section>
      <Formik>
        <article>
          <header>
            {width > 991 && (
              <MDBBtn
                className="cart-btn"
                style={{ bottom: "30px" }}
                onClick={() => {
                  if (token) {
                    navigate(`/cart`, { state: `product/${productInfo.id}` });
                  } else {
                    dispatch(setShowLoginModal(true));
                  }
                }}
              >
                <Icon icon="vaadin:cart" width="30" height="30" />
              </MDBBtn>
            )}
            <section className="product-header">
              <Link
                onClick={(e) => {
                  e.preventDefault();

                  navigate("/store");
                  dispatch(setProductInfo({}));
                }}
              >
                <Icon
                  icon="tabler:arrow-back-up"
                  color="#333333"
                  width="25"
                  height="25"
                />
              </Link>
              {!productInfo_loading ? (
                <h3 className="header-title">
                  {productInfo.merchant_name}
                </h3>
              ) : (
                <Skeleton
                  width={width > 500 ? "25em" : "60%"}
                  height="2em"
                  borderRadius="10px"
                  className=""
                />
              )}
              <Link
                onClick={(e) => {
                  e.preventDefault();

                  dispatch(autoOpenModal["share"]);
                  navigate(`/product/${id}/share`);
                  dispatch(setShareUrl(productInfo.share_url))
                }}
              >
                {!productInfo_loading ? (
                  <Icon
                    icon="ri:share-circle-line"
                    color="#333333"
                    width="20"
                    height="20"
                  />
                ) : (
                  <Skeleton
                    width={width > 500 ? "3.5em" : "3em"}
                    height={width > 500 ? "3.5em" : "3em"}
                    shape="circle"
                  />
                )}
              </Link>
            </section>
          </header>
          <section className="container product-container">
            <figure className="row image-row d-flex justify-content-center">
              {!productInfo_loading ? (
                <ImageSlider
                  title={productInfo.name}
                  images={_.filter(productInfo.medias, (obj) => {
                    return obj.image_type === "product_image";
                  })}
                  alt={productInfo.info ? productInfo.info : productInfo.name}
                  divClassName="image-div"
                  labelClassName="image-count"
                />
              ) : (
                <section>
                  <article className="loading-overlay" />
                  <article className="d-flex justify-content-center align-items-center">
                    <section
                      style={{
                        width: "15em",
                        marginTop: width > 600 ? "3em" : "4em",
                      }}
                    >
                      <Lottie
                        animationData={ImageLoading}
                        style={{ width: "100%" }}
                      />
                    </section>
                    <p className="image-count --loading">0/0</p>
                  </article>
                </section>
              )}
            </figure>
            <article
              className="d-flex justify-content-center"
              style={{ outline: 0 }}
            >
              <section className="product-info">
                <header className="row product-basic">
                  {!productInfo_loading ? (
                    <>
                      <article className="col-6 col-lg-9 col-md-10">
                        <h2 className="_label --product-name">
                          {productInfo.name}
                        </h2>
                        <h4 className="_label --brand-name mt-3">
                          {productInfo.brand}
                        </h4>
                      </article>
                      <article className="col-6 col-lg-3 col-md-2 text-end">
                        {!productInfo_loading && (
                          <>
                            <h2 className="_label --product-name">
                              {productInfo.payment_type === "currency"
                                ? "RM "
                                : ""}
                              {productInfo.price}
                              {productInfo.payment_type !== "currency"
                                ? " PTS"
                                : ""}
                            </h2>
                            {/* <br /> */}
                            <p className="_label --brand-name mt-1">
                              each unit
                            </p>
                          </>
                        )}
                      </article>
                      <article className="mt-3" style={{ display: "contents" }}>
                        {productInfo.tag?.length > 0 &&
                          productInfo.tag.map((item, index) => (
                            <section className="product-tag mt-2" key={index}>
                              {item}
                            </section>
                          ))}
                      </article>
                    </>
                  ) : (
                    <>
                      <article className="col-9 col-lg-10 col-md-10">
                        <Skeleton
                          width={width > 600 ? "50%" : "70%"}
                          height="2em"
                          borderRadius="10px"
                        />
                      </article>
                      <article className="col-3 col-lg-2 col-md-2 text-end">
                        <Skeleton
                          width="100%"
                          height="2em"
                          borderRadius="10px"
                        />
                      </article>
                      <article className="d-flex mt-3">
                        <Skeleton
                          width="10em"
                          height="1.5em"
                          borderRadius="10px"
                          className="me-3"
                        />
                      </article>
                    </>
                  )}
                </header>
                {!productInfo_loading ? (
                  <article>
                    {productInfo.info ? (
                      <section
                        className="product-more mt-5"
                        style={{ paddingBottom: "1.2em" }}
                      >
                        <article className="d-flex align-items-center">
                          <article className="short-line" />
                          <p className="_label --short-title">
                            Product Description
                          </p>
                        </article>
                        <article
                          className="container function-div mt-3"
                          onClick={() => {
                            if (firstShowMore) {
                              setFirstShow(!firstShow);
                            }
                          }}
                        >
                          <p
                            ref={firstDivRef}
                            className={
                              firstShow
                                ? "product-function --hide"
                                : "product-function"
                            }
                            style={{
                              height: firstShowMore
                                ? firstShow
                                  ? "6.2em"
                                  : "auto"
                                : "auto",
                              width: "100%",
                              whiteSpace: "pre-line",
                              fontSize: "12px",
                              padding: "0",
                            }}
                          >
                            {productInfo.info}
                          </p>
                          {firstShowMore && (
                            <section className="mt-3">
                              <p
                                className="_label --see-more "
                                style={{ cursor: "pointer" }}
                              >
                                {firstShow ? "Show More" : "Show Less"}
                              </p>
                            </section>
                          )}
                        </article>
                      </section>
                    ) : (
                      ""
                    )}

                    {productInfo.description ? (
                      <section
                        className="product-more mt-3"
                        style={{ paddingBottom: "1.2em" }}
                      >
                        <article className="d-flex align-items-center">
                          <article className="short-line" />
                          <p className="_label --short-title">
                            Product Function
                          </p>
                        </article>
                        <article
                          className="function-div mt-3"
                          onClick={() => {
                            if (secondShowMore) {
                              setSecondShow(!secondShow);
                            }
                          }}
                        >
                          <p
                            ref={secondDivRef}
                            className={
                              secondShow
                                ? "product-function --hide"
                                : "product-function"
                            }
                            style={{
                              height: secondShowMore
                                ? secondShow
                                  ? "6.2em"
                                  : "auto"
                                : "auto",
                              width: "100%",
                              whiteSpace: "pre-line",
                              fontSize: "12px",
                              padding: "0",
                            }}
                          >
                            {productInfo.description}
                          </p>
                          {secondShowMore && (
                            <section className="row mt-3">
                              <p
                                className="_label --see-more "
                                style={{ cursor: "pointer" }}
                              >
                                {secondShow ? "Show More" : "Show Less"}
                              </p>
                            </section>
                          )}
                        </article>
                      </section>
                    ) : (
                      ""
                    )}

                    {productInfo.properties?.length > 0 &&
                    _.filter(
                      productInfo.medias,
                      (obj) => obj.image_type === "product_guide"
                    ).length > 0 ? (
                      <section className="row product-more mt-4">
                        <article className="d-flex align-items-center">
                          <article className="short-line" />
                          <p className="_label --short-title">How To Use</p>
                        </article>
                        {_.filter(
                          productInfo.medias,
                          (obj) => obj.image_type === "product_guide"
                        )?.map((image, index) => (
                          <article key={index}>
                            <figure className="guide-div d-flex justify-content-center mt-3">
                              <LazyLoadImage
                                src={image.url}
                                alt={productInfo.properties?.length > 0 ? productInfo.properties[index]?.label : "reunion-product-guide"}
                              />
                            </figure>
                            <section className="d-flex mt-3">
                              <figure className="d-flex align-items-start mt-1 me-2">
                                <Icon
                                  icon="bxs:caret-up-square"
                                  color="#7c7c7c"
                                  width="16"
                                  height="16"
                                />
                              </figure>
                              {productInfo.properties?.length > 0 && (
                                <p className="_label --guide-desc">
                                  {productInfo.properties[index]?.label}
                                </p>
                              )}
                            </section>
                          </article>
                        ))}
                      </section>
                    ) : (
                      ""
                    )}
                  </article>
                ) : (
                  <article>
                    <section
                      className="row product-more mt-5"
                      style={{ paddingBottom: "1.2em" }}
                    >
                      <article className="d-flex align-items-center mt-2">
                        <Skeleton
                          width={width > 600 ? "30%" : "40%"}
                          height="2em"
                          borderRadius="10px"
                        />
                      </article>
                      <article className="mt-5">
                        <Skeleton
                          width="100%"
                          height="5em"
                          borderRadius="10px"
                        />
                      </article>
                    </section>
                    <section
                      className="row product-more mt-3"
                      style={{ paddingBottom: "1.2em" }}
                    >
                      <article className="d-flex align-items-center">
                        <Skeleton
                          width={width > 600 ? "30%" : "40%"}
                          height="2em"
                          borderRadius="10px"
                        />
                      </article>
                      <article className="d-flex justify-content-center align-items-center mt-3">
                        <Skeleton
                          width={width > 600 ? "50%" : "70%"}
                          height="18em"
                          borderRadius="10px"
                          className="mt-3"
                        />
                      </article>
                      <Skeleton
                        width="70%"
                        height="1.5em"
                        borderRadius="10px"
                        className="mt-5"
                      />
                      <Skeleton
                        width="90%"
                        height="1.5em"
                        borderRadius="10px"
                        className="mt-3"
                      />
                    </section>
                  </article>
                )}
              </section>
            </article>
          </section>
          {!productInfo_loading && (
            <footer
              className="product-footer text-center"
              style={{
                top:
                  width > 500
                    ? productInfo.balance > 0
                      ? "82%"
                      : "85%"
                    : "77%",
              }}
            >
              <section className="d-flex justify-content-center">
                {productInfo.balance > 0 && (
                  <QuantityPicker
                    min={productInfo?.balance <= 0 ? 0 : 1}
                    max={productInfo?.balance <= 0 ? 0 : productInfo.balance}
                    value={productInfo?.balance <= 0 ? 0 : quantity}
                    onChange={(value) => {
                      if (value === productInfo?.balance) {
                        toast.error("Maximum quantity reached!", {
                          autoClose: 3000,
                          theme: "light",
                          hideProgressBar: true,
                          closeOnClick: true,
                          closeButton: false,
                        });
                      }
                      setQuantity(value);
                    }}
                    smooth
                  />
                )}
              </section>
              <MDBBtn
                className={
                  // add_cart_loading 
                  //   ? "purchase-btn --loading mt-4" :
                  "purchase-btn mt-4"
                }
                onClick={() => {
                  dispatch(
                    setAddCartQuantity({
                      product_id: productInfo.id,
                      quantity: quantity,
                    })
                  );
                  if (token) {
                    dispatch(
                      addToCart({
                        product_id: productInfo.id,
                        quantity: quantity,
                      })
                    )
                      .unwrap()
                      .then((res) => {
                        dispatch(setActiveTab(productInfo.payment_type));
                        dispatch(
                          getCartData({
                            cart_payment_type: productInfo.payment_type,
                          })
                        );
                        toast.success("Product added to cart", {
                          autoClose: 3000,
                          theme: "light",
                          hideProgressBar: true,
                          closeOnClick: true,
                          closeButton: false,
                        });
                        navigate(`/store`);
                        //, { state: `product/${productInfo.id}` }
                      })
                      .catch((ex) => {});
                  } else {
                    dispatch(setShowLoginModal(true));
                    dispatch(setSelectedLogin("main"));
                  }
                }}
                disabled={
                  add_cart_loading ||
                  productInfo.balance <= 0 ||
                  quantity >= productInfo.balance
                    ? true
                    : false
                }
              >
                <p className="text-uppercase d-flex align-items-center">
                  {productInfo.balance > 0
                    ? `Add${add_cart_loading ? 'ing' : ''} to Cart 
                      ${!add_cart_loading ? '-' : ''} 
                      ${productInfo.payment_type === "currency" && !add_cart_loading ? "RM" : ""} 
                      ${!add_cart_loading ? productInfo.price * quantity : ''} ${
                        productInfo.payment_type === "point" && !add_cart_loading ? "Pts" : ""
                      }`
                    : "Sold Out"}
                  {add_cart_loading && <Icon
                    icon="line-md:loading-twotone-loop"
                    width={22}
                    height={22}
                    className="ms-3"
                  />}
                </p>
              </MDBBtn>
            </footer>
          )}
          {shareModal && (
            <ShareModal
              shareProduct={shareProduct}
              url={productInfo.share_url}
              imageUrl={
                productInfo?.medias?.length > 0 && productInfo.medias[0].api_url
              }
            />
          )}
          {showPurchaseModal && (
            <PurchaseModal data={productInfo ? productInfo.share_url : ""} />
          )}
          {showLoginModal && <Login />}
        </article>
      </Formik>
    </section>
  );
}
