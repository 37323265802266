import { useState } from "react";

//lib
import { Icon } from "@iconify/react";
import { useFormikContext } from "formik";
import { MDBInput } from "mdb-react-ui-kit";

//hook
import useWindowDimensions from "../../../components/common/window-dimension";

//component
import Text from "../../../components/element/text";

//redux
import { useDispatch, useSelector } from "react-redux";
import { setSelectedForm } from "../../../redux/reducer/paymentReducer";

export default function AddAttendeeForm() {
  const dispatch = useDispatch();
  const { selectedUser, formState } = useSelector((state) => state.payment);
  const { setFieldValue, values } = useFormikContext();

  const index = selectedUser.index;
  const ticketIndex = selectedUser.ticketIndex;
  const fieldName = `properties[${ticketIndex}][${index}].name`;
  const fieldPhone = `properties[${ticketIndex}][${index}].contact`;
  const [loading, setLoading] = useState(false);

  const [userName, setUserName] = useState(
    formState === "edit" && values.properties[ticketIndex][index].name
      ? values.properties[ticketIndex][index].name
      : ""
  );

  const [userPhone, setUserPhone] = useState(
    formState === "edit" && values.properties[ticketIndex][index].contact
      ? values.properties[ticketIndex][index].contact
      : ""
  );
  const [errorStatus, setErrorStatus] = useState(false);

  const { width } = useWindowDimensions();

  const backFunction = () => {
    dispatch(setSelectedForm(""));
  };

  const handleSubmit = () => {
    if (
      userName !== "" &&
      userPhone !== "" &&
      /^(01)[02-9]-*[0-9]{7}$|^(01)[1]-*[0-9]{8}$/.test(userPhone)
    ) {
      setLoading(true);
      if (formState === "add") {
        const newData = [
          ...values.properties[ticketIndex],
          { name: userName, contact: userPhone },
        ];
        setFieldValue(`properties[${ticketIndex}]`, newData);
        backFunction();
      } else {
        setFieldValue(fieldName, userName);
        setFieldValue(fieldPhone, userPhone);
        backFunction();
      }
    } else {
    }
  };

  return (
    <article style={{ marginTop: width >= 991 ? "0em" : "6em" }}>
      <Text
        name={`properties[${ticketIndex}][${index}].name`}
        placeholder={"First name"}
        as={MDBInput}
        className="mdb-field"
        type={"text"}
        editData={setUserName}
        value={userName}
        setErrorStatus={setErrorStatus}
      />

      {errorStatus && userName === "" && (
        <p className="text-danger  error-message">Name is required</p>
      )}

      <Text
        name={`properties[${ticketIndex}][${index}].contact`}
        placeholder={"Contact"}
        as={MDBInput}
        className="mdb-field"
        type={"tel"}
        editData={setUserPhone}
        value={userPhone}
        setErrorStatus={setErrorStatus}
      />

      {errorStatus && userPhone === "" && (
        <p className="text-danger  error-message">
          Phone number is required
        </p>
      )}

      {errorStatus &&
        userPhone !== "" &&
        !/^(01)[02-9]-*[0-9]{7}$|^(01)[1]-*[0-9]{8}$/.test(userPhone) && (
          <p className="text-danger error-message">
            Invalid phone number format
          </p>
        )}

      <footer
        className="form-group _button save_button"
        onClick={() => {
          if (loading === false) {
            handleSubmit();
          }
        }}
      >
        {loading ? (
          <Icon icon="line-md:uploading-loop" height={"2em"} />
        ) : (
          "Save"
        )}
      </footer>
    </article>
  );
}
