import { useEffect, useRef } from "react";

//lib
import { BottomSheet } from "react-spring-bottom-sheet";
import {
  MDBContainer,
  MDBModal,
  MDBModalContent,
  MDBModalDialog,
} from "mdb-react-ui-kit";
import { RemoveScroll } from "react-remove-scroll";

//lib css
import "react-spring-bottom-sheet/dist/style.css";
import { useDispatch, useSelector } from "react-redux";
import { setAnimationModal, setShowFullModal } from "../../redux/reducer/commonReducer";
import { AnimatePresence, motion } from "framer-motion";

export default function ModalBox({
  show,
  header,
  footer,
  content,
  backDrop,
  className,
  setShow,
  centered,
  screenSize,
  scrollable,
  desktopModal,
  onDismiss,
  onOpen,
  backButton,
}) {
  const bottomSheetRef = useRef(null);
  const dispatch = useDispatch()
  const { showFullModal, animationModal } = useSelector((state) => state.common)

  const handleBottomSheetOpen = () => {
    if (bottomSheetRef.current) {
      bottomSheetRef.current.scrollTo({ top: 0 });
    }
  };

  const handleCloseModalWithDelay = () => {
    dispatch(setShowFullModal(false))
    setTimeout(() => {
      dispatch(setAnimationModal(true))
      backButton();
    }, 500);
  };

  useEffect(() => {
    dispatch(setShowFullModal(show))
  }, [])

  if (desktopModal) {
    return (
      <RemoveScroll>
        <AnimatePresence>
          {showFullModal && 
          <MDBModal
            className={`full-modal-box-container ${className}`}
            tabIndex="-1"
            show={true}
          >
            <article
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
              }}
              onClick={() => handleCloseModalWithDelay()}
            ></article>
            <motion.div
              initial={ animationModal && { 
                scale: 0.2
              }}
              animate={ animationModal && { 
                scale: 1,
                opacity: 1,
                transition: {
                  duration: 0.5
                }
              }}
              exit={ animationModal && {
                opacity: [1, 0],
                scale: [1, 0.8],
                transition: {
                  duration: 0.3,
                },
              }}
              >
              <MDBModalDialog
                centered={centered}
                size={`${screenSize}`}
                scrollable={scrollable ? true : false}
              >
                <MDBModalContent>
                  <motion.div
                    initial={ animationModal && {  
                      opacity: 0
                    }}
                    animate={ animationModal && { 
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        duration: 0.3
                      }
                    }}
                    exit={ animationModal && { 
                      opacity: 0,
                      transition: {
                        duration: 0.3,
                      }
                    }}
                  >
                    {content}
                  </motion.div>
                </MDBModalContent>
              </MDBModalDialog>
            </motion.div>
          </MDBModal>
        }
        </AnimatePresence>
      </RemoveScroll>
    );
  } else {
    return (
      <BottomSheet
        open={show}
        onDismiss={onDismiss}
        // onOpen={handleBottomSheetOpen}
        // snapPoints={({ maxHeight }) => [0.45 * maxHeight, 0.83 * maxHeight]}
        // defaultSnap={({ lastSnap, snapPoints }) => {
        //   lastSnap ?? Math.max(...snapPoinsts);
        // }}
        onOpen={handleBottomSheetOpen}
        snapPoints={
          className == "purchase-page"
            ? ({ maxHeight }) => [maxHeight / 1.3]
            : ({ maxHeight }) => [maxHeight / 1.4]
        }
        scrollLocking={false}
        //blocking={backDrop}
        header={header}
        footer={footer}
        className={className}
        ref={bottomSheetRef}
      >
        <MDBContainer ref={bottomSheetRef} style={{ marginTop: '3em' }}>{content}</MDBContainer>
      </BottomSheet>
    );
  }
}
