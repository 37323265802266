import { useEffect, useState } from "react";

//lib
import { Segmented } from 'antd';
import Lottie from "lottie-react";
import { SegmentedControl } from "segmented-control";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";

//assets
import MediaIcon from "../../assets/json/media-post.json";
import CarLoading from "./../../../src/assets/json/car-loading.json";

//component
import Login from "../Login";
import PostInfo from "./component/post.info";
import SearchBar from "./component/search.bar";
import CreatePost from "./component/create.post";
import ProfileCard from "./component/profile.modal";
import PostListing from "./component/post.card.listing";
import ForumPostHeader from "./component/forum.post.header";
import ForumSettingModal from "../../components/modal/forum.post.setting";
import NotificationModal from "../../components/modal/notification.modal";

//hook
import useWindowDimensions from "../../components/common/window-dimension";

//redux
import {
  getForumListing,
  getNotification,
  setPostList,
} from "../../redux/reducer/forumReducer";
import { useDispatch, useSelector } from "react-redux";
import { openNotificationButton, setShowConfirmModal } from "../../redux/reducer/commonReducer";

//helper
import scrollToTop from "../../helper/scrolltotop.helper";
import { Helmet } from "react-helmet";
import ConfirmationModal from "../../components/modal/comfirmation.modal";


export default function Forum() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [searchParams, setSearchParams] = useSearchParams();
  const post_id = searchParams.get("post");
  const token = localStorage.getItem("token");

  const [data, setData] = useState([]);
  const [start, setStart] = useState(0);
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");
  const [topic, setTopic] = useState("help");
  const [fitler, setFilter] = useState("help");
  const [hasMore, setHasMore] = useState(false);
  const [settingModal, setSettingModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [initialLoading, setInitialLoading] = useState(false);

  const { items, length, postList } = useSelector((state) => state.forum);
  const { showLoginModal } = useSelector((state) => state.setting);
  const { showConfirmModal } = useSelector((state) => state.stock);

  const filterOwnerPost = selectedOption.find((item) => item.code === "post");
  const filterMostLike = selectedOption.find(
    (item) => item.code === "like_count"
  );
  const filterOldPost = selectedOption.find(
    (item) => item.code === "create_at"
  );
  const filterComment = selectedOption.find(
    (item) => item.code === "comment_count"
  );

  const getForumPost = () => {
    setInitialLoading(true);
    let payload = {
      start: start,
      length: length,
      search: search,
      pinned: "",
      topic: fitler,
    };

    if (filterOwnerPost) {
      payload.filter = true;
    }

    if (filterMostLike) {
      payload.sort = "like_count";
    }
    if (filterComment) {
      payload.sortComment = "comment_count";
    }

    if (filterOldPost) {
      payload.order = "asc";
    }

    dispatch(getForumListing(payload))
      .unwrap()
      .then((res) => {

        setInitialLoading(false);
        let newData = [];
        for (var key in res.data.aaData) {
          newData.push({
            ...res.data.aaData[key],
            display_id: `post-${res.data.aaData[key].id}`,
          });
        }
        dispatch(setPostList(newData));

        if (newData.length < res.data.iTotalRecords) {
          setHasMore(true);
        }
      });
  };

  const getMoreForumPost = () => {
    let payload = {
      start: start + 12,
      length: length,
      search: search,
      pinned: "",
      topic: fitler,
    };

    if (filterOwnerPost) {
      payload.filter = true;
    }

    if (filterMostLike) {
      payload.sort = "like_count";
    }

    if (filterComment) {
      payload.sortComment = "comment_count";
    }

    if (filterOldPost) {
      payload.order = "asc";
    }
    dispatch(getForumListing(payload))
      .unwrap()
      .then((res) => {
        setStart(start + 12);
        let newData = [];
        for (var key in res.data.aaData) {
          newData.push({
            ...res.data.aaData[key],
            display_id: `post-${res.data.aaData[key].id}`,
          });
        }
        if (postList.length + newData.length === res.data.iTotalRecords) {
          setHasMore(false);
        }
        dispatch(setPostList([...postList, ...newData]));
      });
  };

  function updateMetaThemeColor() {
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", "#FFF");
  }

  function likeIncrement(likePostId) {
    const updatedData = postList.map(post => {
      if (post.id === likePostId) {
        return {
          ...post,
          like_count: post.like_count + 1 // Increment like count
        };
      }
      return post;
    });
  
    dispatch(setPostList(updatedData));
  }

  useEffect(() => {
    setType('')

    scrollToTop();
    updateMetaThemeColor();
    if (token) {
      dispatch(getNotification({ length: 10, start: 0 }));
    }
  }, []);

  useEffect(() => {
    if (width >= 991) {
      navigate("/");
    }
  }, [width]);

  useEffect(() => {
    setStart(0)
    setInitialLoading(true);

    let payload = {
      start: 0,
      length: length,
      search: search,
      pinned: "",
      topic: fitler,
    };

    if (filterOwnerPost) {
      payload.filter = true;
    }

    if (filterMostLike) {
      payload.sort = "like_count";
    }
    if (filterComment) {
      payload.sortComment = "comment_count";
    }

    if (filterOldPost) {
      payload.order = "asc";
    }

    dispatch(getForumListing(payload))
      .unwrap()
      .then((res) => {
        setInitialLoading(false);
        let newData = [];
        for (var key in res.data.aaData) {
          newData.push({
            ...res.data.aaData[key],
            display_id: `post-${res.data.aaData[key].id}`,
          });
        }
        dispatch(setPostList(newData));

        if (newData.length < res.data.iTotalRecords) {
          setHasMore(true);
        }
    });

    return () => {
      dispatch(openNotificationButton(false));
    };
  }, [fitler, search, selectedOption]);

  return (
    <>
      <Helmet>
        <title>Reunion | Forum</title>
        <meta name="description" content="Join Reunion's vibrant car club community to discuss various car topics in our forum and stay updated with the latest in car culture and knowledge." />
      </Helmet>

      <section className="container forum-post-container">
        <header className="sticky">
          <ForumPostHeader
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            setStart={setStart}
          />
          <SearchBar setSearch={setSearch} setStart={setStart} />
          <section className="row sharing-post-tab-container">
            <Segmented 
              options={[
                { label: "Help", value: "help"},
                { label: "Show Case", value: "showcase" },
                { label: "Sharing", value: "recommend" },
              ]}
              value={fitler} 
              onChange={setFilter}
              block 
            />
          </section>
          <NotificationModal />
        </header>
        {!initialLoading &&
          postList.length === 0 && (
          <article className="row post-card-listing-row">
            <Lottie animationData={MediaIcon} />
            <h3 className="title">No Post Found</h3>
            <p className="desc">
              No one has posted something in this topic.
            </p>
          </article>
          )
        }
        <article className="row listing-container">
          <section className="col-12 p-0">
            <PostListing
              hasMore={hasMore}
              items={data}
              getMoreData={() => getMoreForumPost()}
              loading={initialLoading}
            />
          </section>
        </article>
        <PostInfo
          getForumPost={getForumPost}
          likeIncrement={likeIncrement}
          post_id={post_id}
          setShow={setSettingModal}
        />
        <CreatePost 
          getForumPost={getForumPost} 
          setData={setData} 
          setHasMore={setHasMore} 
          setFilter={setFilter} 
          start={start} 
          setStart={setStart} 
          setType={setType}
          isForum 
        />
        <ProfileCard />
        {showLoginModal && <Login />}
        {settingModal && (
          <ForumSettingModal
            show={settingModal}
            setShow={setSettingModal}
            getForumPost={getForumPost}
          />
        )}
        {showConfirmModal && (
          <ConfirmationModal
            show={showConfirmModal}
            setShow={setShowConfirmModal}
            type={type ? "post" : "ticket" }
          />
        )}
      </section>
    </>
  );
}
