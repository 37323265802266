//lib
import { Icon } from "@iconify/react";
import { useFormikContext } from "formik";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

//redux
import { useDispatch, useSelector } from "react-redux";
import { setSelectedPaymentMethod , setSelectedForm } from "../../../redux/reducer/paymentReducer";

export default function PaymentMethodList({ item }) {
  const dispatch = useDispatch();
  const { selectedPaymentMethod } = useSelector((state) => state.payment);
  const { setFieldValue } = useFormikContext();

  const choosePaymentMethod = (data) => {
    if (selectedPaymentMethod.name === data.name) {
      dispatch(setSelectedPaymentMethod(""));
      setFieldValue("payment_type", "");
    } else {
      dispatch(setSelectedPaymentMethod(data));
      setFieldValue("payment_type", data.name);
      dispatch(setSelectedForm(""))
    }
  };

  return (
    <li className="row payment-row" onClick={() => choosePaymentMethod(item)}>
      <article className="col-3">
        <section className="image-col d-flex justify-content-center">
          <LazyLoadImage src={item.image} alt={item.name} className="payment-image" />
        </section>
      </article>
      <article className="col">
        <p className="_label --name">{item.name}</p>
      </article>
      <article className="col text-end">
        <Icon
          onClick={() => choosePaymentMethod(item)}
          icon={
            selectedPaymentMethod.name !== item.name
              ? "ph:circle-light"
              : "carbon:circle-filled"
          }
          color="#3db8ed"
        />
      </article>
    </li>
  );
}
