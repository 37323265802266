import { useState } from "react";

//lib
import * as Yup from "yup";
import moment from "moment";
import Select from "react-select";
import { Formik, Form, Field } from "formik";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import CalendarPicker from "react-calendar";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";

//lib css
import "react-calendar/dist/Calendar.css";

//hook
import useDatePicker from "../../../components/hook/useDatePicker";
import useWindowDimensions from "../../../components/common/window-dimension";

//redux
import {
  bookingWorkshop,
  calendar,
  getCarReportHistory,
  setDisableMakeBooking,
} from "../../../redux/reducer/carReducer";
import { useDispatch, useSelector } from "react-redux";
import { setShowLoginModal } from "../../../redux/reducer/settingReducer";

export default function CalendarCard() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const { booked_loading } = useSelector((state) => state.car);
  const { notifications } = useSelector((state) => state.car.report);
  const { disableMakeBooking, report } = useSelector((state) => state.car);
  const { width } = useWindowDimensions();

  const today = new Date();
  const oneDayAfter = new Date(today.getTime() + 24 * 60 * 60 * 1000);
  const [selectedHistoryDate, setSelectedHistoryDate] = useState(new Date(oneDayAfter)); // - dev
  const [selectedHistoryTime, setSelectedHistoryTime] = useState();
  const [selectedHistoryTimeValue, setSelectedHistoryTimeValue] = useState();
  const [selectedCarChooseValue, setSelectedCarChooseValue] = useState();

  const [submitted, setSubmitted] = useState(false)

  const [value, onChange] = useState(new Date(oneDayAfter)); //oneDayAfter - dev
  const [newCar, setNewCar] = useState(false);
  const [booking, setBooking] = useState(false);

  const { result, timeOption } = useDatePicker(selectedHistoryDate, value);

  const inspectedCarHistory = report?.inspection_history?.map((inspection) => ({
    label: inspection.car_plate,
    value: inspection.car_plate,
  }));

  inspectedCarHistory.unshift({
    label: "New Car",
    value: "",
  });

  const todayFormat2 = moment(today).format("DD-MM-YYYY");
  const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1);
  nextMonth.setDate(31);
  const oneWeekLater = new Date(today.getTime() + 8 * 24 * 60 * 60 * 1000);
  const tileDisabled = ({ date }) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date <= today || date.getDay() === 0;
  };
  const minDate = new Date(oneDayAfter) //oneDayAfter - dev
  const maxDate = new Date(oneWeekLater);
  const [data, setData] = useState({
    start_date: "",
    car_plate: "",
    model: ""
  });

  const useSchema = Yup.object({
    start_date: Yup.string()
      .required("Required")
      .test((values, ctx) => { 
        if (moment(values).valueOf() <= moment(new Date()).valueOf()) {
          // return true;
          return ctx.createError({
            message: `Sorry, ${values} not longer available.`,
          });
        } else {
          return true;
        }
      }
      ),
    car_plate_choose: Yup.string()
    .test({
      name: 'car-plate-choose-required',
      test: function(value) {

        if (value == "") {
          return this.createError({
            message: 'Required',
            path: 'car_plate_choose',
          });
        }

        return true;
      }
    })
    .nullable(),
    car_plate: Yup.string()
    .test({
      name: 'model-required',
      test: function(value) {
        const newCar = this.resolve(Yup.ref('car_plate_choose'));

        const isInspectedCar = inspectedCarHistory.some(
          (inspectedCar) => inspectedCar.value === value
        );

        if (newCar == "new" && isInspectedCar) {
          return this.createError({
            message: <p>This car plate number is already { width > 770 && <br /> } recorded in our system.</p>,
            path: 'car_plate',
          });
        } else if (newCar == "new") {
            if(!value) {
              return this.createError({
                message: 'Car plate is required',
                path: 'car_plate',
              });
            }
            else {
              return true;
            }
        }

        return true;
      }
    })
    .nullable(),
    model: Yup.string()
    .test({
      name: 'model-required',
      test: function(value) {
        const newCar = this.resolve(Yup.ref('car_plate_choose'));

        if (newCar == "new") {
          if(!value) {
            return this.createError({
              message: 'Car model is required',
              path: 'model',
            });
          }
          else {
            return true;
          }
        }
        
        return true;
      }
    })
    .nullable(),
  });
  
  const renderInputTime = (values, setFieldValue, errors) => {
    return (
      <article>
        <section className="picker-time">
          <p for="startTime" className="visually-hidden">Start Time</p>
            <Select
              isDisabled={booking}
              value={submitted ? '' : selectedHistoryTimeValue}
              name="start_date"
              placeholder="Start Time"
              id="startTime"
              onChange={(e) => {
                setSubmitted(false)
                const date = moment(selectedHistoryDate).format("YYYY-MM-DD") + " " + e.label;
                setFieldValue("start_date", date);
                setSelectedHistoryTime(e.label)
                setSelectedHistoryTimeValue(e)
              }}
              options={timeOption}
              isOptionDisabled={(option) => option.isdisabled}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                control: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isDisabled ? '#f0f0f0' : provided.backgroundColor,
                }),
              }}
            />
          {errors.start_date && !submitted ? (
            <p
              className="form-group _errors"
            >
              {errors.start_date}
            </p>
          ) : null}
        </section>
      </article>
    );
  };

  const renderInputCarPlate = (values, setFieldValue, errors, setFieldError) => {

    return (
      <article>
        <section className="picker-time car_plate">
          <p for="myCar" className="visually-hidden">My Car</p>
          <Select
            value={submitted ? '' : selectedCarChooseValue}
            name="car_plate_choose"
            placeholder="My Car"
            id="myCar"
            onChange={(e) => {
              setSubmitted(false)
              setSelectedCarChooseValue(e)

              if(e.label === "New Car") {
                setNewCar(true)
                setFieldValue("car_plate_choose", "new");
                setFieldValue("car_plate", "");
              } else {
                setNewCar(false)
                setFieldValue("car_plate_choose", e.value);
                setFieldValue("car_plate", e.value);
              }
            }}
            options={inspectedCarHistory}
            isOptionDisabled={(option) => option.isdisabled}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
          />
          {errors.car_plate_choose ? (
            <p className="form-group _errors">{errors.car_plate_choose}</p>
          ) : null}
        </section>
      </article>
    );
  };

  const handleSubmit = (values, setFieldError, setFieldValue, resetForm) => {
    const start_date = moment(selectedHistoryDate).format("YYYY-MM-DD") + " " + selectedHistoryTime;

    setBooking(true)
    dispatch(
      bookingWorkshop({
        start_date: start_date,
        car_plate: values.car_plate,
        model: values.model,
      })
    )
      .unwrap()
      .then((res) => {
        setNewCar(false)
        setSubmitted(true)
        setSelectedCarChooseValue('')
        resetForm(); 

        dispatch(getCarReportHistory());
        dispatch(calendar())
        .unwrap()
        .then((res) => {
          setBooking(false)
          toast.success("Booking Submit.", {
            autoClose: 3000,
            theme: "light",
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
          });
        });
      })
      .catch((ex) => {
        setBooking(false)
        if (ex && ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              if (errors[item][0] === 'Selected date is fully booked. Please select other time slot.' 
              || errors[item][0] === 'This car plate no is already submitted.') {
                toast.error(`${errors[item]}`, {
                  autoClose: 3000,
                  theme: "light",
                  hideProgressBar: true,
                  closeOnClick: true,
                  closeButton: false,
                })
                dispatch(calendar())
                  .unwrap()
                  .then((res) => {
                    dispatch(setDisableMakeBooking(false))
                  })
              } else {
                setFieldError(item, errors[item]);
              }
            });
          }
      }});
  };

  return (
    <article className="calendar-card-container">
      <header className="row justify-content-center">
        <h1 className="banner-title mt-2">Book a Date & Drop-off</h1>
        <h1 className="banner-title mt-3">Time For Your Car Inspection</h1>
        <section className="row booking-history-row">
          {result.map((item, index) => {
            const itemDate = moment(item.formatDate, "DD-MM-YYYY");
            const today = moment(todayFormat2, "DD-MM-YYYY");
            const oneWeek = moment(today).add(8, "days");
          
            const isExpired = itemDate.isBefore(today) || itemDate.isSame(today) || itemDate.isAfter(oneWeek);

            return (
              <h5
                className={`_label ${isExpired ? "--expired" : "--active"}`}
                key={index}
                onClick={() => {
                  const itemDate = moment(item.formatDate, "DD-MM-YYYY");
                  if (!isExpired &&
                    itemDate.isAfter(moment(todayFormat2, "DD-MM-YYYY"))
                    ) {
                      setSelectedHistoryDate(item.value);
                    }
                  }}
                  >
                {item.label} {item.history && <span>•</span>}
              </h5>
            )
          })}
        </section>
      </header>
      <section className="row calendar-box-container">
        <Formik
          enableReinitialize={true}
          validateOnChange={true}
          initialValues={data}
          validationSchema={useSchema}
          onSubmit={(values, { setSubmitting, setFieldError, errors, setFieldValue, resetForm }) => {
            if (token) {
              handleSubmit(values, setFieldError, errors, setFieldValue);
              resetForm({ values: data });
            } else {
              dispatch(setShowLoginModal(true));
            }
          }}
        >
          {({
            values,
            touched,
            errors,
            resetForm,
            setFieldValue,
            setFieldError,
            isSubmiting,
          }) => (
            <Form>
              <CalendarPicker
                onChange={(e) => {
                  onChange(e)
                  setSelectedHistoryDate(e)
                }}
                defaultActiveStartDate={minDate}
                value={selectedHistoryDate}
                minDate={minDate}
                maxDate={maxDate}
                tileDisabled={tileDisabled}
              />
              <article className="mt-4 booking-input-row">
                {token &&
                  <section className="p-0 mb-3">
                    {notifications?.length > 0 && notifications.map((item, itemIndex) => (
                      <section className="row notice-booking" key={itemIndex}>
                        {moment(item.booked_at).format("YYYY-MM-DD") && (
                          <article>
                            <p
                              className={`_label --${
                                moment(selectedHistoryDate).valueOf() <
                                moment(new Date()).valueOf()
                                  ? moment(selectedHistoryDate).format("DD MMMM") ===
                                    moment(new Date()).format("DD MMMM")
                                    ? "active"
                                    : "expired"
                                  : "active"
                              }`}
                            >
                              <Icon
                                icon="icon-park-outline:volume-notice"
                                color={
                                  moment(selectedHistoryDate).valueOf() <
                                  moment(new Date()).valueOf()
                                    ? moment(selectedHistoryDate).format("DD MMMM") ===
                                      moment(new Date()).format("DD MMMM")
                                      ? "#5C5C5C"
                                      : "#a1a1a1"
                                    : "#5C5C5C"
                                }
                              />
                              Car plate {item.car_plate} booking at{" "}
                              {moment(item.booked_at).format("DD MMM YYYY h:mm A")} is {item.status}.
                            </p>
                          </article>
                        )}
                      </section>
                    ))}
                  </section>
                }
                <section className="row m-0 d-flex align-items-center flex-wrap">
                  <article className={`col-${width >= 550 ? 4 : 6}`}>
                    <section>
                      {renderInputTime(values, setFieldValue, errors)}
                    </section>
                    {newCar &&
                      <section style={{ marginTop: (errors.start_date && !submitted) || errors.car_plate_choose ? '1.2em' : '0.6em', marginBottom: errors.model || errors.car_plate ? '1.2em' : '0.6em' }}>
                        <Field 
                          name="model"
                          className="booking-input"
                          placeholder="Car Model"
                          // onChange={(e) => {
                          //   form.setFieldValue('model', e.target.value)

                          //   if(e.target.value == "") {
                          //     form.setFieldError('model', '')
                          //   }
                          // }}
                        />
                        {errors.model ? (
                          <p className="form-group _errors">{errors.model}</p>
                        ) : null}
                      </section>
                    }
                  </article>
                  <article className={`col-${width >= 550 ? 4 : 6}`}>
                    <section>
                      {renderInputCarPlate(values, setFieldValue, errors, setFieldError)}
                    </section>
                    {newCar &&
                      <section style={{ marginTop: (errors.start_date && !submitted) || errors.car_plate_choose ? '1.2em' : '0.6em', marginBottom: errors.car_plate || errors.model ? '1.2em' : '0.6em' }}>
                        <Field 
                          name="car_plate"
                          className="booking-input"
                          placeholder="Car Plate"
                        />
                        {errors.car_plate ? (
                          <p className="form-group _errors" style={{ maxWidth: width < 770 ? '12em' : '' }}>{errors.car_plate}</p>
                        ) : null}
                      </section>
                    }
                  </article>
                  {width > 550 &&
                    <>
                      <article className={`col-${width >= 550 ? 4 : 6}`}>
                        <button
                          disabled={token && (disableMakeBooking || booking)}
                          className="button-group --notification-button --view-post"
                          style={{ fontFamily: "Inter", fontSize: "1.6rem", width: "100%" }}
                          onClick={() => {
                            if (!token) {
                              dispatch(setShowLoginModal(true));
                            }
                          }}
                        >
                          Booking
                          {booking && 
                            <Icon
                              icon="line-md:loading-twotone-loop"
                              width={20}
                              height={20}
                              color="#fafafa"
                              className="ms-3"
                            />
                          }
                        </button>
                      </article>
                    </>
                  }
                </section>
                <section className={`d-flex align-items-center justify-content-center ${(errors.start_date && !submitted) || errors.car_plate ? 'mt-5' : ''}`}>
                  <p className="_label --calander-detail text-center">
                    We will be in touch with you after this to confirm the dates and time.
                  </p>
                </section>
              </article>
              {width < 550 &&
                <article>
                  <section className="d-flex align-items-center justify-content-center m-4">
                    <button
                      disabled={token && (disableMakeBooking || booking)}
                      className="button-group --notification-button --view-post"
                      style={{ fontFamily: "Inter", fontSize: "1.6rem" }}
                      onClick={() => {
                        if (!token) {
                          dispatch(setShowLoginModal(true));
                        }
                      }}
                    >
                      Booking
                      {booking && 
                        <Icon
                          icon="line-md:loading-twotone-loop"
                          width={20}
                          height={20}
                          color="#fafafa"
                          className="ms-3"
                        />
                      }
                    </button>
                  </section>
                </article>
              }
            </Form>
          )}
        </Formik>
      </section>
    </article>
  );
}
