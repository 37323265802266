import { useCallback, useEffect, useState } from "react";

//lib
import Lottie from "lottie-react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { useFormikContext } from "formik";
import { LongPressEventType, useLongPress } from "use-long-press";
import { MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

//redux
import {
  likeAction,
  setCommentStatus,
  setProfileModal,
  updateComment,
} from "../../../redux/reducer/forumReducer";
import { useDispatch, useSelector } from "react-redux";
import { setShowLoginModal } from "../../../redux/reducer/settingReducer";

//assets
import { DefaultAvatar } from "../../../assets/images";
import Like from "./../../../assets/json/like-graphic.json";

//helper
import formatNumber from "../../../helper/formatnumber.helper";

export default function CommentCard({
  handleSetting,
  layer_one,
  post_id,
  type,
  item,
  setPlaceholder,
  getCommentListing,
}) {
  const dispatch = useDispatch();
  const { setFieldValue } = useFormikContext();
  const token = localStorage.getItem("token");
  const editSelectedId = useSelector((state) => state.forum.editCommentStatus);
  const [pressStart, setPressStart] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [longPressed, setLongPressed] = useState(false);
  const [editValue, setEditValue] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [likeEffect, setLikeEffect] = useState(false);

  const callback = useCallback(() => {
    setLongPressed(true);
  }, []);

  const bind = useLongPress(enabled ? callback : null, {
    onStart: (event, meta) => {
      if (token && !editSelectedId) {
        setPressStart(true);
      }
    },
    onFinish: (event, meta) => {
      if (token && !editSelectedId) {
        setPressStart(false);
        setLongPressed(false);
      }
    },
    onCancel: (event, meta) => {
      if (token && !editSelectedId) {
        setPressStart(false);
      }
    },
    filterEvents: (event) => true, // All events can potentially trigger long press
    threshold: 500,
    captureEvent: true,
    cancelOnMovement: false,
    cancelOutsideElement: true,
    detect: LongPressEventType.Pointer,
  });
  const handlers = bind("test context");

  const renderTimes = () => {
    const dateStr = item.created_at;
    const [day, month, year, hours, minutes, seconds] = dateStr.split(/[/: ]/g);

    const givenDate = new Date(year, month - 1, day, hours, minutes, seconds);
    const currentDate = new Date();

    const timeDiff = currentDate.getTime() - givenDate.getTime();

    const minutesDiff = Math.floor(timeDiff / (1000 * 60));
    const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    if (daysDiff > 0) {
      return daysDiff + "d";
    } else if (hoursDiff > 0) {
      return hoursDiff + "h";
    } else if (minutesDiff > 0) {
      return minutesDiff + "m";
    } else {
      return 1 + "m";
    }
  };

  const handleCancel = () => {
    dispatch(setCommentStatus(false));
    setEditValue(null);
    setErrorMsg(null);
  };

  const handleSubmit = () => {
    dispatch(updateComment({ id: editSelectedId, content: editValue }))
      .unwrap()
      .then((res) => {
        getCommentListing();
        handleCancel();
        toast.success("Update Comment Successful.", {
          autoClose: 3000,
          theme: "light",
          hideProgressBar: true,
          closeOnClick: true,
          closeButton: false,
        });
      })
      .catch((ex) => {
        const errors = ex.response.data.errors.content;
        setErrorMsg(errors);
      });
  };

  useEffect(() => {
    if (longPressed && token && !editSelectedId) {
      handleSetting(item.id, "comment", item.username, item.isOwner);
    }
  }, [longPressed]);

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      handleCancel();
    };
  }, []);

  return (
    <section className={type === "two" ? "comment-row" : ""}>
      <article className="d-flex gap-3">
        <header>
          <LazyLoadImage
            style={{
              width: "30px",
              height: "30px",
              padding: 0,
              borderRadius: "50%",
            }}
            alt="reunion-avatar"
            src={item.avatar ? item.avatar : DefaultAvatar}
            onClick={() => {
              dispatch(
                setProfileModal({ data: "profile-modal", id: item.user_id })
              );
            }}
          />
        </header>
        <section
          className={`layout-${type}-comment-container w-100`}
          {...handlers}
          style={{
            background: pressStart ? "#cdd6db57" : "",
            cursor:
              token && !editSelectedId
                ? pressStart
                  ? "grabbing"
                  : "grab"
                : "",
          }}
        >
          {editSelectedId === item.id ? (
            <article>
              <section style={{ position: "relative" }}>
                <textarea
                  className="w-100 edit-content-comment mb-3"
                  value={
                    typeof editValue === "string" ? editValue : item.content
                  }
                  onChange={(e) => {
                    setErrorMsg(null);
                    setEditValue(e.target.value);
                  }}
                />
                <p
                  style={{
                    fontSize: "1rem",
                    color: "#EA3943",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                  }}
                >
                  {errorMsg}
                </p>
              </section>
              <footer className="row m-0 align-items-center d-flex">
                <section className="col p-0 align-items-center d-flex">
                  <MDBBtn
                    type="button"
                    className="--cancel-comment"
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </MDBBtn>
                </section>
                <section className="col p-0 justify-content-end align-items-center d-flex">
                  <MDBBtn
                    type="button"
                    className="--save-comment"
                    onClick={() => handleSubmit()}
                  >
                    Save
                  </MDBBtn>
                </section>
              </footer>
            </article>
          ) : (
            <article className="member-detail">
              <header className="d-flex gap-1">
                <h4
                  className="_label --member-detail-text"
                  style={{
                    cursor:
                      token && !editSelectedId
                        ? pressStart
                          ? "grabbing"
                          : "grab"
                        : "",
                  }}
                >
                  {item.username}
                </h4>
                {item.occupation && (
                  <p
                    style={{
                      cursor:
                        token && !editSelectedId
                          ? pressStart
                            ? "grabbing"
                            : "grab"
                          : "",
                    }}
                    className="_label --member-detail-text"
                  >
                    •
                  </p>
                )}
                {item.occupation && (
                  <p
                    style={{
                      cursor:
                        token && !editSelectedId
                          ? pressStart
                            ? "grabbing"
                            : "grab"
                          : "",
                    }}
                    className="_label --member-detail-text --occupation"
                  >
                    IT Programmer
                  </p>
                )}
                {item.car_model && (
                  <p className="_label --member-detail-text">•</p>
                )}
                {item.car_model && (
                  <p className="_label --member-detail-text --model">
                    Beetle 1.2
                  </p>
                )}
              </header>
              <p
                style={{
                  cursor:
                    token && !editSelectedId
                      ? pressStart
                        ? "grabbing"
                        : "grab"
                      : "",
                }}
                className="_label --comment-content break-all mb-1"
              >
                {item.content}
              </p>
            </article>
          )}
        </section>
      </article>
      <article className="row comment-action-row">
        <section
          className="col-10 comment-action-columns"
          style={type === "two" ? { padding: 0 } : {}}
        >
          <p>{renderTimes()}</p>
          {item.can_like && (
            <section style={{ position: "relative" }}>
              {likeEffect && 
                <article className="loading-css --like">
                  <article className="loading-animation --like" style={{ width: '10em' }}>
                    <Lottie animationData={Like} style={{ width: '100%' }}/>
                  </article>   
                </article>
              }
              <p
                style={{
                  cursor: "pointer",
                  fontWeight: 500,
                  fontSize: "12px",
                  color: item.check_comment_if_like || likeEffect ? "#F44336" : "",
                }}
                onClick={() => {
                  if (token) {
                    if (item.can_like && !item.check_comment_if_like) {
                      setLikeEffect(true)
                      dispatch(
                        likeAction({
                          type: "comment",
                          data: {
                            post_id: post_id,
                            comment_id: item.id,
                            user_id: item.user_id,
                          },
                        })
                      ).then((res) => {
                        setTimeout(() => {
                          setLikeEffect(false);
                        }, 2000);
                        getCommentListing();
                      });
                    }
                  } else {
                    dispatch(setShowLoginModal(true));
                  }
                }}
              >
                Like
              </p>
            </section>
          )}
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              setFieldValue("content", `@${item.username} `);
              setFieldValue("user_id", item.user_id);
              setFieldValue("comment_id", layer_one);
              setPlaceholder(`Reply to ${item.username}`);
            }}
          >
            Reply
          </p>
          <p>Get {formatNumber(item.point)} Point</p>
        </section>
        {item.like_count > 0 && (
          <section className="col-2 align-items-center justify-content-end d-flex gap-1">
            <p>{formatNumber(item.like_count)}</p>
            <Icon icon="flat-color-icons:like" color="#F44336" width={10} />
          </section>
        )}
      </article>
    </section>
  );
}
