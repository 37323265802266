import { useRef, useState, useEffect } from "react";

//lib
import Lottie from "lottie-react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { MDBContainer, MDBModalBody, MDBRow, MDBCol } from "mdb-react-ui-kit";

//assets
import ViewDown from "../../../assets/json/view-down.json";

//component
import ModalBox from "../../../components/modal/full.modal.box";
import InfoHeader from "../../../components/header/info-header";

//hook
import useWindowDimensions from "../../../components/common/window-dimension";

//redux
import { useDispatch, useSelector } from "react-redux";
import { setAnimationModal } from "../../../redux/reducer/commonReducer";
import { reportInfo, setAttachmentInfo } from "../../../redux/reducer/carReducer";
import { closeModalCarReport, openModalAttachment } from "../../../redux/reducer/modalReducer";

export default function ReportListing() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const params = useParams();
  const lottieRef = useRef();

  const { timeline_data } = useSelector((state) => state.car.carReportInfo);
  const [openCard, setOpenCard] = useState([]);

  useEffect(() => {
    dispatch(reportInfo({ booking_id: params.id }));
  }, []);

  // const copyText = (text) => {
  //   navigator.clipboard.writeText(text);
  //   toast(
  //     <div>
  //       <MDBRow className="m-0">
  //         <MDBCol className="text-start">
  //           <label className="_label">Link copied!</label>
  //         </MDBCol>
  //       </MDBRow>
  //     </div>,
  //     {
  //       position: "top-right",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       theme: "light",
  //     }
  //   );
  // };

  const toggleCard = (cardId) => {
    if (openCard.includes(cardId)) {
      setOpenCard(openCard.filter(id => id !== cardId));
    } else {
      setOpenCard([...openCard, cardId]);
    }
  };

  const handleOpenAttachment = (item) => {
    dispatch(openModalAttachment())
    dispatch(setAnimationModal(false))
    dispatch(setAttachmentInfo(item))
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
  
    const day = date.getDate().toString().padStart(2, '0'); // Ensure two-digit day
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ensure two-digit month (0-based index)
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  }

  return (
    <ModalBox
      className="report-container-modal"
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      centered
      show={true}
      backButton={() => {
        dispatch(closeModalCarReport())
        navigate('/car');
      }}
      content={
        <article>
          <InfoHeader
            title="Report"
            backButton={() => {
              dispatch(closeModalCarReport())
              navigate('/car');
            }}
          />
          <MDBModalBody className="p-0">
            <section
              className="container"
              style={{
                paddingTop: width >= 991 ? "1em" : "6em",
                paddingLeft: width >= 991 ? "2em" : "1em",
                paddingRight: width >= 991 ? "2em" : "1em",
              }}
            >
              <article>
                <header style={{ marginBottom: "0.5em" }}>
                  <p className="d-flex">
                    <section className="blue-tag"></section>The Report is generate by a
                    professional maintenance tool.
                  </p>
                </header>
                {timeline_data && Object.keys(timeline_data).map((no, timelineIndex) => (
                  <section style={{ cursor: "pointer" }} className="mb-2 report-box" key={no} onClick={() => toggleCard(no)}>
                    <article className="mb-3">
                      <header style={{ cursor: "pointer" }} className={`mb-2 mt-3`}>
                        Booking No: {no}
                      </header>
                      <section 
                        className={openCard.includes(no) ? "card-display active" : "card-display"}
                        style={{ height: openCard.includes(no) ? 'auto' : 0 }}
                      > 
                      {Object.keys(timeline_data[no]).map((event, j) => (
                        timeline_data[no][event]
                        .filter((item, i) => !(event === "attachment" && i !== 0))
                        .map((item, i) => {
                          return (
                              event === "attachment" && i !== 0 ? 
                              '' :
                              <>
                                <article className={`${j === 0 ? 'mt-4' : 'mt-2'} timeline-wrapper`} >
                                  <section className="timeline-update-at me-4">
                                    <p className='' style={{ fontSize: '0.8em' }}>
                                        {event === "report" ? item.updated_at : formatDate(item.updated_at)}
                                    </p>
                                  </section> 
                                  <section className="timeline-items">
                                    <article className="d-flex mt-1">
                                      <section className="timeline-dot" />
                                    </article>
                                    <article className="timeline-connect" />
                                  </section>
                                  <section className="timeline-content">
                                    <h4 className='timeline-label'>
                                        {event === 'report' ? `Diagnose Report: ${item.diagnose_report}` : 'Report Attachment'}
                                    </h4>
                                    {event === 'report' ? (
                                        <a href={item.report_url} className='flex items-center' target="_blank" rel="noopener noreferrer">
                                            <p className='timeline-link'>Report URL</p> 
                                            <p className=''></p>
                                        </a>
                                    ) :
                                        <article className="flex items-center mb-1" 
                                          onClick={() => handleOpenAttachment({ booking_no: no, data: timeline_data[no]['attachment'] })}
                                        >
                                        <p className='timeline-link underline'>Click to view more</p> 
                                        </article>
                                    }
                                  </section>
                                </article>
                          </>
                          )
                      })))}
                      </section>
                      <footer className={openCard.includes(no) ? "open-btn --opened pointer" : "open-btn pointer"}  onClick={() => toggleCard(no)}>
                        <div>
                          <Lottie lottieRef={lottieRef} animationData={ViewDown} style={{ width: '2.5em' }} className={openCard.includes(no) ? "rotated-animation" : "normal-animation"} />
                        </div>
                      </footer>
                    </article>
                  </section>
                ))}
              </article>
            </section>
          </MDBModalBody>
        </article>
      }
    />
  );
}
