import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "../../services/http.services";
import apiServices from "../../services/api.services";

export const getUserAddressInfo = createAsyncThunk('getUserAddressInfo', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.getUserAddressInfo(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const addToCart = createAsyncThunk('addToCart', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.addToCart(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const updateCart = createAsyncThunk('updateCart', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.updateCart(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const deleteCart = createAsyncThunk('deleteCart', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.deleteCart(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const getCartData = createAsyncThunk('getCartData', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.getCartData(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const getUserBanks = createAsyncThunk('getUserBanks', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.getUserBanks(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const initialData = {
  carts: [],
  paymentTab: [
    {label: 'Currency', value: 'currency'},
    {label: 'Point', value: 'point'},
  ],
  shippingMethod: 'Delivery',
  shippingInfo: {},
  isEditedInfo: true,
  userAddressInfo: {},
  paymentMethod: {},
  openPaymentModal: false,
  bankListing: [],
  addCartQuantity: {},
  activeTab: 'currency',

  add_cart_loading: false,
  cart_loading: false,
  edit_loading: false,
};

const dataSlice = createSlice({
  name: "cart",
  initialState: initialData,
  reducers: {
    setCarts: (state,action) => {
      state.carts = action.payload
    },
    setShippingMethod: (state,action) => {
      state.shippingMethod = action.payload
    },
    setShippingInfo: (state,action) => {
      state.shippingInfo = action.payload
    },
    setIsEditedInfo: (state,action) => {
      state.isEditedInfo = action.payload
    },
    setPaymentMethod: (state,action) => {
      state.paymentMethod = action.payload
    },
    setOpenPaymentModal: (state,action) => {
      state.openPaymentModal = action.payload
    },
    setAddCartQuantity: (state,action) => {
      state.addCartQuantity = action.payload
    },
    setActiveTab: (state,action) => {
      state.activeTab = action.payload
    },
    setEditLoading: (state,action) => {
      state.edit_loading = action.payload
    },
  },
  extraReducers: {
    [getUserAddressInfo.pending]: (state, action) => {
      state.loading = true
    },
    [getUserAddressInfo.fulfilled]: (state, action) => {
        state.loading = false
        state.shippingInfo = state.shippingInfo.name ? state.shippingInfo : action.payload.data
    },
    [addToCart.pending]: (state, action) => {
      state.add_cart_loading = true
    },
    [addToCart.fulfilled]: (state, action) => {
      state.add_cart_loading = false
    },
    [addToCart.rejected]: (state, action) => {
      state.add_cart_loading = false
    },
    [updateCart.pending]: (state, action) => {
      state.edit_loading = true
    },
    [updateCart.fulfilled]: (state, action) => {
      state.loading = false
    },
    [deleteCart.pending]: (state, action) => {
      state.loading = true
    },
    [deleteCart.fulfilled]: (state, action) => {
      state.loading = false
    },
    [getCartData.pending]: (state, action) => {
      state.cart_loading = true
    },
    [getCartData.fulfilled]: (state, action) => {
      state.cart_loading = false
      state.edit_loading = false
      state.carts = action.payload.data.aaData
    },
    [getUserBanks.pending]: (state, action) => {
      state.loading = true
    },
    [getUserBanks.fulfilled]: (state, action) => {
      state.loading = false
      state.bankListing = action.payload.data
    },
  },
});

export const { setCarts, setShippingMethod, setShippingInfo, setIsEditedInfo, setPaymentMethod, setOpenPaymentModal, setAddCartQuantity, setActiveTab, setEditLoading } = dataSlice.actions;

export default dataSlice.reducer;

