//lib
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { MDBBtn } from "mdb-react-ui-kit";

//component
import Text from "../../../components/element/text";

//reducer
import { useDispatch, useSelector } from "react-redux";
import { setIsEditedInfo, setShippingInfo } from "../../../redux/reducer/cartReducer";
import { setSelectedSection } from "../../../redux/reducer/stockReducer";
import useWindowDimensions from "../../../components/common/window-dimension";

export default function ContactInfo() {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { shippingInfo } = useSelector((state) => state.cart);

  const initialValues = {
    // name: shippingInfo.length > 0 ? shippingInfo[0] !== null ?
    //   shippingInfo[0].name : shippingInfo ? shippingInfo.name : '' : '',
    // contact: shippingInfo.length > 0 ? shippingInfo[0] !== null ?
    //   shippingInfo[0].contact : shippingInfo ? shippingInfo.contact : '' : '',
    // address: shippingInfo.length > 0 ? shippingInfo[0] !== null ?
    //   shippingInfo[0].address : shippingInfo ? shippingInfo.address : '' : '',
    // postcode: shippingInfo.length > 0 ? shippingInfo[0] !== null ?
    //   shippingInfo[0].postcode : shippingInfo ? shippingInfo.postcode : '' : '',
    // city: shippingInfo.length > 0 ? shippingInfo[0] !== null ?
    //   shippingInfo[0].city : shippingInfo ? shippingInfo.city : '' : '',
    name: shippingInfo ? shippingInfo.name : '',
    contact: shippingInfo ? shippingInfo.contact : '',
    address: shippingInfo ? shippingInfo.address : '',
    postcode: shippingInfo ? shippingInfo.postcode : '',
    city: shippingInfo ? shippingInfo.city : '',
  };

  
  const useSchema = Yup.object({
    name: Yup.string().required("First name is required"),
    contact: Yup.string()
    .required("Phone number is required")
    .matches(
      /^(01)[02-9]-*[0-9]{7}$|^(01)[1]-*[0-9]{8}$/g,
      "Invalid phone number format"
      ),
      address: Yup.string().required("Address is required"),
      postcode: Yup.string().required("Postcode is required"),
      city: Yup.string().required("City is required"),
  });
    
  const handleSubmit = ({ values }) => {
    dispatch(setShippingInfo(values));
    dispatch(setIsEditedInfo(false));
    dispatch(setSelectedSection('quantity'));
  };

  return (
    <article className={width < 991 ? "mt-4" : ""}>
      <Formik
        initialValues={initialValues}
        validationSchema={useSchema}
        enableReinitialize={true}
        validateOnChange={true}
        onSubmit={(values, { resetForm, setFieldError }) => {
          handleSubmit({ values });
        }}
      >
        {({
          setFieldValue,
          isSubmitting,
          handleReset,
          isValid,
          errors,
          values,
          handleSubmit,
        }) => (
          <Form>
            <section
              className="shipping-info"
              style={{ height: "auto" }}
            >
              <Text
                name="name"
                placeholder="First name"
                className="contact-input"
              />
              <Text
                name="contact"
                placeholder="Contact"
                className="contact-input"
              />
              <Text
                name="address"
                placeholder="Address"
                className="contact-input"
              />
              <Text
                name="postcode"
                placeholder="Postcode"
                className="contact-input"
              />
              <Text name="city" placeholder="City" className="contact-input" />
              <section className="d-flex justify-content-center" style={{ borderTop: width > 991 ? '0.1em solid #f1f1f1' : '', marginTop: width > 991 ? '4em' : '1em' }}>
                <MDBBtn
                  disabled={isValid ? false : true}
                  type="submit"
                  className="save-btn"
                >
                  Save
                </MDBBtn>
              </section>
            </section>
          </Form>
        )}
      </Formik>
    </article>
  );
}
