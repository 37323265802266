import { useState } from "react";

//lib
import { MDBBtn, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

//assets
import WazeIcon from "../../../assets/images/icon/icon-waze.webp";
import WhatsAppIcon from "../../../assets/images/icon/icon-whatsapp.webp";

export default function WorkshopCard({ item }) {
  const [state, setState] = useState(false);

  const imageUrl = item.display_image && item.display_image.length > 0 ?  item.display_image[0].url : ""

  const openDate = item.open_date + " - " + item.close_date 
  const openHours = item.open_time + " - " + item.close_time 
  const result = item.expertise && item.expertise.length > 0 ?  item.expertise  : [] 
  const result2 = result.join(", ")

  const openWazeApp = (destination) => {
    const wazeAppUrl = `waze://?q=${encodeURIComponent(destination)}`;
    const wazeWebUrl = `https://www.waze.com/en-GB/livemap?text=${encodeURIComponent(destination)}`;
  
    window.location.href = wazeAppUrl;
  
    setTimeout(() => {
      window.location.href = wazeWebUrl;
    }, 1000);
  };

  const openPhone = () => {
    const phoneNumber = item.partner_contact ? item.partner_contact : ''
    window.location.href = `tel:${phoneNumber}`
  }

  return (
    <article
      onClick={() => {
        setState(!state);
      }}
      className="workshop-container"
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <MDBBtn
        className="workshop-button"
        onClick={() => {
          setState(true);
        }}
      >
        <h4>{item.workshop_name}</h4>
      </MDBBtn>
      <section className={`workshop-box --${state ? "active pt-5" : "hide pt-5"}`}>
        <h4 className="title">{item.workshop_name}</h4>
        <article className="container">
          <section className="row mt-2 mb-3">
            <p className="label">Opening Hours</p>
            <p className="value">{openDate + " " + openHours}</p>
          </section>
          <section className="row mt-2 mb-2">
            <p className="label">Expertise</p>
            <p className="value">{result2}</p>
          </section>
          <section className="row mt-3">
            <LazyLoadImage src={WazeIcon} alt={item.waze_location} onClick={() => openWazeApp(item.waze_location)}/>
            <LazyLoadImage src={WhatsAppIcon} alt="workshop-whatspp" onClick={() => openPhone()}/>
          </section>
        </article>
      </section>
    </article>
  );
}
