import { useState } from "react";

//lib
import moment from "moment";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { MDBModalBody, MDBRow, MDBCol } from "mdb-react-ui-kit";

//hook
import useWindowDimensions from "../../../components/common/window-dimension";

//redux
import { useDispatch, useSelector } from "react-redux";
import { setAnimationModal } from "../../../redux/reducer/commonReducer";
import {
  closeModalPurchaseInfo,
  openModalOrderHistory,
} from "../../../redux/reducer/modalReducer";

//component
import ModalBox from "../../../components/modal/full.modal.box";
import InfoHeader from "../../../components/header/info-header";

export default function PurchaseInfoModal() {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { isOpenPurchaseInfo } = useSelector((state) => state.modal);

  const { purchaseInfo } = useSelector((state) => state.profile);
  const { statusIcon } = useSelector((state) => state.payment);

  const [toastShown, setToastShown] = useState(false);

  const backButton = () => {
    dispatch(openModalOrderHistory());
    dispatch(closeModalPurchaseInfo());
    dispatch(setAnimationModal(false));
  };

  const copyText = (text) => {
    const textField = document.createElement("textarea");
    textField.value = text;
    document.body.appendChild(textField);
    textField.select();

    try {
      const successful = document.execCommand("copy");
      if (!toastShown) {
        setToastShown(true);
        if (successful) {
          toast(
            <section>
              <section className="row m-0">
                <article className="col text-start">
                  <p className="_label">Location copied!</p>
                </article>
              </section>
            </section>,
            {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              closeButton: false,
              onClose: () => setToastShown(false),
            }
          );
        } else {
          toast.error(
            <section>
              <section className="row m-0">
                <article className="col text-start">
                  <p className="_label">
                    Copy failed. Please try again later.
                  </p>
                </article>
              </section>
            </section>,
            {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              closeButton: false,
              onClose: () => setToastShown(false),
            }
          );
        }
      }
    } catch (error) {}

    document.body.removeChild(textField);
  };

  let totalAmount = 0;

  return (
    <ModalBox
      className={"modal-container"}
      show={isOpenPurchaseInfo}
      centered
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      scrollable
      backButton={backButton}
      content={
        <article>
          <InfoHeader
            showBackButton={true}
            title={purchaseInfo.sales_order}
            backButton={backButton}
            backButtonNoAnimation={backButton}
          />
          <MDBModalBody className="p-0">
            <section className={width >= 991 ? "order-div desktop" : "order-div"}>
              <section className="row">
                <Icon
                  icon={statusIcon[purchaseInfo.order_status].icon}
                  color={statusIcon[purchaseInfo.order_status].color}
                  height={"4.8em"}
                  className="mt-3"
                />
              </section>
              <section className="row info-row">
                <h3
                  className="_label --remark mb-3"
                  style={{ color: statusIcon[purchaseInfo.order_status].color }}
                >
                  {statusIcon[purchaseInfo.order_status].text}
                </h3>
              </section>
              <section className="row payment-row">
                <article className="col-6 text-start">
                  <section className="row">
                    <p className="_label --title">Payment Method</p>
                  </section>
                  <section>
                    <h5 className="_label --text">
                      {purchaseInfo.payment_method === "point"
                        ? "Reunion Point"
                        : "Currency"}
                    </h5>
                  </section>
                </article>
                <article className="col-6 text-end">
                  <section className="row">
                    <p className="_label --title">Paid on</p>
                  </section>
                  <section className="row">
                    <h5 className="_label --text">
                      {moment(purchaseInfo.paid_at).format("DD MMM YYYY H:mm")}
                    </h5>
                  </section>
                </article>
              </section>
              <section className="line"></section>

              {purchaseInfo.order_type === "Delivery" && (
                <section>
                  {(purchaseInfo.order_status === "Updated Tracking Info" ||
                    purchaseInfo.order_status === "Completed") && (
                    <section className="row payment-row">
                      <article className="col-1">
                        <section className="row">
                          <p className="_label --title ">
                            <Icon icon="la:shipping-fast" height={"1.4em"} />
                          </p>
                        </section>
                      </article>
                      <article className="col" style={{ paddingLeft: "0em" }}>
                        <section className="row" style={{ alignItems: "center" }}>
                          <article className="col-10">
                            <h5 className="_label --title ">
                              <span>Shipping Information</span>
                            </h5>
                          </article>
                          <article className="col text-end">
                            <p className="_label --detail pointer">
                              {" "}
                              <a
                                href={
                                  purchaseInfo.tracking_detail &&
                                  purchaseInfo.tracking_detail.tracking_no
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "#3DB8ED" }}
                              >
                                {" "}
                                VIEW
                              </a>{" "}
                            </p>
                          </article>
                        </section>
                        <section className="row">
                          <p className="_label --title">
                            Standard Delivery
                          </p>
                        </section>
                      </article>
                    </section>
                  )}
                  <section className="row payment-row">
                    <article className="col-1">
                      <section className="row" style={{ alignItems: "center" }}>
                        <p className="_label --title ">
                          <Icon icon="ion:location-outline" height={"1.4em"} />
                        </p>
                      </section>
                    </article>
                    <article className="col" style={{ paddingLeft: "0em" }}>
                      <section className="row">
                        <article className="col-10">
                          <h5 className="_label --title ">
                            <span>Delivery Address</span>
                          </h5>
                        </article>
                        <article className="col text-end">
                          <p
                            className="_label --detail pointer"
                            onClick={() =>
                              copyText(
                                purchaseInfo.receiver_address &&
                                  purchaseInfo.receiver_address.address +
                                    "," +
                                    purchaseInfo.receiver_address.postcode +
                                    "" +
                                    purchaseInfo.receiver_address.city
                              )
                            }
                          >
                            COPY
                          </p>
                        </article>
                      </section>
                      <section className="row">
                        <p className="_label --title">
                          {purchaseInfo.receiver_address &&
                            purchaseInfo.receiver_address.name}{" "}
                          |{" "}
                          {purchaseInfo.receiver_address &&
                            purchaseInfo.receiver_address.phone_number}
                        </p>
                      </section>
                      <section className="row"> 
                        <p className="_label --title">
                          {purchaseInfo.receiver_address &&
                            purchaseInfo.receiver_address.address}
                          {purchaseInfo.receiver_address && ", "}
                          {purchaseInfo.receiver_address &&
                            purchaseInfo.receiver_address.postcode}
                          {purchaseInfo.receiver_address && " "}
                          {purchaseInfo.receiver_address &&
                            purchaseInfo.receiver_address.city}
                        </p>
                      </section>
                    </article>
                  </section>
                </section>
              )}

              {purchaseInfo.order_type === "Self Pickup" && (
                <section className="row payment-row">
                  <article className="col-1">
                    <section className="row">
                      <p className="_label --title ">
                        <Icon icon="ion:location-outline" height={"1.4em"} />
                      </p>
                    </section>
                  </article>
                  <article className="col" style={{ paddingLeft: "0em" }}>
                    <section className="row" style={{ alignItems: "center" }}>
                      <article className="col-10">
                        {" "}
                        <h5 className="_label --title">
                          <span>Pick Up Address</span>
                        </h5>
                      </article>
                      <article className="col text-end">
                        <p
                          className="_label --detail pointer"
                          onClick={() =>
                            copyText(
                              "Reunion Motorsports Sdn Bhd - No.22G Jalan Ara SD7/3B Bandar Sri Damansara 52200 Kuala Lumpur W.P Kuala Lumpur Malaysia"
                            )
                          }
                        >
                          COPY
                        </p>
                      </article>
                    </section>
                    <section className="row">
                      <p className="_label --title">
                        {purchaseInfo.from && purchaseInfo.from.name} -{" "}
                        {purchaseInfo.from && purchaseInfo.from.address}
                      </p>
                    </section>
                  </article>
                </section>
              )}

              <section className="line"></section>
              {purchaseInfo?.product_detail.length > 0 &&
                purchaseInfo.product_detail.map((info, index) => {
                  totalAmount += parseInt(info.subtotal * info.quantity);
                  return (
                    <section key={index}>
                      <section className="row payment-row">
                        <section className="row">
                          <h4
                            className="_label --title"
                            style={{
                              fontSize: "14px",
                              color: "#3db8eb",
                              padding: "0em 0.5em 0.5em",
                              fontWeight: "500",
                            }}
                          >
                            Order Item #{index + 1}{" "}
                          </h4>
                        </section>
                        <article className="col-6 text-start">
                          <section className="row">
                            <p className="_label --title">
                              Product Name
                            </p>
                          </section>
                          <section className="row">
                            <h5 className="_label --text">{info.name}</h5>
                          </section>
                        </article>
                        <article className="col-6 text-end">
                          <section className="row">
                            <p className="_label --title">Quantity</p>
                          </section>
                          <section className="row">
                            <h5 className="_label --text">
                              x{info.quantity}
                            </h5>
                          </section>
                        </article>
                      </section>
                      <section className="line"></section>
                      <section className="row payment-row">
                        <article className="col-6 text-start">
                          <section className="row">
                            <p className="_label --title">
                              Single Price
                            </p>
                          </section>
                          <section className="row">
                            <h5 className="_label --text">
                              {purchaseInfo.payment_method === "currency"
                                ? "RM "
                                : ""}
                              {parseFloat(info.subtotal).toFixed(2)}
                              {purchaseInfo.payment_method === "point"
                                ? " PTS"
                                : ""}
                            </h5>
                          </section>
                          <section className="mt-3 row">
                            <p className="_label --title">
                              Shipping Subtotal
                            </p>
                          </section>
                          <section className="row">
                            <h5 className="_label --text">
                              {info.shipping_subtotal
                                ? `${
                                    purchaseInfo.payment_method === "currency"
                                      ? "RM "
                                      : ""
                                  } 
                                ${parseFloat(info.shipping_subtotal).toFixed(
                                  2
                                )} 
                                ${
                                  purchaseInfo.payment_method === "point"
                                    ? " PTS"
                                    : ""
                                }`
                                : "Free shipping"}
                            </h5>
                          </section>
                        </article>
                        <article className="col-6 text-end">
                          <section className="row">
                            <p className="_label --title">Subtotal</p>
                          </section>
                          <section className="row">
                            <h5 className="_label --text">
                              {purchaseInfo.payment_method === "currency"
                                ? "RM "
                                : ""}
                              {parseFloat(
                                info.subtotal * info.quantity
                              ).toFixed(2)}{" "}
                              {}
                              {purchaseInfo.payment_method === "point"
                                ? "PTS"
                                : ""}
                            </h5>
                          </section>
                        </article>
                      </section>
                      <section className="line"></section>
                    </section>
                  );
                })}
              <section className="amount-row">
                {purchaseInfo.payment_method === "currency" && (
                  <section className="row mt-2">
                    <article className="col-6 text-start">
                      <p
                        className="_label --total-gained"
                        style={{ fontSize: "1.3em", fontWeight: "400" }}
                      >
                        Total Points Gained
                      </p>
                    </article>
                    <article className="col-6 text-end">
                      <h5
                        className="_label --amount"
                        style={{
                          fontSize: "1.4em",
                          color: "#4f4f4f",
                          fontWeight: "600",
                        }}
                      >
                        {totalAmount} PTS
                      </h5>
                    </article>
                  </section>
                )}
                <section
                  className={
                    purchaseInfo.payment_method === "currency" ? "mt-0 row" : "mt-0 row"
                  }
                  style={{ paddingTop: "0.5em" }}
                >
                  <article className="col-6 text-start">
                    <h2 className="_label --total">Total Paid : </h2>
                  </article>
                  <article className="col-6 text-end">
                    <h2 className="_label --amount">
                      {purchaseInfo.payment_method === "currency" ? "RM " : ""}
                      {purchaseInfo.total.toFixed(2)}{" "}
                      {/* Render the totalAmount */}
                      {purchaseInfo.payment_method === "point" ? " PTS" : ""}
                    </h2>
                  </article>
                </section>
              </section>
              <section className="row contact-row">
                <p>
                  If you have questions, Reach us directly: {""}
                  <a className="_span" href="mailto:dede_tby@yahoo.co.uk">
                    dede_tby@yahoo.co.uk
                  </a>{" "}
                  or call {""}
                  <a className="_span" href="tel:+60123552455">
                    +6012-3552455
                  </a>
                </p>
              </section>
            </section>
          </MDBModalBody>
        </article>
      }
    />
  );
}
