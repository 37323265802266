import { useEffect, useState } from "react";

//lib
import { MDBContainer } from "mdb-react-ui-kit";

//component
import Login from "../Login";
import { CarReport } from "./component/car.report";
import CalendarCard from "./component/calendar.card";
import ReportListing from "./component/report.listing";
import AttachmentModal from "./component/attachment.modal";
import { WorkshopPartner } from "./component/workshop.partner";

//redux
import { useDispatch, useSelector } from "react-redux";
import { calendar, getCarReportHistory , workshopList} from "../../redux/reducer/carReducer";

//helper
import scrollToTop from "../../helper/scrolltotop.helper";
import { Helmet } from "react-helmet";

export default function Car() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const [show, setShow] = useState(null);
  const { showLoginModal } = useSelector((state) => state.setting);
  const { isOpenAttachment, isOpenCarReport } = useSelector((state) => state.modal);

  function updateMetaThemeColor() {
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", "#FFF");
  }

  useEffect(() => {
    scrollToTop();
    updateMetaThemeColor();
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(getCarReportHistory());
      dispatch(calendar());
    }
  }, [token]);

  useEffect(() => {
    dispatch(workshopList())
  }, []);

  return (
    <>
      <Helmet>
        <title>Reunion | Car</title>
        <meta name="description" content="Effortlessly book your car service online at Reunion. Receive transparent diagnostics reports and schedule maintenance with trusted professionals." />
      </Helmet>

      <section className="container booking-car-container">
        <CalendarCard />
        <WorkshopPartner />
        <CarReport setShow={setShow} />
      </section>
      
      {isOpenCarReport && <ReportListing show={show} setShow={setShow} />}
      {showLoginModal && <Login />}
      {isOpenAttachment && <AttachmentModal show={show} setShow={setShow} />}
    </>
  );
}
