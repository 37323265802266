import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "../../services/http.services";
import authServices from "../../services/auth.services";

export const initialData = {
  start: 0,
  length: 12,
  selectedId: null,
  selectedDataInfo: {
    id: "",
    title: "",
    member_type: "",
    user_name: "",
    created_at: "",
    mainMediaUrl: [],
    content: "",
    comment_list: "",
    comment_count: "",
    job_title: "",
    car_model: "",
    share_url: "",
  },
  settingModal: true,
  profileModdal: false,
  commentLoading: false,
  createPostModal: false,
  createPostLoading: false,
  createCommentLoading: false,
  loading: false,
  createCommentData: {
    post_id: "",
    content: "",
    comment_id: ""
  },
  createPostData: {
    topic: "help", // showcase,help,recommendation
    location: "",
    content: "",
    attachment: [],
  },
  topic: [
    {
      id: 1,
      label: "Help",
      name: "help",
    },
    {
      id: 2,
      label: "Show Case",
      name: "show_case",
    },
    {
      id: 3,
      label: "Sharing",
      name: "recommend",
    },
  ],
  postList: [],
  pinnedPost: [],
  eventListing: [],
  notification_list: [],
  editForumPostStatus: false,
  editCommentStatus: false,
  profile_detail: {},
  profile_detail_loading: false,
  post_info_loading: false,
  postListLoading: false,
  commentListLoading: false,
  previewItem: "",
  shareUrl: "",
  isDiscardPost: false
}

export const getForumListing = createAsyncThunk(
  "forum/getForumListing",
  async (payload, thunkAPI) => {
    try {
      httpService.setToken(authServices.getToken());
      const { data } = await httpService.post(
        process.env.REACT_APP_API_URL + `/forum_post/post_listing`,
        payload
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getForumInfo = createAsyncThunk(
  "forum/getForumInfo",
  async (payload, thunkAPI) => {
    try {
      httpService.setToken(authServices.getToken());
      const { data } = await httpService.post(
        process.env.REACT_APP_API_URL + `/forum_post/get_post_user`,
        payload
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateForumInfo = createAsyncThunk(
  "forum/updateForumInfo",
  async (payload, thunkAPI) => {
    try {
      httpService.setToken(authServices.getToken());
      const { data } = await httpService.post(
        process.env.REACT_APP_API_URL + `/forum_post/update_post`,
        payload
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateComment = createAsyncThunk(
  "forum/updateComment",
  async (payload, thunkAPI) => {
    try {
      httpService.setToken(authServices.getToken());
      const { data } = await httpService.put(
        process.env.REACT_APP_API_URL + `/comment_update`,
        payload
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getForumPinnedPost = createAsyncThunk(
  "forum/getForumPinnedPost",
  async (payload, thunkAPI) => {
    try {
      httpService.setToken(authServices.getToken());
      const { data } = await httpService.post(
        process.env.REACT_APP_API_URL + `/forum_post/post_listing`,
        { pinned: "true" }
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createForumPost = createAsyncThunk(
  "forum/createForumPost",
  async (payload, thunkAPI) => {
    try {
      httpService.setToken(authServices.getToken());
      const { data } = await httpService.post(
        process.env.REACT_APP_API_URL + `/forum_post`,
        payload
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getPostComment = createAsyncThunk(
  "forum/getPostComment",
  async (payload, thunkAPI) => {
    try {
      httpService.setToken(authServices.getToken());
      const { data } = await httpService.post(
        process.env.REACT_APP_API_URL + `/comment/comment_listing`,
        payload
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createComment = createAsyncThunk(
  "forum/createComment",
  async (payload, thunkAPI) => {
    try {
      httpService.setToken(authServices.getToken());
      const { data } = await httpService.put(
        process.env.REACT_APP_API_URL + `/comment/1`,
        payload
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const likeAction = createAsyncThunk(
  "forum/likeAction",
  async (payload, thunkAPI) => {
    try {
      httpService.setToken(authServices.getToken());
      const { data } = await httpService.put(
        process.env.REACT_APP_API_URL + `/activity_log/${payload.type}`,
        payload.data
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getNotification = createAsyncThunk(
  "forum/getNotification",
  async (payload, thunkAPI) => {
    try {
      httpService.setToken(authServices.getToken());
      const { data } = await httpService.get(
        process.env.REACT_APP_API_URL +
          `/activity_log?length=${payload.length}&start=${payload.start}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const reportPost = createAsyncThunk(
  "forum/reportPost",
  async (payload, thunkAPI) => {
    try {
      httpService.setToken(authServices.getToken());
      const { data } = await httpService.patch(
        process.env.REACT_APP_API_URL + `/report_for_user/${payload.id}`,
        payload.data
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const hiddenPost = createAsyncThunk(
  "forum/hiddenPost",
  async (payload, thunkAPI) => {
    try {
      httpService.setToken(authServices.getToken());
      const { data } = await httpService.patch(
        process.env.REACT_APP_API_URL + `/hide_post`,
        payload
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const profileDetail = createAsyncThunk(
  "forum/profileDetail",
  async (payload, thunkAPI) => {
    try {
      httpService.setToken(authServices.getToken());
      const { data } = await httpService.get(
        process.env.REACT_APP_API_URL + `/member_profile?id=${payload.id}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const dataSlice = createSlice({
  name: "forum",
  initialState: initialData,
  reducers: {
    clearSelectedDataInfo: (state, action) => {
      state.selectedDataInfo = {
        id: "",
        title: "",
        member_type: "",
        user_name: "",
        created_at: "",
        mainMediaUrl: [],
        content: "",
        comment_list: "",
        comment_count: "",
        job_title: "",
        car_model: "",
      };
    },
    setCommentStatus: (state, action) => {
      state.editCommentStatus = action.payload;
    },
    clearProfileDetail: (state, action) => {
      state.profile_detail = {};
    },
    setEditForumPost: (state, action) => {
      state.editForumPostStatus = action.payload;
    },
    setSettingModal: (state, action) => {
      state.settingModal = !state.settingModal;
    },
    setLocation: (state, action) => {
      state.createPostData.location = action.payload;
    },
    setProfileModal: (state, action) => {
      state.profileModdal = action.payload.id;
    },
    setCreatePostModal: (state, action) => {
      state.createPostModal = action.payload;
      if (!action.payload) {
        state.createPostData = {
          topic: "help", // showcase,help,recommendation
          location: "",
          content: "",
          attachment: [],
        };
      }
    },
    setCreatePostData: (state, action) => {
      state.createPostData = action.payload;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload.id;
      if (action.payload.data) {
        state.selectedDataInfo = action.payload.data;
        state.createCommentData.post_id = action.payload.data.id;
      }
    },
    setPreviewItem: (state,action) => {
      state.previewItem = action.payload
    },
    setShareUrl: (state,action) => {
      state.shareUrl = action.payload
    },
    setPostList: (state,action) => {
      state.postList = action.payload
    },
    setIsDiscardPost: (state,action) => {
      state.isDiscardPost = action.payload
    },
  },
  extraReducers: {
    [profileDetail.fulfilled]: (state, action) => {
      state.profile_detail_loading = false;
      state.profile_detail = action.payload.data;
    },
    [profileDetail.pending]: (state, action) => {
      state.profile_detail_loading = true;
    },
    [getForumInfo.pending]: (state, action) => {
      state.post_info_loading = true;
    },
    [getForumInfo.fulfilled]: (state, action) => {
      state.post_info_loading = false;
      state.selectedDataInfo = {
        ...state.selectedDataInfo,
        ...action.payload.data,
      };
      state.createCommentData.post_id = action.payload.data.id;
    },
    [getForumInfo.rejected]: (state, action) => {},
    [getNotification.pending]: (state, action) => {},
    [getNotification.fulfilled]: (state, action) => {
      state.notification_list = action.payload.data.aaData;
      state.notification_total_count = action.payload.data.iTotalRecords;
    },
    [getNotification.rejected]: (state, action) => {},
    [createComment.pending]: (state, action) => {
      state.createCommentLoading = true;
    },
    [createComment.fulfilled]: (state, action) => {
      state.createCommentLoading = false;
      state.createCommentData = {
        ...state.createCommentData,
        content: "",
      };
    },
    [createComment.rejected]: (state, action) => {
      state.createCommentLoading = false;
    },
    [createForumPost.pending]: (state, action) => {
      state.createPostLoading = true;
    },
    [createForumPost.fulfilled]: (state, action) => {
      state.createPostLoading = false;
    },
    [createForumPost.rejected]: (state, action) => {
      state.createPostLoading = false;
    },
    [getForumPinnedPost.pending]: (state, action) => {
      state.loading = true;
    },
    [getForumPinnedPost.fulfilled]: (state, action) => {
      state.loading = false;
      let newData = [];
      for (var key in action.payload.data.aaData) {
        newData.push(
          (state.pinnedPost[key] = {
            ...action.payload.data.aaData[key],
            display_id: `pinned-${action.payload.data.aaData[key].id}`,
          })
        );
      }
      state.pinnedPost = newData;
    },
    [getForumPinnedPost.rejected]: (state, action) => {
      state.loading = false;
    },
    [getForumListing.pending]: (state, action) => {
      state.postListLoading = true;
    },
    [getForumListing.fulfilled]: (state, action) => {
      state.postListLoading = false;
      // let newData = [];
      // for (var key in action.payload.data.aaData) {
      //   newData.push({
      //     ...action.payload.data.aaData[key],
      //     display_id: `post-${action.payload.data.aaData[key].id}`,
      //   });
      // }
      // state.items = newData;
    },
    [getForumListing.rejected]: (state, action) => {
      state.postListLoading = false;
    },
  },
});

export const { setIsDiscardPost, setPostList, clearSelectedDataInfo, setCommentStatus, clearProfileDetail, setCreatePostData, setEditForumPost, setSettingModal, setHiddenId, setLocation, setProfileModal, setSelectedId, setCreatePostModal, setPreviewItem, setShareUrl } = dataSlice.actions

export default dataSlice.reducer;

// example for fetching API
