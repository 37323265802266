//lib
import { useFormikContext } from "formik";
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";

export default function ChooseTopic() {
  const { values, setFieldValue } = useFormikContext();

  return (
    <article className="container create-post-step1">
      <h2 className="_label --choose-topic-title">Choose Post Topic</h2>
      <section className="row justify-content-center gap-4 mt-5">
        <h4
          className={
            values["topic"] === "help"
              ? "_label --topic --active pointer"
              : "_label --topic pointer"
          }
          onClick={() => {
            setFieldValue("topic", "help");
          }}
        >
          Help Post
        </h4>
        <h4
          className={
            values["topic"] === "showcase"
              ? "_label --topic --active pointer"
              : "_label --topic pointer"
          }
          onClick={() => {
            setFieldValue("topic", "showcase");
          }}
        >
          Show Case
        </h4>
        <h4
          className={
            values["topic"] === "recommendation"
              ? "_label --topic --active pointer"
              : "_label --topic pointer"
          }
          onClick={() => {
            setFieldValue("topic", "recommendation");
          }}
        >
          Sharing
        </h4>
      </section>
      <section className="row text-start mt-5">
        {values["topic"] === "help" && (
          <p className="_label --topic-detail">
            This is the place for you when you're stuck with a car problem. It
            can be anything: a broken part, confusing instructions, or just not
            knowing what car to buy. You ask your question, and others in the
            community will try to help by giving advice or sharing their
            experiences.
          </p>
        )}
        {values["topic"] === "showcase" && (
          <p className="_label --topic-detail">
            Got something cool to show about your car? This is the spot. It
            might be a big fix you did yourself, a shiny new part you've added,
            or just how great your car looks when it's clean. You can share
            pictures, write about what you've done, and people will admire your
            work.
          </p>
        )}
        {values["topic"] === "recommendation" && (
          <p className="_label --topic-detail">
            Ever found something really good and want to share with others?
            That's what this section is for. Maybe you discovered a great car
            cleaning product, or found a mechanic who does a great job. By
            posting in this section, you can let others know about these good
            things. This way, we all learn from each other and can find the best
            stuff out there.
          </p>
        )}
      </section>
    </article>
  );
}
