import { useState } from "react";

//lib
import { MDBInput, MDBBadge, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Field, useFormikContext } from "formik";
import { Icon } from "@iconify/react";

export default function TagInput({ name, label, className, max, placeholder }) {
  const { values, errors, setFieldValue } = useFormikContext();

  const [inputValue, setInputValue] = useState("");
  const [inputFocus, setInputFocus] = useState(false);

  const maximum = max ? max : 0;

  const handleFocus = () => {
    setInputFocus(true);
  };
  const handleBlur = () => {
    setInputFocus(false);
  };

  const handleInputChange = (e) => {
    // if (e.target.value === ",") {
    //   setInputValue("");
    // } else {
      setInputValue(e.target.value);
    // }
  };

  const handleInputKeyDown = (e) => {
    const normalizeInput = e.key.replace(/,/g, ",");
    if (
      (normalizeInput === "Enter" && inputValue.trim() !== "") ||
      (normalizeInput === "," && inputValue.trim() !== "")
    ) {
      e.preventDefault();
      e.stopPropagation();
      if (values[name].includes(inputValue.trim())) {
        setInputValue("");
      } else if (values[name].length < maximum) {
        const updatedTags = [...values[name], inputValue.trim()];
        setFieldValue(name, updatedTags);
        setInputValue("");
      } else {
        setFieldValue(name, values[name].slice(0, maximum));
        setInputValue("");
      }
    }
  };

  const handleAddItem = (e) => {
      if (values[name].includes(inputValue.trim())) {
        setInputValue("");
      } else if (values[name].length < maximum) {
        const updatedTags = [...values[name], inputValue.trim()];
        setFieldValue(name, updatedTags);
        setInputValue("");
      } else {
        setFieldValue(name, values[name].slice(0, maximum));
        setInputValue("");
      }
  };

  const handleTagRemove = (tag) => {
    const updatedTags = values[name].filter((t) => t !== tag);
    setFieldValue(name, updatedTags);
  };

  return (
    <section className="mt-4">
      <article className="tag-div">
        {values &&
          values[name].map((tag) => (
            <MDBBadge
              key={tag}
              pill
              color="primary"
              className="tag-badge"
              onClick={() => handleTagRemove(tag)}
            >
              {tag} &nbsp;
              <Icon icon="mdi:remove" className="remove-icon" />
            </MDBBadge>
          ))}
      </article>
      <article
        className={`col form-group _text ${className}`}
        style={inputFocus ? { border: "0.1rem solid #3182ce" } : {}}
      >
        <section className={`row form-group _text`}>
          <Field
            value={inputValue}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={(e) => handleInputChange(e)}
            type={"text"}
            name={name}
            label={placeholder}
            className={"focus"}
            as={MDBInput}
            place
          />
          <section className="icon-div" style={{width : "10%"}}>
            <Icon icon="icon-park-outline:add" className="add-icon" color="#222A39" onClick={() => handleAddItem()}/>
          </section>
        </section>
      </article>
    </section>
  );
}
