import { useEffect, useState } from "react";

//lib
import { Icon } from "@iconify/react";
import { useFormikContext } from "formik";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

//redux
import { useDispatch, useSelector } from "react-redux";
import { setTotalPoint } from "../../../redux/reducer/paymentReducer";

//component
import Button from "../../../components/element/button";

export default function PaymentButton({ disabled }) {
  const dispatch = useDispatch()
  const { isSubmitting, isValid, values, setFieldValue } = useFormikContext();
  const { selectedEventId } = useSelector((state) => state.event);
  const { userWallet } = useSelector((state) => state.profile);
  const [number, setNumber] = useState(values.quantity);

  const handleAddTicket = (value) => {
    setNumber(value);
    const newTicket = [];
    setFieldValue("properties", [...values.properties, newTicket]);
    setFieldValue("quantity", value);
  };

  const handleRemoveTicket = (index) => {
    setNumber(index);
    const updatedTickets = [...values.properties];
    updatedTickets.splice(index, 1);
    setFieldValue("properties", updatedTickets);
    setFieldValue("quantity", index);
  };

  const totalPrice = selectedEventId.price * values.quantity;
  const cleanedNumber = userWallet.point_balance
    ? parseFloat(userWallet.point_balance.replace(/,/g, ""))
    : 0;
  const payment_typePrice =
    values.payment_type === "point" ? totalPrice + " PTS" : "RM " + totalPrice;

  useEffect(() => {
    dispatch(setTotalPoint(totalPrice));
  }, [dispatch, totalPrice])

  return (
    <section className="payment-quantity-div">
      <section className="row quantity-row">
        <article className="col text-end">
          <Icon
            icon="ic:outline-minus"
            color="#626668"
            height={"1em"}
            onClick={() => {
              if (number === 1) {
              } else {
                handleRemoveTicket(number - 1);
              }
            }}
          />
        </article>
        <article className="col-8 d-flex justify-content-center">
          <p>{number}</p>
        </article>
        <article className="col text-start">
          <Icon
            icon="ic:outline-add"
            color="#626668"
            height={"1em"}
            onClick={() => {
              if (selectedEventId.balance <= number) {
              } else {
                handleAddTicket(number + 1);
              }
            }}
          />
        </article>
      </section>
      <Button
        type={"submit"}
        name={`${values.payment_type === "point" ? 'TOTAL REDEEM ' : 'TOTAL PAY ' }` + payment_typePrice}
        className="form_button"
        disabled={
          values.payment_type === "point" &&  totalPrice >= cleanedNumber
            ? true
            : false || disabled || !isValid || isSubmitting
        }
      />
    </section>
  );
}
