//lib
import Lottie from "lottie-react";
import PdfRender from "./pdf.render";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";
import VideoThumbnail from 'react-video-thumbnail';

//component
import PostMemberDetail from "./post.member.detail";

//assets
import Loading from "./../../../assets/json/loading-graphic.json";

//helper
import formatNumber from "../../../helper/formatnumber.helper";
import { useSelector } from "react-redux";

export default function PostListing({ hasMore, items, getMoreData, loading }) {
  const navigate = useNavigate();
  const { postList } = useSelector((state) => state.forum);

  return (
    <article className="container">
      {!loading ?
        <InfiniteScroll
          style={{ overflow: "unset" }}
          dataLength={postList.length}
          next={() => {
            getMoreData();
          }}
          hasMore={hasMore}
          loader={postList.length > 0 && <h4 className="text-center mt-5">Loading...</h4>}
        >
          {postList.length > 0 &&
            postList.map((item, index) => {
              const imageSources = {
                s: item?.image_s,
                m: item?.image_m,
                l: item?.image_l,
                xl: item?.image_xl,
                fallback: item?.mainMediaUrl[0]?.url
              };
              
              return (
                <motion.div
                  key={index}
                  className="forum-listing-card"
                  layoutId={item.display_id}
                  onClick={
                    () => navigate("/forum?post=" + item.id)
                  }
                >
                  <PostMemberDetail
                    avatar={item.created_by_avatar}
                    member={item.user_name}
                    member_type={item.member_type}
                    update_date={item.created_at}
                    job_title={item.job_title}
                    car_model={item.car_model}
                  />
                  <section className="row forum-post-content m-0">
                    <article className="col-8">
                      <h4 className="forum-title">{item.content}</h4>
                      <footer className="d-flex forum-actions">
                        <p className="_label --like">
                          {formatNumber(item.like_count)} Like
                        </p>
                        <p className="_label --comment">
                          {formatNumber(item.comment_count)} Comment
                        </p>
                        <p className="_label --point">
                          {formatNumber(item.point)} Point
                        </p>
                      </footer>
                    </article>
                    <article className="p-0 d-flex justify-content-end col-4">
                      <section className="img-columns">
                      {imageSources.s !== null && imageSources.s !== undefined ? 
                        <LazyLoadImage
                          srcSet={`
                              ${imageSources.s} 600w,
                              ${imageSources.m} 1800w,
                              ${imageSources.l} 2200w,
                              ${imageSources.xl} 2600w
                          `}
                          sizes={`
                              (max-width: 599px) 600px,
                              (max-width: 1799px) 1800px,
                              (max-width: 2199px) 2200px,
                              2600px
                          `}
                          src={imageSources.fallback}
                          alt={item.content}
                        />
                      :
                        item?.mainMediaUrl[0]?.mime_type === "mp4" || item?.mainMediaUrl[0]?.mime_type === "MOV" || item?.mainMediaUrl[0]?.mime_type === "mov" ? (
                          <video preload="metadata">
                            <source src={`${item?.mainMediaUrl[0]?.url}#t=0.1`} type="video/mp4" />
                            <source src={`${item?.mainMediaUrl[0]?.url}#t=0.1`} type="video/quicktime" />
                            <track src="captions_en.vtt" kind="captions" srclang="en" label="english_captions" />
                          </video>
                        ) : item?.mainMediaUrl[0]?.mime_type === "pdf" ? (
                          <PdfRender
                            pdf={item?.mainMediaUrl[0]?.api_url}
                            pageNumber={1}
                          />
                        ) : (
                          <LazyLoadImage src={item?.mainMediaUrl[0]?.url} alt={item.content}/>
                        )
                      }
                      </section>
                    </article>
                  </section>
                </motion.div>
              )
            })}
        </InfiniteScroll>
      : 
        <section className="loading-css">
          <article className="loading-animation" style={{ width: '10em' }}>
            <Lottie animationData={Loading} style={{ width: '100%' }}/>
          </article>
        </section>
      }   
    </article>
  );
}
