//lib
import { EffectCreative } from "swiper";
import { MDBRow } from "mdb-react-ui-kit";
import { Skeleton } from "primereact/skeleton";
import { Swiper, SwiperSlide } from "swiper/react";

//component
import WorkshopCard from "./workshop.card";

//hook
import useWindowDimensions from "../../../components/common/window-dimension";

//redux
import { useSelector } from "react-redux";

export function WorkshopPartner() {
  const { width } = useWindowDimensions();
  const { workshop, workshop2, workshop_loading } = useSelector((state) => state.car);

  if (workshop2.length > 0) {
    return (
      <article className="our-workshop-partner">
        <header className="row home-title-row --car-inspection">
          <h2>Our Workshop Partner</h2>
        </header> 
        {!workshop_loading ?
          <section className="pe-5">
            <Swiper
              slidesPerView={
                width >= 1600
                  ? 3
                  : width >= 1400
                  ? 3
                  : width >= 1200
                  ? 2.5
                  : width >= 800
                  ? 2
                  : width >= 700
                  ? 1.5
                  : width >= 620
                  ? 1.5
                  : width >= 400
                  ? 1
                  : 1
              }
              spaceBetween={15}
              // loop={true}
              grabCursor={true}
              creativeEffect={{
                prev: {
                  shadow: false,
                  translate: ["-120%", 0, -500],
                },
                next: {
                  shadow: false,
                  translate: ["120%", 0, -500],
                },
              }}
              modules={[EffectCreative]}
            >
              {workshop2.map((item, index) => (
                <SwiperSlide key={index}>
                  <WorkshopCard item={item} />
                </SwiperSlide>
              ))}
            </Swiper>
          </section>
        :   
          <section className="d-flex mt-3 ms-5">
            {Array.from({ length: width > 750 ? 2 : 1 }, (_, index) => (
                <Skeleton width={width > 1120 ? "30%" : width > 991 ? "40%" : width > 750 ? "40%" : "80%" } height="18em" borderRadius="10px" className="me-lg-4 me-md-3 me-3"/>
            ))}
          </section>
        }
      </article>
    );
  }
}
