//lib
import { useFormikContext } from "formik";
import { MDBRow } from "mdb-react-ui-kit";
import { useState } from "react";
import AuthCode from "react-auth-code-input";

export default function Code({ name, className, label ,disabled }) {
  const { errors, values, touched, setFieldValue } = useFormikContext();
  const [maskedValue, setMaskedValue] = useState("");

  const handleChange = (e) => {
    //setMaskedValue(e.replace(/\d/g, "*"));
    setFieldValue(name, e);
  };

  return (
    <section className={`${className}`}>
      {label && (
        <section className="row">
          <p
            className="form-label ms-3"
            style={className ? { color: "#D1D5DB" } : { color: "#00000099" }}
          >
            {label}
          </p>
        </section>
      )}
      <section
        className={`row form-group _text ${
          errors && touched && errors[name] && touched[name] ? "_errors" : ""
        }`}
      >
        <article className="code-container">
          <AuthCode
            allowedCharacters="numeric"
            autoFocus={true}
            length={6}
            isPassword={true}
            inputClassName="code-input"
            onChange={(e) => handleChange(e)}
            disabled={disabled}
            // placeholder={false}
            // type="password"
            // onCompleted={(e) => handleCompleted(e)}
          />
        </article>
      </section>
      <section className="row">
        {errors && touched && errors[name] && touched[name] ? (
          <p className="text-right text-danger no-padding error-message ms-3" style={{ fontSize: '14px' }}>
            {errors[name]}
          </p>
        ) : null}
      </section>
    </section>
  );
}
