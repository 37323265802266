import { useEffect, useRef, useState } from "react";

//lib
import { Icon } from "@iconify/react";
import { Link, useNavigate } from "react-router-dom";
import { MDBModalBody, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

//hook
import useWindowDimensions from "../../../components/common/window-dimension";

//redux
import { useDispatch, useSelector } from "react-redux";
import { closeAllModal } from "../../../redux/reducer/modalReducer";
import { getUserActivityLog } from "../../../redux/reducer/profileReducer";

//component
import ModalBox from "../../../components/modal/full.modal.box";
import InfoHeader from "../../../components/header/info-header";
import PdfRender from "../../Forum/component/pdf.render";

//assets
import CommentIcon from "../../../assets/images/icon/comment.webp";

//helper
import scrollToTop from "../../../helper/scrolltotop.helper";

export default function ActivityModal() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const length = 20;
  const [top, setTop] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [start, setStart] = useState(0);
  const [record, setRecord] = useState(null);
  const [data, setData] = useState([]);
  const { isOpenActivity } = useSelector((state) => state.modal);
  const [loadingStatus, setLoadingStatus] = useState(false);

  const modalRef = useRef(null);

  const backButton = ({ navigatePost = false }) => {
    if (navigatePost) {
      navigate(`/profile?post=${navigatePost}`);
      dispatch(closeAllModal());
    } else {
      navigate("/profile");
    }
    dispatch(closeAllModal());
  };

  useEffect(() => {
    setTop(true);
  }, [isOpenActivity]);

  useEffect(() => {
    if (top == true && modalRef.current) {
      scrollToTop(modalRef.current);
      setTop(false);
    }
  }, [top]);

  const getActivityData = () => {
    dispatch(getUserActivityLog({ length: length, start: start, filter: [] }))
      .unwrap()
      .then((res) => {
        setData(res.data.aaData);
        setRecord(res.data.iTotalDisplayRecords);
        if (res.data.iTotalDisplayRecords !== res.data.iTotalRecords) {
          setHasMore(true);
        }
      });
  };

  const getMoreData = () => {
    setLoadingStatus(true);
    dispatch(
      getUserActivityLog({ length: length, start: start + length, filter: [] })
    )
      .unwrap()
      .then((res) => {
        setLoadingStatus(false);
        setStart(start + length);

        setRecord(record + res.data.iTotalDisplayRecords);
        if (record + res.data.iTotalDisplayRecords === res.data.iTotalRecords) {
          setHasMore(false);
        }

        const updatedData = res.data.aaData.reverse().map((item) => {
          const matchingRecord = data.find(
            (newRecord) => newRecord.date === item.date
          );
          if (matchingRecord) {
            return {
              ...item,
              total_records: item.total_records + matchingRecord.total_records,
              events: [...matchingRecord.events, ...item.events],
            };
          }
          return item;
        });

        data.reverse().forEach((newRecord) => {
          if (!updatedData.some((item) => item.date === newRecord.date)) {
            updatedData.push(newRecord);
          }
        });

        setData(updatedData.reverse());
      });
  };

  useEffect(() => {
    getActivityData();
  }, []);

  const renderAction = (event) => {
    switch (event.action) {
      case "like comment":
        return (
          <h5 className="action-text pointer">
            {event.username} like {event.alert_username}'s <b>comment</b>.{" "}
          </h5>
        );
      case "comment on post":
        return (
          <h5 className="action-text pointer">
            {event.username} commented on {event.alert_username}'s <b>post</b>.
          </h5>
        );
      case "like post":
        return (
          <h5 className="action-text pointer">
            {event.username} like {event.alert_username}'s <b>post</b>.
          </h5>
        );
      case "reply on comment":
        return (
          <h5 className="action-text pointer">
            {event.username} replied to {event.alert_username}'s <b>comment</b>.
          </h5>
        );
      case "create post":
        return (
          <h5 className="action-text pointer">
            {event.username} create new <b>post</b>.
          </h5>
        );
      default:
      // code block
    }
  };
  const renderIcon = (event) => {
    switch (event.action) {
      case "like comment":
        return (
          <article className="action-icon">
            <Icon icon="flat-color-icons:like" color="#F44336" width={20} />
          </article>
        );
      case "comment on post":
        return (
          <article className="action-icon">
            <LazyLoadImage src={CommentIcon} width={20} alt="reunion-comment" />
          </article>
        );
      case "like post":
        return (
          <article className="action-icon">
            <Icon icon="flat-color-icons:like" color="#F44336" width={20} />
          </article>
        );
      case "reply on comment":
        return (
          <article className="action-icon">
            <LazyLoadImage src={CommentIcon} width={20} alt="reunion-comment" />
          </article>
        );
      default:
    }
  };
  return (
    <ModalBox
      centered
      scrollable
      className={"modal-container"}
      show={isOpenActivity}
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      backButton={() => backButton({ navigatePost: false })}
      content={
        <article>
          <InfoHeader
            showBackButton={true}
            title="Activity Log"
            backButton={() => backButton({ navigatePost: false })}
          />
          <MDBModalBody ref={modalRef} id="scrollableDiv">
            <section
              className={
                width >= 991
                  ? "order-div activity_log desktop"
                  : "order-div activity_log"
              }
            >
              {data.length > 0 &&
                data.map((activity, index) => (
                  <article key={index} style={{ marginBottom: "1.5em" }} className="row pointer">
                    <h2 className="date-title">{activity.date}</h2>
                    <section>
                      {activity.events.length > 0 &&
                        activity.events.map((event, indexEvent) => (
                          <Link
                            className="row event-row"
                            key={indexEvent}
                            onClick={(e) => {
                              e.preventDefault();

                              backButton({ navigatePost: event.post_id });
                            }}
                          >
                            {event.mainMediaUrl.length > 0 ? (
                              <article className="avatar-div pointer">
                                {renderIcon(event)}
                                {event.mainMediaUrl[0]?.mime_type === "pdf" ? (
                                  <PdfRender
                                    pdf={event.mainMediaUrl[0]?.api_url}
                                    pageNumber={1}
                                  />
                                ) : event.mainMediaUrl[0]?.mime_type === "mp4" || 
                                    event.mainMediaUrl[0]?.mime_type === "mov" || 
                                    event.mainMediaUrl[0]?.mime_type === "MOV" ? (
                                  <article className="image-div">
                                    <video className="avatar" style={{width : "5em"}}>
                                      <source src={event?.mainMediaUrl[0]?.url} type="video/mp4" />
                                      <source src={event?.mainMediaUrl[0]?.url} type="video/quicktime" />
                                      <track src="captions_en.vtt" kind="captions" srclang="en" label="english_captions" />
                                    </video>
                                  </article>
                                ) : (
                                  <article className="image-div">
                                    <LazyLoadImage
                                      className="avatar"
                                      src={
                                        event?.mainMediaUrl[0]?.url
                                          ? event.mainMediaUrl[0]?.url
                                          : ""
                                      }
                                      width={50}
                                      alt="reunion-avatar"
                                    />
                                  </article>
                                )}
                              </article>
                            ) : (
                              <article
                                className="avatar-div pointer"
                                style={{ width: "65px" }}
                              ></article>
                            )}
                            <article className="col event-detail-div">
                              {renderAction(event)}
                              <p className="event-content pointer">{event.content}</p>
                            </article>
                            <article className="col d-flex justify-content-end col-2">
                              <p className="event-content pointer">
                                {event.datetime}
                              </p>
                            </article>
                          </Link>
                        ))}
                    </section>
                  </article>
                ))}
              {hasMore ? (
                <article className="row">
                  <p
                    className="event-see-more pointer"
                    onClick={() => {
                      if (!loadingStatus) {
                        getMoreData();
                      }
                    }}
                  >
                    See more
                  </p>
                </article>
              ) : (
                <article className="row">
                  <p className="event-see-more">History Is Empty</p>
                </article>
              )}
            </section>
          </MDBModalBody>
        </article>
      }
    />
  );
}
