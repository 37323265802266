import { createSlice} from "@reduxjs/toolkit"

export const initialData = {
  forumSettingData: {
    id: '',
    type: '',
    username: ''
  },
  shareModal: false,
  showLoginModal: false,
  selectedLogin: 'main',
  loading: false
}

const settingSlice = createSlice({
  name: "setting",
  initialState: initialData,
  reducers: {
    setShareModal: (state,action) => {
      state.shareModal = !state.shareModal
    },
    setForumSettingData: (state,action) => {
      state.forumSettingData = action.payload.data
    },
    setShowLoginModal: (state,action) => {
      state.showLoginModal = action.payload
    },
    setSelectedLogin: (state,action) => {
      state.selectedLogin = action.payload
    },
  },
  extraReducers: {
  },
})

export const { setShareModal, setForumSettingData, setShowLoginModal, setSelectedLogin } = settingSlice.actions;
const { reducer } = settingSlice;
export default reducer;
