import { createSlice } from "@reduxjs/toolkit";

import ForumIcon from "../../assets/images/icon/forum_icon.webp";
import ForumGif from "../../assets/images/icon/forum_icon.gif";
import ProfileIcon from "../../assets/images/icon/profile_icon.webp";
import ProfileGif from "../../assets/images/icon/profile_icon.gif";
import ProductIcon from "../../assets/images/icon/product_icon.webp";
import ProductGif from "../../assets/images/icon/product_icon.gif";
import SettingIcon from "../../assets/images/icon/setting_icon.webp";
import SettingGif from "../../assets/images/icon/setting_icon.gif";

import Partner1 from "../../assets/images/partner/Frame-1.webp";
import Partner2 from "../../assets/images/partner/Frame-2.webp";
import Partner3 from "../../assets/images/partner/Frame-3.webp";
import Partner4 from "../../assets/images/partner/Frame-4.webp";
import Partner5 from "../../assets/images/partner/Frame-5.webp";
import Partner6 from "../../assets/images/partner/Frame-6.webp";
import Partner7 from "../../assets/images/partner/Frame-7.webp";
import Partner8 from "../../assets/images/partner/Frame-8.webp";
import Partner9 from "../../assets/images/partner/Frame-9.webp";
import Partner10 from "../../assets/images/partner/Frame-10.webp";
import Partner11 from "../../assets/images/partner/Frame-11.webp";
import Partner12 from "../../assets/images/partner/Frame-12.webp";

export const initialData = {
  data: {},
  notificationButton: false,
  app_name: "REUNION",
  user_detail: { username: "" },
  version: "0.0.1",
  navigation_item: [
    {
      label: "Home",
      navigate: "/",
      image: ForumIcon,
      animation: ForumGif,
      icon: "fluent:home-48-filled",
    },
    {
      label: "Store",
      navigate: "/store",
      image: ProductIcon,
      animation: ProductGif,
      icon: "fluent:home-48-filled",
    },
    {
      label: "MyCar",
      navigate: "/car",
      image: SettingIcon,
      animation: SettingGif,
      icon: "fluent:home-48-filled",
    },
    {
      label: "Profile",
      navigate: "/profile",
      image: ProfileIcon,
      animation: ProfileGif,
      icon: "fluent:home-48-filled",
    },
  ],
  footerListing: [
    // {
    //   id: 1,
    //   title: "Service",
    //   active: false,
    //   // icon: 'ic:outline-keyboard-arrow-down',
    //   item: [
    //     // {
    //     //   label: "Service",
    //     //   to: "", //"/auction_guide",
    //     // },
    //   ],
    // },
    {
      id: 2,
      title: "About Reunion",
      active: false,
      // icon: 'ic:outline-keyboard-arrow-down',
      item: [
        {
          label: "Privacy Policy",
          to: "/terms-of-use",
          value: "Privacy Policy",
        },
        {
          label: "Terms and Conditions",
          to: "/terms-of-use",
          value: "Terms and Conditions",
        },
        {
          label: "Refund Policy",
          to: "/terms-of-use",
          value: "Refund Policy",
        },
      ],
    },
    // {
    //   id: 3,
    //   title: "Support",
    //   active: false,
    //   // icon: 'ic:outline-keyboard-arrow-down',
    //   item: [
    //     // {
    //     //   label: "Support",
    //     //   to: "",
    //     // },
    //     // {
    //     //   label: "Privacy Policy",
    //     //   //to: "/direct_import"
    //     // },
    //     // {
    //     //     label: "Term of Use",
    //     //     //to: "/direct_import"
    //     // }
    //   ],
    // },
  ],

  t_and_c_Listing: [
    {
      title: "1. Acceptance of Terms",
      content:
        "By accessing and using our platform, you agree to abide by and be bound by these Terms of Use. If you do not agree with any part of these terms, please do not use our platform.",
    },
    {
      title: "2. Platform Description",
      content:
        "Our platform is a community website for Volkswagen car enthusiasts to share information, connect with other enthusiasts, and discuss topics related to Volkswagen cars.",
    },
    {
      title: "3. Prohibited Content",
      content:
        "You agree not to share, post, or distribute violent, pornographic, or offensive content on our platform. While using our platform, you must not engage in any illegal activities or infringe upon the rights of others.",
    },
    {
      title: "4. User Behavior",
      content:
        "You are responsible for your behavior on our platform. You must respect other users and adhere to community guidelines. We reserve the right to suspend or terminate your account for violations of these Terms of Use.",
    },
    {
      title: "5. Intellectual Property",
      content:
        "All content provided on our platform is protected by copyright and other intellectual property laws. You may not use any content from our platform for commercial purposes without our permission.",
    },
    {
      title: "6. Privacy",
      content:
        "We collect and process personal information in accordance with our Privacy Policy.",
    },
    {
      title: "7. Termination",
      content:
        "We reserve the right to terminate or suspend your access to our platform at any time, without notice, for reasons of violating these Terms of Use.",
    },
    {
      title: "8. Changes to the Terms",
      content:
        "We may update these Terms of Use from time to time. It is your responsibility to review them regularly.",
    },
  ],

  privacyListing: [
    {
      title: "1. Information We Collect",
      content:
        "When you register on our platform, we collect personal information such as your name, email address, and other relevant details. We may collect non-personal information, such as IP addresses and browsing data.",
    },
    {
      title: "2. How We Use Your Information",
      content:
        "We use your information to provide and enhance the functionality of our platform. We do not sell, rent, or share your personal information with third parties without your consent.",
    },
    {
      title: "3. Security",
      content:
        "We take reasonable measures to protect your personal information, but data transmitted over the internet is not entirely secure.",
    },
    {
      title: "4. Third-Party Links",
      content:
        "Our platform may contain links to third-party websites. We are not responsible for their privacy policies.",
    },
    {
      title: "5. Children's Privacy",
      content:
        "Our platform is not intended for children under 13 years old. We do not knowingly collect children's information.",
    },
    {
      title: "6. Contact Information",
      content:
        "If you have any questions or concerns about our privacy policy, please contact us at ",
      phone_no: "+6012-3552455.",
      company: "Company Name : Reunion Motorsports Sdn Bhd",
      address:
        "Address: No.22G JALAN ARA SD7/3B BANDAR SRI DAMANSARA 52200 KUALA LUMPUR W.P KUALA LUMPUR MALAYSIA",
      email: "Email: dede_tby@yahoo.co.uk",
    },
    {
      title: "7. Third-Party Links",
      content:
        "Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of such websites.",
    },
    {
      title: "8. Changes to This Notice",
      content:
        "We may periodically update this Privacy Notice. We encourage you to review this notice regularly to stay informed about how we are protecting your data.",
    },
    {
      title: "9. Contact Us",
      content: `For any inquiries regarding this Privacy Notice or our data practices, please contact: `,
      phone_no: "+6012-3552455.",
    },
  ],

  refundListing: [
    {
      title: "1. Refund Policy Overview",
      content:
        'This Refund Policy describes the terms and conditions under which Reunion Motorsports Sdn Bhd ("we," "our," or "us") provides refunds to customers for the purchase of our products or services. By making a purchase with us, you agree to this refund policy.',
    },
    {
      title: "2. Eligibility for Refunds",
      content: "We offer refunds for the following circumstances:",
      list: [
        {
          list: "The Item has not been received by Buyer;",
        },
        {
          list: "The Item was defective and/or damaged on delivery;",
        },
        {
          list: "The Item received is incomplete (missing quantity or accessories);",
        },
        {
          list: "Seller has delivered an Item that does not match the agreed specification (e.g. wrong size, colour, etc.) to Buyer;",
        },
        {
          list: "The Item delivered to Buyer is materially different from the description provided by Seller in the listing of the Item;",
        },
        {
          list: "The Item received has physical damage (e.g. dented, scratched, shattered);",
        },
        {
          list: "The Item received is faulty (e.g. malfunction, does not work as intended);",
        },
      ],
    },
    {
      title: "3. Refund Process",
      content:
        "Customers must contact our customer support team within 3 working days to request a refund. Refund requests made outside of this period may not be considered. When contacting our customer support team for a refund, please provide the following information:",
      list: [
        {
          list: "Proof of purchase (e.g., order number, receipt);",
        },
        {
          list: "Proof of video or photos;",
        },
        {
          list: "Detailed reason for the refund request;",
        },
        {
          list: "Any additional information or documentation that may be relevant;",
        },
        {
          list: "We reserve the right to approve or deny refund requests at our sole discretion.",
        },
      ],
    },
    {
      title: "4. Refund Timeframe",
      content:
        "Approved refunds will be processed within 14 days from the date of approval. The time it takes for the refund to be reflected in your account may vary depending on your payment method and financial institution.",
    },
    {
      title: "5. Exclusions",
      content: "The following circumstances are not eligible for refunds:",
      list: [
        {
          list: "Change of mind or personal preferences;",
        },
        {
          list: "Failure to use a product or service;",
        },
        {
          list: "Delays or disruptions beyond our control, such as acts of nature or unforeseen events.",
        },
      ],
    },
    {
      title: "6. Contact Us",
      content:
        "If you have any questions or concerns about our refund policy, please contact us at:",
      phone: "Phone Number : +6012-3552455",
      company: "Company Name: Reunion Motorsports Sdn Bhd;",
      address:
        "Address: NO.22G Jalan Ara SD7/3B Bandar Sri Damansara 52200 K.L;",
      email: "Email Address: dede_tby@yahoo.co.uk.",
    },
    {
      title: "7. Changes to this Refund Policy",
      content:
        "We reserve the right to modify or update this refund policy at any time. Any changes will be posted on our website, and the revised policy will apply to all purchases made after the effective date of the updated policy.",
    },
  ],

  partner: [
    {
      image: Partner1,
    },
    {
      image: Partner2,
    },
    {
      image: Partner3,
    },
    {
      image: Partner4,
    },
    {
      image: Partner5,
    },
    {
      image: Partner6,
    },
    {
      image: Partner7,
    },
    {
      image: Partner8,
    },
    {
      image: Partner9,
    },
    {
      image: Partner10,
    },
    {
      image: Partner11,
    },
    {
      image: Partner12,
    },
  ],
  loading: false,
  token: "",
  showFullModal: false,
  showConfirmModal: false,
  animationModal: true,
};

const dataSlice = createSlice({
  name: "common",
  initialState: initialData,
  reducers: {
    openNotificationButton: (state, action) => {
      state.notificationButton = action.payload;
    },
    setToken: (state, action) => {
      state.token = localStorage.getItem("token");
    },
    setFooterItemActive: (state, action) => {
      void (state.footerListing[action.payload].active =
        !state.footerListing[action.payload].active);
    },
    setShowFullModal: (state, action) => {
      state.showFullModal = action.payload;
    },
    setShowConfirmModal: (state, action) => {
      state.showConfirmModal = action.payload;
    },
    setAnimationModal: (state, action) => {
      state.animationModal = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  setRefresh,
  setToken,
  setFooterItemActive,
  openNotificationButton,
  setShowFullModal,
  setAnimationModal,
  setShowConfirmModal,
} = dataSlice.actions;

export default dataSlice.reducer;
