//lib
import { MDBModalBody } from "mdb-react-ui-kit";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setShowLoginModal } from "../../redux/reducer/settingReducer";

//component
import ModalBox from "../../components/modal/full.modal.box";
import LoginForm from "./component/form";
import useWindowDimensions from "../../components/common/window-dimension";

export default function Login({ show }) {
  const { width } = useWindowDimensions();
  const { showLoginModal, selectedLogin } = useSelector(
    (state) => state.setting
  );
  const dispatch = useDispatch();

  const backButton = () => {
    dispatch(setShowLoginModal(false));
  };

  return (
    <ModalBox
      className={"login-modal-container"}
      show={showLoginModal}
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      backButton={backButton}
      centered
      content={
        <MDBModalBody>
          <LoginForm />
        </MDBModalBody>
      }
    />
  );
}
