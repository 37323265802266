import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

//lib
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalFooter,
  MDBModalTitle,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
} from "mdb-react-ui-kit";
import { RemoveScroll } from "react-remove-scroll";
import { toast } from "react-toastify";

// redux
import {
  claimMission,
  getMissionList,
  getProfile,
  postUnlockMission,
} from "../../redux/reducer/profileReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  setPaymentLoading,
  setPaymentMethod,
  setShowConfirmModal,
  setShowPurchaseModal,
} from "../../redux/reducer/stockReducer";
import { closeAllModal, closeModalPaymentSuccess, openModalEvent, openModalOrderHistory, openModalPurchase } from "../../redux/reducer/modalReducer";
import { postPurchaseTicket, setSelectedPaymentMethod, setSurePay } from "../../redux/reducer/paymentReducer";

import Lottie from "lottie-react";
import SuccessGraphic from "../../assets/json/success-graphic.json"

// api
import apiServices from "../../services/api.services";
import { deleteCart, getCartData } from "../../redux/reducer/cartReducer";
import { setShowFullModal } from "../../redux/reducer/commonReducer";
import { Icon } from "@iconify/react";

export default function SuccessModal({ type }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");

  const { isOpenPaymentSuccess } = useSelector((state) => state.modal);

  return (
    <RemoveScroll>
      <MDBModal
        staticBackdrop
        className={`success-modal`}
        tabIndex="-1"
        show={isOpenPaymentSuccess}
      >
        <MDBModalDialog
          centered={true}
        >
          <MDBModalContent>
            <MDBModalBody>
              <section className="row d-flex justify-content-end">
                <Link
                  className="text-end pe-4 ps-5 pt-3" 
                  onClick={(e) => {
                    e.preventDefault();

                    dispatch(closeModalPaymentSuccess()) 
                    navigate('/profile')
                  }}
                >
                    <Icon icon="ep:close" width="25" color="#222a39" />
                </Link>
              </section>
              <section className="row d-flex justify-content-center p-5 mb-5">
                <section className="round-bg">
                    <Lottie animationData={SuccessGraphic} />
                </section>
                <h1 className="success-title">
                    Payment Successful
                </h1>
                <p className="success-desc">
                    Thank you for purchasing! We are delighted to inform you that we received your payment.
                </p>
                <MDBBtn 
                  className="view-btn mt-5" 
                  onClick={() => {
                    dispatch(closeModalPaymentSuccess())
                    navigate('/profile')
                    if(type === "ticket") {
                        dispatch(openModalEvent());
                    } else {
                        dispatch(openModalOrderHistory());
                    }
                  }}
                >
                  View Details
                </MDBBtn>
              </section>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </RemoveScroll>
  );
}
