import { useEffect, useState } from "react";

//lib
import { Icon } from "@iconify/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MDBBtn, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Helmet } from "react-helmet";

//component
import Login from "../Login";
import PurchasePage from "../Purchase";
import Partner from "./component/our.partner";
import PostPinned from "./component/post.pinned";
import SharingPost from "./component/sharing.post";
import PostInfo from "../Forum/component/post.info";
import CreatePost from "../Forum/component/create.post";
import EventListing from "./component/eventListingModal";
import EventCarousell from "./component/event.carousell";
import EventModal from "../Profile/component/eventModal";
import ProfileCard from "../Forum/component/profile.modal";
import ShareModal from "../../components/modal/share.modal";
import ForumSettingModal from "../../components/modal/forum.post.setting";
import NotificationModal from "../../components/modal/notification.modal";

//redux
import { getForumListing, setPostList } from "../../redux/reducer/forumReducer";

//hook
import useWindowDimensions from "../../components/common/window-dimension";

//redux
import { openModalEventListing } from "../../redux/reducer/modalReducer";
import {
  getUserOrderDetails,
  setSelectedForm,
  setSelectedPaymentMethod,
} from "../../redux/reducer/paymentReducer";
import { useDispatch, useSelector } from "react-redux";
import { getHome } from "../../redux/reducer/homeReducer";
import { getProfile } from "../../redux/reducer/profileReducer";
import { openNotificationButton } from "../../redux/reducer/commonReducer";
import { setShowConfirmModal } from "../../redux/reducer/stockReducer";
import ConfirmationModal from "../../components/modal/comfirmation.modal";

//helper
import scrollToTop from "../../helper/scrolltotop.helper";
import { useRef } from "react";

export default function Home() {
  const [settingModal, setSettingModal] = useState(false);
  const token = localStorage.getItem("token");
  const [start, setStart] = useState(4);
  const [length, setLength] = useState(12);
  const [hasMore, setHasMore] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [secondLoad, setSecondLoad] = useState(false);
  const [data, setData] = useState([]);
  const { pinnedPost, post } = useSelector((state) => state.home.home);
  const [selectedOption, setSelectedOption] = useState([]);
  const [initialLoading, setInitialLoading] = useState(false);
  const filterOwnerPost = selectedOption.find((item) => item.code === "post");
  const filterMostLike = selectedOption.find(
    (item) => item.code === "like_count"
  );
  const filterOldPost = selectedOption.find(
    (item) => item.code === "create_at"
  );
  const filterComment = selectedOption.find(
    (item) => item.code === "comment_count"
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const sharingPostRef = useRef();
  const { isOpenEventListing, isOpenPayment, isOpenUserEvent } = useSelector(
    (state) => state.modal
  );
  const { showLoginModal, shareModal } = useSelector((state) => state.setting);
  const { showConfirmModal } = useSelector((state) => state.stock);
  const { postList } = useSelector((state) => state.forum);
  const [fitler, setFilter] = useState("");
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");

  function updateMetaThemeColor() {
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", "#222A39");
  }

  function likeIncrement(likePostId) {
    const updatedData = postList.map((post) => {
      if (post.id === likePostId) {
        return {
          ...post,
          like_count: post.like_count + 1, // Increment like count
        };
      }
      return post;
    });

    dispatch(setPostList(updatedData));
  }

  const getListing = () => {
    setInitialLoading(true);

    let payload = {
      start: start,
      length: length,
      search: search,
      pinned: "",
      topic: fitler,
    };

    if (filterOwnerPost) {
      payload.filter = true;
    }

    if (filterMostLike) {
      payload.sort = "like_count";
    }

    if (filterComment) {
      payload.sortComment = "comment_count";
    }

    if (filterOldPost) {
      payload.order = "asc";
    }

    if(width > 991) {
      dispatch(getForumListing(payload))
      .unwrap()
      .then((res) => {
        let newData = [];
        for (var key in res.data.aaData) {
          newData.push({
            ...res.data.aaData[key],
            display_id: `post-${res.data.aaData[key].id}`,
          });
        }
        dispatch(setPostList(newData));
        if (newData.length < res.data.iTotalRecords) {
          setHasMore(true);
        }
        setInitialLoading(false);
      });
    }
  };

  const getMoreForumPost = () => {

    let payload = {
      start: firstLoad ? 4 : secondLoad ? 8 : start + 12,
      length: firstLoad ? 4 : length,
      search: search,
      pinned: "",
      topic: fitler,
    };

    if (filterOwnerPost) {
      payload.filter = true;
    }

    if (filterMostLike) {
      payload.sort = "like_count";
    }

    if (filterComment) {
      payload.sortComment = "comment_count";
      payload.order = "desc";
    }

    if (filterOldPost) {
      payload.order = "asc";
    }

    if(width > 991) {
      dispatch(getForumListing(payload))
      .unwrap()
      .then((res) => {
        if(secondLoad) {
          setSecondLoad(false)
        } else if (firstLoad) {
          setFirstLoad(false)
          setSecondLoad(true)
        }

        if(secondLoad) {
          setStart(start + 4);
        } else if (!firstLoad && !secondLoad) {
          setStart(start + 12);
        }

        let newData = [];
        for (var key in res.data.aaData) {
          newData.push({
            ...res.data.aaData[key],
            display_id: `post-${res.data.aaData[key].id}`,
          });
        }
        if (start !== 0 && newData.length === 0) {
          setHasMore(false);
        }
        dispatch(setPostList([...postList, ...newData]));
      });
    }
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(getProfile());
    }
  }, [token]);

  useEffect(() => {
    setType('')
    // setFirstLoad(true)
    dispatch(getHome());

    return () => {
      dispatch(openNotificationButton(false));
    };
  }, []);

  useEffect(() => {
    updateMetaThemeColor();
  }, []);

  useEffect(() => {
    if(!firstLoad) {
      setStart(0)
    }

    let payload = {
      start: 0,
      length: firstLoad ? 4 : length,
      search: search,
      pinned: "",
      topic: fitler,
    };

    if (filterOwnerPost) {
      payload.filter = true;
    }

    if (filterMostLike) {
      payload.sort = "like_count";
    }
    if (filterComment) {
      payload.sortComment = "comment_count";
    }

    if (filterOldPost) {
      payload.order = "asc";
    }

    setInitialLoading(true);

    if(width > 991) {
      dispatch(getForumListing(payload))
        .unwrap()
        .then((res) => {
          setInitialLoading(false);
          let newData = [];
          for (var key in res.data.aaData) {
            newData.push({
              ...res.data.aaData[key],
              display_id: `post-${res.data.aaData[key].id}`,
            });
          }
          dispatch(setPostList(newData));

          if (newData.length < res.data.iTotalRecords) {
            setHasMore(true);
          }
        });
    }
  }, [fitler, search, selectedOption]);

  useEffect(() => {
    if(firstLoad && postList.length === 4) {
      getMoreForumPost()
    }
  }, [firstLoad, postList])

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const referenceNo = params.get("reference_no");

    if (referenceNo) {
      dispatch(setSelectedForm(""));
      dispatch(setSelectedPaymentMethod(""));
      dispatch(openModalEventListing());
      dispatch(
        getUserOrderDetails({
          reference_no: referenceNo,
        })
      );
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Reunion | Home</title>
        <meta name="description" content="Join Reunion's vibrant car club community to discuss various car topics in our forum, participate in exclusive car events, and stay updated with the latest in car culture and knowledge." />
      </Helmet>

      <section
        className="container home-container"
        style={{
          position: width >= 991 ? "relative" : "unset",
          paddingTop:
            pinnedPost.length === 0
              ? width >= 991
                ? "2em"
                : "17em"
              : width >= 991
              ? "2em"
              : "17em",
        }}
      >
        {/* passed by chatgpt */}
        <NotificationModal />
        <PostPinned />
        <EventCarousell />
        {width >= 991 && <Partner />}
        <article ref={sharingPostRef} >
          <SharingPost
            start={start}
            fitler={fitler}
            search={search}
            setFilter={setFilter}
            setSearch={setSearch}
            setStart={setStart}
            items={data}
            hasMore={hasMore}
            getMoreData={() => getMoreForumPost()}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            loading={initialLoading}
            secondLoad={secondLoad}
          />
        </article>
        {width < 991 && (
          <section className="row justify-content-center m-4">
            <MDBBtn
              className="button-group --notification-button --view-post"
              type="button"
              onClick={() => {
                navigate("/forum");
              }}
            >
              <p style={{ cursor: "pointer" }}>
                View All Post&nbsp;
                <Icon 
                  icon="icon-park-outline:more-app" 
                  color="#FAFAFA" 
                  width={17} 
                />
              </p>
            </MDBBtn>
          </section>
        )}
        {width < 991 && <Partner />}
        <PostInfo
          setShow={setSettingModal}
          getForumPost={getListing}
          likeIncrement={likeIncrement}
        />
        <CreatePost 
          getForumPost={getListing} 
          setData={setData} 
          setHasMore={setHasMore} 
          setFilter={setFilter} 
          start={start} 
          setStart={setStart} 
          sharingPostRef={sharingPostRef} 
          setType={setType}
        />
        <ProfileCard />
      </section>

      {isOpenEventListing && <EventListing />}
      {isOpenUserEvent && <EventModal />}
      
      {isOpenPayment && <PurchasePage />}
      {showLoginModal && <Login />}
      {settingModal && (
        <ForumSettingModal show={settingModal} setShow={setSettingModal} />
      )}
      {showConfirmModal && (
        <ConfirmationModal
          show={showConfirmModal}
          setShow={setShowConfirmModal}
          type={type ? "post" : "ticket" }
        />
      )}
      {shareModal && width > 991 && (
        <ShareModal url={window.location.href} type="1" />
      )}
    </>
  );
}
