//lib
import { useSearchParams } from "react-router-dom";

//component
import InfoHeader from "../../../components/header/info-header";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setAnimationModal } from "../../../redux/reducer/commonReducer";
import { openModalEventListing, closeModalPurchase  } from "../../../redux/reducer/modalReducer";
import { setClearOrderDetails, setSelectedForm } from "../../../redux/reducer/paymentReducer.js";

export default function PurchaseDetails() {
  const dispatch = useDispatch();
  const { selectedForm, titleName } = useSelector((state) => state.payment);
  const [ searchParams, setSearchParams ] = useSearchParams();

  const closeModal = () => { 
    dispatch(openModalEventListing());
    dispatch(closeModalPurchase());
    dispatch(setClearOrderDetails(''))
    setSearchParams("");
    dispatch(setAnimationModal(false))
  };

  const backButton = () => {
    dispatch(setSelectedForm(""));
  };

  return (
    <InfoHeader
      title={titleName[selectedForm]}
      backButton={selectedForm === "" ? closeModal : backButton}
      backButtonNoAnimation={selectedForm === "" ? closeModal : backButton}
    />
  );
}
