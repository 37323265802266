import { useRef, useState } from "react";

//lib
import ReactPlayer from "react-player";
import { Image } from "primereact/image";

//hook
import useWindowDimensions from "../../../components/common/window-dimension";

export default function VideoCard({ image, index, poster, type, videoMov, video }) {
  const playerRef = useRef(null);
  const { width } = useWindowDimensions();
  const [canvas, setCanvas] = useState(null);

  const isAndroid = /Android/i.test(navigator.userAgent);
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  const videoUrl = isAndroid ? video : isIOS ? video : video;

  const handleOnReady = () => {
    const video = document.querySelector(`#video-${index} video`);

    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0);
    const base64 = canvas.toDataURL("image/png");
    setCanvas(base64);
  };

  return (
    <section style={{ height: type ? type == "gif" ? "50px" : "35px" : width >= 991 ? "30em" : "20em", width: type ? type == "gif" ? "50px" : "35px" : "", marginRight: type ? type == "gif" ? "0px" : "0.5em" : "0px" }} onClick={() => {}}>
      {canvas === null ? (
        <ReactPlayer
          config={{
            file: {
              attributes: { poster: poster ? poster : null, playsinline: type ? "true" : "false", },
              tracks: [
                {
                  src: "captions_en.vtt",
                  kind: "captions",
                  label: "english_captions",
                },
              ],
            },
          }}
          onClick={() => {}}
          ref={playerRef}
          style={{
            height: width >= 991 ? "" : "13em",
            width: width < 991 ? "100% !important" : "",
            zIndex: 2,
            position: "relative",
          }}
          loop={type ? true : false}
          controls={type ? false : true}
          id={image ? `video-${index}` : ''}
          url={videoUrl}
          width="100%"
          height="100%"
          onReady={image ? () => handleOnReady() : null}
          className="react-player"
          playing={type ? true : false}
        />
      ) : (
        <Image src={canvas} alt="Image" width="120" />
      )}
    </section>
  );
}
