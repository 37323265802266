//lib
import React, { useState } from 'react';
import Slider from 'react-slick';
import { LazyLoadImage } from 'react-lazy-load-image-component';

//lib css
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function ImageSlider ({ images, alt, divClassName, labelClassName }) {

    const [currentSlide, setCurrentSlide] = useState(0);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    };

    return (
        <>
        <article className={`${divClassName}`}>
            <Slider {...settings}>
                {images?.map((image, index) => (
                    <section key={index} className='d-flex justify-content-center'>
                        <LazyLoadImage src={image.url} alt={alt} title={alt} />
                    </section>
                ))}
            </Slider>
        </article>
        <p className={`${labelClassName}`}>{currentSlide + 1}/{images?.length}</p>
        </>
    );
};
