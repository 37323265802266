//lib
import { Link, useNavigate } from "react-router-dom";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

//redux
import { useSelector } from "react-redux";

//component
import VideoCard from "../../pages/Forum/component/video.card";
import { closeModalPostPreview } from "../../redux/reducer/modalReducer";

export default function MainNavBar() {
  const navigate = useNavigate();
  const { navigation_item } = useSelector((state) => state.common);

  return (
    <nav className="fixed-bottom main-navbar-container">
      <section className="row">
        {navigation_item.map((item, index) => (
          <Link
            className="col"
            key={index}
            onClick={(e) => {
              e.preventDefault();

              navigate(item.navigate);
            }}
          >
            {window.location.pathname === item.navigate ? (
              <LazyLoadImage alt="reunion-nav" title="reunion-nav" src={item.animation} width={50} height={50} style={{ cursor: "pointer" }}/>
            ) : (
              <LazyLoadImage alt="reunion-nav" title="reunion-nav" src={item.image} width={50} height={50} style={{ cursor: "pointer" }} />
            )}
          </Link>
        ))}
      </section>
    </nav>
  );
}
