//lib
import { Icon } from "@iconify/react";
import { useFormikContext } from "formik";
import { MDBCol, MDBRow, MDBContainer } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

//asset
import { Logo2 } from "../../../assets/images";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setSelectedForm } from "../../../redux/reducer/paymentReducer";

export default function PaymentMethod() {
  const dispatch = useDispatch();
  const { selectedPaymentMethod, totalPoint } = useSelector((state) => state.payment);
  const { selectedEventId } = useSelector((state) => state.event);
  const { userWallet } = useSelector((state) => state.profile);
  const { errors, values } = useFormikContext();

  const totalPrice = selectedEventId.price * values.quantity;
  const cleanedNumber = userWallet.point_balance
    ? parseFloat(userWallet.point_balance.replace(/,/g, ""))
    : 0;

  return (
    <article className="container mt-5">
      <header>
        <section className="d-flex align-items-center">
          <section className="short-line" />
          <h3 className="_label --form-title">Payment Method</h3>
        </section>
        <section className="row">
          <p className="_label --detail ms-4">
            We'll only contact you about updates to your order
          </p>
        </section>
      </header>
      <section className="info-div">
        <section className="row">
          {selectedEventId.payment_type !== "point" && (
            <article
              className="add_btn"
              onClick={() => dispatch(setSelectedForm("choose_payment"))}
            >
              <Icon icon="carbon:add-alt" height={"0.925em"} />
              <p className="_label --add_btn">&nbsp; Choose</p>
            </article>
          )}

          {values.payment_type !== "" &&
            selectedEventId.payment_type !== "point" && (
              selectedPaymentMethod.image &&
              <section className="row payment-row m-0 mt-3">
                <article className="col-3">
                  <section className="image-col image-col2">
                    <LazyLoadImage
                      src={selectedPaymentMethod.image}
                      alt={selectedPaymentMethod.name}
                      className="payment-image"
                    />
                  </section>
                </article>
                <article className="col-7">
                  <p className="_label --name">
                    {selectedPaymentMethod.name}
                  </p>
                </article>
              </section>
            )}

          {!errors.payment_type && selectedEventId.payment_type !== "point" &&
            <p className='_label --detail ms-1 mt-4'>
              Note: You will get{' '}
              <span className='_label _span'>{parseInt(totalPoint)} points </span>
              from this purchase after payment success
            </p>
          }

        {errors && errors.payment_type && (
            <p className="text-danger  error-message">
              <Icon icon="material-symbols:info-outline" /> &nbsp;{" "}
              {errors.payment_type}
            </p>
          )}    

          {selectedEventId.payment_type === "point" && (
            <>
              <section className="row payment-row m-0 mt-3 mb-2">
                <article className="col-3">
                  <section
                    className="image-col image-col2"
                    style={{
                      width: "3em",
                      height: "1.1em",
                      display: "flex",
                      alignItems: "center",
                    }}
                    >
                    <LazyLoadImage
                      src={Logo2}
                      alt="reunion-point"
                      className="payment-image"
                      style={{ width: "100%" }}
                      />
                  </section>
                </article>
                <article className="col-4">
                  <p className="_label --name">Reunion Point</p>
                </article>
                <article className="col text-end">
                  <p className="_label --name">
                    Balance : {userWallet.point_balance}{" "}
                  </p>
                </article>
              </section>
              {selectedEventId.payment_type === "point" &&  userWallet.point_balance !== undefined && totalPrice >= cleanedNumber && 
                <p className="text-danger  error-message">
                  <Icon icon="material-symbols:info-outline" /> &nbsp;{" "}
                  Insufficient reunion wallet point
                </p>
              }
            </>
          )}
        </section>
      </section>
    </article>
  );
}
