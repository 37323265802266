import { useState } from "react";

//lib
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

export default function AttendeeUser({ item, key }) {

  return (
    <article className="col-12 col-md-12">
      <section className="row attendee-row pointer">
        <p>
          Attendee Name : <span className="_span">{item.name}</span>
        </p>
        <p>
          Attendee Contact : <span className="_span">{item.contact}</span>
        </p>
      </section>
    </article>
  );
}
