//lib
import { Field, useFormikContext } from "formik";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

export default function Select({
  label,
  name,
  options,
  disabled,
  placeholder,
  className,
}) {
  const { errors } = useFormikContext();

  return (
    <article
      className={`col form-group _select ${className} ${
        errors[name] ? "_errors2" : ""
      }`}
    >
      {label && (
        <section className="row">
          <p className="form-label">{label}</p>
        </section>
      )}
      <Field as="select" name={name} disabled={disabled}>
        {/* <option value="">
          {placeholder ? placeholder : "- Please Select -"}
        </option> */}
        {options &&
          options.map((option) => (
            <option key={option.id} value={option.value}>
              {option.label}
            </option>
          ))}
      </Field>
      {name !== "gender" && errors && errors[name] ? (
        <p className="text-danger text-right no-padding error-message">
          {errors[name]}
        </p>
      ) : null}
    </article>
  );
}
