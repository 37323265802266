//lib
import { TwitterShareButton, TelegramShareButton } from "react-share";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MDBCol, MDBContainer, MDBModalBody, MDBRow } from "mdb-react-ui-kit";

//component
import ModalBox from "../../components/modal/full.modal.box";
import InfoHeader from "../../components/header/info-header";

//hook
import useWindowDimensions from "../../components/common/window-dimension";

//redux
import { useDispatch, useSelector } from "react-redux";
import { setShareModal } from "../../redux/reducer/settingReducer";

export default function ShareModal({ url, shareProduct, imageUrl, type }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { width } = useWindowDimensions();
  const { id } = useParams();
  const { shareModal } = useSelector((state) => state.setting);
  const { shareUrl } = useSelector((state) => state.forum);

  const location = useLocation();
  const { search } = location;
  const queryParams = new URLSearchParams(search);
  const post = queryParams.get("post");
  const cleanedUrl = url.replace(/\/share$/, "");

  const postId = post ? post.split("/")[0] : null;

  const backButton = () => {
    dispatch(setShareModal());
    if (shareProduct) {
      navigate(`/product/${id}`);
    } else {
      if (width < 991) {
        if(pathname === '/') {
          navigate(`/?post=${postId}`);
        } else {
          navigate(`/forum?post=${postId}`);
        }
      } else {
        navigate(`/?post=${postId}`);
      }
    }
  };

  const createBlobFromURL = async (element) => {
    try {
      const response = await fetch(element);
      if (!response.ok) {
        throw new Error("Image request failed");
      }

      const blob = await response.blob();
      return blob;
    } catch (error) {
      throw error;
    }
  };

  // const otherFunction = async (image) => {
  //   const blob = await createBlobFromURL(image)
  //   const shareData = {
  //     files: [new File([blob], `share.jpg`, { type: blob.type })],
  //   }

  //   await navigator
  //     .share({
  //       // files: shareData.files,
  //       text: shareProduct ? `${shareProduct}` : `${url}`,
  //     })
  //     .then((res) => {
  //     })
  //     .catch((ex) => {
  //     });
  // };

  const otherFunction = async (title, image, url) => {
    // setLoading(true)
    const blob = await createBlobFromURL(image);
    const shareData = {
      files: [new File([blob], `share.jpg`, { type: blob.type })],
    };
    if ("canShare" in navigator && type) {
      await navigator
        .share({
          text: `${title}\nHey everyone, I came across this amazing discovery. To explore further, feel free to check it out at: ${url}`,
        })
        .then((res) => {})
        .catch((ex) => {});
      // setLoading(false)
    } else {
      await navigator
        .share({
          // files: shareData.files,
          text: `${title}\nHey everyone, I came across this amazing discovery. To explore further, feel free to check it out at: ${url}`,
        })
        .then((res) => {})
        .catch((ex) => {});
    }
  };

  //u=https:example.com?imageurl=${this.state.imageUrl}}

  function FacebookShareButton({ url, imageUrl }) {
    const handleShareClick = () => {
      //const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=https://reunion.ax-tech.co/?post=150&imageurl=${encodeURIComponent(imageUrl)}`;
      const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        url
      )}`;
      window.open(facebookShareUrl, "_blank");
    };

    return (
      <div className="_action-card --social pointer" onClick={handleShareClick}>
        <Icon
          icon="logos:facebook"
          width="1.5em"
          height="1.5em"
          className="me-3"
        />
        Facebook
      </div>
    );
  }

  return (
    <article>
      {/* <HeadTags title={url} metaDescription={shareProduct} imageUrl={imageUrl} /> */}
      <ModalBox
        centered
        scrollable
        staticBackdrop={true}
        show={shareModal}
        className={"share-modal-container"}
        backButton={backButton}
        screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
        onDismiss={() => dispatch(setShareModal())}
        content={
          <MDBModalBody>
            <InfoHeader title={"Share Via"} backButton={backButton} />
            <section className="container body-container">
              <section className="row m-0 gap-3">
                {" "}
                <TwitterShareButton url={shareUrl}>
                  <article className="_action-card --social pointer">
                    <Icon
                      icon="logos:twitter"
                      width="1.5em"
                      height="1.5em"
                      className="me-3"
                    />
                    Twitter
                  </article>
                </TwitterShareButton>
                <FacebookShareButton url={shareUrl} imageUrl={imageUrl} />
                  {/* <div className="_action-card --social pointer">
                    <Icon
                      icon="logos:facebook"
                      width="1.5em"
                      height="1.5em"
                      className="me-3"
                    />
                    Facebook
                  </div>
                </FacebookShareButton> */}
                <TelegramShareButton url={shareUrl}>
                  <article className="_action-card --social pointer">
                    <Icon
                      icon="logos:telegram"
                      width="1.5em"
                      height="1.5em"
                      className="me-3"
                    />
                    Telegram
                  </article>
                </TelegramShareButton>
                <article
                  onClick={() => { otherFunction('Welcome to Reunion!', imageUrl, shareUrl) }}
                  className="_action-card --social pointer"
                >
                  Other
                </article>
                <article className="_action-card --save pointer">
                  <CopyToClipboard
                    onCopy={() => {
                      toast(
                        <section>
                          <section className="row m-0">
                            <article className="text-start">
                              <p className="_label">Link copied!</p>
                            </article>
                          </section>
                        </section>,
                        {
                          position: "top-right",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          theme: "light",
                        }
                      );
                    }}
                    text={shareUrl}
                  >
                    <p className="pointer">
                      <Icon icon="mingcute:copy-line" /> Copy Link
                    </p>
                  </CopyToClipboard>
                </article>
              </section>
            </section>
          </MDBModalBody>
        }
      />
    </article>
  );
}
