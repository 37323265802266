import axios from 'axios'
import { toast } from 'react-toastify'

axios.interceptors.response.use(null, (error) => {
  const authError = error.response && error.response.status === 401
  const unexpectedError = error.response.status === 500

  if (authError) {
    localStorage.clear()
  }

  if (unexpectedError) {
    toast.error('An unexpected error occurred @contact support', {
      theme: 'colored',
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
    })
  }
  return Promise.reject(error)
})

function setToken(token) {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${token}`,
    Accept: `application/json`,
  }
}

const httpService = {
  get: axios.get,
  patch: axios.patch,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setToken,
}

export default httpService
