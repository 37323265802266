import { useEffect, useRef, useState } from "react";

//lib
import Lottie from "lottie-react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBBtn,
  MDBTooltip,
} from "mdb-react-ui-kit";

//assets
import ViewDown from "../../../assets/json/view-down.json";

//redux
import { useDispatch, useSelector } from "react-redux";
import {
  putUpdateOrder,
  setPurchaseInfo,
} from "../../../redux/reducer/profileReducer";
import { getOrderHistory } from "../../../redux/reducer/orderReducer";
import { setAnimationModal } from "../../../redux/reducer/commonReducer";
import {
  closeModalOrderHistory,
  openModalPurchaseInfo,
} from "../../../redux/reducer/modalReducer";

export default function OredrCard({ item }) {
  const lottieRef = useRef();

  const dispatch = useDispatch();
  const now = new Date();
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const formattedTime = `${hours}:${minutes}`;
  const [openCard, setOpenCard] = useState(false);

  const { isOpenPurchaseInfo } = useSelector((state) => state.modal);

  const setPurchaseData = () => {
    dispatch(setPurchaseInfo(item));
    dispatch(openModalPurchaseInfo());
    dispatch(closeModalOrderHistory());
    dispatch(setAnimationModal(false));
  };

  const updateOrderStatus = (id) => {
    dispatch(putUpdateOrder({ id: id }))
      .unwrap()
      .then((res) => {
        dispatch(getOrderHistory({ order_type: "product" }));
        toast.success("Receive Item successful.", {
          autoClose: 3000,
          theme: "light",
          hideProgressBar: true,
          closeOnClick: true,
          closeButton: false,
        });
      });
  };

  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.setSpeed(3.0); // Set playback speed to 2.0
    }
  }, []);

  return (
    <section className="col-12">
      <article className="container order-card-container">
        <section className="row">
          <article className="col-9" onClick={() => setOpenCard(!openCard)}>
            <h5 className="_label --order_no mb-0">
              SALES ORDER : {item.sales_order}
            </h5>
          </article>
          <article className="col text-end">
            {!isOpenPurchaseInfo && (
              <MDBTooltip tag="a" title="Info">
                <Icon
                  icon="iconamoon:invoice-light"
                  color="EEEEEE"
                  height={"1.3em"}
                  style={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => {
                    setPurchaseData();
                  }}
                />
              </MDBTooltip>
            )}
          </article>
        </section>
        <section className="row" onClick={() => setOpenCard(!openCard)}>
          <p className="_label --order_no">
            Status : {item.order_status}
          </p>
        </section>
        <section
          className={openCard ? "card-display active" : "card-display"}
          style={{ height: openCard ? 78 + 3 * 50 : 0 }}
        >
          <section className="row mt-1" onClick={() => setOpenCard(!openCard)}>
            <article className="col">
              <p className="_label --form">From :</p>
            </article>
            <article className="col">
              <p className="_label --form">To :</p>
            </article>
          </section>
          <section className="row" onClick={() => setOpenCard(!openCard)}>
            <article className="col mt-1">
              <section className="row">
                <p className="_label --address">
                  {item.from && item.from.name}
                </p>
              </section>
              <section className="row">
                <p className="_label --address">
                  {item.from && item.from.company_registration_no}
                </p>
              </section>
            </article>
            <article className="col mt-2">
              <section className="row">
                <p className="_label --user">
                  {item.order_type === "Self Pickup"
                    ? "Self Pickup"
                    : item.receiver_address && item.receiver_address.name}
                </p>
              </section>
              <section className="row">
                <p className="_label --user">
                  {item.order_type === "Self Pickup" && item.to
                    ? item.to.phone_number
                    : item.receiver_address &&
                      item.receiver_address.phone_number}
                </p>
              </section>
            </article>
          </section>
          <section className="line"></section>
          <article className="container step-container">
            {item.order_status !== "Completed" &&
              item.order_status !== "Failed" &&
              item.tracking_detail &&
              item.tracking_detail.date && (
                <section className="row step-row">
                  <article className="col-4">
                    {item.tracking_detail && (
                      <p className="_label --date">
                        Today {formattedTime}
                      </p>
                    )}{" "}
                  </article>
                  <article className="col-2">
                    <Icon
                      icon="material-symbols:circle"
                      color="#EEEEEE"
                      height="0.6em"
                    />
                  </article>
                  <article className="col">
                    <MDBBtn
                      className="receive-btn"
                      onClick={() => {
                        updateOrderStatus(item.id);
                      }}
                    >
                      Receive Item
                    </MDBBtn>
                  </article>
                </section>
              )}
            {item.order_status === "Accepted" &&
              item.order_status !== "Failed" &&
              item.order_type === "Self Pickup" && (
                <section className="row step-row">
                  <article className="col-4">
                    {item.tracking_detail && (
                      <p className="_label --date">
                        Today {formattedTime}
                      </p>
                    )}{" "}
                  </article>
                  <article className="col-2">
                    <Icon
                      icon="material-symbols:circle"
                      color="#EEEEEE"
                      height="0.6em"
                    />
                  </article>
                  <article className="col">
                    <MDBBtn
                      className="receive-btn"
                      onClick={() => {
                        updateOrderStatus(item.id);
                      }}
                    >
                      Receive Item
                    </MDBBtn>
                  </article>
                </section>
              )}
            {item.order_status == "Completed" &&
              item.order_status !== "Failed" && (
                <section className="row step-row">
                  <article className="col-4">
                    {item.tracking_detail && (
                      <p className="_label --date">
                        {item.complete_at}
                      </p>
                    )}{" "}
                  </article>
                  <article className="col-2">
                    <Icon
                      icon="material-symbols:circle"
                      color="#EEEEEE"
                      height="0.6em"
                    />
                  </article>
                  <article className="col">
                    <p className="_label --status">Thank You</p>
                  </article>
                </section>
              )}
            {item.tracking_detail &&
              item.tracking_detail.date &&
              item.order_type !== "Self Pickup" &&
              item.order_status !== "Failed" && (
                <section className="row step-row">
                  <article className="col-4">
                    {item.tracking_detail && (
                      <p className="_label --date">
                        {item.tracking_detail.date} {item.tracking_detail.time}
                      </p>
                    )}{" "}
                  </article>
                  <article className="col-2">
                    <Icon
                      icon="material-symbols:circle"
                      color="#EEEEEE"
                      height="0.6em"
                    />
                  </article>
                  <article className="col">
                    <p
                      className="_label --status"
                      style={{ textDecoration: "underline" }}
                    >
                      <a
                        href={
                          item.tracking_detail &&
                          item.tracking_detail.tracking_no
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#EEEEEE" }}
                      >
                        {" "}
                        Tracking Number
                      </a>{" "}
                    </p>
                  </article>
                </section>
              )}
            {item.accept_at &&
              item.order_status !== "Requested" &&
              item.order_status !== "Failed" && (
                <>
                  {item.order_type == "Self Pickup" && (
                    <section className="row step-row">
                      <article className="col-4">
                        {item.accept_at && (
                          <p className="_label --date">
                            {item.accept_at}
                          </p>
                        )}{" "}
                      </article>
                      <article className="col-2">
                        <Icon
                          icon="material-symbols:circle"
                          color="#EEEEEE"
                          height="0.6em"
                        />
                      </article>
                      <article className="col">
                        <p className="_label --status">
                          Ready To Pick Up
                        </p>
                      </article>
                    </section>
                  )}
                  <section className="row step-row">
                    <article className="col-4">
                      {item.accept_at && (
                        <p className="_label --date">
                          {item.accept_at}
                        </p>
                      )}{" "}
                    </article>
                    <article className="col-2">
                      <Icon
                        icon="material-symbols:circle"
                        color="#EEEEEE"
                        height="0.6em"
                      />
                    </article>
                    <article className="col">
                      <p className="_label --status">Order Accepted</p>
                    </article>
                  </section>
                </>
              )}
            {item.payment_detail &&
              item.payment_detail.date &&
              item.order_status !== "Failed" && (
                <section className="row step-row">
                  <article className="col-4">
                    {item.payment_detail && (
                      <p className="_label --date">
                        {item.payment_detail.date} {item.payment_detail.time}
                      </p>
                    )}
                  </article>
                  <article className="col-2">
                    <Icon
                      icon="material-symbols:circle"
                      color="#EEEEEE"
                      height="0.6em"
                    />
                  </article>
                  <article className="col">
                    <p className="_label --status">
                      {item.payment_detail && item.payment_detail.text}
                    </p>
                  </article>
                </section>
              )}
            {item.order_status == "Pending" && (
              <section className="row step-row">
                <article className="col-4">
                  {item.tracking_detail && (
                    <p className="_label --date">{item.complete_at}</p>
                  )}{" "}
                </article>
                <article className="col-2">
                  <Icon
                    icon="material-symbols:circle"
                    color="#EEEEEE"
                    height="0.6em"
                  />
                </article>
                <article className="col">
                  <p className="_label --status">Pending</p>
                </article>
              </section>
            )}
            {item.order_status == "Failed" && (
              <section className="row step-row">
                <article className="col-4">
                  {item.tracking_detail && (
                    <p className="_label --date">{item.complete_at}</p>
                  )}{" "}
                </article>
                <article className="col-2">
                  <Icon
                    icon="material-symbols:circle"
                    color="#EEEEEE"
                    height="0.6em"
                  />
                </article>
                <article className="col">
                  <p className="_label --status">Failed</p>
                </article>
              </section>
            )}
          </article>
        </section>
        <section
          className={
            openCard ? "open-btn --opened pointer" : "open-btn pointer"
          }
          onClick={() => setOpenCard(!openCard)}
        >
          <div>
            <Lottie
              lottieRef={lottieRef}
              animationData={ViewDown}
              style={{ width: "2.5em" }}
              className={openCard ? "rotated-animation" : "normal-animation"}
            />
          </div>
        </section>
      </article>
    </section>
  );
}
