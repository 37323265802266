//lib
import { Icon } from "@iconify/react";
import { MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";

//redux
import { useDispatch } from "react-redux";
import { setCreatePostModal } from "../../../redux/reducer/forumReducer";
import { setShowLoginModal } from "../../../redux/reducer/settingReducer";

//component
import ButtonFilter from "../../../components/element/button.filter";
import { ButtonNotification } from "../../../components/element/button.notification";

export default function ForumPostHeader({ selectedOption, setSelectedOption ,setStart }) {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  return (
    <section className="header-forum">
      <article>
        <h1 className="_label --title-forum">Forum Post</h1>
      </article>
      <article className="action-button">
        <ButtonFilter
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          setStart={setStart}
        />
        <ButtonNotification />
        <MDBBtn
          className="button-group --notification-button --create-post"
          type="button"
          onClick={() => {
            if (token) {
              dispatch(setCreatePostModal("create-post"));
            } else {
              dispatch(setShowLoginModal(true));
            }
          }}
        >
          <p>
            Post <Icon icon="mingcute:add-line" color="#FAFAFA" width={18} />
          </p>
        </MDBBtn>
      </article>
    </section>
  );
}
