import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import httpService from "../../services/http.services"
import auth from "../../services/auth.services"

export const initialData = {
  orderHistory: [],
  loading: false,
  reopenPurchase: false
}

export const getOrderHistory = createAsyncThunk('order/post_order_history', async(payload, thunkAPI) => {
    try{
        httpService.setToken(auth.getToken());
        const { data } = await httpService.post(process.env.REACT_APP_API_URL + `/user_order_history`, payload)
        return data
    }catch(error){
        return thunkAPI.rejectWithValue(error)
    }
})

const orderSlice = createSlice({
  name: "order",
  initialState: initialData,
  reducers: {
    setReopenPurchase: (state, action) => {
      state.reopenPurchase = action.payload
    },
  },
  extraReducers: {
    [getOrderHistory.pending]: (state, action) => {
        state.loading = true
    },
    [getOrderHistory.fulfilled]: (state, action) => {
        state.loading = false
        state.orderHistory = action.payload.data
    },
    [getOrderHistory.rejected]: (state, action) => {
        state.loading = false
    },
  },
})

export const { setRefresh, setReopenPurchase } = orderSlice.actions

export default orderSlice.reducer

// example for fetching API