//lib
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { EffectCreative } from "swiper";
import { useFormikContext } from "formik";
import { Swiper, SwiperSlide } from "swiper/react";
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";

//component
import VideoCard from "./video.card";

//hook
import useWindowDimensions from "../../../components/common/window-dimension";
import { openModalPostPreview } from "../../../redux/reducer/modalReducer";
import { useDispatch } from "react-redux";
import { setCreatePostModal, setPreviewItem } from "../../../redux/reducer/forumReducer";
import { useEffect, useState } from "react";
import { Image as Images } from "primereact/image";

export default function CreatePostContent({ handleAttachment }) {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const { values, errors, setFieldValue } = useFormikContext();

  const [imageWidth, setImageWidth] = useState(null);
  const [imageHeight, setImageHeight] = useState(null);
  const [sizeLimitError, setSizeLimitError] = useState('')

  const attachmentStatus =
    values?.attachment?.length > 0 &&
    values?.attachment[0] &&
    values?.attachment[0]?.type?.split("/")[0];
    
  const MAX_LIMIT = {
    image: 5,
    video: 1,
    application: 1,
  };

  const loadImageDimensions = (previewItem) => {

    const img = new Image();
    img.src = URL.createObjectURL(previewItem);
  
    img.onload = function () {
      setImageWidth(img.width);
      setImageHeight(img.height);
    };
  }

  const validateImageDimensions = (previewItem) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(previewItem);
  
      img.onload = function () {
        resolve({ width: img.width, height: img.height });
      };
    });
  };
  
  const validateFileSize = async () => {
    for (const item of values.attachment) {
      if (item?.type?.split("/")[0] === "application" && item.size / (1024 * 1024) > 5) {
        setSizeLimitError('File size limit is 5MB.');
        return;
      } else if (item?.type?.split("/")[0] === "video" && item.size / (1024 * 1024) > 50) {
        setSizeLimitError('Video file size limit is 50MB.');
        return;
      } else if (item?.type?.split("/")[0] === "image") {
        const dimensions = await validateImageDimensions(item);

        if (dimensions.width > 2500 || dimensions.height > 2500) {
          setSizeLimitError('Image dimensions should not exceed 2500px.');

          return;
        }
      // } else if (
      //   (item?.type?.split("/")[0] === "application" && item.size / (1024 * 1024) > 5) &&
      //   (item?.type?.split("/")[0] === "video" && item.size / (1024 * 1024) > 50)
      // ) {
      //   setSizeLimitError('File size limit is 5MB, video file size limit is 50MB.');
      //   return;
      } else {
        setSizeLimitError('');
      }
    }
  };

  useEffect(() => {
    validateFileSize();
  }, [values.attachment.length]);

  return (
    <article className="container create-post-step3">
      <section>
        <Swiper
          slidesPerView={width >= 991 ? 6 : width >= 1200 ? 7 : 2.5}
          spaceBetween={10}
          grabCursor={true}
          creativeEffect={{
            prev: {
              shadow: false,
              translate: ["-120%", 0, -500],
            },
            next: {
              shadow: false,
              translate: ["120%", 0, -500],
            },
          }}
          modules={[EffectCreative]}
        >
          {values?.attachment?.length > 0 &&
            values.attachment.map((item, index) => (
              <SwiperSlide key={index} className="upload-image-container" onMouseOver={() => loadImageDimensions(item)}> 
                {item?.type?.split("/")[0] === "image" ? (
                  <Images
                    src={URL.createObjectURL(item)}
                    alt="reunion-creating-post"
                    width="120"
                  />
                ) : item?.type?.split("/")[0] === "video" ? (
                  <VideoCard
                    video={URL.createObjectURL(item)}
                    index={index}
                    image={true}
                  />
                ) : item?.type?.split("/")[0] === "application" ? (
                  <section className="document-upload">
                    <article className="container">
                      <Icon
                        icon="heroicons:document-solid"
                        color="#c5c5c5"
                        width={60}
                      />
                      <p>{item.name}</p>
                    </article>
                  </section>
                ) : (
                  <Images src={item.url} alt="reunion-creating-post" width="120" />
                )}
                {values.attachment.length > 1 && (
                  <section className="remove-icon">
                    <Icon
                      icon="charm:cross"
                      color="#c5c5c5"
                      width={60}
                      onClick={() => {
                        const newValue = values.attachment.filter(
                          (item, indexItem) => indexItem !== index
                        );
                        setFieldValue("attachment", newValue);
                        setSizeLimitError('')
                      }}
                    />
                    <p>
                      {item?.type?.split("/")[0] === "application" || item?.type?.split("/")[0] === "video" ?
                        `${parseFloat(item.size / (1024 * 1024)).toFixed(2)} MB`
                      : 
                        imageWidth !== null ? `${imageWidth}x${imageHeight}` : ''
                      }
                    </p>
                  </section>
                )}
              </SwiperSlide>
            ))}
            {values?.attachment?.length < MAX_LIMIT[attachmentStatus] && (
              <SwiperSlide className="upload-image-container">
                <section
                  className="add-post-image"
                  onClick={() => handleAttachment()}
                >
                  <Icon icon="ic:round-plus" color="#c5c5c5" width={60} />
                </section>
              </SwiperSlide>
            )}
        </Swiper>
      </section>
      <section className="d-flex mt-4 align-items-end">
        <p
          style={{
            fontSize: "1.3rem",
            textAlign: "start",
            width: "100%",
            color: "#F44336",
          }}
        >
          {sizeLimitError}
        </p>
        <p
          style={{
            fontSize: "1.1rem",
            textAlign: "end",
            width: "100%",
            color: errors.attachment ? "#F44336" : "#C5C5C5",
          }}
        >
          maximum upload <span>{MAX_LIMIT[attachmentStatus]}</span> attachment
        </p>
      </section>
      <input
        type="file"
        name="attachment"
        id="attachment"
        // value={values.attachment}
        onChange={(event) => {
          setFieldValue("attachment", [
            ...values.attachment,
            ...Array.from(event.target.files).slice(0, 5),
          ]);
          if (
            Array.from(event.target.files).length > MAX_LIMIT[attachmentStatus]
          ) {
            event.preventDefault();
            toast.error(
              `Cannot upload files more than ${MAX_LIMIT[attachmentStatus]}`,
              {
                autoClose: 3000,
                theme: "light",
                hideProgressBar: true,
                closeOnClick: true,
                closeButton: false,
              }
            );
            return;
          }
        }}
        multiple
        hidden
        accept="image/jpeg, image/png, image/gif"
      />
      <aside className="row post-content-row m-0">
        <textarea
          className={
            errors["content"]
              ? "_input --post-content errors-msg"
              : "_input --post-content"
          }
          value={values["content"]}
          placeholder="Add Content"
          onChange={(e) => {
            setFieldValue("content", e.target.value.slice(0, 1500));
          }}
        />
        <p className="suffix-input">{values["content"].length} / 1500</p>
      </aside>
    </article>
  );
}
