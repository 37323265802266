//lib
import { Icon } from "@iconify/react";
import { FieldArray, useFormikContext } from "formik";
import { MDBCol, MDBRow, MDBContainer } from "mdb-react-ui-kit";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  setSelectedForm,
  setSelectedUser,
  setFormState,
} from "../../../redux/reducer/paymentReducer";

export default function AttendeeInfo() {
  const dispatch = useDispatch();
  const { values, errors } = useFormikContext();
  const { selectedEventId } = useSelector((state) => state.event);

  const addUser = (item) => {
    dispatch(setFormState("add"));
    dispatch(setSelectedForm("add"));
    dispatch(setSelectedUser(item));
  };

  const editUser = (item) => {
    dispatch(setSelectedForm("add"));
    dispatch(setSelectedUser(item));
    dispatch(setFormState("edit"));
  };

  return (
    <article className="container">
      <header>
        <section className="d-flex align-items-center">
          <section className="short-line" />
          <h3 className="_label --form-title">Attendee Info</h3>
        </section>
        <section className="row">
          <p className="_label --detail ms-4">
            We <span className="_span">require</span> the full name of each
            attendee for registration. This ticket can accommodate information for
            a{" "}
            <span className="_span">
              {" "}
              maximum of {selectedEventId.attendee_number} attendees
            </span>
            .
          </p>
        </section>
      </header>
      <section className="attendeen-div">
        <FieldArray name="properties">
          {({ remove, push }) => (
            <>
              {values.properties.map((ticketItem, ticketIndex) => {
                return (
                  <article className="info-div" key={ticketIndex}>
                    <FieldArray name={`properties[${ticketIndex}]`}>
                      {({ remove, push }) => (
                        <>
                          <section className="row">
                            {ticketItem.length > 0 &&
                              ticketItem.map((item, index) => {
                                return (
                                  <article
                                    className="col-5 col-md-4 mb-3"
                                    key={index}
                                  >
                                    <section className="add_btn">
                                      <article
                                        className="d-flex align-items-center col-10"
                                        onClick={() =>
                                          editUser({
                                            ticketIndex: ticketIndex,
                                            index: index,
                                          })
                                        }
                                      >
                                        <Icon
                                          icon="tabler:user"
                                          height={"0.925em"}
                                        />
                                        <p className="_label --add_btn">
                                          &nbsp; {item.name}
                                        </p>
                                      </article>
                                      <article
                                        className="col"
                                        onClick={() => {
                                          remove(index);
                                        }}
                                      >
                                        {ticketItem.length > 1 && (
                                          <Icon
                                            icon="typcn:delete"
                                            height={"1em"}
                                          />
                                        )}
                                      </article>
                                    </section>
                                  </article>
                                );
                              })}
                          </section>
                          {selectedEventId.attendee_number >
                            values.properties[ticketIndex].length && (
                            <section className="row">
                              <section
                                className="add_btn"
                                onClick={() => {
                                  addUser({
                                    ticketIndex: ticketIndex,
                                    index: ticketItem.length,
                                  });
                                }}
                              >
                                <Icon
                                  icon="carbon:add-alt"
                                  height={"0.925em"}
                                />
                                <p className="_label --add_btn">
                                  &nbsp; Add
                                </p>
                              </section>
                            </section>
                          )}
                          {errors &&
                            errors.properties &&
                            errors.properties[ticketIndex] && (
                              <p className="text-danger  error-message">
                                <Icon icon="material-symbols:info-outline" />{" "}
                                &nbsp; {errors.properties[ticketIndex]}
                              </p>
                          )}
                        </>
                      )}
                    </FieldArray>
                  </article>
                );
              })}
            </>
          )}
        </FieldArray>
      </section>
    </article>
  );
}
