import { useRef } from "react";

//lib
import {
  motion,
  useTransform,
  useMotionValue,
  useAnimationFrame
} from "framer-motion";
import { wrap } from "@motionone/utils";
import { MDBRow } from "mdb-react-ui-kit";

//redux
import { useSelector } from "react-redux";

//hook
import useWindowDimensions from "../../../components/common/window-dimension";

function ParallaxText({ children, baseVelocity = 100 }) {
  const baseX = useMotionValue(0);
  const x = useTransform(baseX, (v) => `${wrap(-20, -45, v)}%`);

  const directionFactor = useRef(1);
  useAnimationFrame((t, delta) => {
    let moveBy = directionFactor.current * baseVelocity * (delta / 1000);
    moveBy += directionFactor.current * moveBy;
    baseX.set(baseX.get() + moveBy);
  });

  return (
    <section className="parallax">
      <motion.div className="scroller" style={{ x }}>
        <article>{children}</article>
        <article className="ms-5">{children}</article>
        <article className="ms-5">{children}</article>
        <article className="ms-5">{children}</article>
      </motion.div>
    </section>
  );
}

export default function Partner() {
  const { width } = useWindowDimensions();
  const { partner } = useSelector((state) => state.common);

  return (
    <article className="partner-container">
      <header className={`row home-title-row ${width < 991 ? 'm-0' : ''}`}>
        <h2>Our Partner</h2>
      </header>
      <section>
        <ParallaxText baseVelocity={-0.8} style={{ display: 'flex' }}>
          {partner.map((item, index) => (
            <section key={index} className="parallax-div">
              <img 
                src={item.image} 
                alt="reunion-partner" 
                width={width >= 991 ? 200 : 100} 
                height={width >= 991 ? 110 : 55} 
                loading={index > 6 ? "lazy" : "eager"} 
              />
            </section>
          ))}
        </ParallaxText>
      </section>
    </article>
  );
}


