import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import httpService from "../../services/http.services"
import auth from "../../services/auth.services"

//image
import { DefaultAvatar } from "../../assets/images"
import authServices from "../../services/auth.services"

export const initialData = {
  data: {},
  setting_item: [
    {
      label: "Account Settings",
      status : false ,
      items : [
        {
            label : "Personal Information" , 
            icon : "gg:profile",
            state  :"user"
        },
        {
            label : "Purchase History" , 
            icon : "uil:transaction",
            state : "purchase"
        },
        {
            label : "Point History" , 
            icon : "tdesign:undertake-transaction",
            state : "point"
        },
        {
            label : "My Event" , 
            icon : "material-symbols:event-outline",
            state : "event"
        },
        
      ]
    },
    {
      label: "Your Activity",
      description: "Review your activity and content that you're tagged in.",
      status : false,
      items : [
        {
          label : "Activity Log", 
          icon : "tabler:history",
          state : "activity"
        }
      ]
    },
    // {
    //   label: "Legal",
    //   status : true ,
    //   items : [
    //     {
    //         label : "Term of Service" , 
    //         icon : "ri:hotel-line"
    //     },
    //     {
    //         label : "Privacy" , 
    //         icon : "ri:hotel-line"
    //     },
    //   ]
    // },
  ],
  guest: {
    avatar : DefaultAvatar,
  },
  seletedAvatar : "",
  purchaseInfo : {},
  //api
  profile : {},
  userWallet : {},
  response : {},
  mission_list : [],
  walletHistory : [],
  userTicketHistory : [],
  orderDetail : [],
  loading: false,
  token: "",
  selectedAvatarBackground: "",
  profile_loading: false,
  avatar_list_loading: false,
}

export const getProfile = createAsyncThunk('get/profile', async(payload, thunkAPI) => {
    try{
        httpService.setToken(authServices.getToken());
        const { data } = await httpService.get(process.env.REACT_APP_API_URL + `/profile`, payload)
        return data
    }catch(error){
        return thunkAPI.rejectWithValue(error)
    }
})

export const putUserInformation = createAsyncThunk('put/information', async(payload, thunkAPI) => {
  try{
      const { data } = await httpService.put(process.env.REACT_APP_API_URL + `/update_profile_part_one`, payload)
      return data
  }catch(error){
      return thunkAPI.rejectWithValue(error)
  }
})

export const putUserInformationTwo = createAsyncThunk('put/information/2', async(payload, thunkAPI) => {
  try{
      const { data } = await httpService.put(process.env.REACT_APP_API_URL + `/update_profile_part_two`, payload)
      return data
  }catch(error){
      return thunkAPI.rejectWithValue(error)
  }
})

export const getMissionList = createAsyncThunk('get/mission', async(payload, thunkAPI) => {
  try{
      const { data } = await httpService.get(process.env.REACT_APP_API_URL + `/user_mission_listing`, payload)
      return data
  }catch(error){
      return thunkAPI.rejectWithValue(error)
  }
})

export const postUnlockMission = createAsyncThunk('post/unload', async(payload, thunkAPI) => {
  try{
      const { data } = await httpService.post(process.env.REACT_APP_API_URL + `/unlock_user_mission`, payload)
      return data
  }catch(error){
      return thunkAPI.rejectWithValue(error)
  }
})

export const claimMission = createAsyncThunk('put/claimMission', async(payload, thunkAPI) => {
  try{
      const { data } = await httpService.put(process.env.REACT_APP_API_URL + `/claim_complete_mission?mission_id=${payload.mission_id}`)
      return data
  }catch(error){
      return thunkAPI.rejectWithValue(error)
  }
})

export const postWalletHistory = createAsyncThunk('post/get_wallet_history', async(payload, thunkAPI) => {
  try{
      httpService.setToken(auth.getToken());
      const { data } = await httpService.post(process.env.REACT_APP_API_URL + `/user_wallet`, payload)
      return data
  }catch(error){
      return thunkAPI.rejectWithValue(error)
  }
})

export const postUserTicketHistory = createAsyncThunk('post/user_ticket_history', async(payload, thunkAPI) => {
  try{
      httpService.setToken(auth.getToken());
      const { data } = await httpService.post(process.env.REACT_APP_API_URL + `/user_ticket_history`, payload)
      return data
  }catch(error){
      return thunkAPI.rejectWithValue(error)
  }
})

export const getAvatar = createAsyncThunk('get/get_avatar', async(payload, thunkAPI) => {
  try{
      httpService.setToken(auth.getToken());
      const { data } = await httpService.get(process.env.REACT_APP_API_URL + `/user_avatar_listing`)
      return data
  }catch(error){
      return thunkAPI.rejectWithValue(error)
  }
})

export const setNickname = createAsyncThunk('set/set_nickname', async(payload, thunkAPI) => {
  try{
      httpService.setToken(auth.getToken());
      const { data } = await httpService.post(process.env.REACT_APP_API_URL + `/set_user_avatar`, payload)
      return data
  }catch(error){
      return thunkAPI.rejectWithValue(error)
  }
})

export const getUserWallet = createAsyncThunk('get/get_wallet_balance', async(payload, thunkAPI) => {
  try{
      httpService.setToken(authServices.getToken());
      const { data } = await httpService.get(process.env.REACT_APP_API_URL + `/get_wallet_balance`, payload)
      return data
  }catch(error){
      return thunkAPI.rejectWithValue(error)
  }
})

export const getUserActivityLog = createAsyncThunk('get/getUserActivityLog', async(payload, thunkAPI) => {
  try{
      httpService.setToken(authServices.getToken());
      const { data } = await httpService.post(process.env.REACT_APP_API_URL + `/activity_log/activity_for_user`, payload)
      return data
  }catch(error){
      return thunkAPI.rejectWithValue(error)
  }
})

export const putUpdateOrder = createAsyncThunk('put/user_update_order', async(payload, thunkAPI) => {
  try{
      httpService.setToken(auth.getToken());
      const { data } = await httpService.put(process.env.REACT_APP_API_URL + `/user_update_order`, payload)
      return data
  }catch(error){
      return thunkAPI.rejectWithValue(error)
  }
})

export const getOrderdetail = createAsyncThunk('get/user_get_order_details', async(payload, thunkAPI) => {
  try{
      httpService.setToken(auth.getToken());
      const { data } = await httpService.get(process.env.REACT_APP_API_URL + `/user_get_order_details?reference_no=${payload.reference_no}`)
      return data
  }catch(error){
      return thunkAPI.rejectWithValue(error)
  }
})

const dataSlice = createSlice({
  name: "profile",
  initialState: initialData,
  reducers: {
    setUserInformation: (state,action) => {
      state.user = {
        ...action.payload,  
        //avatar: Avatar1,
        point: 1000,
        city: "Kuala Lumpur",
        car: "Better 1.2",
        occupation: "Programmer" 
      }
    },
    setToken: (state, action) => {
        state.token = localStorage.getItem('token')
    },
    setSeletedAvatar: (state, action) => {
      state.seletedAvatar = action.payload
  },
  setSelectedAvatarBackground: (state, action) => {
    state.selectedAvatarBackground = action.payload
  },
  setPurchaseInfo: (state, action) => {
    state.purchaseInfo = action.payload
},
  },
  extraReducers: {
    [setNickname.pending]: (state, action) => {
      state.loading = true
    },
    [setNickname.fulfilled]: (state, action) => {
        state.loading = false
    },
    [setNickname.rejected]: (state, action) => {
        state.loading = false
    },
    [getAvatar.pending]: (state, action) => {
        state.avatar_list_loading = true
    },
    [getAvatar.fulfilled]: (state, action) => {
        state.avatar_list_loading = false
        state.avatar = action.payload.data
    },
    [getAvatar.rejected]: (state, action) => {
        state.loading = false
    },
    [getProfile.pending]: (state, action) => {
        state.profile_loading = true
    },
    [getProfile.fulfilled]: (state, action) => {
        state.profile_loading = false
        state.profile = action.payload.data
    },
    [getProfile.rejected]: (state, action) => {
        state.profile_loading = false
    },
    [putUserInformation.pending]: (state, action) => {
      state.loading = true
    },
    [putUserInformation.fulfilled]: (state, action) => {
        state.loading = false
        state.response = action.payload
    },
    [putUserInformation.rejected]: (state, action) => {
        state.loading = false
        state.response = action.payload

    },
    [putUserInformationTwo.pending]: (state, action) => {
      state.loading = true
    },
    [putUserInformationTwo.fulfilled]: (state, action) => {
      state.loading = false
      state.response = action.payload
    },
    [putUserInformationTwo.rejected]: (state, action) => {  
        state.loading = false
        state.response = action.payload
    },
    [getMissionList.pending]: (state, action) => {
      state.loading = true
    },
    [getMissionList.fulfilled]: (state, action) => {
      state.loading = false
      state.mission_list = action.payload.data.aaData
    },
    [getMissionList.rejected]: (state, action) => {  
      state.loading = false
    },

    [postWalletHistory.pending]: (state, action) => {
      state.loading = true
    },
    [postWalletHistory.fulfilled]: (state, action) => {
      state.loading = false
      state.walletHistory = action.payload.data.aaData
    },
    [postWalletHistory.rejected]: (state, action) => {
      state.loading = false
    },

    [postUserTicketHistory.pending]: (state, action) => {
      state.loading = true
    },
    [postUserTicketHistory.fulfilled]: (state, action) => {
      state.loading = false
      state.userTicketHistory = action.payload.data.aaData
    },
    [postUserTicketHistory.rejected]: (state, action) => {
      state.loading = false
    },

    [getUserWallet.pending]: (state, action) => {
      state.loading = true
    },
    [getUserWallet.fulfilled]: (state, action) => {
      state.loading = false
      state.userWallet = action.payload.data
    },
    [getUserWallet.rejected]: (state, action) => {
      state.loading = false
    },
    [getOrderdetail.pending]: (state, action) => {
      state.loading = true
    },
    [getOrderdetail.fulfilled]: (state, action) => {
      state.loading = false
      state.orderDetail = action.payload.data
    },
    [getOrderdetail.rejected]: (state, action) => {
      state.loading = false
    },
  },
})

export const { setUserInformation, setRefresh, setToken , setSeletedAvatar , setPurchaseInfo, setSelectedAvatarBackground} = dataSlice.actions

export default dataSlice.reducer

// example for fetching API