//lib
import { MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";

export default function Button({ label, name, type, disabled, className }) {
  return (
    <article className={`col form-group _button ${className}`}>
      {label && (
        <section className="row">
          <p htmlFor={name} className="form-label">
            {label}
          </p>
        </section>
      )}
      <MDBBtn
        type={type}
        className="button-group submit-button"
        disabled={disabled ? disabled : false}
      >
        {name}
      </MDBBtn>
    </article>
  );
}
