import { useRef, useEffect, useState} from "react";

//lib
import { useNavigate } from "react-router-dom";
import { MDBBtn, MDBModalBody, MDBRow, MDBCol } from "mdb-react-ui-kit";

//redux
import { useDispatch, useSelector } from "react-redux";
import { closeAllModal } from "../../../redux/reducer/modalReducer";
import {
  setNickname,
  getProfile,
  getAvatar,
} from "../../../redux/reducer/profileReducer";

//component
import AvatarList from "./avatarList";
import ModalBox from "../../../components/modal/full.modal.box";
import AvatarBackgroundList from "../component/avatarBackgroundList";
import InfoHeader from "../../../components/header/info-header";

//helper
import scrollToTop from "../../../helper/scrolltotop.helper";

export default function AvatarModal() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [top, setTop] = useState(false);
  const { isOpenAvatarList } = useSelector((state) => state.modal);
  const { avatar, seletedAvatar, selectedAvatarBackground } = useSelector((state) => state.profile);

  const backButton = () => {
    dispatch(closeAllModal());
    navigate("/profile");
  };

  const modalRef = useRef(null);

  useEffect(() => {
    setTop(true);
  }, [isOpenAvatarList]);

  useEffect(() => {
    if (top == true && modalRef.current) {
      scrollToTop(modalRef.current);
      setTop(false);
    }
  }, [top]);


  const handleSubmit = () => {
    dispatch(closeAllModal());
    dispatch(setNickname({ avatar_image: seletedAvatar, avatar_background_image: selectedAvatarBackground }))
      .unwrap()
      .then((res) => {
        dispatch(getProfile());
      });
  };

  useEffect(() => {
    dispatch(getAvatar());
  }, []);

  return (
    <ModalBox
      className={"modal-container"}
      backButton={backButton}
      show={isOpenAvatarList}
      screenSize="s"
      centered
      scrollable
      content={
        <article>
          <InfoHeader
            showBackButton={true}
            title="Select Display Avatar"
            backButton={backButton}
          />
          <MDBModalBody className='p-4' ref={modalRef}>
            <section className="row avatar-row pt-3">
              <article className="d-flex">
                <article style={{ width: '5px', height: '30px', borderRadius: '2px', background: '#3db8ed' }} />
                <h3 style={{ color: '#333333', fontSize: '18px', fontFamily: 'Inter', marginLeft: '0.5em', lineHeight: 'unset' }}>Avatar</h3>
              </article>
              {avatar?.avatars?.map((item, index) => {
                return <AvatarList key={index} item={item} />;
              })}
            </section>
            <section className="row avatar-row pt-5" style={{ paddingBottom: '5em' }}>
              <article className="d-flex">
                <article style={{ width: '5px', height: '30px', borderRadius: '2px', background: '#3db8ed' }} />
                <h3 style={{ color: '#333333', fontSize: '18px', fontFamily: 'Inter', marginLeft: '0.5em', lineHeight: 'unset' }}>Avatar Background</h3>
              </article>
              {avatar?.avatar_backgrounds?.map((item, index) => {
                return <AvatarBackgroundList key={index} item={item} />;
              })}
            </section>
            <article className={`col form-group _button ${"form_button"}`}>
              <MDBBtn
                onClick={() => handleSubmit()}
                type={"button"}
                className="button-group submit-button"
                disabled={false}
              >
                Select
              </MDBBtn>
            </article>
          </MDBModalBody>
        </article>
      }
    />
  );
}
