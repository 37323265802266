import { useEffect, useRef, useState } from "react";

//lib
import { MDBBtn, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import filterIcon from "@iconify/icons-mi/filter";
import cross2 from "@iconify/icons-radix-icons/cross-2";
import { MultiSelect } from "primereact/multiselect";

//redux
import { useDispatch, useSelector } from "react-redux";
import { openNotificationButton } from "../../redux/reducer/commonReducer";

export default function ButtonFilter({ selectedOption, setSelectedOption ,setStart}) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const token = localStorage.getItem("token");
  const { notificationButton } = useSelector((state) => state.common);

  // no delete
  const optionsOne = [
    { name: "My Post", code: "post" },
    { name: "Oldest Post", code: "create_at" },
    { name: "Most Like", code: "like_count" },
  ];

  const optionsTwo = [
    { name: "Oldest Post", code: "create_at" },
    { name: "Most Like", code: "like_count" },
  ];

  const buttonFilterRef = useRef(null);

  useEffect(() => {
    if (notificationButton) {
      setShow(false);
    }
  }, [notificationButton]);

  const checkLatestPost = selectedOption.find(
    (item) => item.code === "create_at"
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (show && !buttonFilterRef.current.contains(event.target)) {
        setShow(false);
      }
    };
  
    document.addEventListener("click", handleClickOutside);
  
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [show]);

  return (
    <section className="row post-filter-row">
      <MDBBtn
        ref={buttonFilterRef}
        style={{
          width:
            window.location.pathname === "/forum" && selectedOption.length > 0
              ? "55px"
              : "36px",
        }}
        className="filter-button"
        aria-label="filter"
        onClick={() => {
          setShow(!show);
          dispatch(openNotificationButton(false));
        }}
      >
        <Icon icon={filterIcon} width={25} color="#222A39" />
        {window.location.pathname === "/forum" && selectedOption.length > 0 && (
          <label className="count">{selectedOption.length}</label>
        )}
      </MDBBtn>
      {window.location.pathname === "/" && (
        <article className="filter-tag">
          {selectedOption.map((item, index) => (
            <p
              key={index}
              onClick={() => {
                const filter = selectedOption.filter(
                  (filterData) => filterData.code !== item.code
                );
                setSelectedOption(filter);
              }}
            >
              {item.name} <Icon icon={cross2} />
            </p>
          ))}
          {!checkLatestPost && <p>Latest Post</p>}
        </article>
      )}
      <article
        className={
          window.location.pathname === "/"
            ? "filter-checkbox-modal"
            : "filter-checkbox-modal forum"
        }
        style={{ height: show ? "auto" : 0, borderWidth: show ? "2px" : 0 }}
      >
        <p id="citySelect" className="visually-hidden">
          Select Cities
        </p>
        <MultiSelect
          inline
          value={selectedOption}
          onChange={(e) => {
            setSelectedOption(e.value);
            setStart(0);
          }}
          options={token ? optionsOne : optionsTwo}
          optionLabel="name"
          placeholder="Select Cities"
          maxSelectedLabels={3}
          className="w-full md:w-20rem"
          aria-labelledby="citySelect"
          role="listitem"
        />
      </article>
    </section>
  );
}
