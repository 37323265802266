import { useEffect, useState } from "react";

//lib
import _ from "lodash";
import * as Yup from "yup";
import Lottie from "lottie-react";
import { Form, Formik } from "formik";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";

//assets
import OrderExpired from "../../../assets/json/order-expired.json"

//redux
import { useDispatch, useSelector } from "react-redux";
import { openModalOrderHistory } from "../../../redux/reducer/modalReducer";
import { getCartData, getUserAddressInfo, setShippingMethod } from "../../../redux/reducer/cartReducer";
import { setPaymentStatus, setSelectedMerchantId, setShippingOption } from "../../../redux/reducer/checkoutReducer";
import { getUserWalletBalance, setPaymentLoading, setShowConfirmModal, setShowPurchaseModal, setSurePay } from "../../../redux/reducer/stockReducer";

//hook
import useWindowDimensions from "../../../components/common/window-dimension";

//component
import PurchaseModal from "./purchase.modal";
import CheckoutTable from "./checkout.table";
import CheckoutPayment from "./checkout.payment";
import ConfirmationModal from "../../../components/modal/comfirmation.modal";

//services
import apiServices from "../../../services/api.services";

export default function Checkout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { width } = useWindowDimensions();
  const token = localStorage.getItem("token");

  const { carts, shippingInfo, paymentMethod } = useSelector((state) => state.cart);
  const { checkout_summary, deliveryMerchantId, pickupMerchantId, selectedMerchantId } = useSelector((state) => state.checkout);
  const { showPurchaseModal, showConfirmModal, payment_loading, walletBalance } = useSelector((state) => state.stock);
  const [ paymentType, setPaymentType ] = useState(state?.activeTab && state.activeTab !== null ? state.activeTab : 'currency');
  const [ redirectCart, setRedirectCart ] = useState(false);
  const [ changingShipping, setChangingShipping ] = useState(false);

  const totalPrices = carts.totalCartTotal
  const cleanedNumber = walletBalance.point_balance
    ? parseFloat(walletBalance.point_balance.replace(/,/g, ""))
    : 0;

  const initialValues = deliveryMerchantId.length > 0
  ? {
      properties: shippingInfo ? shippingInfo : {},
      payment_type: paymentType,
      delivery_merchant_id: deliveryMerchantId,
      pickup_merchant_id: pickupMerchantId,
      payment_method:
        paymentType === "point"
          ? "point"
          : paymentMethod.name
            ? paymentMethod.name
            : "",
      code: paymentMethod.code,
    }
  : {
      payment_type: paymentType,
      delivery_merchant_id: deliveryMerchantId,
      pickup_merchant_id: pickupMerchantId,
      payment_method:
        paymentType === "point"
          ? "point"
          : paymentMethod.name
            ? paymentMethod.name
            : "",
      code: paymentMethod.code,
  };

  const handleSubmit = async ({ values, setFieldError, errors }) => {
    if(deliveryMerchantId.length !== 0 || pickupMerchantId.length !== 0) {
      if(paymentType !== "point" && (totalPrices !== 0 || totalPrices !== 0.00)) {
        dispatch(setPaymentStatus(true))
        if(process.env.REACT_APP_IS_PAYMENT_ONLINE === "true") {
          dispatch(setPaymentLoading(true))
          dispatch(setShowPurchaseModal(false));

          try {
            const response = await apiServices.checkout(values);

            if(response) {
              const paymentUrl = response.data;
              window.location.href = paymentUrl;
              // dispatch(setPaymentLoading(false))
              dispatch(setPaymentStatus(false))
              dispatch(openModalOrderHistory(false));
            } else {
              dispatch(setPaymentLoading(false))
            }
          } catch (ex) {
            dispatch(setPaymentLoading(false))
            dispatch(setPaymentStatus(false))
            if (ex && ex.response.status === 422) {
              const errors = ex.response.data.errors;
              if (errors && Object.keys(errors).length > 0) {
                Object.keys(errors).map((item, i) =>
                  toast.error(`${errors[item]}`, {
                    autoClose: 3000,
                    theme: "light",
                    hideProgressBar: true,
                    closeOnClick: true,
                    closeButton: false,
                  })
                );
              }
            }
          }
        } else {
          toast.error(`This feature is not supported yet.`, {
            autoClose: 3000,
            theme: "light",
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
          })
        }
      } else {
          dispatch(setSurePay(values));
          dispatch(setShowPurchaseModal(false));
          dispatch(setShowConfirmModal(true));
      }
    }
  }

  const useSchema = Yup.object({
    payment_method: Yup.string().required("Select one payment method to proceed order"),

  });

  useEffect(() => {
    dispatch(getUserAddressInfo());
  }, []);

  useEffect(() => {
    dispatch(getCartData({
      cart_payment_type: paymentType
    }));
  }, [paymentType]);

  useEffect(() => {
    dispatch(getUserWalletBalance());
  }, []);

  useEffect(() => {
    setChangingShipping({merchant_id: selectedMerchantId, changing: true})
    dispatch(
      setShippingOption({
        delivery_merchant_ids: deliveryMerchantId,
        pickup_merchant_ids: pickupMerchantId,
        cart_payment_type: paymentType,
      })
    )
    .unwrap()
    .then((res) => {
      dispatch(getCartData({
        cart_payment_type: paymentType
      }))
      .unwrap()
      .then((res) => { 
        setChangingShipping({merchant_id: selectedMerchantId, changing: false})
      })
    })
  }, [deliveryMerchantId, pickupMerchantId])

    useEffect(() => {
    const timeoutOne = setTimeout(() => {
      if(carts.totalCartItems === 0) {
        setRedirectCart(true)
      }
    }, 7000);

    const timeoutTwo = setTimeout(() => {
      if(carts.totalCartItems === 0) {
        navigate('/cart');
      }
    }, 10000);

    return () => {
      clearTimeout(timeoutOne)
      clearTimeout(timeoutTwo)
    }
  }, [checkout_summary]);

  return (
    //Cart
    <section>
      <article className="container checkout-container">
        <Formik
          initialValues={initialValues}
          validationSchema={paymentType === 'currency' ? useSchema : ''}
          enableReinitialize={true}
          onSubmit={(values, { errors, setFieldError }) => {
            handleSubmit({ values, errors, setFieldError });
          }}
        >
          {({
            setFieldValue,
            isSubmitting,
            handleReset,
            isValid,
            errors,
            values,
            handleSubmit,
          }) => (
            <Form>
              <header className="d-flex" style={{ justifyContent: 'space-between', color: '#00b4ff' }}>
                <h1 className="_label --checkout-title d-flex align-items-center">
                  <Link>
                    <Icon icon="ep:back" color="#000" width="30" height="30" className="me-5" 
                    onClick={(e) => { 
                      e.preventDefault();

                      navigate(`/cart`) 
                    }}
                    />
                  </Link>
                    Checkout
                  <Icon icon="mdi:cash-register" color="#79d3f9" width={38} height={38} className="ms-3"/>
                </h1>
              </header>
                {carts.totalCartItems !== 0 ? 
                  <>
                    <section className={width > 991 ? "row mt-3" : "row mt-0"}>
                      <article className={width > 991 ? "col-8" : "col-12"}>
                        <CheckoutTable changingShipping={changingShipping} />
                      </article>
                      {width > 991 &&
                        <article className="col-4 ps-5 pe-5">
                          <CheckoutPayment totalPrices={totalPrices} cleanedNumber={cleanedNumber} />
                        </article>
                      }
                    </section>
                    {width < 991 && 
                      <section className="mt-5">
                        <article>
                          <CheckoutPayment totalPrices={totalPrices} cleanedNumber={cleanedNumber} />
                        </article>
                      </section>
                    }
                  </>
                :
                  <section
                    className="d-flex align-items-center justify-content-center"
                    style={{ minHeight: "650px" }}
                  >
                    <article style={{ width: width > 991 ? "16em" : "14em" }}>
                      <Lottie animationData={OrderExpired} />
                      {!redirectCart ?
                        <p className="_label --empty-cart d-flex justify-content-center">
                          Cart is expired..
                        </p>
                      : 
                        <p className="_label --empty-cart text-center">
                          Redirecting back to cart page..
                        </p>
                      }
                    </article>
                  </section>
                }
            </Form>
          )}
        </Formik>
      </article>
          {showConfirmModal && (
            <ConfirmationModal
              show={showConfirmModal}
              setShow={setShowConfirmModal}
              type="purchase"
            />
          )}
        {showPurchaseModal && <PurchaseModal />}
    </section>
  );
}
