//lib
import _ from 'lodash';
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { ProgressBar } from "react-progressbar-fancy";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function MissionList({ item, setComfirmation }) {
  const full = 100 / item.target_count;

  const getImageUrl = (item) => {
    if (item.medias) {
      const imageType = item.medias.some(media => media.image_type === "avatar_background")
        ? "avatar_background"
        : "profile_avatar";
  
      const foundMedia = item.medias.find(media => media.image_type === imageType);
  
      if (foundMedia) {
        return foundMedia.url;
      }
    }
  }

  return (
    <article
      style={{ position: "relative", cursor: "pointer" }}
      className={
        "mission-div"
      }
      onClick={() => {
        if (item.mission_status === "in progress") {
        } else if (item.mission_status === "completed") {
          setComfirmation({ id: item.id, type: "completed" });
        }
      }}
    >
      <section className="row items-row pointer d-flex align-items-center">
        <article className="col-2 image-col p-0">
          <section className="image-div">
            {item.medias.length > 0 && (
              <LazyLoadImage
                src={item.medias &&                                                 
                  _.find(item.medias, {
                  image_type: "mission_icon",
                  })?.url
                }
                title={item.title}
                alt={item.description}
                className="mission-image"
              />
            )}
          </section>
        </article>
        <article className="col-9 p-0">
          <section className="row d-flex align-items-center" style={{ position: "relative" }}>
            <p className="target-label">
              {item.mission_status === "completed"
                ? "Wait for claim"
                : `${item.progress_count ? item.progress_count : 0} / ${
                    item.target_count
                  }`}
            </p>
            <ProgressBar
              className={
                item.mission_status === "in progress" && item.progress_count > 0
                  ? ""
                  : item.mission_status === "completed"
                  ? ""
                  : "disable"
              }
              label={
                <h4 style={{ lineHeight: "0.9em", fontSize: '16px', marginBottom: item.description === "" ? '0.8em' : '' }}>
                  {item.title}
                  <p
                    style={{
                      width: "100%",
                      display: "flex",
                      fontSize: "1rem",
                      color: "#a6a6a6",
                    }}
                  >
                    {item.description}
                  </p>
                </h4>
              }
              primaryColor={"#52595B"}
              secondaryColor={"#222A39"}
              darkTheme
              score={
                item.progress_count
                  ? item.progress_count > item.target_count
                    ? item.target_count * full
                    : item.progress_count * full
                  : 0
              }
            />
            {item.medias.length > 0 && (
              <article className='absolute-container'>
                <section style={{ position: 'relative', width: '100%', height: '100%' }}>
                  <section className='shine' />
                  <LazyLoadImage
                    title={item.title}
                    src={getImageUrl(item)}
                    alt={item.description}
                    className="bonus-image"
                  />
                </section>
              </article>
            )}
          </section>
        </article>
      </section>
    </article>
  );
}
