import { useRef } from "react";
import { useEffect, useState } from "react";

//lib
import { useNavigate, useParams } from "react-router-dom";
import { MDBModalBody } from "mdb-react-ui-kit";

//hook
import useWindowDimensions from "../../../components/common/window-dimension";

//component
import ContactInfo from "./contact.info";
import ChooseQuantity from "./choose.quantity";
import SelectPaymentMethod from "./select.payment.method";
import ModalBox from "../../../components/modal/half.modal.box";
import InfoHeader from "../../../components/header/info-header";

//redux
import { useDispatch, useSelector } from "react-redux";
import {
  getUserBanks,
  getUserWalletBalance,
  setSelectedSection,
  setShowPurchaseModal,
} from "../../../redux/reducer/stockReducer";
import { getUserAddressInfo } from "../../../redux/reducer/cartReducer";

//helper
import scrollToTop from "../../../helper/scrolltotop.helper";

export default function PurchaseModal({ data }) {
  const bottomSheetRef = useRef(null);

  const { width } = useWindowDimensions();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [top, setTop] = useState(false);
  const { showPurchaseModal, selectedSection, userAddressInfo, walletBalance, bankListing, bank_loading } =
    useSelector((state) => state.stock);

  const backButton = () => {
    // if (selectedSection === "quantity" selectedSection === "method") {
      dispatch(setShowPurchaseModal(false));
    // }
  };

  const backButtonNoAnimation = () => {
    dispatch(setSelectedSection("quantity"));
  };

  const modalRef = useRef(null);

  useEffect(() => {
    if (top == true && modalRef.current) {
      scrollToTop(modalRef.current);
      setTop(false);
    }
  }, [top]);
  
  useEffect(() => {
    setTop(true);
  }, [showPurchaseModal, selectedSection]);


  useEffect(() => {
    dispatch(getUserAddressInfo());
  }, []);

  useEffect(() => {
    dispatch(getUserWalletBalance());
  }, []);

  useEffect(() => {
    dispatch(
      getUserBanks()
    );
  }, []);

  const handleBottomSheetOpen = () => {
    if (bottomSheetRef.current) {
      bottomSheetRef.current.scrollToTop();
    }
  };

  return (
    <>
      <ModalBox
        centered
        scrollable
        className={"purchase-modal-container"}
        backButton={backButton}
        show={showPurchaseModal}
        staticBackdrop={true}
        desktopModal={width >= 991 ? true : false}
        screenSize={width >= 991 ? "xl" : "lg"}
        onOpen={handleBottomSheetOpen}
        content={
          <article>
            {selectedSection === "contact" ?
              <InfoHeader
                title={
                  selectedSection === "quantity"
                    ? "Select Shipping Info"
                    : selectedSection === "contact"
                    ? "Add Contact Info"
                    : selectedSection === "method"
                    ? "Select Payment Method"
                    : ""
                }
                backButton={backButtonNoAnimation}
                backButtonNoAnimation={backButtonNoAnimation}
              />
            : 
              <InfoHeader
                title={
                  selectedSection === "quantity"
                    ? "Select Shipping Info"
                    : selectedSection === "contact"
                    ? "Add Contact Info"
                    : selectedSection === "method"
                    ? "Select Payment Method"
                    : ""
                }
                backButton={backButton}
              />
            }
          {width < 991 ? (
            <section ref={bottomSheetRef}>
              {selectedSection === "quantity" ? (
                <ChooseQuantity
                  data={data}
                  userData={userAddressInfo}
                  pointBalance={walletBalance}
                />
              ) : selectedSection === "contact" ? (
                <ContactInfo userData={userAddressInfo} />
              ) : selectedSection === "method" ? (
                <SelectPaymentMethod data={data} bankData={bankListing} loading={bank_loading} />
              ) : (
                ""
              )}
            </section>
          ) : (
            <>
            <MDBModalBody className="p-0" ref={modalRef}>
              <section style={{ padding: selectedSection === "method" ? "0em 2em" : "1em 2em" }}>
                {selectedSection === "quantity" ? (
                  <ChooseQuantity
                    data={data}
                    userData={userAddressInfo}
                    pointBalance={walletBalance}
                  />
                ) : selectedSection === "contact" ? (
                  <ContactInfo userData={userAddressInfo} />
                ) : selectedSection === "method" ? (
                  <SelectPaymentMethod data={data} bankData={bankListing} loading={bank_loading} />
                ) : (
                  ""
                )}
              </section>
            </MDBModalBody>
            </>
          )
          }
        </article>
        }
      />
    </>
  );
}
