import { useEffect, useState } from "react";

//lib
import _ from "lodash";
import {
  MDBBtn,
  MDBCard,
  MDBCol,
  MDBContainer,
  MDBRow,
} from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router";
import { Skeleton } from "primereact/skeleton";
import { LazyLoadImage } from "react-lazy-load-image-component";

//component
import Login from "../Login";
import CreatePost from "../Forum/component/create.post";

//hook
import useWindowDimensions from "../../components/common/window-dimension";

//redux
import {
  getProductListing,
  setPaymentMethod,
  setQuantity,
  setShowPurchaseModal,
} from "../../redux/reducer/stockReducer";
import { useDispatch, useSelector } from "react-redux";
import { setShowLoginModal } from "../../redux/reducer/settingReducer";

//helper
import scrollToTop from "../../helper/scrolltotop.helper";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function Store() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const token = localStorage.getItem("token");
  const { productListing, loading, skeletonData } = useSelector(
    (state) => state.stock
  );
  const { showLoginModal } = useSelector((state) => state.setting);

  const [searchValue, setSearchValue] = useState("");

  function updateMetaThemeColor() {
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", "#FFF");
  }

  useEffect(() => {
    scrollToTop();
    updateMetaThemeColor();
  }, []);

  useEffect(() => {
    if (searchValue !== "") {
      const timeOutId = setTimeout(() => {
        dispatch(
          getProductListing({
            search: searchValue,
          })
        );
      }, 500);
      return () => clearTimeout(timeOutId);
    } else {
      dispatch(
        getProductListing({
          search: searchValue,
        })
      );
    }
  }, [searchValue]);

  return (
    <>
      <Helmet>
        <title>Reunion | Store</title>
        <meta name="description" content="Explore a wide selection of car parts and accessories in the reunion market." />
      </Helmet>

      <section className="container store-container">
        <header>
          <nav>
            <MDBBtn
              className="cart-btn"
              onClick={() => {
                if (token) {
                  navigate(`/cart`, { state: "store" });
                } else {
                  dispatch(setShowLoginModal(true));
                }
              }}
            >
              <Icon icon="vaadin:cart" width="30" height="30" />
            </MDBBtn>
          </nav>
          <h1 className="_label --welcome-title mt-2">
            Welcome To Our Store
            <br />
            Only Recommend Best For You
          </h1>
          <section className="d-flex justify-content-center">
            <article className="row store-search">
              <article className="col-10">
                <input
                  className="search-col"
                  placeholder="Search item name"
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                />
              </article>
              <article className="col-2 icon-col">
                {searchValue ? (
                  <Icon
                    icon="material-symbols:close"
                    color="#fafafa"
                    width="25"
                    height="25"
                    onClick={() => {
                      setSearchValue("");
                    }}
                  />
                ) : (
                  <Icon
                    icon="eva:search-outline"
                    color="#fafafa"
                    width="25"
                    height="25"
                  />
                )}
              </article>
            </article>
          </section>
        </header>

        <article>
        {!loading ? (
          Object.entries(productListing).map(([merchantName, products]) => {
            return (
              <section key={merchantName}>
                <header className="row line-container">
                  <section className="store-line" />
                  <h2 className="_label --line-title">{merchantName}</h2>
                </header>

                <section className="row" style={{ margin: "1.5em 0 0 0" }}>
                  {products.map((product, index) => {
                    return product.status !== "inactive" ? (
                      <Link
                        className={`col-${4} col-sm-6 col-lg-${
                          width <= 1200 ? 3 : 2
                        } col-md-3 mb-3`}
                        onClick={(e) => {
                          e.preventDefault();

                          navigate(`/product/${product.id}`);
                          dispatch(setShowPurchaseModal(false));
                          dispatch(setPaymentMethod(""));
                          dispatch(setQuantity(1));
                        }}
                        key={index}
                      >
                        <article className="card product-card">
                          <h3 style={{ wordBreak: "unset" }}>
                            {product.name}
                          </h3>
                          <p>
                            {product.payment_type !== "point" ? "RM " : ""}
                            {product.price}
                            {product.payment_type === "point" ? " PTS" : ""}
                          </p>
                          <p className="product-img">
                            <LazyLoadImage
                              title={product.name}
                              alt={product.name}
                              src={
                                _.find(product.medias, {
                                  image_type: "product_image",
                                })?.url
                              }
                            />
                          </p>
                        </article>
                      </Link>
                    ) : (
                      ""
                    );
                  })}
                </section>
              </section>
            );
          })
        ) : (
          <section className="row skeleton-row">
            {skeletonData.map((item) => {
              return (
                <article
                  className={
                    width >= 991
                      ? "col-2 skeleton-col mt-5"
                      : "col-4 skeleton-col mt-5"
                  }
                  key={item}
                >
                  <article className="card product-card">
                    <section className="row text-detail">
                      <Skeleton
                        height="1.5rem"
                        width="8em"
                        className="mb-2"
                      ></Skeleton>
                    </section>
                    <section className="row">
                      <Skeleton
                        height="1.5rem"
                        width="8em"
                        className="mb-2"
                      ></Skeleton>
                    </section>
                    <section className="product-img">
                      <Skeleton width="100%" height="11em"></Skeleton>
                    </section>
                  </article>
                </article>
              );
            })}
          </section>
        )}
        </article>
        <CreatePost />
      </section>
      {showLoginModal && <Login />}
    </>
  );
}
