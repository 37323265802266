import { useState, useEffect } from "react";

//lib
import axios from "axios";

//redux
import { useDispatch } from "react-redux";
import { setLocation } from "../../redux/reducer/forumReducer";

export default function useGeoInfo() {

  const dispatch = useDispatch();
  const [location, setLocation] = useState("");

  const getGeoInfo = () => {
    const hasAskedForPermission = localStorage.getItem("hasAskedForPermission");

    if (hasAskedForPermission === "true") {
      fetchLocationFromIP();
    } else {
      fetchLocationFromGeolocation();
    }
  };

  const fetchLocationFromGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (successCallback) => {
          const { latitude, longitude } = successCallback.coords;
          fetchLocationFromCoordinates(latitude, longitude);
          localStorage.setItem("hasAskedForPermission", "true");
        },
        errorCallback
      );
    } else {
    }
  };

  const fetchLocationFromCoordinates = (latitude, longitude) => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        const suburb = data.address.suburb || data.address.city || "Unknown";
        setLocation(suburb);
        dispatch(setLocation(suburb));
      })
      .catch(errorCallback);
  };

  const fetchLocationFromIP = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        const data = response.data;
        const city = data.city || "Unknown";
        setLocation(city);
        dispatch(setLocation(city));
      })
      .catch(errorCallback);
  };

  const errorCallback = (error) => {
    localStorage.setItem("hasAskedForPermission", "true");
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  return location;
}