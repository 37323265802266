export default function formatNumber(num, action) {
    if (action == 'separator') {
        return parseInt(num).toLocaleString()
    } else {
        var units = ['k', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'], decimal
    
        for (var i = units.length - 1; i >= 0; i--) {
            decimal = Math.pow(1000, i + 1)
    
            if (num <= -decimal || num >= decimal) {
            const number = +(num / decimal).toFixed(2)
            if (units[i] === 'Y') {
                return number.toLocaleString() + units[i]
            }
            return number.toLocaleString() + units[i]
            }
        }
    }

    return num
}
