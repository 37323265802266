import { useEffect, useState } from "react";

//lib
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import { MDBCol, MDBRow, MDBContainer, MDBModalBody } from "mdb-react-ui-kit";

//hook
import useWindowDimensions from "../../components/common/window-dimension";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  closeAllModal,
  closeModalPurchase,
  openModalEvent,
} from "../../redux/reducer/modalReducer";
import {
  postPurchaseTicket,
  setSelectedPaymentMethod,
  setSurePay,
} from "../../redux/reducer/paymentReducer.js";
import { getUserWallet } from "../../redux/reducer/profileReducer.js";

//component
import PurchaseHeader from "./component/purchaseHeader.jsx";
import AttendeeInfo from "./component/attendeeInfo.jsx";
import PaymentMethod from "./component/paymentMethod.jsx";
import AddAttendeeForm from "./component/addAttendeeForm.jsx";
import PaymentMethodList from "./component/paymentMethodList.jsx";
import PaymentButton from "./component/paymentBtn.jsx";
import ModalBox from "../../components/modal/half.modal.box.jsx";
import { getUserBanks, setShowConfirmModal } from "../../redux/reducer/stockReducer.js";
import { setTicketPaymentLoading } from "../../redux/reducer/eventReducer";
import { setAnimationModal, setShowFullModal } from "../../redux/reducer/commonReducer";

export default function PurchasePage() {
  const dispatch = useDispatch();
  const { isOpenPayment } = useSelector((state) => state.modal);
  const { selectedForm, paymentList, selectedPaymentMethod, orderDetails, surePayTicket } =
    useSelector((state) => state.payment);
  const { selectedEventId } = useSelector((state) => state.event);
  const { bankListing } = useSelector((state) => state.stock);

  const { width } = useWindowDimensions();

  const [paymentStatus, setPaymentStatus] = useState(false);

  const [ticketData, setTicketData] = useState(
    selectedEventId.payment_type === "point"
      ? {
          properties: surePayTicket?.properties ? surePayTicket.properties : [[]],
          payment_type: "point",
          ticket_id: selectedEventId ? selectedEventId.id : "",
          quantity: surePayTicket?.quantity ? surePayTicket.quantity : 1,
        }
      : {
          properties: orderDetails.properties ? orderDetails.properties : [[]],
          ticket_id: selectedEventId ? selectedEventId.id : "",
          payment_method: selectedPaymentMethod.name
            ? selectedPaymentMethod.name
            : "",
          payment_type: "",
          code: selectedPaymentMethod.code ? selectedPaymentMethod.code : "",
          quantity: orderDetails.quantity ? orderDetails.quantity : 1,
        }
  );

  useEffect(() => {
    dispatch(setAnimationModal(true))
  }, [])

  useEffect(() => {
    if (orderDetails) {
      dispatch(
        setSelectedPaymentMethod({
          name: orderDetails.payment_method ? orderDetails.payment_method : "",
          code: orderDetails.payment_code ? orderDetails.payment_code : "",
        })
      );
    }
  }, [orderDetails]);

  const formSchema = Yup.object({
    properties: Yup.array().of(
      Yup.array()
        .of(
          Yup.object({
            name: Yup.string().required("Name is required"),
            contact: Yup.string().required("Contact is required"),
          })
        )
        .min(1, "At least 1 user is required")
        .max(
          selectedEventId.attendee_number ? selectedEventId.attendee_number : 2,
          `At least ${
            selectedEventId.attendee_number
              ? selectedEventId.attendee_number
              : "2"
          } user is required`
        )
    ),
    payment_type: Yup.string().required("Select one method to payment"),
  });

  const handleSubmit = async ({ values, setFieldError }) => {
    setPaymentStatus(true);

      if(selectedEventId.payment_type === "point") {
        dispatch(setSurePay({
          properties: values.properties,
          payment_type: selectedEventId.payment_type,
          ticket_id: values.ticket_id,
          quantity: values.quantity,
        }))
        setPaymentStatus(false);
        dispatch(setShowFullModal(false));
        if(width < 991) {
          dispatch(closeModalPurchase());
        }
        dispatch(setShowConfirmModal(true));
      } else {
        if(process.env.REACT_APP_IS_PAYMENT_ONLINE === "true") {
          // dispatch(closeModalPurchase());
          dispatch(setShowFullModal(false));
          if(width < 991) {
            dispatch(closeModalPurchase());
          }
          dispatch(setTicketPaymentLoading(true));
          dispatch(
            postPurchaseTicket({
              properties: values.properties,
              payment_type: selectedEventId.payment_type,
              ticket_id: values.ticket_id,
              payment_method: selectedPaymentMethod.name,
              code: selectedPaymentMethod.code,
              quantity: values.quantity,
            })
          )
          .unwrap()
          .then((res) => {
            const paymentUrl = res.data;
            window.location.href = paymentUrl;
            setPaymentStatus(false);
          })
          .catch((ex) => {
            setPaymentStatus(false);
            dispatch(setTicketPaymentLoading(false));
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                setFieldError(item, errors[item]);
              });
            }
          });
      } else {
        toast.error(`This feature is not supported yet.`, {
          autoClose: 3000,
          theme: "light",
          hideProgressBar: true,
          closeOnClick: true,
          closeButton: false,
        })
      }
    }
  };

  const closeModal = () => {
    dispatch(closeAllModal());
    dispatch(setSelectedPaymentMethod(""));
  };

  useEffect(() => {
    if (selectedEventId.payment_type === "point") {
      dispatch(getUserWallet());
    } else {
      dispatch(getUserBanks());
    }
  }, []);

  return (
    <ModalBox
      centered
      scrollable
      onDismiss={closeModal}
      backButton={closeModal}
      show={isOpenPayment}
      className="purchase-page"
      staticBackdrop={true}
      desktopModal={width >= 991 ? true : false}
      screenSize={width >= 991 ? "xl" : "lg"}
      content={
        <article>
          <PurchaseHeader />
          <MDBModalBody className="purchase-modal mt-3">
            <Formik
              initialValues={ticketData}
              validationSchema={formSchema}
              onSubmit={(values, { resetForm, setFieldError }) => {
                handleSubmit({ values, setFieldError });
                resetForm({ values: values });
              }}
            >
              {({
                errors,
                isValid,
                resetForm,
                isSubmitting,
                setFieldError,
                values,
              }) => (
                <Form>
                  <section
                    className={
                      width >= 991
                        ? "container event-container desktop"
                        : "container event-container"
                    }
                  >
                    <article>
                      {selectedForm === "" && (
                        <header className="row event-item">
                          <article className="col">
                            <h2 className="_label --name">
                              {selectedEventId.title}
                            </h2>
                          </article>
                          <article className="col text-end">
                            <section className="row">
                              <p className="_label --price">
                                {selectedEventId.payment_type !== "point" ? "RM " : ""}
                                {selectedEventId.price} 
                                {selectedEventId.payment_type === "point" ? " PTS" : ""}
                              </p>
                            </section>
                            <section className="row">
                              <p className="_label --text">Each Ticket</p>
                            </section>
                          </article>
                        </header>
                      )}
                      {/* done */}
                      {selectedForm === "" && 
                        <section>
                          <AttendeeInfo />
                        </section>
                      }
                      {selectedForm === "" && 
                        <section>
                          <PaymentMethod />
                        </section>
                      }
                      {selectedForm === "add" && 
                        <section>
                          <AddAttendeeForm />
                        </section>
                      }
                      {selectedForm === "choose_payment" && (
                        <section>
                          <ul style={{ marginTop: width >= 991 ? "0em" : "6em" }} className="p-0">
                            {bankListing?.length > 0 &&
                              bankListing.map((item, index) => {
                                return (
                                  item.name && (
                                    <PaymentMethodList key={index} item={item} />
                                  )
                                );
                              })}
                          </ul>
                        </section>
                      )}
                      {errors && errors.ticket_id && (
                        <section className="row">
                          <p className="text-danger  error-message">
                            {errors.ticket_id}
                          </p>
                        </section>
                      )}
                      {selectedForm === "" && (
                        <footer className="d-flex justify-content-center ">
                          <PaymentButton disabled={paymentStatus} />
                        </footer>
                      )}
                    </article>
                  </section>
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        </article>
      }
    />
  );
}
