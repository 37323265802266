import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import apiServices from "../../services/api.services"
import httpService from "../../services/http.services"
import auth from "../../services/auth.services"

export const initialData = {
loading : false ,
selectedForm : "",
 titleName : {
    add: "Add Attendee Info",
    choose_payment: "Select payment method",
    "": "Purchase Details",
  },
attendeeUser : [],
selectedUser : {}, 
formState : undefined,
selectedPaymentMethod : "",
paymentList : [
],
 statusIcon : {
  Completed: {
    icon: "ep:success-filled",
    color: "#65df8b",
    text: "Purchase Successful",
  },
  Pending: {
    icon: "ic:outline-pending",
    color: "#fce02c",
    text: "Order is in Pending",
  },
  "Updated Tracking Info": {
    icon: "material-symbols:deployed-code-update-outline-sharp",
    color: "#879fe5",
    text: "Order Tracking Information",
  },
  Requested: {
    icon: "mingcute:send-plane-line",
    color: "#0094ff",
    text: "Order Requested",
  },
  Accepted:{
    icon: "ep:success-filled",
    color: "#65df8b",
    text: "Purchase Successful",
  },
  Failed:{
    icon: "zondicons:exclamation-solid",
    color: "#ff5041",
    text: "Purchase Failed",
  }
},
orderDetails: {},
// api
selectedTicketID : "" ,
response : {},
ex : {},
totalPoint: 0,
surePay: {},
}

export const postPurchaseTicket = createAsyncThunk('post/purchase_ticket', async(payload, thunkAPI) => {
  try{
      httpService.setToken(auth.getToken());
      const { data } = await httpService.post(process.env.REACT_APP_API_URL + `/purchase_ticket`, payload)
      return data
  }catch(error){
      return thunkAPI.rejectWithValue(error)
  }
})


export const getUserOrderDetails = createAsyncThunk('getUserOrderDetails', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.getUserOrderDetails(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

const dataSlice = createSlice({
  name: "payment",
  initialState: initialData,
  reducers: {
    setSelectedForm: (state,action) => {
        state.selectedForm = action.payload
    },
    setSelectedUser: (state,action) => {
      state.selectedUser = action.payload
  },
  setFormState: (state, action) => {
   
      state.formState = action.payload;
    
  },
  setAttendeeUser: (state,action) =>{
    void(state.attendeeUser =
      state.attendeeUser.filter((data) =>  
      data.id !== action.payload.id,
      )
      )
      state.attendeeUser.push(action.payload)
  },
  setSelectedPaymentMethod: (state,action) => {
    state.selectedPaymentMethod = action.payload
  },

  setSelectedTicketID: (state,action) => {
    state.selectedTicketID = action.payload
  },

  setClearOrderDetails: (state,action) => {
    state.orderDetails = action.payload
  },
  setTotalPoint: (state,action) => {
    state.totalPoint = action.payload
  },
  setSurePay: (state,action) => {
    state.surePayTicket = action.payload
  },
  },
  extraReducers: {
    [postPurchaseTicket.pending]: (state, action) => {
      state.loading = true
    },
    [postPurchaseTicket.fulfilled]: (state, action) => {
      state.loading = false
      state.response = action.payload
    },
    [postPurchaseTicket.rejected]: (state, action) => {  
       state.loading = false
      state.ex = action.payload
    },
    [getUserOrderDetails.pending]: (state, action) => {
      state.loading = true
    },
    [getUserOrderDetails.fulfilled]: (state, action) => {
        state.loading = false
        state.orderDetails = action.payload.data
    },
  },
})

export const { setSelectedForm ,  setSelectedUser  , setAttendeeUser , setFormState , setSelectedTicketID , setSelectedPaymentMethod, setClearOrderDetails, setTotalPoint, setSurePay}= dataSlice.actions

export default dataSlice.reducer

// example for fetching API