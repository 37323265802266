import { useState } from "react";

//lib
import { Document, Page, pdfjs } from "react-pdf";

export default function PdfRender({ pdf, pageNumber }) {
  const [numPages, setNumPages] = useState(null);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
      {numPages && (
        <Page style={{ innerHeight: "660px" }} pageNumber={pageNumber} />
      )}
    </Document>
  );
}
 