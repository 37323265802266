//lib
import { Icon } from "@iconify/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

//asset
import LogoMain from "../../assets/images/REUNION-Logo.webp";

//redux
import { useDispatch, useSelector } from "react-redux";
import { setFooterItemActive } from "../../redux/reducer/commonReducer";
import useWindowDimensions from "../common/window-dimension";

export default function FooterOne() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { pathname } = useLocation();

  const { footerListing } = useSelector((state) => state.common);

  return (
    <>
      <footer className={`footer-container footer-one`} breakpoint="none">
        <section className="image-frame">
          <LazyLoadImage fetchpriority="low" src={LogoMain} title="reunion-logo" alt="reunion-logo" className="pt-3" />
        </section>
        <section className="row pt-4">
          <h5 className="_label --desc-text">
            Reunion is Malaysia Largest Car Club Platform
          </h5>
        </section>
        <section
          className="line-container --grey-thin"
          breakpoint="none"
        ></section>
        {footerListing.map((listing, index) => (
          <section className="p-0" breakpoint="none" key={index}>
            <section
              className="row pt-3 pb-3 m-0"
              onClick={() => dispatch(setFooterItemActive(index))}
              style={{ cursor: "pointer" }}
            >
              <article className="col p-0">
                <p className="_label --item-title pointer">{listing.title}</p>
              </article>
              <article className="col text-end p-0">
                <Icon
                  color="#D1D5DB"
                  icon={
                    listing.active
                      ? "ic:outline-keyboard-arrow-up"
                      : "ic:outline-keyboard-arrow-down"
                  }
                  width={15}
                  height={15}
                />
              </article>
            </section>
            <section
              style={{ height: listing.active ? listing.item.length * 22 : 0 }}
              className={
                listing.active
                  ? `listing-item-container active`
                  : `listing-item-container`
              }
            >
              {listing.item.map((listing_item, index) => (
                <article className="p-0" breakpoint="none" key={index}>
                  <Link
                    onClick={(e) => {
                      e.preventDefault();

                      if (listing_item.label === "Import UK Car") {
                        window.open(listing_item.to, "_blank");
                      } else {
                        navigate(listing_item.to , {state : { value : listing_item.value ? listing_item.value : ""}});
                      }
                    }}
                    className="_label --item-text pointer"
                  >
                    {listing_item.label}
                  </Link>
                </article>
              ))}
            </section>
          </section>
        ))}
        <section className="row mt-4">
          <p className="_label --desc-text">
            Reach us directly:{" "}
            <a className="_label --highlight-text" href="mailto:dede_tby@yahoo.co.uk">
              dede_tby@yahoo.co.uk
            </a>{" "}
            or call : {" "}
            <a className="_label --highlight-text" href="tel:+60123552455" >+6012-3552455</a>
          </p>
          <br />
        </section>
        <section className="row">
          <p className="_label --desc-text --special">
          2018-2023 Reunion Motorsports Sdn Bhd (1231230-U) All rights reserved. (Version {process.env.REACT_APP_VERSION})
          </p>
        </section>
      </footer>
    </>
  );
}
