//lib
import moment from "moment";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

export default function PointHistory({ item }) {

  const bigText = {
    purchase: "Purchase",
    "fill up profile": "Fill up profile",
    "like post": "Like post",
    "like comment": "Like comment",
    "manual adjustment": "Manual Adjustment",
  };

  return (
    <section className="row recods-row">
      <article className="col-7">
        <section className="row">
          <h6 className="_label --name">{bigText[item.type]}</h6>
        </section>
        <section className="row">
          <p className="_label --description" style={{ cursor: "text", userSelect: "text"}}>{item.description}</p>
        </section>
      </article>
      <article className="col text-end">
        <h5
          className={item.amount > 0 ? "_label --point2" : "_label --point"}
        >
          {item.amount} Point
        </h5>
        <section className="row">
          <p className="_label --time">
            {moment(item.created_at, "DD/MM/YYYY HH:mm:ss").format(
              "DD/MM/YYYY HH:mm"
            )}
          </p>
        </section>
      </article>
    </section>
  );
}
