//lib
import Lottie from "lottie-react";
import { MDBRow } from "mdb-react-ui-kit";

//assets
import MissionGraphic from "../../../assets/json/mission-graphic.json";

export default function EmptyPage({ name }) {
  const descriptionText = {
    event: "You haven't participated in any event yet.🐺",
    purchase: "You haven't made any orders yet.🐺",
    point: "You haven't any records yet.🐺",
  };

  return (
    <article className="empty-div">
      <section className="row image-row">
        <Lottie animationData={MissionGraphic} />
      </section>
      <section className="row">
        <h3>History Is Empty</h3>
      </section>
      <section className="row">
        <p>{descriptionText[name]}</p>
      </section>
    </article>
  );
}
