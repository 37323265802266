import { useEffect, useRef, useState } from "react";

//lib
import { useNavigate } from "react-router-dom";
import { MDBModalBody, MDBRow, MDBCol } from "mdb-react-ui-kit";

//hook
import useWindowDimensions from "../../../components/common/window-dimension";

//redux
import { useDispatch, useSelector } from "react-redux";
import { closeAllModal } from "../../../redux/reducer/modalReducer";

//component
import ModalBox from "../../../components/modal/full.modal.box";
import InfoHeader from "../../../components/header/info-header";
import Notify from "../../../components/common/notify-component";
import InformationForm from "./informationForm";
import OccupationForm from "./organizeForm";

//helper
import scrollToTop from "../../../helper/scrolltotop.helper";

export default function InformationModal() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [top, setTop] = useState(false);
  const { isOpenUserInformation } = useSelector((state) => state.modal);
  const data = [
    "Complete your profile and receive 1,000 Reunion point!",
    "Some info only allowed limited change attempt. Please make sure to fill in the correct information.",
  ];

  const modalRef = useRef(null);

  const backButton = () => {
    dispatch(closeAllModal());
    navigate("/profile");
  };

  useEffect(() => {
    setTop(true);
  }, [isOpenUserInformation]);

  useEffect(() => {
    if (top == true && modalRef.current) {
      scrollToTop(modalRef.current);
      setTop(false);
    }
  }, [top]);

  return (
    <ModalBox
      centered
      scrollable
      className={"modal-container"}
      backButton={backButton}
      show={isOpenUserInformation}
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      content={
        <article>
          <InfoHeader
            showBackButton={true}
            title="My Infomation"
            backButton={backButton}
          />
          <MDBModalBody ref={modalRef} className="">
            <section className={width >= 991 ? "order-div desktop" : "order-div"}>
              <Notify item={data} />
              <section className="row">
                <section className="row tag-row">
                  <article className="col tag d-flex">
                    <article className="short-div" />
                    <h3 className="_label --title">
                      Your profile is help us to understand more about you
                    </h3>
                  </article>
                </section>
                <InformationForm />
                <section className="row tag-row">
                  <article className="col tag d-flex">
                    <article className="short-div" />
                    <h3 className="_label --title">
                      This Info is help us to organize an event that suit you
                    </h3>
                  </article>
                </section>
                <OccupationForm />
              </section>
            </section>
          </MDBModalBody>
        </article>
      }
    />
  );
}
