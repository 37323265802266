//lib
import { MDBModal, MDBModalDialog, MDBModalContent } from "mdb-react-ui-kit";
import { RemoveScroll } from "react-remove-scroll";
import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useEffect } from "react";
import { setAnimationModal, setShowFullModal } from "../../redux/reducer/commonReducer";
import { useDispatch, useSelector } from "react-redux";

export default function ModalBox({
  className,
  show,
  screenSize,
  content,
  setShow,
  scrollable,
  centered,
  backButton,
}) {
  const dispatch = useDispatch()
  const { showFullModal, animationModal } = useSelector((state) => state.common)

  const handleCloseModalWithDelay = () => {
    dispatch(setShowFullModal(false))
    setTimeout(() => {
      dispatch(setAnimationModal(true))
      backButton();
    }, 500);
  };

  useEffect(() => {
    dispatch(setShowFullModal(show))
  }, [])

  return (
    <section>
      <RemoveScroll>
        <AnimatePresence>
          {showFullModal && 
            <MDBModal
              className={`full-modal-box-container ${className}`}
              tabIndex="-1"
              show={true}
              // onHide={() => setShowModal(false)} // Use the delayed close function
            >
              <article
                style={{
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                }}
                onClick={() => handleCloseModalWithDelay()} // Use the delayed close function
              ></article>
              <motion.div
                initial={ animationModal && { 
                  scale: 0.2
                }}
                animate={ animationModal && { 
                  scale: 1,
                  opacity: 1,
                  transition: {
                    duration: 0.5
                  }
                }}
                exit={ animationModal && {
                  opacity: [1, 0],
                  scale: [1, 0.8],
                  transition: {
                    duration: 0.3,
                  },
                }}
              >
                <MDBModalDialog
                  centered={centered}
                  size={`${screenSize}`}
                  scrollable={scrollable ? true : false}
                >
                  <MDBModalContent>
                    <motion.div
                      initial={ animationModal && { 
                        opacity: 0
                      }}
                      animate={ animationModal && { 
                        opacity: 1,
                        transition: {
                          delay: 0.3,
                          duration: 0.3
                        }
                      }}
                      exit={ animationModal && { 
                        opacity: 0,
                        transition: {
                          duration: 0.3,
                        }
                      }}
                    >
                      {content}
                    </motion.div>
                  </MDBModalContent>
                </MDBModalDialog>
              </motion.div>
            </MDBModal>
          }
        </AnimatePresence>
      </RemoveScroll>
    </section>
  );
}
