import React, { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown'
import { useParams , useLocation} from 'react-router-dom';
import useWindowDimensions from '../../../components/common/window-dimension'
export default function TandCDropdown({ containerRef, section1, section2 , section3 }) {
  const form = useLocation();
  const { width } = useWindowDimensions()
  const [activeSection, setActiveSection] = useState(false);
  const [selectedValue , setSelectedValue] = useState({name : "Terms and Conditions"})
  const option = [{name : "Terms and Conditions"} , {name : "Privacy Policy"} , {name : "Refund Policy"}]
  const handleChange = (e) => {
    setSelectedValue(e.target.value)
    setActiveSection(!activeSection)
  }

  useEffect(() => {
    function handleScroll() {
      const section1Top = section1.current.offsetTop;
      const section2Top = section2.current.offsetTop;
      const section3Top = section3.current.offsetTop;
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;

      if (scrollPosition >= section1Top - windowHeight * 0.15 && scrollPosition < section2Top - windowHeight * 0.15) {
        setSelectedValue({name : "Terms and Conditions"})
      } else if (scrollPosition >= section2Top - windowHeight * 0.15 && scrollPosition < section3Top - windowHeight * 0.15) {
        setSelectedValue({name : "Privacy Policy"})
      } 
      else if (scrollPosition >= section3Top - windowHeight * 0.15) {
        setSelectedValue({name : "Refund Policy"})
      }
       else {
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [section1, section2 , section3]);

  useEffect(() => {
    if(selectedValue.name && selectedValue.name === "Terms and Conditions"){
        section1.current.scrollIntoView({
            behavior: 'smooth', // Optional: Add smooth scrolling animation
            block: 'start', // Scroll to the top of the element
            inline: 'nearest', // Keep the element nearest to the top
          });
    
    } else if(selectedValue.name && selectedValue.name === "Privacy Policy"){
        section2.current.scrollIntoView({
            behavior: 'smooth', // Optional: Add smooth scrolling animation
            block: 'start', // Scroll to the top of the element
            inline: 'nearest', // Keep the element nearest to the top
          });
    
    }
    else if(selectedValue.name && selectedValue.name === "Refund Policy"){
      section3.current.scrollIntoView({
          behavior: 'smooth', // Optional: Add smooth scrolling animation
          block: 'start', // Scroll to the top of the element
          inline: 'nearest', // Keep the element nearest to the top
        });
  
  }
   
  }, [activeSection]);

  useEffect(() => {
    setActiveSection(!activeSection)
    setSelectedValue(form.state && form.state.value !== "" ? {name : form.state.value} : {name : "Terms and Conditions"})
  },[form.state])

  return (
    <section className='t_and_c-dropdown-div' style={{display : width < 991 ? "" : "none"}}>
      <Dropdown 
        value={selectedValue} onChange={(e) => handleChange(e)} 
        options={option} 
        optionLabel="name" 
        placeholder="Select a City" 
        className="w-full md:w-14rem" 
      />
    </section>
  );
}
