//react
import { useEffect, useState } from "react";

//lib
import "swiper/css";
import * as Yup from "yup";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Lottie from "lottie-react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import { Skeleton } from "primereact/skeleton";
import { Pagination, Navigation } from "swiper";
import { RemoveScroll } from "react-remove-scroll";
import { Swiper, SwiperSlide } from "swiper/react";
import { SegmentedControl } from "segmented-control";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

//assets
import MediaIcon from "../../../assets/json/media-post.json";

//component
import VideoCard from "./video.card";
import PdfRender from "./pdf.render";
import ImgRender from "./img.render";
import CommentCard from "./comment.card";
import PostMemberDetail from "./post.member.detail";
import InfoHeader from "../../../components/header/info-header";
import ShareModal from "../../../components/modal/share.modal";

//hook
import useWindowDimensions from "../../../components/common/window-dimension";

//redux
import {
  setForumSettingData,
  setShareModal,
  setShowLoginModal,
} from "../../../redux/reducer/settingReducer";
import {
  clearSelectedDataInfo,
  createComment,
  getForumInfo,
  getPostComment,
  likeAction,
  setProfileModal,
  setShareUrl,
} from "../../../redux/reducer/forumReducer";
import { useDispatch, useSelector } from "react-redux";
import { getHome } from "../../../redux/reducer/homeReducer";
import { openActivityModal } from "../../../redux/reducer/modalReducer";

//assets
import Like from "./../../../assets/json/like-graphic.json";
import BigLike from "./../../../assets/json/big-like-graphic.json";
import PostImageLoading from "./../../../assets/json/post-image-loading-graphic.json";
import Item from "antd/es/list/Item";

export default function PostInfo({ setShow, likeIncrement }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { width } = useWindowDimensions();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedId = searchParams.get("post");
  const user = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");
  const [fitler, setFilter] = useState("like_count");
  const [currentIndex, setCurrentIndex] = useState(1);
  const [commentData, setCommentData] = useState([]);
  const [commentAdded, setCommentAdded] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [likeEffect, setLikeEffect] = useState(false);
  const [bigLikeEffect, setBigLikeEffect] = useState(false);
  const [alreadyLike, setAlreadyLike] = useState(false);
  const [seeMore, setSeeMore] = useState(false);

  const loc = useLocation();
  const queryParams = new URLSearchParams(loc.search);
  const postParam = queryParams.get("post");
  const parts = postParam ? postParam.split("/") : [];
  const state = parts[1];

  const [placeholder, setPlaceholder] = useState("Type something here..");
  const {
    id,
    title,
    member_type,
    user_name,
    created_at,
    create_by_avatar,
    mainMediaUrl,
    content,
    comment_count,
    job_title,
    car_model,
    location,
    isOwner,
    last_updated_at,
    is_like,
    like_count,
    user_id,
    status,
    share_url, 
    topic
  } = useSelector((state) => state.forum.selectedDataInfo);
  const { commentLoading, createCommentData, post_info_loading, postListLoading } = useSelector(
    (state) => state.forum
  );

  const { shareModal } = useSelector((state) => state.setting);

  const getCommentListing = () => {
    dispatch(
      getPostComment({
        post_id: selectedId || id,
        user_id: Number(user),
        order: fitler,
      })
    )
      .unwrap()
      .then((res) => {
        setCommentData(res.data.aaData);
      });
  };

  const getPostInfo = () => {
    dispatch(getForumInfo({ id: selectedId })).then((res) => {
      getCommentListing();
    });
  };

  useEffect(() => {
    if (!post_info_loading && selectedId) {
      getCommentListing();
    }
  }, [fitler]);

  useEffect(() => {
    if (selectedId) {
      getPostInfo();
    }
  }, [selectedId]);

  const useSchema = Yup.object({});

  const handleBackButton = () => {
    setSeeMore(false)
    setCommentData([]);
    setAlreadyLike(false);
    dispatch(clearSelectedDataInfo());
    if (window.location.pathname === "/profile") {
      dispatch(openActivityModal());
      navigate("/profile");
    } else {
      if (window.location.pathname === "/forum") {
        navigate("/forum");
      } else {
        navigate("/");
        if (width < 991) {
          dispatch(getHome());
        } else {
        }
      }
    }
  };

  const handleSetting = (commentId, type, username, owner) => {
    setShow(true);
    if (type === "comment") {
      dispatch(
        setForumSettingData({
          data: {
            id: commentId,
            type: type,
            username: username,
            isOwner: owner,
          },
        })
      );
    } else {
      dispatch(
        setForumSettingData({
          data: { id: id, type: "post", username: user_name, isOwner: isOwner },
        })
      );
    }
  };

  const formatCash = (num, digits = 2) => {
    var units = ["k", "M", "B", "T", "P", "E", "Z", "Y"],
      decimal;

    for (var i = units.length - 1; i >= 0; i--) {
      decimal = Math.pow(1000, i + 1);

      if (num <= -decimal || num >= decimal) {
        const number = +(num / decimal).toFixed(digits);
        if (units[i] === "Y") {
          return number.toLocaleString() + units[i];
        }
        return number.toLocaleString() + units[i];
      }
    }

    return num;
  };

  const handleSeeMore = () => {
    setSeeMore(true);
  };

  const handleSubmit = (values, setFieldValue) => {
    if (token) {
      setCreateLoading(true);
      dispatch(createComment(values))
        .unwrap()
        .then((res) => {
          getCommentListing();
          setCommentAdded(true);
          setCreateLoading(false);
          setFieldValue("comment_id", "");
        });
    } else {
      dispatch(setShowLoginModal(true));
    }
  };

  const autoOpenModal = {
    share: setShareModal(true),
  };

  const MAX_CHARACTERS = 500;

  const truncateContent = (text) => {
    if (text.length <= MAX_CHARACTERS) {
      return text;
    }
  
    return text.substring(0, MAX_CHARACTERS);
  };
  
  const renderContent = (content) => {
    if (content.length <= MAX_CHARACTERS) {
      return <p className="_label --post-detail break-all">{content}</p>;
    } else {
      return (
        <article>
          <p className="_label --post-detail break-all">
            {seeMore ? content : truncateContent(content)}
            {seeMore ? '' : '...'}&nbsp;
            {!seeMore && (
              <span className="see-more" onClick={handleSeeMore}>
                See More
              </span>
            )}
          </p>
        </article>
      );
    }
  };

  useEffect(() => {
    setSeeMore(false)

    if (state) {
      dispatch(autoOpenModal[state]);
    }
  }, []);

  const mobileLayout = (values, setFieldValue) => {
    return (
      <section>
        <article className="mobile-layout-container p-0">
          {width < 991 && bigLikeEffect && (
            <figure className="loading-css --big-like">
              <section
                className="loading-animation --big-like"
                style={{ width: "10em" }}
              >
                <Lottie animationData={BigLike} style={{ width: "100%" }} />
              </section>
            </figure>
          )}
          <header>
            <PostMemberDetail
              avatar={create_by_avatar}
              member={user_name}
              member_type={member_type}
              update_date={created_at}
              car_model={car_model}
              job_title={job_title}
              settingButton={token ? () => handleSetting() : null}
              avatarClick={() => {
                dispatch(setProfileModal({ data: "profile-modal", id: user_id }));
              }}
              loading={post_info_loading}
            />
          </header>
          <section>
            {forumCommentSection(
              width,
              mainMediaUrl,
              title,
              content,
              comment_count,
              commentLoading,
              commentData,
              setPlaceholder,
              getCommentListing,
              setFilter
            )}
          </section>
        </article>
        {
          <nav className="fixed-bottom make-comment-container">
            <section className="row m-0">
              <article className="col">
                <Field
                  id="content"
                  name="content"
                  values={values}
                  placeholder={placeholder}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setFieldValue("content", newValue);
                  }}
                />
              </article>
              <article
                className={`d-flex justify-content-center align-items-center ${
                  !post_info_loading ? "gap-4" : "gap-3"
                } col-1`}
              >
                {!post_info_loading ? (
                  <>
                    <section>
                      <Icon
                        onClick={() => {
                          if (values.content.trim() !== "") {
                            setFieldValue("content", "");
                            setFieldValue("comment_id", "");
                            setPlaceholder("Type something here..");
                            handleSubmit(values);
                          }
                        }}
                        icon="iconamoon:send-fill"
                        width={30}
                        color={
                          values.content.trim() !== "" ? "#3DB8ED" : "#909090"
                        }
                        style={{ cursor: "pointer", width: "30px" }}
                      />
                    </section>
                  </>
                ) : (
                  <section>
                    <Skeleton width="32px" height="32px" shape="circle" />
                  </section>
                )}
              </article>
            </section>
          </nav>
        }
      </section>
    );
  };

  const desktopLayout = (values, setFieldValue) => {
    return (
      <section className="container modal-container">
        {width > 991 && bigLikeEffect && (
          <figure className="loading-css --big-like">
            <article
              className="loading-animation --big-like"
              style={{ width: "10em" }}
            >
              <Lottie animationData={BigLike} style={{ width: "100%" }} />
            </article>
          </figure>
        )}
        <section className="row m-0">
          <article
            className="col-7 p-0 d-flex align-items-center justify-content-center"
            style={{ position: "relative" }}
          >
            <section className="slide-pages">
              <p>
                {currentIndex}/
                {mainMediaUrl && mainMediaUrl.length > 0
                  ? mainMediaUrl.length
                  : 0}
              </p>
            </section>
            <Swiper
              slidesPerView={1}
              spaceBetween={0}
              grabCursor={true}
              navigation={
                mainMediaUrl && mainMediaUrl?.length > 1 ? true : false
              }
              onSlideChange={(s) => setCurrentIndex(s.activeIndex + 1)}
              modules={[Navigation]}
            >
              {!post_info_loading ? (
                mainMediaUrl &&
                mainMediaUrl.length > 0 &&
                mainMediaUrl.map((item, index) => (
                  <SwiperSlide key={index}>
                    <figure className="image-container-box">
                      <article className="box">
                        {item.mime_type == "mp4" || 
                        item.mime_type === "MOV" || 
                        item.mime_type === "mov" ? (
                          <VideoCard video={item.url} />
                        ) : item.mime_type === "pdf" ? (
                          <PdfRender
                            pdf={item.api_url}
                            pageNumber={currentIndex}
                          />
                        ) : (
                          <ImgRender
                            item={item}
                            index={index}
                            alt={content}
                          />
                        )}
                      </article>
                    </figure>
                  </SwiperSlide>
                ))
              ) : (
                <Lottie animationData={PostImageLoading} />
              )}
            </Swiper>
          </article>
          <article className="col-5 p-0">
            {mobileLayout(values, setFieldValue)}
          </article>
        </section>
      </section>
    );
  };

  const forumCommentSection = () => {
    return (
      <article style={{ paddingTop: width < 991 ? "0em" : "3em" }}>
        <header className="forum-content">
          {width < 991 && (
            <figure className="image-container">
              {!post_info_loading ? (
                <Swiper
                  slidesPerView={1}
                  spaceBetween={0}
                  grabCursor={true}
                  pagination={true}
                  modules={[Pagination]}
                >
                  {mainMediaUrl &&
                    mainMediaUrl.map((item, index) => (
                      <SwiperSlide key={index}>
                        <section className="image-container-box">
                          <article className="box">
                            {item.mime_type == "mp4" ||
                             item.mime_type === "MOV" ||
                             item.mime_type === "mov" ? (
                              <VideoCard video={item.url} />
                            ) : item.mime_type === "pdf" ? (
                              <PdfRender
                                pdf={item.api_url}
                                pageNumber={currentIndex}
                              />
                            ) : (
                              <LazyLoadImage
                                key={index}
                                src={item.url}
                                alt={content}
                              />
                            )}
                          </article>
                        </section>
                      </SwiperSlide>
                    ))}
                </Swiper>
              ) : (
                <Lottie animationData={PostImageLoading} />
              )}
            </figure>
          )}
          <article className="row">{renderContent(content)}</article>
        </header>
        <section className="post-location m-0">
          {!post_info_loading ? (
            <p className="_label --member-detail-small-text w-auto p-0">
              Last update at {last_updated_at}
            </p>
          ) : (
            <p className="_label --member-detail-small-text w-auto p-0 d-flex">
              Last update at{" "}
              <Skeleton
                width="100px"
                height="20px"
                borderRadius="2em"
                shape="rectangle"
                className="ms-2"
              />
            </p>
          )}
          <p className="_label --member-detail-small-text w-auto ps-2">
            {location ? `• ${location}` : ""}
          </p>
        </section>
        <section
          className="d-flex justify-content-end"
          style={{ padding: "0.5em 1em" }}
        >
          {!post_info_loading ? (
            <>
              <article style={{ position: "relative" }}>
                {likeEffect && (
                  <section className="loading-css --like">
                    <article
                      className="loading-animation --like"
                      style={{ width: "10em" }}
                    >
                      <Lottie animationData={Like} style={{ width: "100%" }} />
                    </article>
                  </section>
                )}
                <p
                  style={{
                    position: "absolute",
                    fontSize: "1rem",
                    top: "-5px",
                    left: "-5px",
                    background: "#d6d6d6",
                    width: 18,
                    height: 18,
                    textAlign: "center",
                    borderRadius: "2em",
                    fontFamily: "Inter",
                  }}
                >
                  {alreadyLike
                    ? formatCash(like_count + 1)
                    : formatCash(like_count)}
                </p>
                <Icon
                  color={
                    likeEffect || is_like || alreadyLike ? "#F44336" : "#707070"
                  }
                  onClick={() => {
                    if (token) {
                      if (isOwner) {
                        toast.error("Can't like your own post", {
                          theme: "colored",
                          hideProgressBar: true,
                          closeOnClick: true,
                          closeButton: false,
                          autoClose: 2000,
                        });
                      } else if (is_like) {
                      } else {
                        setLikeEffect(true);
                        setBigLikeEffect(true);
                        dispatch(
                          likeAction({
                            type: "post",
                            data: {
                              post_id: id,
                              user_id: Number(user),
                            },
                          })
                        )
                          .unwrap()
                          .then((res) => {
                            likeIncrement(id);
                            setAlreadyLike(true);
                            //getPostInfo();
                            setTimeout(() => {
                              setBigLikeEffect(false);
                            }, 2000);
                            setTimeout(() => {
                              setLikeEffect(false);
                            }, 2000);
                          })
                          .catch((ex) => {
                            const error =
                              ex?.response?.data?.errors?.post_id[0];
                            if (error) {
                              toast.error(error, {
                                theme: "colored",
                                hideProgressBar: true,
                                closeOnClick: true,
                                closeButton: false,
                              });
                            }
                          });
                      }
                    } else {
                      dispatch(setShowLoginModal(true));
                    }
                  }}
                  icon={
                    likeEffect
                      ? "solar:chat-square-like-bold-duotone"
                      : "solar:chat-square-like-line-duotone"
                  }
                  width={30}
                  style={{
                    cursor: isOwner
                      ? "no-drop"
                      : is_like
                      ? "no-drop"
                      : "pointer",
                    width: "30px",
                  }}
                />
              </article>
              <Link style={{ paddingLeft: "0.5em" }}>
                <Icon
                  onClick={(e) => {
                    e.preventDefault();

                    dispatch(autoOpenModal['share']);
                    dispatch(setShareUrl(share_url))
                    if(width > 991) {
                      navigate(`/?post=${id}/share`);
                    } else {
                      if(pathname === '/') {
                        navigate(`/?post=${id}/share`);
                      } else {
                        navigate(`/forum?post=${id}/share`);
                      }
                    }
                  }}
                  icon="basil:share-box-outline"
                  width={30}
                  style={{ cursor: "pointer", width: "30px" }}
                  color="#707070"
                />
              </Link>
            </>
          ) : (
            Array.from({ length: 2 }, (_, index) => (
              <article key={index}>
                <Skeleton
                  width="32px"
                  height="32px"
                  shape="circle"
                  className="ms-3"
                />
              </article>
            ))
          )}
        </section>
        <section className="row comment-detail m-0">
          <article className="d-flex align-items-center gap-2 p-0 col-5">
            <p style={{ fontSize: "1.4rem" }}>Comment</p>
            <section className="d-flex align-items-center pb-1">
              <Icon icon="iconamoon:comment" width={19} color="#565353" />
            </section>
            <p style={{ fontSize: "1.4rem" }}>
              {formatCash(comment_count)}
            </p>
          </article>
          <article className="col-7 d-flex justify-content-end p-0">
            {!post_info_loading ? (
              <section className="segmented-control-container">
                <SegmentedControl
                  name="segmented-control"
                  options={[
                    {
                      label: "Most relevant",
                      value: "like_count",
                    },
                    { label: "All Comment", value: "id", default: true },
                  ]}
                  setValue={(newValue) => setFilter(newValue)}
                  style={{ width: "100%", color: "#909090" }}
                />
              </section>
            ) : (
              <Skeleton
                width="180px"
                height="36px"
                borderRadius="2em"
                shape="rectangle"
              />
            )}
          </article>
        </section>
        <section className="comment-detail m-0">
          {createLoading && (
            <article className="d-flex gap-3 mb-4">
              <section>
                <Skeleton shape="circle" size="30px" className="mr-2" />
              </section>
              <section className="w-100 ms-4">
                <Skeleton
                  height="6rem"
                  className="mb-2"
                  borderRadius="15px"
                ></Skeleton>
              </section>
            </article>
          )}
          {commentLoading && (
            <article className="d-flex gap-3">
              <section>
                <Skeleton shape="circle" size="3rem" className="mr-2" />
              </section>
              <section className="w-100">
                <Skeleton height="5rem" className="mb-2"></Skeleton>
              </section>
            </article>
          )}
          {!post_info_loading &&
            !commentLoading &&
            commentData?.length === 0 && (
              <article className="d-flex gap-3 justify-content-center">
                <p
                  className="text-center"
                  style={{
                    fontSize: "1.2rem",
                    color: "#868686",
                    paddingTop: "1em",
                  }}
                >
                  No comment yet
                </p>
              </article>
            )}
          {commentData?.length > 0 &&
            commentData.map((layerOneItem, index) => (
              <article key={index}>
                <CommentCard
                  handleSetting={handleSetting}
                  layer_one={layerOneItem.id}
                  post_id={id}
                  type={"one"}
                  item={layerOneItem}
                  setPlaceholder={setPlaceholder}
                  getCommentListing={getCommentListing}
                  loading={createLoading}
                />
                {layerOneItem.sub_comment.length > 0 &&
                  layerOneItem.sub_comment.map((item, index) => (
                    <section key={index}>
                      <CommentCard
                        handleSetting={handleSetting}
                        layer_one={layerOneItem.id}
                        post_id={id}
                        type={"two"}
                        item={item}
                        setPlaceholder={setPlaceholder}
                        getCommentListing={getCommentListing}
                        loading={createLoading}
                      />
                    </section>
                  ))}
              </article>
            ))}
        </section>
      </article>
    );
  };

  if (status === "inactive") {
    return (
      <article>
        <AnimatePresence>
          {selectedId && (
            <RemoveScroll>
              <motion.div
                className="forum-listing-card --active"
                initial={{
                  backgroundColor: width > 991 ? "#ffffff00" : "#fafafa",
                  scale: 0.2,
                }}
                animate={{
                  scale: 1,
                  backgroundColor: width > 991 ? "#6a6868e0" : "#fafafa",
                }}
                exit={{
                  opacity: [1, 0],
                  scale: 0.5,
                  backgroundColor: width > 991 ? "#ffffff00" : "#fafafa",
                  transition: {
                    duration: 0.2,
                  },
                }}
                transition={{
                  scale: { duration: 0.3 },
                  backgroundColor: { delay: 0.1, duration: 0.5 },
                }}
              >
                {width < 991 && (
                  <InfoHeader
                    title={
                      topic === "help" ? 
                      "Help Post" : 
                      topic === "recommendation" ? 
                      "Sharing" : 
                      topic === "showcase" ? 
                      "Showcase"
                      : ""
                    }
                    backButton={() => {
                      handleBackButton();
                    }}
                    settingButton={null}
                  />
                )}
                <section className="forum-post-info-content d-flex align-items-center">
                  {width >= 990 && (
                    <Link
                      className="button-back-round pointer"
                      onClick={(e) => {
                        e.preventDefault();

                        handleBackButton();
                      }}
                    >
                      <Icon
                        icon="tabler:arrow-back-up"
                        className="back-icon"
                        width={24}
                      />
                    </Link>
                  )}
                  {width >= 990 && (
                    <section
                      className="container modal-container"
                      style={{ display: "grid", alignItems: "center" }}
                    >
                      <section className="row m-0">
                        <section className="row post-card-listing-row justify-content-center">
                          <Lottie
                            animationData={MediaIcon}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              width: "15em",
                            }}
                          />
                          <h4 className="title text-center">
                            No Post Found
                          </h4>
                          <p className="desc text-center">
                            The link you clicked maybe broken or the page may
                            have been remove.
                          </p>
                        </section>
                      </section>
                    </section>
                  )}
                  {width < 991 && (
                    <section className="container mobile-layout-container p-0">
                      <section className="row post-card-listing-row justify-content-center">
                        <Lottie
                          animationData={MediaIcon}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "15em",
                          }}
                        />
                        <h4 className="title text-center">
                          No Post Found
                        </h4>
                        <p className="desc text-center">
                          The link you clicked maybe broken or the page may
                          have been remove.
                        </p>
                      </section>
                    </section>
                  )}
                </section>
              </motion.div>
            </RemoveScroll>
          )}
        </AnimatePresence>
      </article>
    );
  }

  return (
    <article>
      <AnimatePresence>
        {selectedId && (
          <RemoveScroll>
            <Formik
              enableReinitialize={true}
              validateOnChange={true}
              validateOnBlur={true}
              validateOnMount={true}
              initialValues={createCommentData}
              validationSchema={useSchema}
              onSubmit={(
                values,
                { setSubmitting, resetForm, setFieldError, setFieldValue }
              ) => {
                if (token) {
                  handleSubmit(values, setFieldError, setFieldValue);
                  resetForm({ values: "" });
                } else {
                  dispatch(setShowLoginModal(true));
                }
              }}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <motion.div
                    onClick={width < 991 ? null : () => handleBackButton()}
                    className="forum-listing-card --active"
                    initial={{
                      backgroundColor: width > 991 ? "#ffffff00" : "#fafafa",
                      scale: 0.2,
                    }}
                    animate={{
                      scale: 1,
                      backgroundColor: width > 991 ? "#6a6868e0" : "#fafafa",
                    }}
                    exit={{
                      opacity: [1, 0],
                      scale: 0.5,
                      backgroundColor: width > 991 ? "#ffffff00" : "#fafafa",
                      transition: {
                        duration: 0.2,
                      },
                    }}
                    transition={{
                      scale: { duration: 0.3 },
                      backgroundColor: { delay: 0.1, duration: 0.5 },
                    }}
                  >
                    {width < 991 && (
                      <InfoHeader
                        title={
                          topic === "help" ? 
                          "Help Post" : 
                          topic === "recommendation" ? 
                          "Sharing" : 
                          topic === "showcase" ? 
                          "Showcase"
                          : ""
                        }
                        backButton={() => {
                          handleBackButton();
                          setLikeEffect(false);
                          setBigLikeEffect(false);
                        }}
                        settingButton={token ? () => handleSetting() : null}
                      />
                    )}
                    <section>
                      <article
                        className="forum-post-info-content"
                        onClick={(event) => event.stopPropagation()}
                      >
                        {width >= 990 && (
                          <header
                            className="button-back-round pointer"
                            onClick={() => {
                              handleBackButton();
                              setLikeEffect(false);
                              setBigLikeEffect(false);
                            }}
                          >
                            <Icon
                              icon="tabler:arrow-back-up"
                              className="back-icon"
                              width={24}
                            />
                          </header>
                        )}
                        {width >= 990 && desktopLayout(values, setFieldValue)}
                        {width < 991 && mobileLayout(values, setFieldValue)}
                      </article>
                    </section>
                  </motion.div>
                </Form>
              )}
            </Formik>
          </RemoveScroll>
        )}
      </AnimatePresence>
      {shareModal && width < 991 && <ShareModal url={share_url} imageUrl={mainMediaUrl?.length > 0 && mainMediaUrl[0].api_url} type='1'/>}
    </article>
  );
}
