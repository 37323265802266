import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//lib
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalFooter,
  MDBModalTitle,
  MDBModalBody,
  MDBModalHeader,
} from "mdb-react-ui-kit";
import { RemoveScroll } from "react-remove-scroll";
import { toast } from "react-toastify";

// redux
import {
  claimMission,
  getMissionList,
  getProfile,
  postUnlockMission,
} from "../../redux/reducer/profileReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  setPaymentLoading,
  setPaymentMethod,
  setShowConfirmModal,
  setShowPurchaseModal,
} from "../../redux/reducer/stockReducer";
import { closeAllModal, openModalEvent, openModalOrderHistory, openModalPurchase } from "../../redux/reducer/modalReducer";
import { postPurchaseTicket, setSelectedPaymentMethod, setSurePay } from "../../redux/reducer/paymentReducer";

// api
import apiServices from "../../services/api.services";
import { deleteCart, getCartData } from "../../redux/reducer/cartReducer";
import { setShowFullModal } from "../../redux/reducer/commonReducer";
import { setIsDiscardPost } from "../../redux/reducer/forumReducer";

export default function ConfirmationModal({
  className,
  show,
  title,
  content,
  footer,
  setShow,
  type,
  value,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");

  const { surePay, payment_loading } = useSelector((state) => state.stock);
  const { surePayTicket } = useSelector((state) => state.payment);
  const { activeTab } = useSelector((state) => state.cart);

  const handleUnlockMission = () => {
    dispatch(postUnlockMission({ mission_id: show.id }))
      .unwrap()
      .then((res) => {
        dispatch(getMissionList());
        setShow(false);
        toast.success("Unlock Mission successful.", {
          autoClose: 3000,
          theme: "light",
          hideProgressBar: true,
          closeOnClick: true,
          closeButton: false,
        });
      })
      .catch((ex) => {
        const error = ex?.response?.data?.errors.error;
        if (error) {
          setErrorMsg(error);
        }
      });
  };

  const handleClaimMission = () => {
    dispatch(claimMission({ mission_id: show.id }))
      .unwrap()
      .then((res) => {
        dispatch(getProfile());
        dispatch(getMissionList());
        setShow(false);
        toast.success("Claim Avatar successful.", {
          autoClose: 3000,
          theme: "light",
          hideProgressBar: true,
          closeOnClick: true,
          closeButton: false,
        });
      })
      .catch((ex) => {
        const error = ex?.response?.data?.errors.error;

        if (error) {
          setErrorMsg(error);
        }
      });
  };

  const handlePurchaseProduct = async () => {
    try {
      const response = await apiServices.checkout(surePay);

      if (response && response.data.type === 'point' ) {
        dispatch(setShowConfirmModal(false));
        dispatch(setPaymentMethod(""));
        navigate("/profile");
        dispatch(openModalOrderHistory(false));
        toast.success("Make order successful.", {
          autoClose: 3000,
          theme: "light",
          hideProgressBar: true,
          closeOnClick: true,
          closeButton: false,
        });
      } else {
        const paymentUrl = response.data;
        window.location.href = paymentUrl;
        dispatch(openModalOrderHistory(false));
      }
    } catch (ex) {
      if (ex && ex.response.status === 422) {
        const errors = ex.response.data.errors;
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) =>
            toast.error(`${errors[item]}`, {
              autoClose: 3000,
              theme: "light",
              hideProgressBar: true,
              closeOnClick: true,
              closeButton: false,
            })
          );
        }
      }
    }
  };

  const handlePurchaseTicket = async () => {
    // dispatch(setPaymentLoading(true))
    dispatch(postPurchaseTicket(surePayTicket))
      .unwrap()
      .then((res) => {
        if (res.data && res.data.type == "point") {
          // dispatch(setPaymentLoading(false))
          dispatch(closeAllModal());
          dispatch(setSurePay({}))
          dispatch(setShowConfirmModal(false));
          dispatch(setSelectedPaymentMethod(""));
          navigate('/profile')
          dispatch(openModalEvent());
          toast.success("Payment successful.", {
            autoClose: 3000,
            theme: "light",
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
          });
        }
      })
      .catch((ex) => {
        dispatch(setPaymentLoading(false))
        if (ex && ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) =>
              toast.error(`${errors[item]}`, {
                autoClose: 3000,
                theme: "light",
                hideProgressBar: true,
                closeOnClick: true,
                closeButton: false,
              })
            );
          }
        }
      });
  };

  const handleDeleteCart = async () => {
    dispatch(deleteCart({
      id: value
    }))      
      .unwrap()
      .then((res) => {
          dispatch(getCartData({
            cart_payment_type: activeTab
          }));
          dispatch(setShowConfirmModal(false));
          toast.success("Cart item deleted successfully", {
            autoClose: 3000,
            theme: "light",
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
          });
        }
      )
      .catch((ex) => {
        if (ex && ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) =>
              toast.error(`${errors[item]}`, {
                autoClose: 3000,
                theme: "light",
                hideProgressBar: true,
                closeOnClick: true,
                closeButton: false,
              })
            );
          }
        }
      });
  };

  const handleDiscardPost = async () => {
    dispatch(setIsDiscardPost(true))
    dispatch(setShowConfirmModal(false))
  };

  return (
    <RemoveScroll>
      <MDBModal
        staticBackdrop
        className={`comfirmation-modal`}
        tabIndex="-1"
        show={show ? true : false}
        // setShow={setShow}
      >
        <MDBModalDialog
          centered={true}
          //   size={``}
          //   scrollable={scrollable ? true : false}
        >
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                {show.type === "unlock"
                  ? "Unlock Mission Task"
                  : type === "purchase"
                  ? "Purchase Product"
                  : type === "ticket"
                  ? "Purchase Ticket"
                  : type === "delete_cart"
                  ? "Delete Cart Item"
                  : type === "post"
                  ? "Discard Editing Post"
                  : "Collect Avatar"}
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <p>
                {show.type === "unlock"
                  ? "Are you sure you want to unlock this mission task?"
                  : type === "purchase"
                  ? "Are you sure you want to purchase this product?"
                  : type === "ticket"
                  ? "Are you sure you want to purchase this ticket?"
                  : type === "delete_cart"
                  ? "Are you sure you want to delete this cart item?"
                  : type === "post"
                  ? "If go back now, you will lose all the changes that you've made."
                  : "You can try your collected avatar when click the profile picture."}
              </p>
              {errorMsg && (
                <p style={{ color: "#EA3943", fontSize: "1rem" }}>
                  {errorMsg}
                </p>
              )}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                className="close-button"
                onClick={() => {
                  if (type === "purchase") {
                    dispatch(setShow(false));
                  } else if (type === "ticket") {
                    dispatch(setShow(false));
                    dispatch(setShowFullModal(true));
                    dispatch(openModalPurchase());
                  } else {
                    setShow(false);
                    dispatch(setShowConfirmModal(false))
                  }
                }}
              >
                Close
              </MDBBtn>
              <MDBBtn
                className="comfirm-button"
                style={{ backgroundColor: type === "delete_cart" || type === "post" ? "#dc4c64" : "#3DB8ED" }}
                disabled={payment_loading}
                onClick={() => {
                  if (show.type === "unlock") {
                    handleUnlockMission();
                  } else if (type === "purchase") {
                    handlePurchaseProduct();
                  } else if (type === "ticket") {
                    handlePurchaseTicket();
                  } else if (type === "delete_cart") {
                    handleDeleteCart();
                  } else if (type === "post") {
                    handleDiscardPost();
                  } else {
                    handleClaimMission();
                  }
                }}
              >
                {show.type === "unlock"
                  ? "Unlock"
                  : type === "purchase" || type === "ticket" || type === "delete_cart"
                  ? "Yes"
                  : type === "post"
                  ? "Discard"
                  : "Collect"}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </RemoveScroll>
  );
}
