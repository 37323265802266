import { useEffect, useState } from "react";

//lib

//component

//hook
import useWindowDimensions from "../../../components/common/window-dimension";
import { useDispatch, useSelector } from "react-redux";
import { RemoveScroll } from "react-remove-scroll";
import InfoHeader from "../../../components/header/info-header";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import PdfRender from "./pdf.render";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import VideoCard from "./video.card";
import ImgRender from "./img.render";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Skeleton } from "primereact/skeleton";
import { Icon } from "@iconify/react";
import { DefaultAvatar } from "../../../assets/images";
import { SegmentedControl } from "segmented-control";

//redux


export default function PreviewPost({  }) {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { createPostModal, previewItem } = useSelector((state) => state.forum);
  const { isOpenPostPreview } = useSelector((state) => state.modal);

  const [ activeTab, setActiveTab ] = useState("Cover");
  const [currentIndex, setCurrentIndex] = useState(1);

  const [imageWidth, setImageWidth] = useState(null);
  const [imageHeight, setImageHeight] = useState(null);

  const [reminderOne, setReminderOne] = useState(false);
  const [reminderTwo, setReminderTwo] = useState(false);

  const [seeMore, setSeeMore] = useState(false);

  const menuTabs = [
    {label: 'Cover'},
    {label: 'Content'},
  ]

  const loadImageDimensions = (previewItem) => {

    if(previewItem.type?.split("/")[0] === "image") {
        const img = new Image();
        img.src = URL.createObjectURL(previewItem);
        
        img.onload = function () {
          setImageWidth(img.width);
          setImageHeight(img.height);
        };
    } else if(previewItem.type?.split("/")[0] === "video" 
      || previewItem.type?.split("/")[0] === "application") {
        setImageWidth(parseFloat(previewItem.size / (1024 * 1024)).toFixed(2))
    }
  };

  const handleSeeMore = () => {
    setSeeMore(true);
  };

  const MAX_CHARACTERS = 500;

  const truncateContent = (text) => {
    if (text.length <= MAX_CHARACTERS) {
      return text;
    }
  
    return text.substring(0, MAX_CHARACTERS);
  };
  
  const renderContent = (content) => {
    if (content.length <= MAX_CHARACTERS) {
      return <p className="--post-detail break-all">{content ? content : 'Your content..'}</p>;
    } else {
      return (
        <section className="p-0">
          <p className="--post-detail break-all">
            {seeMore ? content : truncateContent(content)}
            {seeMore ? '' : '...'}&nbsp;
            {!seeMore && (
              <span className="see-more" onClick={handleSeeMore}>
                See More
              </span>
            )}
          </p>
        </section>
      );
    }
  };

  useEffect(() => {
    loadImageDimensions(previewItem.cover)
  }, [])


  return (
    <article className="preview-row">
        <header 
            className="preview-main-tab"
            style={{ 
                height: activeTab === 'Content' && width > 991 ? '0em' : activeTab === 'Content' && width < 991 ? '5em' : '1em'
            }}
        >
            {menuTabs.map((tab, tabIndex) => (
                <p 
                    className={`preview-tab col-6 ${ activeTab === tab.label ? '--active' : ''}`} 
                    style={{ borderTopLeftRadius: activeTab === "Cover" && width > 991 ? '2em' : '', borderTopRightRadius: activeTab === "Content" && width > 991 ? '2em' : '' }}
                    onClick={() => {
                        setActiveTab(tab.label)
                        setSeeMore(false)
                    }}
                >
                    {tab.label}
                </p>
            ))}
        </header>
        <section className={`preview-content ${activeTab === 'Content' && width > 991 ? 'd-flex p-0' : activeTab === 'Content' && width < 991 ? 'p-0' : ''} `}>
        {activeTab === "Cover" ?
            <article>
                <h4 className="preview-text --bold mb-3">Desktop preview </h4>
                    <section className={`${width > 991 ? 'd-flex gap-5' : 'd-grid gap-3'}`}>
                        <article className={`col-lg-4 col-md-4 col-12 d-flex justify-content-center`}>
                            <section className="preview-image-container">
                            {previewItem.cover?.type?.split("/")[0] === "video" ? (
                                <video>
                                <source src={URL.createObjectURL(previewItem.cover)} type="video/mp4" />
                                <source src={URL.createObjectURL(previewItem.cover)} type="video/quicktime" />
                                <track src="captions_en.vtt" kind="captions" srclang="en" label="english_captions" />
                                </video>
                            ) : previewItem.cover?.type?.split("/")[0] === "application" ? (
                                <PdfRender pdf={URL.createObjectURL(previewItem.cover)} pageNumber={1} />
                            ) : (
                                <LazyLoadImage src={URL.createObjectURL(previewItem.cover)} width="100%" alt="reunion-preview-post" />
                            )}
                            </section>
                        </article>
                        <article className="col-lg-8 col-md-8 col-12 preview-text --grey">
                            {previewItem.cover?.type?.split("/")[0] === "image" ?
                                <p className="mt-2">
                                    <span className="preview-text --blue">Recommended size: 200 x 200.</span> <br/> Uploaded image size: {imageWidth} x {imageHeight}. <br/>
                                    For optimal display, consider uploading images that match these dimensions.
                                </p>
                            : previewItem.cover?.type?.split("/")[0] === "video" ?
                                <p className="mt-2">
                                    <span className="preview-text --blue">Limit video file size: 50 MB.</span> <br/> Uploaded video file size: {imageWidth} MB. <br/>
                                </p>
                            :
                                <p className="mt-2">
                                    <span className="preview-text --blue">Limit file size: 5 MB.</span> <br/> Uploaded file size: {imageWidth} MB. <br/>
                                </p>
                            }
                        </article>
                    </section>
                <h4 className="preview-text --bold mt-5 mb-3">Mobile preview </h4>
                    <section className={`${width > 991 ? 'd-flex gap-5' : 'd-grid gap-3'}`}>
                        <article className={`col-lg-4 col-md-4 col-12 d-flex justify-content-center`}>
                            <section className="preview-image-container --mobile">
                            {previewItem.cover?.type?.split("/")[0] === "video" ? (
                                <video>
                                <source src={URL.createObjectURL(previewItem.cover)} type="video/mp4" />
                                <source src={URL.createObjectURL(previewItem.cover)} type="video/quicktime" />
                                <track src="captions_en.vtt" kind="captions" srclang="en" label="english_captions" />
                                </video>
                            ) : previewItem.cover?.type?.split("/")[0] === "application" ? (
                                <PdfRender pdf={URL.createObjectURL(previewItem.cover)} pageNumber={1} />
                            ) : (
                                <LazyLoadImage src={URL.createObjectURL(previewItem.cover)} width="100%" alt="reunion-preview-post" />
                            )}
                            </section>
                        </article>
                        <article className="col-lg-8 col-md-8 col-12 preview-text --grey">
                            {previewItem.cover?.type?.split("/")[0] === "image" ?
                                <p className="mt-2">
                                    <span className="preview-text --blue">Recommended size: 200 x 200.</span> <br/> Uploaded image size: {imageWidth} x {imageHeight}. <br/>
                                    For optimal display, consider uploading images that match these dimensions.
                                </p>
                            : previewItem.cover?.type?.split("/")[0] === "video" ?
                                <p className="mt-2">
                                    <span className="preview-text --blue">Limit video file size: 50 MB.</span> <br/> Uploaded video file size: {imageWidth} MB. <br/>
                                </p>
                            :
                                <p className="mt-2">
                                    <span className="preview-text --blue">Limit file size: 5 MB.</span> <br/> Uploaded file size: {imageWidth} MB. <br/>
                                </p>
                            }
                        </article>
                    </section>
                <p className="preview-text --note">* Note: For optimal display on Facebook, please upload the images that are <span className="preview-text --small-blue">1200 x 630 pixels</span> but minimum <span className="preview-text --small-blue">200 x 200 pixels</span> and under <span className="preview-text --small-blue">1 MB</span>.</p>
            </article>
        : 
            <>
                <section
                    className="col-lg-7 col-md-7 col-12 p-0 align-items-center"
                    style={{ position: "relative", overflow: 'hidden', maxHeight: '526px', display: width > 991 ? 'flex' : '' }}
                >
                    {width < 991 &&
                        <header className="d-flex align-items-center p-3 gap-3">
                            <LazyLoadImage
                                style={{
                                    width: "30px",
                                    height: "30px",
                                    padding: 0,
                                    borderRadius: "50%",
                                }}
                                alt="reunion-avatar"
                                src={DefaultAvatar}
                            />
                            <section className="preview-member-detail"> 
                                <article className="d-flex gap-1">
                                    <p
                                        className="_label --member-detail-text"
                                    >
                                        Username
                                    </p>
                                    <p className="_label --member-detail-text">•</p>
                                    <p className="_label --member-detail-text --occupation">
                                        Occupation
                                    </p>
                                    <p className="_label --member-detail-text">•</p>
                                    <p className="_label --member-detail-text --model">
                                        Car Model
                                    </p>
                                </article>
                                <article className="d-flex gap-1">
                                    <p className="_label --member-detail-small-text mt-2">
                                        Registered Date
                                    </p>
                                </article>
                            </section> 
                        </header>
                    }
                    <section className="preview-slide">
                        <p>
                            {currentIndex}/
                            {previewItem.attachment && previewItem.attachment.length > 0
                            ? previewItem.attachment.length
                            : 0}
                        </p>
                    </section>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        grabCursor={true}
                        navigation={
                            false
                        }
                        onSlideChange={(s) => setCurrentIndex(s.activeIndex + 1)}
                        modules={[Navigation]}
                    >
                        {previewItem.attachment &&
                        previewItem.attachment.length > 0 &&
                        previewItem.attachment.map((item, index) => (
                        <SwiperSlide key={index} className="image-slide">
                            <section className="preview-image-content">
                                <article className="box">
                                    {item.type?.split("/")[0] === "video" ? (
                                        <VideoCard video={URL.createObjectURL(item)} />
                                    ) : item.type?.split("/")[0] === "application" ? (
                                        <PdfRender pdf={URL.createObjectURL(item)} pageNumber={currentIndex} />
                                    ) : (
                                        <LazyLoadImage key={index} src={URL.createObjectURL(item)} alt="reunion-preview-post"/>
                                    )}
                                </article>
                            </section>
                        </SwiperSlide>

                        ))
                        }
                    </Swiper>
                </section>
                <section className="col-lg-5 col-md-5 col--12 ps-3" style={{ position: 'relative' }}>
                    <article className="container preview-card-container p-0">
                        <section className="row preview-card-header align-items-center">
                            {width > 991 &&
                                <article className="d-flex align-items-center gap-3 card-header">
                                    <LazyLoadImage
                                        style={{
                                            width: "30px",
                                            height: "30px",
                                            padding: 0,
                                            borderRadius: "50%",
                                        }}
                                        alt="reunion-avatar"
                                        src={DefaultAvatar}
                                    />
                                    <header className="preview-member-detail"> 
                                        <section className="d-flex gap-1">
                                            <p
                                            className="_label --member-detail-text"
                                            >
                                                Username
                                            </p>
                                            <p className="_label --member-detail-text">•</p>
                                            <p className="_label --member-detail-text --occupation">
                                                Occupation
                                            </p>
                                            <p className="_label --member-detail-text">•</p>
                                            <p className="_label --member-detail-text --model">
                                                Car Model
                                            </p>
                                        </section>
                                        <section className="d-flex gap-1">
                                            <p className="_label --member-detail-small-text mt-2">
                                                Registered Date
                                            </p>
                                        </section>
                                    </header> 
                                </article>
                            }
                            <article className="p-0">
                                <section className="forum-preview-content">
                                    <article className="row">{renderContent(previewItem.content)}</article>
                                    <article className="row post-location m-0">
                                        <p className="_label --member-detail-small-text w-auto p-0 d-flex align-items-center">
                                            Last update at 31.12.1999 &nbsp;•&nbsp; Selangor 
                                        </p>
                                    </article> 
                                    <article className="d-flex justify-content-end" style={{ padding: '0.5em 1em' }}>
                                        <section style={{ position: "relative" }}>
                                            <Icon
                                                color={"#707070"}
                                                icon={"solar:chat-square-like-line-duotone"}
                                                width={30}
                                            />
                                        </section>
                                        <section style={{ paddingLeft: '0.5em' }}>
                                            <Icon
                                                icon="basil:share-box-outline"
                                                width={30}
                                                color="#707070"
                                            />
                                        </section>
                                    </article>
                                    <article className="row comment-detail m-0">
                                        <section className="d-flex align-items-center gap-2 p-0 col-5">
                                            <h5 style={{ fontSize: "1.4rem", lineHeight: 'unset', fontWeight:'unset', margin: 0 }}>Comment</h5>
                                            <article className="d-flex align-items-center pb-1">
                                                <Icon icon="iconamoon:comment" width={19} color="#565353" />
                                            </article>
                                        </section>
                                        <section className="col-7 d-flex justify-content-end p-0">
                                            <article className="segmented-control-container">
                                                <SegmentedControl
                                                    name="segmented-control"
                                                    options={[
                                                        {label: "Most relevant", default: true},
                                                        {label: "All Comment"},
                                                    ]}
                                                    style={{ width: "100%", color: "#909090" }}
                                                />
                                            </article>
                                        </section>
                                    </article>
                                    <article className="row comment-detail m-0">
                                        <section className="d-flex gap-3 justify-content-center">
                                            <p
                                            className="text-center"
                                            style={{
                                            fontSize: "1.2rem",
                                            color: "#868686",
                                            paddingTop: "1em",
                                            }}
                                            >
                                            No comment yet
                                            </p>
                                        </section>
                                    </article>
                                </section>
                            </article> 
                        </section>
                    </article>
                </section>
            </>
        }
        </section>
    </article>
  );
}
