//lib
import { Icon } from "@iconify/react";
import { MDBRow } from "mdb-react-ui-kit";
import { Skeleton } from "primereact/skeleton";
import { LazyLoadImage } from "react-lazy-load-image-component";

//hook
import useWindowDimensions from "../../../components/common/window-dimension";

//assets
import { DefaultAvatar } from "../../../assets/images";

export default function PostMemberDetail({
  avatar,
  member,
  member_type,
  update_date,
  job_title,
  car_model,
  settingButton,
  avatarClick,
  loading,
}) {
  const { width } = useWindowDimensions();

  const totalLength = () => {
    let memberLength = member?.length ? member?.length : 0;
    let jobTitleLength = job_title?.length ? job_title?.length : 0;
    let carModelLength = car_model?.length ? car_model?.length : 0;

    const total = memberLength + jobTitleLength + carModelLength;

    return total;
  };

  return (
    <section className="row forum-post-card-header">
      {!loading?
        <LazyLoadImage
          onClick={() => {
            if (avatarClick) {
              avatarClick();
            }
          }}
          style={{
            width: "30px",
            height: "30px",
            padding: 0,
            borderRadius: "50%",
          }}
          alt="reunion-avatar"
          src={avatar ? avatar : DefaultAvatar}
        />
      : 
        <Skeleton
          shape="circle"
          width="30px"
          height="30px"
        />
      }
      {!loading?
        <article className="member-detail"  
          onClick={() => {
            if (avatarClick) {
              avatarClick();
            }
          }}
        >
          <section className="d-flex gap-1" style={{ alignItems: 'baseline' }}>
            <h5
              className="_label --member-detail-text m-0"
              style={{ textTransform: "capitalize" }}
            >
              {totalLength() > 28 && member.length > 8
                ? member.slice(0, 8) + "..."
                : member}
            </h5>
            {job_title && (
              <p className="_label --member-detail-text">•</p>
            )}
            {job_title && (
              <h5 className="_label --member-detail-text --occupation m-0">
                {totalLength() > 28 && job_title.length > 8
                  ? job_title.slice(0, 8) + "..."
                  : job_title}
              </h5>
            )}
            {car_model && (
              <p className="_label --member-detail-text">•</p>
            )}
            {car_model && (
              <h5 className="_label --member-detail-text --model m-0">
                {totalLength() > 28 && car_model.length > 5
                  ? car_model.slice(0, 5) + "..."
                  : car_model}
              </h5>
            )}
          </section>
          <section className="d-flex gap-1">
            <p className="_label --member-detail-small-text">
              {update_date}
            </p>
            <p className="_label --member-detail-small-text">
              {member_type}
            </p>
          </section>
        </article>
      : 
        <article className="member-detail"> 
          <Skeleton
            shape="rectangle"
            width="220px"
            height="20px"
            borderRadius="2em"
          />
        </article> 
      }
      {width >= 991 && settingButton && !loading && ( // no showing out on ui
        <div style={{ width: "auto", marginLeft: "auto" }}>
          <Icon
            icon="solar:menu-dots-bold"
            onClick={() => settingButton()}
            className="setting-icon"
            color="#6B6B6B"
            width={24}
          />
        </div>
      )}
    </section>
  );
}
