import { useEffect, useRef, useState } from "react";

//lib
import { Icon } from "@iconify/react";
import { useFormikContext} from "formik";
import { Link, useNavigate } from "react-router-dom";
import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";

//redux
import { useDispatch } from "react-redux";
import { setShowFullModal } from "../../../redux/reducer/commonReducer";
import { setShowLoginModal } from "../../../redux/reducer/settingReducer";

//component
import Text from "../../../components/element/text";
import Code from "../../../components/element/code";

//service
import authServices from "../../../services/auth.services";

export default function LoginContent({ loading }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(null);
  const [timerId, setTimerId] = useState(null);
  const [phone, setPhone] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const [firstTimeOtp, setFirstTimeOtp] = useState(true);
  const { errors, values , setFieldError , setFieldValue , isValid , validateForm} = useFormikContext();

  const [otpLoading, setOtpLoading] = useState(false);

  const usernameInputRef = useRef(null);
  
  useEffect(() => {
    setTimeout(() => {
      usernameInputRef.current && usernameInputRef.current.focus();
    }, 0);
  }, []);
  
  useEffect(() => {
    if (countdown === 0) {
      clearInterval(timerId);
      setCountdown(null);
    }
  }, [countdown, timerId]);

  useEffect(() => {
    if(countdown > 0 && errorStatus === "false"){
    } 
    else if (countdown === 0) {
      setErrorStatus("")
      validateForm()
    }
  },[countdown])

  const handleClick = async ({ values, setFieldError ,setFieldValue }) => {
    setPhone(values.username)
    setOtpLoading(true)
    try {
      const response = await authServices.sendVerificationCode({
        username: values.username,
      });
      if (response) {
        setOtpLoading(false)
        setFieldValue(values ,values)
        if (countdown === null) {
          setCountdown(120);
          const timer = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
          }, 1000);
          setTimerId(timer);
        }
      }
    } catch (ex) {
      setOtpLoading(false)
      setErrorStatus("false")
      if (ex && ex.response.status === 422) {
        const errors = ex.response.data.errors;
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) => {
            const errorMesage = errors[item]
            const regex = /\d+/; 
             const matches = errorMesage[0].match(regex);
             if (matches) {
              const number = parseInt(matches[0], 10);
              if (countdown === null) {
                setCountdown(number);
                const timer = setInterval(() => {
                  setCountdown((prevCountdown) => prevCountdown - 1);
                }, 1000);
                  setTimerId(timer);
                  setFieldError(item, errors[item])
              }
            } else {
            }
            
          }
          );
        }
      }
    }
  };

  const formatTimer = (time) => {
    if (time === 120) {
      return `( ${time}s )`;
    } else {
      return `( ${time}s )`;
    }
  };

  const handleCloseModalWithDelay = () => {
    dispatch(setShowFullModal(false))
    setTimeout(() => {
      dispatch(setShowLoginModal(false));
    }, 500)
  }

  return (
    <article className="container p-4">
      <header className="row d-flex">
        <nav
          className="d-flex justify-content-start"
          onClick={() => {
            handleCloseModalWithDelay()
          }}
        >
          <Icon icon="ep:back" width="25" color="#cccccc" />
        </nav>
        <section
          className="d-flex align-items-start mb-5"
          style={{ justifyContent: "center" }}
        >
          <h1 className="_label --welcome-subtitle">
            Welcome Folks
          </h1>
        </section>
      </header>
      <section style={{position : "relative"}}>
        <Text
          name="username"
          label="Phone Number"
          placeholder="eg. 0123456789"
          className="phone-input"
          autoFocus={usernameInputRef}
          inputMode="numeric"
        />
        <section className="row d-flex justify-content-center m-0" style={{position :"absolute" , bottom : errors && errors.username ? "0.9em" : "-7px" , right : "11px"}} >
          <MDBBtn 
            disabled={
              countdown !== null || errors && errors.username
                ? true
                : false
            }
            className={`${otpLoading ? "": "button-group --sign-in-no-space"} pointer mb-5 d-flex align-items-center`}
            type="button"
            style={{fontSize : "0.7em" , width: "auto" , height : "auto", borderRadius: '15px', background: otpLoading ? 'none': '', boxShadow: otpLoading ? 'none': ''}}
            onClick={() => {
              if (firstTimeOtp) {
                setFirstTimeOtp(false);
              }
              handleClick({values , setFieldError , setFieldValue})
            }}
          >
            {otpLoading ? 
              <Icon icon="line-md:loading-twotone-loop" width="25px" height="25px" />
            : countdown === null && firstTimeOtp
              ? "REQUEST"
              : countdown === null && !firstTimeOtp
              ? "RESEND"
              : `RESEND ${formatTimer(countdown)}`
            }
          </MDBBtn>
        </section>
      </section>
      <Code
        className="form-input"
        name="verification_code"
        label="Enter OTP"
        disabled={false}
      />
      <section className="row mt-5 tc-row pointer" 
        onClick={() => {
            setFieldValue("terms_condition", !values.terms_condition)
        }}
      >
        <article className="col-lg-1 col-md-1 col-1">
          <input
            type="checkbox"
            name="terms_condition"
            checked={values.terms_condition === true ? true : false}
            onChange={(e) =>
              e.target.checked
                ? setFieldValue("terms_condition", true)
                : setFieldValue("terms_condition", false)
            }
          />
        </article>
        <article className="col-lg-11 col-md-11 col-11">
          <p className="_label --tc">
            I have read and agree to reunion{" "}
            <Link className="_label --tc-span pointer" 
              onClick={(e) => { 
                e.preventDefault();

                navigate('/terms-of-use', {state : { value : "Terms and Conditions"}}) 
            }}>Terms and Conditions</Link>{" "}
            and&nbsp;
            <Link className="_label --tc-span pointer" 
            onClick={(e) => { 
              e.preventDefault(); 
              
              navigate('/terms-of-use', {state : { value : "Privacy Policy"}}) 
            }}>Privacy Policy</Link>
          </p>
        </article>
      </section>
      <section className="row d-flex justify-content-center " style={{margin : "2.2em 0em 0em 0em"}}>
        <MDBBtn 
          disabled={errorStatus === "false" ? true : 
            !loading && isValid && values.verification_code?.length === 6
              ? false
              : true
          }
          className="button-group --sign-in-no-space pointer mb-5"
          type="submit"
        >
          <p className="d-flex justify-content-center align-items-center">LOG IN / SIGN UP 
          {loading && <Icon icon="line-md:loading-twotone-loop" width="22px" height="22px" className="ms-3"/>}
          </p>
        </MDBBtn>
      </section>
    </article>
  );
}

