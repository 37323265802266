//lib
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";

//hook
import useWindowDimensions from "../common/window-dimension";
import { setAnimationModal, setShowFullModal } from "../../redux/reducer/commonReducer";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

export default function InfoHeader({
  title,
  infoButton,
  backButton,
  settingButton,
  backButtonNoAnimation,
}) {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions();

  const handleCloseModalWithDelay = () => {
    dispatch(setShowFullModal(false))
    setTimeout(() => {
      backButton();
    }, 500);
  };

  return (
    <header className="container">
      <nav
        className="row info-header-container"
        style={{
          position: width >= 991 ? "sticky" : "fixed",
          marginTop: width >= 991 ? "0em" : "-1em",
        }}
      >
        <section className="row info-row">
          <Link className="info-col col-2" id="back-button"
            onClick={(e) => { 
              e.preventDefault();

              backButtonNoAnimation ? backButton() : handleCloseModalWithDelay()
            }}
          >
            {(backButton || backButtonNoAnimation) && (
              <Icon
                icon="tabler:arrow-back-up"
                className="back-icon mb-1"
                color="#222a39"
                width={24}
                style={{ cursor: "pointer" }}
              />
            )}
          </Link>
          <article className="col info-col">
            <h1 style={{ fontSize: width >= 991 ? "18px" : "" }}>
              {title}
            </h1>
          </article>
          <Link
            className="info-col col-2"
            onClick={(e) => {
              e.preventDefault();

              if (settingButton) {
                settingButton();
              } else if (infoButton) {
                infoButton();
              }
            }}
          >
            {settingButton && (
              <Icon
                icon="solar:menu-dots-bold"
                color="#222a39"
                className="setting-icon"
                width={24}
              />
            )}
            {infoButton && (
              <Icon
                icon="fluent:info-32-regular"
                className="setting-icon"
                width={24}
                color="#C5C5C5"
              />
            )}
          </Link>
        </section>
      </nav>
    </header>
  );
}
