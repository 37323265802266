import { useEffect, useState } from "react";

//lib
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { RemoveScroll } from "react-remove-scroll";
import { AnimatePresence, motion } from "framer-motion";
import { MDBBtn, MDBContainer, MDBRow } from "mdb-react-ui-kit";

//component
import ChooseTopic from "./choose.topic";
import CreatePostContent from "./create.post.content";
import InfoHeader from "../../../components/header/info-header";

//hook
import useGeoInfo from "../../../components/hook/useGeoInfo";
import useWindowDimensions from "../../../components/common/window-dimension";

//redux
import {
  createForumPost,
  getForumInfo,
  getForumListing,
  setCreatePostModal,
  setEditForumPost,
  setIsDiscardPost,
  setPostList,
  setPreviewItem,
  updateForumInfo,
} from "../../../redux/reducer/forumReducer";
import { useDispatch, useSelector } from "react-redux";
import { getMissionList } from "../../../redux/reducer/profileReducer";
import PreviewPost from "./preview.post";
import { closeModalPostPreview, openModalPostPreview } from "../../../redux/reducer/modalReducer";
import { upload } from "@testing-library/user-event/dist/upload";
import scrollToTop from "../../../helper/scrolltotop.helper";
import { setShowConfirmModal } from "../../../redux/reducer/stockReducer";

export default function CreatePost({ getForumPost, setType, setData, setHasMore, isForum, setFilter, setStart, sharingPostRef, start }) {
  const location = useGeoInfo();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState();
  const [uploadLargeFile, setUploadLargeFile] = useState(false);
  const { createPostModal, createPostData, editForumPostStatus, postList, isDiscardPost } = useSelector(
    (state) => state.forum
  );
  const { isOpenPostPreview } = useSelector(
    (state) => state.modal
  );
  var fileInput = document.getElementById("attachment");

  const [state, setState] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const useSchema = Yup.object({
    title: Yup.string(),
    content: Yup.string(),
    attachment: Yup.array().max(5),
  });

  const validateImageDimensions = (previewItem) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(previewItem);
  
      img.onload = function () {
        resolve({ width: img.width, height: img.height });
      };
    });
  };
  

  const handleSubmit = async (values, setFieldError) => {
    let hasLargeFile = false;
  
    const formData = new FormData();
    const asyncOperations = [];
  
    for (let key in values) {
      if (key === "attachment") {
        values.attachment.forEach((item, index) => {

          if (item.type?.split("/")[0] === "video") {
            if (item.size > 50 * 1024 * 1024) {
              hasLargeFile = true;
              setLoading(false);
              toast.error("Video file size limit is 50MB", {
                autoClose: 3000,
                theme: "light",
                hideProgressBar: true,
                closeOnClick: true,
                closeButton: false,
              });
            } else {
              formData.append(`attachment[${index}]`, item);
            }
          } else if (item.type?.split("/")[0] === "application") {
            if (item.size > 5 * 1024 * 1024) {
              hasLargeFile = true;
              setLoading(false);
              toast.error("File size limit is 5MB", {
                autoClose: 3000,
                theme: "light",
                hideProgressBar: true,
                closeOnClick: true,
                closeButton: false,
              });
            } else {
              formData.append(`attachment[${index}]`, item);
            }
          } else if (item.type?.split("/")[0] === "image") {
            asyncOperations.push(
              validateImageDimensions(item).then((dimensions) => {
                if (dimensions.width > 2500 || dimensions.height > 2500) {
                  hasLargeFile = true;
                  setLoading(false);
                  toast.error("Image dimensions should not exceed 2500px.", {
                    autoClose: 3000,
                    theme: "light",
                    hideProgressBar: true,
                    closeOnClick: true,
                    closeButton: false,
                  });
                } else {
                  formData.append(`attachment[${index}]`, item);
                }
              })
            );
          } else {
            formData.append(`attachment[${index}]`, item);
          }
        });
      } else {
        formData.append(`${key}`, values[key]);
      }
    }
  
    await Promise.all(asyncOperations);
  
    formData.append(`title`, values["content"]);
    if (location && !values["location"]) {
      formData.append(`location`, `${location}`);
    }

    if (!hasLargeFile) {
      dispatch(createForumPost(formData))
        .unwrap()
        .then((res) => {
          setLoading(false);
          dispatch(setCreatePostModal(false));
          if(setFilter && width < 991 && isForum) {
            setFilter(values.topic === 'recommendation' ? 'recommend' : values.topic)
          } else {
            setFilter("")
          }
          if (window.location.pathname === "/profile") {
            dispatch(getMissionList());
          }
          if (getForumPost) {
            dispatch(getForumListing({
              start: 0,
              length: 12,
              search: "",
              pinned: "",
              topic: width < 991 && isForum ? values.topic : '',
            }))
            .unwrap()
            .then((res) => {
              setStart(0);
              dispatch(setPostList([]));
              
              let newData = [];
              for (var key in res.data.aaData) {
                newData.push({
                  ...res.data.aaData[key],
                  display_id: `post-${res.data.aaData[key].id}`,
                });
              }
              dispatch(setPostList(newData));
              if (newData.length !== res.data.iTotalRecords) {
                setHasMore(true);
              }
              if(sharingPostRef) {
                sharingPostRef.current.scrollIntoView({ behavior: 'smooth' });
              } else {
                scrollToTop()
              }
            });
          }
          toast.success("Create Post Successful.", {
            autoClose: 3000,
            theme: "light",
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
          });
        })
        .catch((ex) => {
          setLoading(false);
          if (ex && ex.response.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                setFieldError(item, errors[item]);
              });
            }
          } else {
          }
        });
    }
  };

  const handleEdit = (values, setFieldError) => {
    const formData = new FormData();
    for (let key in values) {
      if (key === "attachment") {
        values.attachment.map((item, index) => {
          if (item.url) {
          } else {
            formData.append(`attachment[${index}]`, item);
          }
        });
      } else {
        formData.append(`${key}`, values[key]);
      }
    }
    formData.append(`title`, values["content"]);

    dispatch(updateForumInfo(formData))
      .unwrap()
      .then((res) => {
        setLoading(false);
        dispatch(getForumInfo({ id: values.post_id }));
        dispatch(setCreatePostModal(false));
        dispatch(setEditForumPost(false));
        if (getForumPost) {
          dispatch(getForumListing({
            start: 0,
            length: start + 12,
            search: "",
            pinned: "",
            topic: width < 991 && isForum ? values.topic : '',
          }))
          .unwrap()
          .then((res) => {
            setStart(start + 12);
            dispatch(setPostList([]));
            
            let newData = [];
            for (var key in res.data.aaData) {
              newData.push({
                ...res.data.aaData[key],
                display_id: `post-${res.data.aaData[key].id}`,
              });
            }
            dispatch(setPostList(newData));
            if (newData.length !== res.data.iTotalRecords) {
              setHasMore(true);
            }
          });
        }
        toast.success("Update Post Successful.", {
          autoClose: 3000,
          theme: "light",
          hideProgressBar: true,
          closeOnClick: true,
          closeButton: false,
        });
      })
      .catch((ex) => {
        setLoading(false);

        if (ex && ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
            });
          }
        }
      });
  };

  const handleAttachment = () => {
    setLoading(false);
    document.getElementById("attachment").click();
  };

  useEffect(() => {
    return () => {
      if (editForumPostStatus) {
        dispatch(setEditForumPost(false));
      }
    };
  }, []);

  useEffect(() => {
    const simulateButton = document.getElementById("back-button")

    setTimeout(() => {
      if(isDiscardPost) {
        setType('')
        simulateButton.click();
      }
    }, 300);
  }, [isDiscardPost]);

  return (
    <article>
      <AnimatePresence>
        {createPostModal && (
          <RemoveScroll>
            <motion.div
              className="forum-listing-card --active"
              layoutId={createPostModal}
              initial={{ backgroundColor: width > 991 ? "#ffffff00" : "#fafafa", scale: 0.2 }}
              animate={{
                scale: 1,
                backgroundColor: width > 991 ? "#6a6868e0" : "#fafafa",
              }}
              exit={{
                opacity: [1, 0],
                scale: 0.5,
                backgroundColor: width > 991 ? "#ffffff00" : "#fafafa",
                transition: {
                  duration: 0.2
                },
              }}
              transition={{
                scale: { duration: 0.3 },
                backgroundColor: { delay: 0.1, duration: 0.5 },
              }}
            >
              {loading && 
                <section className="create-post-overlay">
                  <section className="loading-icon">
                    <Icon
                        icon="line-md:loading-twotone-loop"
                        width="50px"
                        height="50px"
                        color="#3db8eb"
                    />
                  </section>
                </section>
              }
              <Formik
                enableReinitialize={true}
                validateOnChange={true}
                validateOnMount={true}
                initialValues={createPostData}
                validationSchema={useSchema}
                onSubmit={(values, { setSubmitting, setFieldError }) => {
                  setLoading(true);
                  if (values.attachment.length === 0) {
                    handleAttachment();
                  } else {
                    if (!editForumPostStatus) {
                      handleSubmit(values, setFieldError);
                    } else {
                      handleEdit(values, setFieldError);
                    }
                  }
                }}
              >
                {({
                  values,
                  touched,
                  errors,
                  resetForm,
                  setFieldValue,
                  isSubmiting,
                }) => (
                  <Form>
                    {width >= 991 && (
                      <motion.div
                        style={{
                          position: "absolute",
                        }}
                        initial={{ scale: 0.5 }}
                        animate={{ scale: 1 }}
                        transition={{
                          scale: { delay: 0.5, duration: 0.5 }, // Transition for scale
                        }}
                        onClick={() => {
                          setUploadLargeFile(false)
                          if (!editForumPostStatus) {
                            if (values.attachment.length === 0) {
                              dispatch(setCreatePostModal(false));
                            } else {
                              if (isOpenPostPreview) {
                                dispatch(closeModalPostPreview())
                              } else {
                                setType('post')
                                dispatch(setShowConfirmModal(true))
                                // fileInput.value = null;
                                // setFieldValue("attachment", []);
                              }
                            }
                          } else {
                            if(isDiscardPost) {
                              dispatch(setCreatePostModal(false));
                            } else {
                              setType('post')
                              dispatch(setShowConfirmModal(true))
                            }
                          }
                        }}
                      ></motion.div>
                    )}
                    {width < 991 && !loading && (
                      <InfoHeader
                        backButton={() => {
                        setUploadLargeFile(false)

                          if (!editForumPostStatus) {
                            if (values.attachment.length === 0) {
                              dispatch(setCreatePostModal(false));
                            } else {
                              if (isOpenPostPreview) {
                                dispatch(closeModalPostPreview())
                              } else {
                                if(isDiscardPost) {
                                  setFieldValue("content", '');
                                  setFieldValue("attachment", []);
                                  dispatch(setIsDiscardPost(false))
                                } else {
                                  setType('post')
                                  dispatch(setShowConfirmModal(true))
                                }
                              }
                            }
                          } else {
                            if(isDiscardPost) {
                              dispatch(setCreatePostModal(false));
                            } else {
                              setType('post')
                              dispatch(setShowConfirmModal(true))
                            }
                          }
                        }}
                      />
                    )}
                    <section 
                      className="container forum-post-info-content --create-post relative"
                      style={{ padding: isOpenPostPreview ? '0em' : '' }}
                    >
                      {width >= 991 && !loading && (
                        <nav
                          id="back-button"
                          className="button-back-round pointer"
                          onClick={() => {
                            if (!editForumPostStatus) {
                              if (values.attachment.length === 0) {
                                dispatch(setCreatePostModal(false));
                              } else {
                                if (isOpenPostPreview) {
                                  dispatch(closeModalPostPreview())
                                } else {
                                  if(isDiscardPost) {
                                    setFieldValue("content", '');
                                    setFieldValue("attachment", []);
                                    dispatch(setIsDiscardPost(false))
                                  } else {
                                    setType('post')
                                    dispatch(setShowConfirmModal(true))
                                  }
                                }
                              }
                            } else {
                              if(isDiscardPost) {
                                dispatch(setCreatePostModal(false));
                              } else {
                                setType('post')
                                dispatch(setShowConfirmModal(true))
                              }
                            }
                          }}
                        >
                          <Icon
                            icon="tabler:arrow-back-up"
                            className="back-icon"
                            width={24}
                          />
                        </nav>
                      )}
                      {values.attachment.length > 0 ? (
                        isOpenPostPreview ? 
                          <PreviewPost />
                        :
                          <CreatePostContent handleAttachment={handleAttachment} />
                      ) : (
                        <ChooseTopic />
                      )}
                      <input
                        key={values.attachment.length}
                        type="file"
                        name="attachment"
                        accept="image/*, video/mp4, application/pdf"
                        id="attachment"
                        onChange={(event) => {
                          const files = Array.from(event.target.files).slice(0, 5);
                        
                          files.forEach((file) => {
                            if (file.type?.split("/")[0] === "video") {
                              if (file.size > 50 * 1024 * 1024) {
                                toast.error("Video file size limit is 50MB", {
                                  autoClose: 3000,
                                  theme: "light",
                                  hideProgressBar: true,
                                  closeOnClick: true,
                                  closeButton: false,
                                });
                              } else {
                                setFieldValue("attachment", [...files]);
                              }
                            } else if (file.type?.split("/")[0] === "application") {
                              if (file.size > 5 * 1024 * 1024) {
                                toast.error("File size limit is 5MB", {
                                  autoClose: 3000,
                                  theme: "light",
                                  hideProgressBar: true,
                                  closeOnClick: true,
                                  closeButton: false,
                                });
                              } else {
                                setFieldValue("attachment", [...files]);
                              }
                            } else if (file.type?.split("/")[0] === "image") {
                              const img = new Image();
                              img.src = URL.createObjectURL(file);
                        
                              img.onload = function () {
                                if (img.width > 2500 || img.height > 2500) {
                                  toast.error("Image dimensions should not exceed 2500px.", {
                                    autoClose: 3000,
                                    theme: "light",
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    closeButton: false,
                                  });
                                } else {
                                  setFieldValue("attachment", [...files]);
                                }
                              }
                            } else {
                              setFieldValue("attachment", [...files]);
                            }
                          });
                        }}
                        multiple
                        hidden
                      />
                      {!isOpenPostPreview ?
                        <aside className="create-post-submit-button"
                          style={{ 
                            bottom: values.attachment.length === 0 ? '5em' : '4em'
                          }}
                        >
                          {!editForumPostStatus ? 
                            values.attachment.length > 0 ?
                                <section 
                                  className="d-flex align-items-center justify-content-center gap-2"
                                  onClick={() => {
                                    dispatch(openModalPostPreview())
                                    dispatch(setCreatePostModal(true))
                                    dispatch(setPreviewItem({
                                      cover: values.attachment[0],
                                      attachment: values.attachment,
                                      content: values.content
                                    }))
                                  }}
                                >
                                  <Icon icon="ph:eye-duotone" width='18' height='18' color='#59b2ff' />
                                  <p className="pointer">Preview</p>
                                </section>
                            : '' : ''
                          }
                          <MDBBtn
                            className="button-group --notification-button --create-post-round"
                            type="submit"
                            disabled={values.attachment.length > 0 && loading}
                          >
                            {!editForumPostStatus
                              ? values.attachment.length > 0
                                ? "Post"
                                : "Next"
                              : "Update"}
                          </MDBBtn>
                        </aside>
                      : ''}
                    </section>
                  </Form>
                )}
              </Formik>
            </motion.div>
          </RemoveScroll>
        )}
      </AnimatePresence>
    </article>
  );
}
