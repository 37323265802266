import { useEffect } from "react";

//lib
import { init } from "ityped";
import { motion } from "framer-motion";
import { EffectCreative } from "swiper";
import { Link, useNavigate } from "react-router-dom";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { Swiper, SwiperSlide } from "swiper/react";

//hook
import useWindowDimensions from "../../../components/common/window-dimension";

//redux
import { useDispatch, useSelector } from "react-redux";

//component
import { ButtonNotification } from "../../../components/element/button.notification";
import { Skeleton } from "primereact/skeleton";

export default function PostPinned() {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { home_loading } = useSelector((state) => state.home);
  const { pinnedPost } = useSelector((state) => state.home.home);
  const { profile } = useSelector((state) => state.profile);
  
  useEffect(() => {
    init(document.querySelector(".detail"), {
      strings: ["Car Lover", "Car Owner", "Car Expert", "REUNION"],
      loop: true,
      typeSpeed: 500,
      backSpeed: 300,
    });
  }, []);

  const swiperOptions = {
    allowSlideNext: !home_loading,
    allowSlidePrev: !home_loading,
    allowTouchMove: !home_loading,
  };

  return (
    <article className="forum-pin-post-container">
      <header className="row pin-details">
        <article className={width < 991 ? "col" : "col p-0"}>
          {width < 991 && (
            <h1 className="_label --banner-title">
              Welcome, {profile.user_nickname ? profile.user_nickname : ""}
            </h1>
          )}
          {width < 991 && (
            <h1 className="_label --banner-title">
              Share about your car to
            </h1>
          )}
          <span className="_label --banner-title">
            {width >= 991 &&
              ` Welcome ${
                profile.user_nickname ? profile.user_nickname : ""
              }, Share about your car to `}
            <span className="detail"></span>
          </span>
        </article>
        <article className="col d-flex justify-content-end col-3">
          <ButtonNotification />
        </article>
      </header>
      <section>
        <Swiper
          slidesPerView={width >= 991 ? 3.5 : 1.3}
          spaceBetween={15}
          grabCursor={!home_loading ? true : false}
          creativeEffect={{
            prev: {
              shadow: false,
              translate: ["-120%", 0, -500],
            },
            next: {
              shadow: false,
              translate: ["120%", 0, -500],
            },
          }}
          modules={[EffectCreative]}
          {...swiperOptions}
        >
          {!home_loading ?
            pinnedPost.length > 0 &&
            pinnedPost.map((item, index) => (
              <SwiperSlide key={index}>
                <Link
                  key={index}
                  className="post-pinned-card"
                  layoutId={item.display_id}
                  onClick={(e) => {
                    e.preventDefault();

                    navigate("/?post=" + item.id)
                  }}
                >
                  <h3 className="_label --title break-all">
                    {item.title.length > 65
                      ? item.title.slice(0, 62) + "..."
                      : item.title}
                  </h3>
                  <section className="text-end">
                    <p className="_label --like-detail">
                      {item.like_count} people like about this post
                    </p>
                  </section>
                </Link>
              </SwiperSlide>
            ))
          :
            Array.from({ length: 2 }, (_, pinnedPostIndex) => (
              <SwiperSlide >
                <motion.div className="post-pinned-card --loading">
                  <Skeleton width="100%" height="100%" borderRadius="0.8em" className="pinned-post-skeleton"/>
                </motion.div>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </section>
    </article>
  );
}
