import { useEffect, useState } from "react";

//lib
import _ from "lodash";
import { Icon } from "@iconify/react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";

//redux
import { useDispatch, useSelector } from "react-redux";
import { setShowConfirmModal } from "../../../redux/reducer/stockReducer";
import { setDeliveryOrPickup } from "../../../redux/reducer/checkoutReducer";
import { getCartData, setActiveTab, updateCart } from "../../../redux/reducer/cartReducer";

//component
import CartTable from "./cart.table";
import ConfirmationModal from "../../../components/modal/comfirmation.modal";

//hook
import useWindowDimensions from "../../../components/common/window-dimension";

export default function Cart() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { state } = useLocation();
  
  const { carts, paymentTab, cart_loading, activeTab } = useSelector((state) => state.cart);
  const { showConfirmModal } = useSelector((state) => state.stock);
  const [ selectedCartId, setSelectedCartId ] = useState(null);
  const [ isAtBottom, setIsAtBottom ] = useState(false);
  
  const handleQuantityChange = (cartId, newQuantity) => {
    dispatch(updateCart({
      cart_item_id: cartId,
      quantity: newQuantity
    }))
      .unwrap()
      .then((res) => {
        dispatch(getCartData({
          cart_payment_type: activeTab
        }));
      })
      .catch((ex) => {
      });
  };

  const handleDeleteProduct = (cartId) => {
    setSelectedCartId(cartId)
    dispatch(setShowConfirmModal(true));
  };

  const handleScroll = () => {
    const scrollY = window.scrollY || window.pageYOffset;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    const isBottom = scrollY + windowHeight >= documentHeight - 300;
    setIsAtBottom(isBottom)
  };

  const handleCheckout = () => {
    const dataToPass = {
      activeTab: activeTab
    };
    navigate('/checkout', { state: dataToPass }); 
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if(!cart_loading) {
      dispatch(getCartData({
        cart_payment_type: activeTab
      }))
    }
  }, [activeTab]);

  return (
    <section>
      <article className="container cart-container">
        <h1 className="_label --cart-title d-flex align-items-center">
            <Link>
              <Icon icon="ep:back" color="#000" width="30" height="30" className="me-5" 
              onClick={(e) => { 
                e.preventDefault();

                navigate(`/${state ? state : 'store'}`) 
              }}
            />
            </Link>
            Shopping Cart
            <Icon icon="solar:cart-bold-duotone" color="#3db8eb" width={38} height={38} className="ms-3"/>
        </h1>
        <nav className="d-flex payment-type-tab">
        {paymentTab.map((tab, tabIndex) => (
          <h3
            className={`tab ${tab.value === activeTab ? '--active' : ''}`} 
            onClick={() => {
              dispatch(setActiveTab(tab.value))
            }}
            style={{cursor : "pointer"}}
            key={tab.value} 
          >
            {tab.label}
          </h3>
        ))}
        </nav>
        <section>
          <CartTable cartData={carts.cart_merchant_info} paymentType={activeTab} editQuantity={handleQuantityChange} deleteQuantity={handleDeleteProduct} />
          <footer className={`cart-footer ${isAtBottom ? '--no-shadow' : ''}`}>
            <p className="d-flex align-items-baseline" style={{ fontSize: width < 991 ? '14px' : '' }}>Total ({carts?.totalCartItems ? carts.totalCartItems : 0} items): 
            <span className="_label --total-span ms-3"> {activeTab === 'currency' && 'RM' } {parseFloat(carts?.totalCartItems ? carts.totalCartSubtotal : 0).toFixed(2)} {activeTab === 'point' && 'pts' }</span></p>
            <MDBBtn 
              className="ms-lg-5 ms-md-5 ms-4" 
              disabled={carts.totalCartItems === 0} 
              onClick={()=>{
                handleCheckout()
                dispatch(setDeliveryOrPickup(null))
              }}>
                Checkout
            </MDBBtn>
          </footer>
        </section>

      </article>
      {showConfirmModal && (
        <ConfirmationModal
          show={showConfirmModal}
          setShow={setShowConfirmModal}
          value={selectedCartId}
          type="delete_cart"
        />
      )}
    </section>
  );
}
