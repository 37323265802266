//lib
import Lottie from "lottie-react";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { SegmentedControl } from "segmented-control";
import { MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";
import InfiniteScroll from "react-infinite-scroll-component";
import { LazyLoadImage } from "react-lazy-load-image-component";

//redux
import { setCreatePostModal } from "../../../redux/reducer/forumReducer";

//component
import PdfRender from "../../Forum/component/pdf.render";
import SearchBar from "../../Forum/component/search.bar";
import ButtonFilter from "../../../components/element/button.filter";

//assets
import { DefaultAvatar } from "../../../assets/images";
import MediaIcon from "../../../assets/json/media-post.json";
import CarLoading from "./../../../assets/json/car-loading.json";

//hook
import useWindowDimensions from "../../../components/common/window-dimension";

//redux
import { useDispatch, useSelector } from "react-redux";
import { setShowLoginModal } from "../../../redux/reducer/settingReducer";
import { closeModalPostPreview } from "../../../redux/reducer/modalReducer";
import { Segmented } from "antd";
import VideoThumbnail from "react-video-thumbnail";
import { useState } from "react";
import { useEffect } from "react";

export default function SharingPost({
  start,
  fitler,
  search,
  hasMore,
  setFilter,
  setSearch,
  setStart,
  getMoreData,
  // items,
  selectedOption,
  setSelectedOption,
  loading,
  secondLoad
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const { width } = useWindowDimensions();
  const { post } = useSelector((state) => state.home.home);
  const { home_loading } = useSelector((state) => state.home);
  const { postList } = useSelector((state) => state.forum);

  const [ viewMore, setViewMore ]  = useState(false);
  const [ showViewMore, setShowViewMore ]  = useState(true);

  const formatCash = (num, digits = 2) => {
    var units = ["k", "M", "B", "T", "P", "E", "Z", "Y"],
      decimal;

    for (var i = units.length - 1; i >= 0; i--) {
      decimal = Math.pow(1000, i + 1);

      if (num <= -decimal || num >= decimal) {
        const number = +(num / decimal).toFixed(digits);
        if (units[i] === "Y") {
          return number.toLocaleString() + units[i];
        }
        return number.toLocaleString() + units[i];
      }
    }

    return num ? num : 0;
  };

  // const ResponsiveImage = ({ imageSources }) => {
  //   return (
  //     <LazyLoadImage
  //       srcSet={`
  //           ${imageSources.s} 600w,
  //           ${imageSources.m} 1800w,
  //           ${imageSources.l} 2200w,
  //           ${imageSources.xl} 2600w
  //       `}
  //       sizes={`
  //           (max-width: 599px) 600px,
  //           (max-width: 1799px) 1800px,
  //           (max-width: 2199px) 2200px,
  //           2600px
  //       `}
  //       src={imageSources.fallback}
  //       alt="reunion-post"
  //     />
  //   )
  // };

  const renderMobilePostListing = (item, index) => {
    const imageSources = {
      s: item?.image_s,
      m: item?.image_m,
      l: item?.image_l,
      xl: item?.image_xl,
      fallback: item?.mainMediaUrl[0]?.url
    };

    return (
      <motion.div
        key={index}
        className="post-container"
        layoutId={item.display_id}
        onClick={
          () => navigate("/?post=" + item.id)
        }
      >
        <header className="row forum-post-card-header m-0">
          <LazyLoadImage
            style={{
              width: "30px",
              height: "30px",
              padding: 0,
              borderRadius: "50%",
            }}
            alt="reunion-avatar"
            src={item.created_by_avatar ? item.created_by_avatar : DefaultAvatar}
          />
          <section className="member-detail">
            <article className="d-flex gap-1">
              <p className="_label --member-detail-text">
                {item.user_name}
              </p>
              {item.job_title && (
                <p className="_label --member-detail-text">•</p>
              )}
              {item.job_title && (
                <p className="_label --member-detail-text --occupation">
                  {item.job_title}
                </p>
              )}
              {item.car_model && (
                <p className="_label --member-detail-text">•</p>
              )}
              {item.car_model && (
                <p className="_label --member-detail-text --model">
                  {item.car_model}
                </p>
              )}
            </article>
            <article className="d-flex gap-1">
              <p className="_label --member-detail-small-text">
                {item.created_at}
              </p>
              <p className="_label --member-detail-small-text">
                register member
              </p>
            </article>
          </section>
        </header>
        <section className="row forum-post-content m-0">
          <article className="col-8 p-0 pe-4">
            <p className="forum-title">{item.content}</p>
            <section className="forum-actions d-flex">
              <p className="_label --like">
                {formatCash(item.like_count)} Like
              </p>
              <p className="_label --comment">
                {formatCash(item.comment_count)} Comment
              </p>
              <p className="_label --point">
                {formatCash(item.point)} Point
              </p>
            </section>
          </article>
          <article className="col-4 p-0 d-flex justify-content-end">
            <figure className="img-columns">
              {imageSources.s !== null && imageSources.s !== undefined ? 
                <LazyLoadImage
                  srcSet={`
                      ${imageSources.s} 600w,
                      ${imageSources.m} 1800w,
                      ${imageSources.l} 2200w,
                      ${imageSources.xl} 2600w
                  `}
                  sizes={`
                      (max-width: 599px) 600px,
                      (max-width: 1799px) 1800px,
                      (max-width: 2199px) 2200px,
                      2600px
                  `}
                  src={imageSources.fallback}
                  alt={item.content}
                />
              :
                item?.mainMediaUrl[0]?.mime_type === "mp4" || item?.mainMediaUrl[0]?.mime_type === "MOV" || item?.mainMediaUrl[0]?.mime_type === "mov" ? (
                <video preload="metadata">
                  <source src={`${item?.mainMediaUrl[0]?.url}#t=0.1`} type="video/mp4" />
                  <source src={`${item?.mainMediaUrl[0]?.url}#t=0.1`} type="video/quicktime" />
                  <track src="captions_en.vtt" kind="captions" srclang="en" label="english_captions" />
                </video>
                ) : item?.mainMediaUrl[0]?.mime_type === "pdf" ? (
                  <PdfRender pdf={item?.mainMediaUrl[0]?.api_url} pageNumber={1} />
                ) : (
                  <LazyLoadImage src={item?.mainMediaUrl[0]?.url} width="100%" alt={item.content} />
                )
              }
            </figure>
          </article>
        </section>
      </motion.div>
    );
  };

  const renderDesktopPostListing = (item, index) => {
    const imageSources = {
      s: item?.image_s,
      m: item?.image_m,
      l: item?.image_l,
      xl: item?.image_xl,
    };

    const fallbackImg = item?.mainMediaUrl[0]?.url;

    return (
      <motion.div
        key={index}
        className={
          width >= 1300
            ? "col-3 post-container-desktop p-0"
            : "col-4 post-container-desktop p-0"
        }
        layoutId={item.display_id}
        onClick={
          () => navigate("/?post=" + item.id)
        }
      >
        <figure className="image-container">
          {imageSources.s !== null ? 
            <LazyLoadImage
              srcSet={`
                  ${imageSources.s} 600w,
                  ${imageSources.m} 1800w,
                  ${imageSources.l} 2200w,
                  ${imageSources.xl} 2600w
              `}
              sizes={`
                  (max-width: 599px) 600px,
                  (max-width: 1799px) 1800px,
                  (max-width: 2199px) 2200px,
                  2600px
              `}
              src={imageSources.fallback}
              alt={item.content}
            />
          :
            item?.mainMediaUrl[0]?.mime_type === "mp4" || item?.mainMediaUrl[0]?.mime_type === "MOV" || item?.mainMediaUrl[0]?.mime_type === "mov" ? (
              <video preload="metadata">
                <source src={`${item?.mainMediaUrl[0]?.url}#t=0.1`} type="video/mp4" />
                <source src={`${item?.mainMediaUrl[0]?.url}#t=0.1`} type="video/quicktime" />
                <track src="captions_en.vtt" kind="captions" srclang="en" label="english_captions" />
              </video>
            ) : item?.mainMediaUrl[0]?.mime_type === "pdf" ? (
              <PdfRender pdf={item?.mainMediaUrl[0]?.api_url} pageNumber={1} />
            ) : (
              <LazyLoadImage src={item?.mainMediaUrl[0]?.url} width="100%" alt={item.content} />
            )
          }
        </figure>
        <article className="detail">
          <p className="forum-title">{item.content}</p>
          <section className="row" style={{ alignItems: "center", marginTop: "3px" }}>
            <LazyLoadImage
              alt="reunion-avatar"
              src={item.created_by_avatar ? item.created_by_avatar : DefaultAvatar}
              style={{
                height: "30px",
                width: "30px",
                padding: 0,
                borderRadius: "50%",
              }}
            />
            <p>{formatCash(item.like_count)} Like</p>
            <p>{formatCash(item.comment_count)} Comment</p>
          </section>
        </article>
      </motion.div>
    );
  };

  const renderPostEmpty = () => {
    return (
      <section className="row post-card-listing-row">
        <Lottie animationData={MediaIcon} />
        <h3 className="title">No Post Found</h3>
        <p className="desc">
          No one has posted something in this topic.
        </p>
      </section>
    );
  };

  useEffect(() => {
    if(fitler !== '' || (search !== undefined && search !== '') || selectedOption.length > 0) {
      setShowViewMore(false)
    } else {
      setShowViewMore(true)
    }
  }, [fitler, search, selectedOption])

  return (
    <section className="sharing-post-container">
      <header className="row home-title-row m-0">
        <h2>Sharing Post</h2>
        {width < 991 && (
          <MDBBtn
            className="button-group --notification-button --create-post"
            type="button"
            onClick={() => {
              if (token) {
                dispatch(closeModalPostPreview());
                dispatch(setCreatePostModal("create-post"));
              } else {
                dispatch(setShowLoginModal(true));
              }
            }}
          >
            <p style={{ cursor: "pointer" }}>
              Post <Icon icon="mingcute:add-line" color="#FAFAFA" width={18} />
            </p>
          </MDBBtn>
        )}
      </header>
      {width >= 991 && (
        <nav>
          <section className="row m-0">
            <article className="col-md-3">
              <SearchBar setSearch={setSearch} setStart={setStart} />
            </article>
            <article className="col">
              <section className="sharing-post-tab-container">
                <Segmented
                    options={[
                      { label: "All", value: ""},
                      { label: "Help", value: "help"},
                      { label: "Show Case", value: "showcase" },
                      { label: "Sharing", value: "recommend" },
                    ]}
                    value={fitler} 
                    onChange={setFilter}
                    block 
                  />
              </section>
            </article>
          </section>
          <ButtonFilter
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            setStart={setStart}
          />
        </nav>
      )}
      {width >= 991
        ? postList.length === 0 && !loading && renderPostEmpty()
        : post.length === 0 && !home_loading && renderPostEmpty()}
        <article className="grid">
          <section className="container row" style={{ position: 'relative', minHeight: width < 991 ? '480px' : ''}}>
          {!home_loading ?
            width >= 991 ? (
              <InfiniteScroll
                style={{ overflow: "unset" }}
                dataLength={postList.length}
                next={() => {
                  if(start === 4 && fitler === '' && (search === undefined || search === '') && selectedOption.length === 0 && secondLoad) {
                  } else {
                    getMoreData();
                  }
                }}
                hasMore={start === 4 && fitler === '' && (search === undefined || search === '') && selectedOption.length === 0 && secondLoad ? false : hasMore}
                loader={start === 4 ? '' : hasMore && postList.length > 0 && <h4 className="text-center mt-5">Loading...</h4>}
              >
                <section className="row grid">
                  {postList.length > 0 &&
                    postList.map((item, index) => {
                      return renderDesktopPostListing(item, index);
                    })}
                </section>
                {!viewMore && showViewMore && secondLoad && 
                <section className="row justify-content-center m-4">
                  <MDBBtn
                    className="button-group --notification-button --view-post d-flex align-items-center justify-content-center"
                    type="button"
                    onClick={() => {
                      getMoreData();
                      setViewMore(true)
                    }}
                  >
                    <p style={{ cursor: "pointer" }}>
                      View All Post&nbsp;
                      <Icon 
                        icon="icon-park-outline:more-app" 
                        color="#FAFAFA" 
                        width={18} 
                      />
                    </p>
                  </MDBBtn>
                </section>
                }
              </InfiniteScroll>
            ) : (
              post.length > 0 &&
              post.map((item, index) => {
                if (index < 4) {
                  return renderMobilePostListing(item, index);
                }
              })
            )
          : 
            <section style={{ minHeight: '250px' }}>
              <article style={{ width: '10em', position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <Lottie animationData={CarLoading} style={{ width: '100%' }}/>
              </article>   
            </section>
          }
          </section>
        </article>
    </section>
  );
}
