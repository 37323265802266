import { useEffect } from "react";

//lib
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { MDBModalBody, MDBRow, MDBCol, MDBContainer } from "mdb-react-ui-kit";

//hook
import useWindowDimensions from "../../../components/common/window-dimension";

//redux
import { useDispatch, useSelector } from "react-redux";
import { closeAllModal } from "../../../redux/reducer/modalReducer";
import { postTicketListing } from "../../../redux/reducer/eventReducer";
import { getOrderHistory } from "../../../redux/reducer/orderReducer";

//component
import EventCard from "./eventCard";
import ModalBox from "../../../components/modal/full.modal.box";
import InfoHeader from "../../../components/header/info-header";

export default function EventListing() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { isOpenEventListing } = useSelector((state) => state.modal);
  const token = localStorage.getItem('token');

  const { selectedEventId, loading, ticketLing } = useSelector(
    (state) => state.event
  );

  const backButton = () => {
    dispatch(closeAllModal());
    navigate(`/`);
  };

  const handleClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (selectedEventId !== null && !loading) {
      handleClick(selectedEventId);
    }
  }, [selectedEventId, loading]);

  useEffect(() => {
    dispatch(postTicketListing());
    if(token) {
      dispatch(getOrderHistory({order_type : "ticket"}))
    }
  }, []);

  return (
    <ModalBox
      className={"modal-container"}
      show={isOpenEventListing}
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      backButton={backButton}
      centered
      scrollable
      content={
        <article>
          <InfoHeader
            showBackButton={true}
            title="Reunion Event"
            backButton={backButton}
          />
          <MDBModalBody className="p-0">
            <section className={width >= 991 ? "order-div desktop" : "order-div"}>
              <article className="row">
                {loading ? (
                  <Icon
                    icon="line-md:loading-twotone-loop"
                    height={"4em"}
                    style={{ marginTop: "5em" }}
                  />
                ) : ticketLing &&
                  ticketLing.length > 0 &&
                  ticketLing.map((item, index) => {
                    return <EventCard key={index} item={item} />;
                  })
                }
              </article>
            </section>
          </MDBModalBody>
        </article>
      }
    />
  );
}
