import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "../../services/http.services";
import authServices from "../../services/auth.services";

export const initialData = {
  selectedId: '',
  disableCalendar: [],
  carReportInfo: [],
  report: {
    booked_at: '',
    status: '',
    inspection_history: []
  },
  workshop: [],
  // {
  //     id: 1,
  //     background: workshop1,
  //     workshop_label: 'Batu Pahat',
  //     opening_hours: 'Mon - Sun 9am - 10pm',
  //     expertise: 'Tire, Oil, Engine, Interior part',
  //     waze: "url",
  //     whatsapp: 'contact'
  // },{
  //     id: 2,
  //     background: workshop2,
  //     workshop_label: 'Batu Pahat',
  //     opening_hours: 'Mon - Sun 9am - 10pm',
  //     expertise: 'Tire, Oil, Engine, Interior part',
  //     waze: "url",
  //     whatsapp: 'contact'
  // },{
  //     id: 3,
  //     background: workshop3,
  //     workshop_label: 'Batu Pahat',
  //     opening_hours: 'Mon - Sun 9am - 10pm',
  //     expertise: 'Tire, Oil, Engine, Interior part',
  //     waze: "url",
  //     whatsapp: 'contact'
  // }
  workshop2 : [],
  disableMakeBooking: true,
  booked_loading: false,
  workshop_loading: false,
  calendar_loading: false,
  attachmentInfo: [],
};

export const getCarReportHistory = createAsyncThunk('car/getCarReportHistory', async(payload, thunkAPI) => {
  try{
    httpService.setToken(authServices.getToken());
    const { data } = await httpService.post(process.env.REACT_APP_API_URL + `/user_car_report`, payload)
    return data
  }catch(error){
      return thunkAPI.rejectWithValue(error)
  }
})

export const bookingWorkshop = createAsyncThunk('car/bookingWorkshop', async(payload, thunkAPI) => {
  try{
    httpService.setToken(authServices.getToken());
    const { data } = await httpService.post(process.env.REACT_APP_API_URL + `/booking`, payload)
    return data
  }catch(error){
      return thunkAPI.rejectWithValue(error)
  }
})

export const calendar = createAsyncThunk('car/calendar', async(payload, thunkAPI) => {
  try{
    httpService.setToken(authServices.getToken());
    const { data } = await httpService.post(process.env.REACT_APP_API_URL + `/calendar`)
    return data
  }catch(error){
      return thunkAPI.rejectWithValue(error)
  }
})

export const reportInfo = createAsyncThunk('car/reportInfo', async(payload, thunkAPI) => {
  try{
    httpService.setToken(authServices.getToken());
    const { data } = await httpService.post(process.env.REACT_APP_API_URL + `/user_car_report_info`, payload)
    return data
  }catch(error){
      return thunkAPI.rejectWithValue(error)
  }
})

export const workshopList = createAsyncThunk('get/workshop', async(payload, thunkAPI) => {
  try{
    httpService.setToken(authServices.getToken());
    const { data } = await httpService.get(process.env.REACT_APP_API_URL + `/workshop_list`, payload)
    return data
  }catch(error){
      return thunkAPI.rejectWithValue(error)
  }
})

const dataSlice = createSlice({
  name: "car",
  initialState: initialData,
  reducers: {
    setSelectedId: (state,action) => {
      state.selectedId = action.payload
    },
    setAttachmentInfo: (state,action) => {
      state.attachmentInfo = action.payload
    },
    setDisableMakeBooking: (state,action) => {
      state.disableMakeBooking = action.payload
    }
  },
  extraReducers: {
    [reportInfo.pending]: (state, action) => {
    },
    [reportInfo.fulfilled]: (state, action) => {
      state.carReportInfo = action.payload.data
    },
    [reportInfo.rejected]: (state, action) => {
    },
    [calendar.pending]: (state, action) => {
      state.calendar_loading = true
    },
    [calendar.fulfilled]: (state, action) => {
        state.calendar_loading = false
        state.disableCalendar = action.payload.data
    },
    [calendar.rejected]: (state, action) => {
        state.loading = false
    },
    [bookingWorkshop.pending]: (state, action) => {
      state.loading = true
      state.disableMakeBooking = true
    },
    [bookingWorkshop.fulfilled]: (state, action) => {
        state.loading = false
    },
    [bookingWorkshop.rejected]: (state, action) => {
        state.loading = false
    },
    [getCarReportHistory.pending]: (state, action) => {
        state.booked_loading = true
    },
    [getCarReportHistory.fulfilled]: (state, action) => {
        state.booked_loading = false
        state.report = action.payload.data
        if(action.payload.data.status === "true"){
          state.disableMakeBooking = false
        }
    },
    [getCarReportHistory.rejected]: (state, action) => {
        state.booked_loading = false
    },
    [workshopList.pending]: (state, action) => {
      state.workshop_loading = true
    },
    [workshopList.fulfilled]: (state, action) => {
      state.workshop2 = action.payload.data.aaData
      state.workshop_loading = false
    },
    [workshopList.rejected]: (state, action) => {
    },
  },
});

export const { setSelectedId, setAttachmentInfo, setDisableMakeBooking } = dataSlice.actions;

export default dataSlice.reducer;

// example for fetching API
