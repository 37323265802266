import { useEffect, useState } from "react";

//lib
import Lottie from "lottie-react";
import { Icon } from "@iconify/react";
import { useSearchParams, useParams } from "react-router-dom";
import { MDBBtn, MDBContainer, MDBRow } from "mdb-react-ui-kit";

//hook
import useWindowDimensions from "../../components/common/window-dimension";

//redux
import { useDispatch, useSelector } from "react-redux";
import {
  openModalMissionList,
  openModalOrderHistory,
  openActivityModal,
  openModalEvent,
  openModalRedeemHistory,
  openModalUserInformation,
  openModalPaymentSuccess,
} from "../../redux/reducer/modalReducer";
import {
  getProfile,
  getMissionList,
  getAvatar,
} from "../../redux/reducer/profileReducer";
import { getUserWalletBalance } from "../../redux/reducer/stockReducer";
import { setShowLoginModal } from "../../redux/reducer/settingReducer";

//assets
import MissionGraphic from "./../../assets/json/mission-graphic.json";

//componet
import UserProfile from "./component/userProfile";
import Notify from "../../components/common/notify-component";
import MissionList from "./component/missionList";
import ProfileSetting from "./component/accountSetting";
import Login from "../Login";

//modal
import AvatarModal from "./component/avatarModal";
import MissionModal from "./component/missonModal";
import InformationModal from "./component/infomationModal";
import PurchaseModal from "./component/purchaseModal";
import PointModal from "./component/pointModal";
import EventModal from "./component/eventModal";
import ConfirmationModal from "../../components/modal/comfirmation.modal";
import CreatePost from "../Forum/component/create.post";
import ActivityModal from "./component/activity.logs";
import ForumSettingModal from "../../components/modal/forum.post.setting";
import PostInfo from "../Forum/component/post.info";
import PurchaseInfoModal from "./component/purchaseInfoModal";
import SuccessModal from "../../components/modal/success.modal";

//helper
import scrollToTop from "../../helper/scrolltotop.helper";
import { Helmet } from "react-helmet";

export default function Profile() {
  const dispatch = useDispatch();

  const [settingModal, setSettingModal] = useState(false);
  const [comfirmation, setComfirmation] = useState(false);
  const [type, setType] = useState("");

  const params = useParams();
  const { width } = useWindowDimensions();
  const token = localStorage.getItem("token");
  const [searchParams, setSearchParams] = useSearchParams();

  const { setting_item, mission_list } = useSelector((state) => state.profile);
  const { showLoginModal } = useSelector((state) => state.setting);
  const { walletBalance } = useSelector((state) => state.stock);

  const {
    isOpenUserEvent,
    isOpenUserInformation,
    isOpenRedeem,
    isOpenOrder,
    isOpenAvatarList,
    isOpenMissionList,
    isOpenActivity,
    isOpenPurchaseInfo,
    isOpenPaymentSuccess,
  } = useSelector((state) => state.modal);

  const data = [
    "Any announcement or remind will be update here as personal notification ",
  ];

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  //call api
  const handleCallApi = () => {
    dispatch(getAvatar());
    dispatch(getProfile());
    dispatch(getMissionList());
    dispatch(getUserWalletBalance());
  }

  function updateMetaThemeColor() {
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", "#222A39");
  }

  const autoOpenModal = {
    user: openModalUserInformation(),
    purchase: openModalOrderHistory(),
    point: openModalRedeemHistory(),
    event: openModalEvent(),
    activity: openActivityModal(),
  };

  useEffect(() => {
    if (params.state) {
      dispatch(autoOpenModal[params.state]);
    }
  }, []);

  useEffect(() => {
    scrollToTop();
    updateMetaThemeColor();
    if (token) {
      dispatch(getAvatar());
      dispatch(getProfile());
      dispatch(getUserWalletBalance());
    }
    dispatch(getMissionList());
  }, [token]);

  useEffect(() => {
    if (searchParams.get("type") === "product" && searchParams.get("status") === "success") {
      setType("product")
      dispatch(openModalPaymentSuccess())
    } else if (searchParams.get("type") === "ticket" && searchParams.get("status") === "success") {
      setType("ticket")
      dispatch(openModalPaymentSuccess())
    } else if (searchParams.get("type") === "ticket") {
      dispatch(openModalEvent());
    } else if (searchParams.get("type") === "product") {
      dispatch(openModalOrderHistory());
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Reunion | Profile</title>
        <meta name="description" content="Dive into the Reunion car club community, complete missions, earn points, unlock avatars, and personalize your profile to showcase your passion for cars." />
      </Helmet>

      <section
        className={
          width >= 991
            ? "container profile-container desktop-container"
            : "container profile-container"
        }
      >
        <header className={width >= 991 ? "desktop-user" : "mobile-user"}>
          <UserProfile />
        </header>
        <article className={width >= 991 ? "desktop" : "mobile"}>
          <Notify item={data} />
          <section className="row mt-2">
            <h2 className="_label --mission">
              Collect Avatar&nbsp;
              <Icon icon="material-symbols:info-outline" height={"20px"} />
            </h2>
          </section>
          <section className="row mission-container">
            {mission_list.length > 0 &&
              mission_list.map((item, index) => {
                if (index <= 2) {
                  return (
                    <MissionList
                      key={index}
                      item={item}
                      setComfirmation={setComfirmation}
                    />
                  );
                }
              })}{" "}
            {mission_list.length === 0 && (
              <section className="row get-mission-row">
                <article className="logo-div pointer">
                  <Lottie animationData={MissionGraphic} />
                </article>
                <p className="_label --unlock-text">
                  No Mission Available
                </p>
              </section>
            )}
          </section>
          {mission_list.length > 3 && (
            <section className="row view-all-mission-button">
              <MDBBtn
                onClick={() => {
                  if(token) {
                    dispatch(openModalMissionList());
                  } else {
                    dispatch(setShowLoginModal(true));
                  }
                }}
              >
                View All
              </MDBBtn>
            </section>
          )}
          {setting_item.map((item, index) => {
            if (!token && item.status === false) {
              return null;
            }
            return (
              <ProfileSetting
                key={index}
                item={item}
                clickFunction={autoOpenModal}
              />
            );
          })}
          {token && (
            <section className="row mt-3">
              <p className="_label --logout pointer" onClick={() => handleLogout()}>
                Log Out
              </p>
            </section>
          )}
        </article>
        <PostInfo setShow={setSettingModal} />
        {settingModal && (
          <ForumSettingModal show={settingModal} setShow={setSettingModal} />
        )}
      </section>
      {isOpenMissionList && <MissionModal setComfirmation={setComfirmation} />}
      {showLoginModal && <Login />}
      {isOpenAvatarList && <AvatarModal />}
      {isOpenUserInformation && <InformationModal />}
      {isOpenActivity && <ActivityModal />}
      {isOpenOrder && <PurchaseModal />}
      {isOpenPurchaseInfo && <PurchaseInfoModal pointBalance={walletBalance} />}
      {isOpenRedeem && <PointModal />}
      {isOpenUserEvent && <EventModal />}
      {isOpenPaymentSuccess && <SuccessModal type={type}/>}
      {comfirmation && (
        <ConfirmationModal show={comfirmation} setShow={setComfirmation} type="mission" />
      )}
      <CreatePost />
    </>
  );
}
