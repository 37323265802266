import { useEffect } from "react";

//lib
import { Icon } from "@iconify/react";
import { MDBModalBody, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";

//hook
import useWindowDimensions from "../../../components/common/window-dimension";

//redux
import { useDispatch, useSelector } from "react-redux";
import { closeAllModal } from "../../../redux/reducer/modalReducer";
import { getOrderHistory } from "../../../redux/reducer/orderReducer";

//component
import EventCard from "./eventCard";
import EmptyPage from "./emptyPage";
import ModalBox from "../../../components/modal/full.modal.box";
import InfoHeader from "../../../components/header/info-header";

export default function EventModal() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { isOpenUserEvent } = useSelector((state) => state.modal);
  const { orderHistory, loading } = useSelector((state) => state.order);

  const backButton = () => {
    dispatch(closeAllModal());
    navigate("/profile");
  };

  useEffect(() => {
    dispatch(getOrderHistory({ order_type: "ticket" }));
  }, []);

  return (
    <ModalBox
      className={"modal-container"}
      show={isOpenUserEvent}
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      backButton={backButton}
      centered
      scrollable
      content={
        <article>
          <InfoHeader
            showBackButton={true}
            title="My Event"
            backButton={backButton}
          />
          <MDBModalBody className="p-0">
            <section className={width >= 991 ? "order-div desktop" : "order-div"}>
              {/* <MDBRow className="tag-row">
                <MDBCol className="tag d-flex">
                  <div className="short-div" />
                  <label className="_label --title">
                    Can't buy reunion points; participation is important
                  </label>
                </MDBCol>
              </MDBRow> */}
              <MDBRow>
                {loading ? (
                  <Icon
                    icon="line-md:loading-twotone-loop"
                    height={"4em"}
                    style={{ marginTop: "5em" }}
                  />
                ) : orderHistory.length > 0 ? (
                  orderHistory.map((item, index) => {
                    return <EventCard key={index} item={item} />;
                  })
                ) : (
                  <EmptyPage name="event" />
                )}
              </MDBRow>
            </section>
          </MDBModalBody>
        </article>
      }
    />
  );
}
