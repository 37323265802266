//lib
import { useNavigate } from "react-router-dom";
import { MDBContainer, MDBModalBody } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

//hook
import useWindowDimensions from "../../../components/common/window-dimension";

//redux
import { useDispatch, useSelector } from "react-redux";
import { closeAllModal } from "../../../redux/reducer/modalReducer";

//component
import MissionList from "./missionList";
import ModalBox from "../../../components/modal/full.modal.box";
import InfoHeader from "../../../components/header/info-header";

//image
import { Logo } from "../../../assets/images";

export default function MissionModal({ setComfirmation }) {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const { isOpenMissionList } = useSelector((state) => state.modal);
  const { mission_list } = useSelector((state) => state.profile);

  const backButton = () => {
    dispatch(closeAllModal());
    navigate("/profile");
  };

  return (
    <ModalBox
      className={"modal-container"}
      show={isOpenMissionList}
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      backButton={backButton}
      scrollable
      centered
      content={
        <article>
          <InfoHeader
            showBackButton={true}
            title="Mission List"
            backButton={backButton}
          />
          <MDBModalBody className="p-0">
            <section className="container" style={{ paddingTop: width >= 991 ? "0em" : "1em" }}>
              {mission_list.length > 0 ? (
                mission_list.map((item, index) => {
                  return (
                    <MissionList
                      key={index}
                      item={item}
                      setComfirmation={setComfirmation}
                    />
                  );
                })
              ) : (
                <article className="image-div">
                  <LazyLoadImage
                    src={Logo}
                    className="logo-image pointer"
                    alt="reunion-logo"
                    fetchpriority="high"
                  />
                </article>
              )}
            </section>
          </MDBModalBody>
        </article>
      }
    />
  );
}
