import http from "./http.services"


async function getProductListing(payload) {
  const response = await http.get(process.env.REACT_APP_API_URL + `/user_product_listing?search=${payload.search}`)
  return response.data
}

async function getProductInfo(payload) {
  const response = await http.get(process.env.REACT_APP_API_URL + `/user_product_info/${payload.id}`)
  return response.data
}

async function createOrder(payload) {
  const response = await http.post(process.env.REACT_APP_API_URL + `/order`, payload)
  return response.data
}

async function getUserAddressInfo() {
  http.setToken(localStorage.getItem('token'))
  const response = await http.get(process.env.REACT_APP_API_URL + `/user_address_info`)
  return response.data
}

async function getUserWalletBalance() {
  http.setToken(localStorage.getItem('token'))
  const response = await http.get(process.env.REACT_APP_API_URL + `/get_wallet_balance`)
  return response.data
}

async function getUserBanks() {
  http.setToken(localStorage.getItem('token'))
  const response = await http.get(process.env.REACT_APP_API_URL + `/user_get_banks`)
  return response.data
}

async function getUserOrderDetails(payload) {
  http.setToken(localStorage.getItem('token'))
  const response = await http.get(process.env.REACT_APP_API_URL + `/user_get_order_details?reference_no=${payload.reference_no}`)
  return response.data
}

async function addToCart(payload) {
  http.setToken(localStorage.getItem('token'))
  const response = await http.post(process.env.REACT_APP_API_URL + `/cart`, payload)
  return response.data
}

async function updateCart(payload) {
  http.setToken(localStorage.getItem('token'))
  const response = await http.put(process.env.REACT_APP_API_URL + `/cart`, payload)
  return response.data
}

async function deleteCart(payload) {
  http.setToken(localStorage.getItem('token'))
  const response = await http.delete(process.env.REACT_APP_API_URL + `/delete_cart_item/${payload.id}`, payload)
  return response.data
}

async function getCartData(payload) {
  http.setToken(localStorage.getItem('token'))
  const response = await http.get(process.env.REACT_APP_API_URL + `/cart?cart_payment_type=${payload.cart_payment_type}`)
  return response.data
}

async function setShippingOption(payload) {
  http.setToken(localStorage.getItem('token'))
  const response = await http.post(process.env.REACT_APP_API_URL + `/set_shipping_option`, payload)
  return response.data
}

async function checkout(payload) {
  http.setToken(localStorage.getItem('token'))
  const response = await http.post(process.env.REACT_APP_API_URL + `/user_checkout`, payload)
  return response.data
}

async function getCheckoutItem(payload) {
  http.setToken(localStorage.getItem('token'))
  const checkoutItemIdsString = payload.checkout_id.join(',');

  const response = await http.get(process.env.REACT_APP_API_URL + `/user_checkout_items?checkout_item_ids=${checkoutItemIdsString}`);
  return response.data;
}

const apiService = {
  getProductListing,
  getProductInfo,
  createOrder,
  getUserAddressInfo,
  getUserWalletBalance,
  getUserBanks,
  getUserOrderDetails,
  addToCart,
  updateCart,
  deleteCart,
  getCartData,
  checkout,
  getCheckoutItem,
  setShippingOption,
};

export default apiService