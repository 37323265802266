//lib
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

//assets
import Logo from "../../assets/images/logo.webp";
import LogoWhite from "../../assets/images/logo-white.webp";
import { MDBRow } from "mdb-react-ui-kit";

export default function MainHeader() {
  const navigate = useNavigate();

  return (
    <>
      <header className="main-header-container --mobile pointer">
        <LazyLoadImage
          src={
            window.location.pathname === "/"
              ? LogoWhite
              : window.location.pathname === "/profile"
              ? LogoWhite
              : Logo
          }
          title="reunion-logo"
          alt="reunion-logo"
          height={40}
          width={150}
          onClick={() => navigate("/")}
        />
        <h1
          style={{
            color:
              window.location.pathname === "/"
                ? "#FAFAFA"
                : window.location.pathname === "/profile"
                ? "#FAFAFA"
                : "#222A39",
          }}
          // onClick={() => navigate("/")}
        >
        </h1>
      </header>
    </>
  );
}
