//lib
import { Icon } from "@iconify/react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { Link, useNavigate } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

export default function ProfileSetting({ item, clickFunction }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <article className="profile-setting-div">
      <section className="row mt-3">
        <h2 className="_label --title">{item.label}</h2>
        {item.description && (
          <p className="_label --desc">{item.description}</p>
        )}
      </section>
      {item.items.map((item, index) => {
        return (
          <Link
            key={index}
            className="row items-row pointer"
            onClick={(e) => {
              e.preventDefault();

              dispatch(clickFunction[item.state]);
              navigate(`/profile/${item.state}`);
            }}
          >
            <article className="col-1">
              <Icon icon={item.icon} width="1.8em" color="#999999" style={{ fontSize: '15px' }} />
            </article>
            <article className="col-10">
              <h4 style={{ cursor: "pointer" }} className="_label --name">
                {item.label}
              </h4>
            </article>
            <article className="col-1 d-flex justify-content-end">
              <Icon
                icon="ion:chevron-back-outline"
                rotate={2}
                height={"1.1em"}
              />
            </article>
          </Link>
        );
      })}
    </article>
  );
}
