//lib
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBContainer,
  MDBRow,
} from "mdb-react-ui-kit";
import { Link, useNavigate } from "react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";
import { RemoveScroll } from "react-remove-scroll";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";

//hook
import useWindowDimensions from "../common/window-dimension";

//redux
import { useDispatch, useSelector } from "react-redux";
import {
  getForumInfo,
  hiddenPost,
  reportPost,
  setCommentStatus,
  setCreatePostData,
  setCreatePostModal,
  setEditForumPost,
  setHiddenId,
  setSelectedId,
} from "../../redux/reducer/forumReducer";
import { getHome } from "../../redux/reducer/homeReducer";

export default function ForumSettingModal({ show, setShow, getForumPost }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { id, type, username, isOwner } = useSelector(
    (state) => state.setting.forumSettingData
  );
  const { selectedDataInfo } = useSelector((state) => state.forum);
  const name = username;

  const handleHiddenPost = () => {
    dispatch(hiddenPost({ id: id, type: type }))
      .then((res) => {
        setShow(false);
        toast.success("Hidden Post Submit.", {
          autoClose: 3000,
          theme: "light",
          hideProgressBar: true,
          closeOnClick: true,
          closeButton: false,
        });
      })
      .then((res) => {
        if (type === "post") {
          if (window.location.pathname === "/forum") {
            navigate("/forum");
            getForumPost();
          } else {
            navigate("/");
            if (width < 991) {
              dispatch(getHome());
            } else {
              getForumPost();
            }
          }
        }
        dispatch(setHiddenId({ type: type, id: id }));
      });
  };

  const handleReportPost = () => {
    dispatch(reportPost({ id: id, data: { status: `report_${type}` } })).then(
      (res) => {
        setShow(false);
        toast.success("Report Post Submit.", {
          autoClose: 3000,
          theme: "light",
          hideProgressBar: true,
          closeOnClick: true,
          closeButton: false,
        });
      }
    );
  };

  const handleEditPost = () => {
    dispatch(setEditForumPost(true));
    dispatch(
      setCreatePostData({
        post_id: selectedDataInfo.id,
        content: selectedDataInfo.content,
        topic: selectedDataInfo.topic,
        attachment: selectedDataInfo.mainMediaUrl,
      })
    );
    dispatch(setCreatePostModal("create-post"));
    setShow(false);
  };

  const handleEditComment = () => {
    setShow(false);
    dispatch(setCommentStatus(id));
  };

  const content = () => {
    return (
      <article className="container forum-post-container">
        <section className="setting-box">
          {!isOwner && (
            <Link
              className="row setting-tab"
              onClick={(e) => {
                e.preventDefault();

                handleHiddenPost();
              }}
            >
              <article className="col-2 icon-section p-0 d-flex justify-content-center">
                <Icon icon="entypo:squared-cross" width={20} />
              </article>
              <article className="col-10 detail-section p-0">
                <h4>Hide {type}</h4>
                <p className="text-small">See fewer {type} like this</p>
              </article>
            </Link>
          )}
          {!isOwner && (
            <section
              className="row setting-tab"
              onClick={() => {
                handleReportPost();
              }}
            >
              <article className="col-2 icon-section p-0 d-flex justify-content-center">
                <Icon icon="mingcute:report-fill" width={30} />
              </article>
              <article className="col-10 detail-section p-0">
                <h4>Report {type}</h4>
                <p className="text-small">
                  We won't let <span style={{ fontWeight: 700 }}>{name}</span>{" "}
                  know who reported this.
                </p>
              </article>
            </section>
          )}
          {isOwner && (
            <section
              className="row setting-tab"
              style={{ padding: "0.5em" }}
              onClick={() => {
                if (type === "post") {
                  handleEditPost();
                } else if (type === "comment") {
                  handleEditComment();
                }
              }}
            >
              <article className="col-2 icon-section p-0 d-flex justify-content-center">
                <Icon icon="solar:pen-bold" width={30} />
              </article>
              <article className="col-10 detail-section d-flex align-items-center p-0">
                <h4>Edit {type}</h4>
              </article>
            </section>
          )}
        </section>
      </article>
    );
  };

  return (
    <RemoveScroll>
      {width >= 991 ? (
        <MDBModal
          className={`full-modal-box-container --full-modal`}
          show={show}
          setShow={setShow}
        >
          <MDBModalDialog size={`md`} scrollable={false}>
            <MDBModalContent style={{ height: 'auto', minHeight: 0 }}>{content()}</MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      ) : (
        <BottomSheet
          open={show}
          onDismiss={() => {
            setShow(false);
          }}
          snapPoints={({ maxHeight }) => [maxHeight / 3]}
          blocking={true}
          className={"setting-forum-modal"}
        >
          {content()}
        </BottomSheet>
      )}
    </RemoveScroll>
  );
}
