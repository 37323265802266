import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import httpService from "../../services/http.services"
import auth from "../../services/auth.services"

export const initialData = {
  home: {
    ticket: [],
    post: [],
    pinnedPost: []
  },
  loading: false,
  home_loading: false
}

export const getHome = createAsyncThunk('get/home', async(payload, thunkAPI) => {
    try{
        httpService.setToken(auth.getToken());
        const { data } = await httpService.get(process.env.REACT_APP_API_URL + `/home`, payload)
        return data
    }catch(error){
        return thunkAPI.rejectWithValue(error)
    }
})

const homeSlice = createSlice({
  name: "home",
  initialState: initialData,
  reducers: {
  },
  extraReducers: {
    [getHome.pending]: (state, action) => {
        state.home_loading = true
    },
    [getHome.fulfilled]: (state, action) => {
        state.home_loading = false

        let newData = []
        for(var key in action.payload.data.pinned_post){
          newData.push( {...action.payload.data.pinned_post[key], display_id: `pinned-${action.payload.data.pinned_post[key].id}`})
        }

        let postNewData = []
        for(var keyPost in action.payload.data.post){
          postNewData.push({...action.payload.data.post[keyPost], display_id: `post-${action.payload.data.post[keyPost].id}`})
        }
        state.home = { 
          ticket: action.payload.data.ticket ? action.payload.data.ticket : [],
          post: postNewData,
          pinnedPost: newData
        }
    },
    [getHome.rejected]: (state, action) => {
    },
  },
})

export const { setRefresh } = homeSlice.actions

export default homeSlice.reducer

// example for fetching API