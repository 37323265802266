import { useEffect } from "react";

//lib
import { Icon } from "@iconify/react";
import { Skeleton } from "primereact/skeleton";
import { RemoveScroll } from "react-remove-scroll";
import { AnimatePresence, motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";

//lib css
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

//assets
import defaultBg from "../../../assets/images/default_avatar_background.webp"
import { DefaultAvatar } from "../../../assets/images"

//redux
import {
  clearProfileDetail,
  profileDetail,
  setProfileModal,
} from "../../../redux/reducer/forumReducer";
import { useDispatch, useSelector } from "react-redux";

//helper
import formatNumber from "../../../helper/formatnumber.helper";
import { fontSize } from "@mui/system";

export default function ProfileCard() {
  const dispatch = useDispatch();
  const { profileModdal, profile_detail, profile_detail_loading } = useSelector((state) => state.forum);

  useEffect(() => {
    if (profileModdal) {
      dispatch(profileDetail({ id: profileModdal }));
    }

    return () => dispatch(clearProfileDetail());
  }, [profileModdal]);

  return (
    <article>
      <AnimatePresence>
        {profileModdal && (
          <RemoveScroll>
            <motion.div
              className="forum-listing-card --profile-modal"
              layoutId={profileModdal}
            >
              <section
                style={{
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                }}
                onClick={() => dispatch(setProfileModal(false))}
              ></section>
              <section>
                <article
                  className={`container profile-modal-container ${profile_detail?.user_avatar_background ? '' : '--default'}`}
                  style={{
                    backgroundImage: `url(${profile_detail?.user_avatar_background ? profile_detail.user_avatar_background : defaultBg})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <header className="header-row --top d-flex justify-content-center align-items-center">
                    <article className="col-2">
                      <section
                        className="button-back-round pointer"
                        onClick={() => dispatch(setProfileModal(false))}
                      >
                        <Icon
                          icon="tabler:arrow-back-up"
                          className="back-icon"
                          width={20}
                        />
                      </section>
                    </article>
                    <article className="col-8 text-center">
                      <h2 style={{ color: "#FAFAFA", fontSize: '16px' }}>User Profile</h2>
                    </article>
                    <article className="d-flex justify-content-end col-2">
                      <section className="button-back-round --transparent pointer">
                      </section>
                    </article>
                  </header>
                  <figure className="header-row d-flex justify-content-center align-items-center">
                  {!profile_detail_loading ?
                      <LazyLoadImage
                        style={{
                          width: "75px",
                          height: "75px",
                          padding: 0,
                          borderRadius: "50%",
                        }}
                        src={
                          profile_detail.user_avatar && profile_detail.user_avatar !== undefined && profile_detail.user_avatar !== ''
                          ? profile_detail.user_avatar
                          : DefaultAvatar
                        }
                        alt="reunion-avatar"
                      />
                    : 
                      <Skeleton
                        shape="circle" 
                        width="75px"
                        height="75px"
                        className="p-0"
                      />
                  }
                  </figure>
                  <section className="header-row d-flex justify-content-center align-items-center">
                  {!profile_detail_loading ? 
                    profile_detail.user_nickname && (
                      <h4 className="_label --name">
                        {profile_detail.user_nickname}
                      </h4>
                    ) : 
                      <Skeleton
                        shape="rectangle" 
                        borderRadius="2rem"
                        width="50%"
                        height="3em"
                        className="p-0"
                      />
                  }
                  </section>
                  {!profile_detail_loading ?
                    <section>
                      {!profile_detail.occupation && (
                        <section className="row header-row d-flex justify-content-center align-items-center">
                          <p className="_label --info">
                            {profile_detail.occupation}
                            {profile_detail.industry
                              ? ` in ${profile_detail.industry}`
                              : ""}
                          </p>
                        </section>
                      )}
                      {profile_detail.car_model && (
                        <section className="row header-row d-flex justify-content-center align-items-center">
                          <p className="_label --info">
                            {profile_detail.car_model} Car Owner
                          </p>
                        </section>
                      )}
                      {profile_detail.city && (
                        <section className="row header-row d-flex justify-content-center align-items-center">
                          <p className="_label --info">
                            {profile_detail.city} City Member
                          </p>
                        </section>
                      )}
                      {(profile_detail.point_contribution ||  profile_detail.point_contribution === 0) && (
                        <section className="row header-row d-flex justify-content-center align-items-center">
                          <p className="_label --info">
                            {formatNumber(profile_detail.point_contribution, 'separator')} Point Contribution
                          </p>
                        </section>
                      )}
                    </section>
                  :
                    Array.from({ length: 2 }, (_, profileDetailIndex) => (
                      <section className="row header-row d-flex justify-content-center align-items-center" key={profileDetailIndex}>
                        <Skeleton
                          shape="rectangle" 
                          borderRadius="2rem"
                          width="70%"
                          height="2.4em"
                          className="p-0"
                        />
                      </section>
                    ))
                  }
                </article>
              </section>
            </motion.div>
          </RemoveScroll>
        )}
      </AnimatePresence>
    </article>
  );
}
