import { useState , useEffect} from "react";

//assest
import NoImage from "../../../assets/images/no_image.webp"

//lib
import { Icon } from "@iconify/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";

//redux
import { useDispatch , useSelector } from "react-redux";
import { openModalPurchase } from "../../../redux/reducer/modalReducer";
import { setSelectedEventId } from "../../../redux/reducer/eventReducer";
import { setShowLoginModal } from "../../../redux/reducer/settingReducer";
import { closeModalEventListing } from "../../../redux/reducer/modalReducer";
import { setSelectedForm, setSelectedPaymentMethod } from "../../../redux/reducer/paymentReducer";
import { setReopenPurchase } from "../../../redux/reducer/orderReducer";
import { setAnimationModal } from "../../../redux/reducer/commonReducer";

export default function EventCard({ item }) {
  const dispatch = useDispatch();
  const { orderHistory } = useSelector((state) => state.order);

  const [ticketId , setTicketId] = useState([]);

  const today = new Date();
  const token = localStorage.getItem("token");

  const paymentStep = () => {
    if (token) {
      dispatch(setSelectedForm(""));
      dispatch(setSelectedEventId(item));
      dispatch(setSelectedPaymentMethod(""));
      dispatch(openModalPurchase());
      dispatch(closeModalEventListing());
      dispatch(setAnimationModal(false))
    } else {
      dispatch(setReopenPurchase(true))
      dispatch(closeModalEventListing());
      dispatch(setShowLoginModal(true));
      dispatch(setSelectedForm(""));
      dispatch(setSelectedEventId(item));
      dispatch(setSelectedPaymentMethod(""));
    }
  };

  const TicketBtn = ({ startDate, endDate, eventDate }) => {
    return (
      <article
        className={
          endDate.getTime() > today.getTime() &&
          today.getTime() > startDate.getTime() &&
          item.balance > 0
            ? "status-div active"
            : "status-div"
        }
        style={{
          cursor:
            endDate.getTime() > today.getTime() &&
            today.getTime() > startDate.getTime() &&
            item.balance > 0
              ? "pointer"
              : "default",
              background : item.balance <= 0 && endDate.getTime() > today.getTime() && today.getTime() > startDate.getTime() && "#919191",
              boxShadow : item.balance <= 0 && endDate.getTime() > today.getTime() && today.getTime() > startDate.getTime() && "0px 4px 0px 0px #5a5a5a",
        }}
        onClick={() => {
          if (
            endDate.getTime() > today.getTime() &&
            today.getTime() > startDate.getTime() &&
            item.balance > 0
          ) {
            paymentStep();
          }
        }}
      >
        <h4
          className="_label --status"
          style={{
            cursor:
              endDate.getTime() > today.getTime() &&
              today.getTime() > startDate.getTime() &&
              item.balance > 0
                ? "pointer"
                : "default",
          }}
        >
          {today.getTime() > eventDate.getTime()
            ? "Event End"
            : startDate.getTime() > today.getTime()
            ? "Coming Soon"
            : item.balance <= 0
            ? "Sold Out"
            : today.getTime() < endDate.getTime()  && ticketId.includes(item.id) 
            ? item.quantity - item.balance + " / " + item.quantity + " Sold"
            : today.getTime() < endDate.getTime() 
            ? "Purchase Ticket"
            : today.getTime() > endDate.getTime()
            ? "Deadline has arrived"
            : ""}
        </h4>
      </article>
    );

  };

  useEffect(() => {
    orderHistory.map((item) => {
      if (ticketId.includes(item.id)){
      } else {
     ticketId.push(item.ticket_id)
      }})
    }
  ,[])

  return (
    <article className="container event-card" id={item.id}>
      <header className="row title-row">
        <article className="col-1">
          <Icon
            icon="heroicons:ticket-solid"
            height={"1.5em"}
            color="#1d71bf"
          />
        </article>
        <article className="col">
          <h2 className="_label --title mt-1">{item.title}</h2>
        </article>
      </header>
      <section className="row ticket-row">
        <article className="col-4 image-col">
          <figure className="image-col">
          {item.image.length > 0 && (
            <LazyLoadImage
              title={item.title}
              src={item.image.length > 0 ? item.image[0].url : NoImage}
              alt={item.description}
              className="event-image"
            />
          )}
          </figure>
        </article>
        <article className="p-0 col-8">
          <section className="row" style={{ minHeight: "6em" }}>
            <p className="_p --description">{item.description}</p>
          </section>
          <section className="row status-row">
            <TicketBtn
              startDate={new Date(item.start_date)}
              endDate={new Date(item.end_date)}
              eventDate={new Date(item.event_date)}
            />
          </section>
        </article>
      </section>
      <footer className="detail-div">
        <section className="row detail-row">
          <article className='col-5 d-flex' style={{alignItems : "center"}}>
            <h3 className="_label --detail">Event Details</h3>
          </article>
          <article className="col">
            <section className="row date-row mt-2">
              <p className="_label --date">
                <Icon icon="ant-design:field-time-outlined" height={"1.2em"} />{" "}
                &nbsp; Start Booking : {item.start_date}
              </p>
            </section>
            <section className="row date-row mt-0">
              <p className="_label --date">
                <Icon icon="ant-design:field-time-outlined" height={"1.2em"} />{" "}
                &nbsp; Event Date : {item.event_date}
              </p>
            </section>
          </article>
        </section>
        <section className="detail-data">
          <p className="_p --description2">{item.content}</p>
        </section>
      </footer>
    </article>
  );
}
