//hook
import useWindowDimensions from "../common/window-dimension";

//component
import FooterOne from "../footer/footer-one";
import MainHeader from "../header/main-header";
import MainNavBar from "../navbar/main-navbar";
import SideNavbar from "../navbar/side-navbar";

import Lottie from "lottie-react";
import { useSelector } from "react-redux";
import Loading from "./../../assets/json/loading-graphic.json";
import { useEffect, useState } from "react";
import scrollToTop from "../../helper/scrolltotop.helper";
import { useLocation } from "react-router-dom";

export default function MainLayout(props) {
  const { width } = useWindowDimensions();
  const { pathname } = useLocation();
  const { ticket_payment_loading } = useSelector((state) => state.event);
  const { payment_loading } = useSelector((state) => state.stock);
  
  // const [isFooterSticky, setFooterSticky] = useState(false);

  // let scrollTimeout;

  useEffect(() => {
    if (ticket_payment_loading || payment_loading) {
      scrollToTop()
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [ticket_payment_loading, payment_loading]);
  
  // const handleScroll = () => {
  //   setFooterSticky(false); // Reset sticky when scrolling
  //   clearTimeout(scrollTimeout);

  //   scrollTimeout = setTimeout(() => {
  //     const isOverThreshold = window.scrollY > 600;
  //     const isAtBottom = window.innerHeight + window.scrollY + 3000 >= document.body.offsetHeight;

  //     setFooterSticky(isOverThreshold);
  //   }, 500);
  // };

  // useEffect(() => {
  //   if (pathname === '/' && width > 991) {
  //     window.addEventListener('scroll', handleScroll);

  //     return () => {
  //       window.removeEventListener('scroll', handleScroll);
  //     };
  //   } else {
  //     setFooterSticky(false);
  //   }
  // }, [pathname]);
  
  return (
    <>
      {(ticket_payment_loading || payment_loading) && 
      <aside className="product-overlay" style={{ height: '100vh'}}>
        <section className="d-flex justify-content-center">
          <article className="d-grid justify-content-center" style={{ width: '17em', top: width > 991 ? '48%' : '35%', left: '50%', position: 'absolute', transform: width > 991 ? 'translate(-50%, -48%)' : 'translate(-50%, -30%)' }}>
            <Lottie animationData={Loading} style={{ width: '120%' }}/>
          </article>
          <h5 style={{ width: '90%', top: width > 991 ? '62%' : '55%', left: '50%', position: 'absolute', transform: width > 991 ? 'translate(-50%, -62%)' : 'translate(-50%, -45%)', textAlign: 'center', fontSize: width > 991 ? '1.7em' : '1.4em', color: '#000000', fontFamily: 'inter-regular', lineHeight: 'unset' }}>
            You will be redirected to payment gateway soon..
            <br />
            <p>Please <span style={{ color: '#00baff', fontWeight: '600' }}>DO NOT</span> close this page or press refresh or back button.</p>
          </h5>
        </section>
      </aside>
      }
      {width < 991 && <MainHeader />}
      {width >= 991 && <SideNavbar />}
      <main className="content-container">{props.children}</main>
      <FooterOne />
      {width < 991 && <MainNavBar />}
    </>
  );
}
