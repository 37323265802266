export default function scrollToTop(modal) {
    if (modal) {
        modal.scrollTo({
          top: 0,
          behavior: "smooth",
        });
    } else {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
}
