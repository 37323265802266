import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useWindowDimensions from "../../../components/common/window-dimension";
import { useState } from "react";
import { MDBCard, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import _ from "lodash";
import { setSelectedSection, setShowPurchaseModal } from "../../../redux/reducer/stockReducer";
import { setDeliveryFee, setPickupLocation, setSelectedMerchantId } from "../../../redux/reducer/checkoutReducer";

export default function CheckoutTable({ changingShipping }) {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { width } = useWindowDimensions();

  const { carts } = useSelector((state) => state.cart);
  const [ paymentType, setPaymentType ] = useState(state?.activeTab && state.activeTab !== null ? state.activeTab : 'currency');
  const { deliveryMerchantId, pickupMerchantId } = useSelector((state) => state.checkout);

  return (
    //Cart
    <>
        {width > 991 ?
            <section>
                {carts.cart_merchant_info?.length !== 0 && carts.cart_merchant_info !== undefined && Object.entries(carts.cart_merchant_info).map(([merchantName, checkoutItems], index) => {
                    return (
                        <article>
                            {index !== 0 && <section className="mt-5"/> }
                            <section className="card">
                                <table>
                                    { checkoutItems.items?.length > 0 && index === 0 &&
                                        <thead>
                                            <tr>
                                            <th className="_th --product">
                                                <article className="d-flex align-items-center">
                                                    <h3 className="_label --short-title">Product Ordered</h3>
                                                </article>
                                            </th>
                                            <th>Unit Price</th>
                                            <th>Amount</th>
                                            <th style={{ whiteSpace: 'wrap' }}>Item Subtotal</th>
                                            </tr>
                                        </thead>
                                    }
                                    <>
                                    {checkoutItems.items?.length > 0 && 
                                        <tbody>
                                            <tr className="merchant-row">
                                            <td colSpan="6"> 
                                                <h4 className="d-flex align-items-center">                
                                                <Icon
                                                    icon="carbon:store" 
                                                    color="#414141"
                                                    width="20"
                                                    height="20"
                                                    className="pointer me-3"
                                                />
                                                {merchantName}
                                                </h4>   
                                            </td>
                                            </tr>
                                            {checkoutItems.items?.length > 0 && checkoutItems.items?.map((item, itemIndex) => (
                                            <>
                                                <tr>
                                                <td colSpan="6" className="p-3"> 
                                                </td>
                                                </tr>
                                                <tr key={itemIndex}>
                                                <td className="_td --product">
                                                    <section className="d-flex">
                                                        <article className="image-div">
                                                            <LazyLoadImage
                                                                title={item.name}
                                                                alt={item.name}
                                                                src={
                                                                    _.find(item.image, {
                                                                    image_type: "product_image",
                                                                    })?.url
                                                                }
                                                            />
                                                        </article>
                                                        <article className="d-block">
                                                            <p className="pointer">{item.name}</p>
                                                            <p className="pointer _label --brand-name mt-">{item.brand}</p>
                                                        </article>
                                                    </section>
                                                </td>
                                                <td>
                                                    {paymentType === 'currency' && 'RM '} {item.unit_price.toFixed(2)} {paymentType === 'point' && ' pts'}
                                                </td>
                                                <td className="_td --quantity">
                                                    {item.quantity}
                                                </td>
                                                <td>
                                                    {paymentType === 'currency' && 'RM '} {(
                                                    item.total_price
                                                    ).toFixed(2)} {paymentType === 'point' && ' pts'}
                                                </td>
                                                </tr>
                                            </>
                                            ))}
                                        </tbody>
                                    }
                                    </>
                                </table>
                                { checkoutItems.items?.length > 0 && 
                                    <footer>
                                        <section className="bottom-row --shipping">
                                            <article className="col-2 col-lg-3">
                                                <p className="d-flex align-items-center _label --shipping-option justify-content-end">Shipping Option: </p>
                                            </article>

                                            {(deliveryMerchantId.includes(checkoutItems.merchant_id) || pickupMerchantId.includes(checkoutItems.merchant_id)) &&
                                                <article className="col-3 col-lg-2 d-flex justify-content-center">
                                                    <p className="_label --shipping-type"> {deliveryMerchantId.includes(checkoutItems.merchant_id) ? 'Delivery' : pickupMerchantId.includes(checkoutItems.merchant_id) ? 'Self Pickup' : '' } </p>
                                                </article>
                                            }
                                            <article 
                                                className="col-2 col-lg-2 d-flex justify-content-end" 
                                                onClick={() => { 
                                                    dispatch(setShowPurchaseModal(true))
                                                    dispatch(setSelectedSection('quantity'))
                                                    dispatch(setSelectedMerchantId(checkoutItems.merchant_id))
                                                    dispatch(setDeliveryFee(checkoutItems.display_delivery_fee))
                                                    dispatch(setPickupLocation(`${merchantName} - ${checkoutItems.display_address}`))
                                                }}
                                            >
                                                <p 
                                                    className="_label --change-shipping" 
                                                > {deliveryMerchantId.includes(checkoutItems.merchant_id) || pickupMerchantId.includes(checkoutItems.merchant_id) ? 'Change' : 'Select'}
                                                </p> 
                                            </article>

                                            {!changingShipping.changing ?
                                                (deliveryMerchantId.includes(checkoutItems.merchant_id) || pickupMerchantId.includes(checkoutItems.merchant_id)) &&
                                                <article className="col-2 d-flex justify-content-center" style={{ paddingLeft: '1.7em' }}>
                                                    <p className="_label --shipping-type">
                                                        {paymentType === 'currency' && 'RM'} {parseFloat(checkoutItems.delivery_fee).toFixed(2)} {paymentType === 'point' && 'pts'}
                                                    </p> 
                                                </article>
                                            : 
                                                changingShipping.merchant_id === checkoutItems.merchant_id ?
                                                    <Icon
                                                        icon="line-md:loading-twotone-loop"
                                                        width="25px"
                                                        height="25px"
                                                        color="#3db8eb"
                                                        className="ms-5 me-3"
                                                    />
                                                :
                                                (deliveryMerchantId.includes(checkoutItems.merchant_id) || pickupMerchantId.includes(checkoutItems.merchant_id)) &&
                                                <article className="col-2 d-flex justify-content-center" style={{ paddingLeft: '1.7em' }}>
                                                    <p className="_label --shipping-type">
                                                        {paymentType === 'currency' && 'RM'} {parseFloat(checkoutItems.delivery_fee).toFixed(2)} {paymentType === 'point' && 'pts'}
                                                    </p> 
                                                </article>
                                            }
                                        </section>
                                        {!deliveryMerchantId.includes(checkoutItems.merchant_id) && !pickupMerchantId.includes(checkoutItems.merchant_id) && (
                                            <article className="d-flex justify-content-end col-12 mb-3 pe-4">
                                                <article className="_label --error-msg">
                                                    <p className="d-flex gap-2"><Icon icon="jam:alert" color="#dc4c64" />Select one shipping method to proceed order</p>
                                                </article>
                                            </article>
                                        )}
                                        <article className="bottom-row --order-total">
                                            <p className={`d-flex align-items-center _label --order-total d-flex justify-content-end`}>Order Total ({checkoutItems.cart_item_count} items):</p>
                                            <h3 className={`_label --total-span ms-3 col-${width > 1450 ? 2 : width > 1200 ? 3 : width > 1000 ? 4 : 4} d-flex justify-content-center`} style={{ paddingLeft: '1.6em' }}> {paymentType === 'currency' && 'RM '} {parseFloat(checkoutItems.subtotal).toFixed(2)} {paymentType === 'point' && ' pts'}</h3>
                                        </article>
                                    </footer>
                                }
                            </section>
                        </article>
                    )
                })}
            </section>
        : 
            <section>
                {carts.cart_merchant_info?.length !== 0 && carts.cart_merchant_info !== undefined && Object.entries(carts.cart_merchant_info).map(([merchantName, checkoutItems], index) => {
                    return (
                    <article>
                        {index !== 0 && <section className="mt-5"/> }
                        <section className="card">
                        {checkoutItems.items?.length > 0 && index === 0 &&
                            <header className="mobile --header" style={{ background: '#f7f8fa', textAlign: 'left', padding: '1.2em 1em' }}>
                                <h3 className="_label --short-title d-flex align-items-center">
                                    Product Ordered
                                </h3>
                            </header>
                        }
                        {checkoutItems.items?.length > 0 && 
                            <section className="mobile --merchant-row">
                                <h4 className="d-flex align-items-center">                
                                    <Icon
                                    icon="carbon:store" 
                                    color="#414141"
                                    width="20"
                                    height="20"
                                    className="pointer me-3"
                                    />
                                    {merchantName}
                                </h4>   
                            </section>
                        }
                        {checkoutItems.items?.length > 0 && checkoutItems.items?.map((item, itemIndex) => (
                            <section className="row" key={itemIndex}>
                              <article className="mobile --product">
                                <article className="d-flex p-4">
                                    <section className="image-div" style={{ width: '6em', marginRight: '1.5em', minWidth: '6em' }} >
                                        <LazyLoadImage
                                            alt={item.name}
                                            src={
                                                _.find(item.image, {
                                                image_type: "product_image",
                                                })?.url
                                            }
                                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                        />
                                    </section>
                                    <section className="grid-div">
                                        <p className="pointer --name">{item.name}</p>
                                        <p className="pointer _label --brand-name">{item.brand}</p>
                                        <article className="d-flex" style={{ justifyContent: 'space-between' }}>
                                            <p className="pointer">{paymentType === 'currency' && 'RM '} {item.unit_price.toFixed(2)} {paymentType === 'point' && 'pts '}</p>
                                            <p className="pointer" style={{ fontSize: '14px' }}>x{item.quantity}</p>
                                        </article>
                                    </section>
                                </article>
                              </article>
                            </section>
                        ))}
                        {checkoutItems.items?.length > 0 && 
                            <footer>
                                <section className="bottom-row --shipping"
                                    onClick={() => { 
                                        dispatch(setShowPurchaseModal(true))
                                        dispatch(setSelectedSection('quantity'))
                                        dispatch(setSelectedMerchantId(checkoutItems.merchant_id))
                                        dispatch(setDeliveryFee(checkoutItems.display_delivery_fee))
                                        dispatch(setPickupLocation(checkoutItems.display_address))
                                    }}
                                >
                                    <article className={`col-${ !deliveryMerchantId.includes(checkoutItems.merchant_id) && !pickupMerchantId.includes(checkoutItems.merchant_id) ? 5 : 4}`}>
                                        <p className="d-flex justify-content-end align-items-center _label --shipping-option">Shipping Option: </p>
                                    </article>

                                    {(deliveryMerchantId.includes(checkoutItems.merchant_id) || pickupMerchantId.includes(checkoutItems.merchant_id)) &&
                                        <article className="col-3 d-flex justify-content-center">
                                            <p className="_label --shipping-type"> {deliveryMerchantId.includes(checkoutItems.merchant_id) ? 'Delivery' : pickupMerchantId.includes(checkoutItems.merchant_id) ? 'Self Pickup' : '' } </p>
                                        </article>
                                    }
                                    {(!deliveryMerchantId.includes(checkoutItems.merchant_id) && !pickupMerchantId.includes(checkoutItems.merchant_id)) &&
                                        <article 
                                            className="col-4 d-flex justify-content-end" 
                                            onClick={() => { 
                                                dispatch(setShowPurchaseModal(true))
                                                dispatch(setSelectedSection('quantity'))
                                                dispatch(setSelectedMerchantId(checkoutItems.merchant_id))
                                                dispatch(setDeliveryFee(checkoutItems.display_delivery_fee))
                                                dispatch(setPickupLocation(checkoutItems.display_address))
                                            }}
                                        >
                                        <p 
                                            className="_label --change-shipping" 
                                        > {deliveryMerchantId.includes(checkoutItems.merchant_id) || pickupMerchantId.includes(checkoutItems.merchant_id) ? 'Change' : 'Select'}</p> 
                                        </article>
                                    }
                                    {!changingShipping.changing ?
                                        (deliveryMerchantId.includes(checkoutItems.merchant_id) || pickupMerchantId.includes(checkoutItems.merchant_id)) &&
                                        <article className="col-4 d-flex justify-content-center" style={{ paddingLeft: '1.7em' }}>
                                            <p className="_label --shipping-type">{paymentType === 'currency' && 'RM'} {parseFloat(checkoutItems.delivery_fee).toFixed(2)} {paymentType === 'point' && 'pts'}</p> 
                                        </article>
                                    : 
                                        changingShipping.merchant_id === checkoutItems.merchant_id ?
                                            <Icon
                                                icon="line-md:loading-twotone-loop"
                                                width="25px"
                                                height="25px"
                                                color="#3db8eb"
                                                className="ms-5 me-3"
                                            />
                                        :
                                        (deliveryMerchantId.includes(checkoutItems.merchant_id) || pickupMerchantId.includes(checkoutItems.merchant_id)) &&
                                        <article className="col-2 d-flex justify-content-center" style={{ paddingLeft: '1.7em' }}>
                                            <p className="_label --shipping-type">{paymentType === 'currency' && 'RM'} {parseFloat(checkoutItems.delivery_fee).toFixed(2)} {paymentType === 'point' && 'pts'}</p> 
                                        </article>
                                    }
                                    <article className="col-1 d-flex justify-content-end" >
                                        <Icon
                                            icon="mingcute:right-line" 
                                            color="#707070"
                                            width="18"
                                            height="18"
                                            className="pointer"
                                        />
                                    </article>
                                </section>
                                {!deliveryMerchantId.includes(checkoutItems.merchant_id) && !pickupMerchantId.includes(checkoutItems.merchant_id) && (
                                    <article className="d-flex justify-content-end col-10 pb-3 pe-5 w-100" style={{ backgroundColor: '#f7f8fa' }}>
                                        <article className="_label --error-msg">
                                            <p className="d-flex gap-2"><Icon icon="jam:alert" color="#dc4c64" />Select one shipping method to proceed order</p>
                                        </article>
                                    </article>
                                )}
                                <article className="bottom-row --order-total">
                                    <p className="d-flex align-items-center _label --order-total d-flex justify-content-end">Order Total ({checkoutItems.items.length} items):</p>
                                    <h3 className="_label --total-span ms-3 col-sm-3 col-md-3 col-xs-5 d-flex justify-content-end" style={{ paddingLeft: '1.6em' }}> {paymentType === 'currency' && 'RM '} {parseFloat(checkoutItems.subtotal).toFixed(2)} {paymentType === 'point' && 'pts'}</h3>
                                </article>
                            </footer>
                        }
                        </section>
                    </article>
                    )
                })}
            </section>
        }
    </>               
  );
}
