import { useState } from "react";

//redux
import { useDispatch, useSelector } from "react-redux";
import { setSelectedSection, setShowPurchaseModal } from "../../../redux/reducer/stockReducer";

//assets
import { Logo2 } from "../../../assets/images";

//lib
import _ from "lodash";
import { Icon } from "@iconify/react";
import { useFormikContext } from "formik";
import { useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MDBBtn, MDBCard, MDBCol, MDBRow } from "mdb-react-ui-kit";

//hook
import useWindowDimensions from "../../../components/common/window-dimension";

export default function CheckoutPayment({ totalPrices, cleanedNumber }) {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { width } = useWindowDimensions();

  const { carts, paymentMethod } = useSelector((state) => state.cart);
  const [ paymentType, setPaymentType ] = useState(state?.activeTab && state.activeTab !== null ? state.activeTab : 'currency');
  const { walletBalance } = useSelector((state) => state.stock);
  const { paymentStatus } = useSelector((state) => state.checkout);

  const { errors } = useFormikContext();

  return (
    <section>
        <article className="payment-section mt-3">
            <header className="d-flex align-items-center">
                <section className="short-line" />
                <h2 className="_label --short-title">Payment method</h2>
            </header>
            <p className="_label --short-subtitle ms-3 mt-2">
                We'll only contact you about updates to your order
            </p>
            <article className="info-div mt-4">
            {paymentType !== "point" ?
                <>
                {!paymentMethod.name ? 
                    <section
                        className="d-flex add-div"
                        onClick={() => {
                        dispatch(setShowPurchaseModal(true))
                        dispatch(setSelectedSection('method'))
                        }}
                    >
                        <Icon
                        icon="carbon:add-alt"
                        color="#707070"
                        width={15}
                        height={15}
                        />
                        <p className="_label --add ms-2">Choose</p>
                    </section>
                : 
                    <>
                    <section
                        className="d-flex add-div"
                        onClick={() => {
                        dispatch(setShowPurchaseModal(true))
                        dispatch(setSelectedSection('method'))
                        }}
                    >
                        <p className="_label --add ms-2">Choose</p>
                    </section>
                    <section className="mt-4">
                        <section
                            style={{ fontSize: "12px" }}
                            className="row d-flex align-items-center ms-2"
                        >
                            <article className="col-lg-2 col-md-1 col-sm-1 col-2 d-flex justify-content-center">
                                <section className="method-div --choose" style={{ width: '100%' }}>
                                    <LazyLoadImage src={paymentMethod.image} alt={paymentMethod.name} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                </section>
                            </article>
                            <p className="col-lg-7 col-md-7 col-6">
                                {paymentMethod.name}
                            </p>
                        </section>
                    </section>
                    </>
                
                }
                </>
            : 
                <>
                    <section className="mt-4">
                    <section
                        style={{ fontSize: "12px" }}
                        className="row d-flex align-items-center"
                    >
                        <article className="col-lg-2 col-md-1 col-sm-1 col-2">
                        <section className="payment-logo" style={{ width: '100%' }}>
                            <LazyLoadImage src={Logo2} alt="reunion-point" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
                        </section>
                        </article>
                        <article className="col-lg-5 col-md-7 col-4">
                        Reunion Point
                        </article>
                        <article className="col-lg-5 col-md-3 col-6">
                        Balance: {walletBalance?.point_balance ? walletBalance.point_balance : 0}
                        </article>
                    </section>
                    {walletBalance.point_balance !== undefined && totalPrices >= cleanedNumber &&
                        <section className="_label --error-msg no-padding mt-3">
                            <p className="d-flex gap-2"><Icon icon="jam:alert" color="#dc4c64" />Sorry, your Reunion Point is insufficient</p>
                        </section>
                    }
                    </section>
                </>
            }
            {errors && errors.payment_method && paymentType !== "point" && (
                <section className="_label --error-msg no-padding mt-3">
                    <p className="d-flex gap-2"><Icon icon="jam:alert" color="#dc4c64" />{errors.payment_method}</p>
                </section>
            )}
            {!errors.payment_method && paymentType !== "point" &&
                <p className='_label --short-subtitle ms-1 mt-4'>
                    Note: You will get{' '}
                    <span className='_label --short-span' style={{ color: '#26b5f3' }}> {parseInt(carts?.totalCartSubtotal ? carts.totalCartSubtotal : 0 )} points </span>
                    from this purchase after payment success
                </p>
            }
            </article>
        </article>
        <article className="subtotal-section mt-5">
            <header className="d-flex align-items-center">
                <section className="short-line" />
                <h2 className="_label --short-title">Order Summary</h2>
            </header>
            <section className="card mt-4" style={{ padding: '1em 2em'}}>
                <article>
                    <article className="col-12 text-end"> 
                        <article className="d-flex mt-4 _label --order-total">
                            <p className={`col-${width > 1500 ? 8 : width > 1300 ? 7 : width > 991 ? 6 : width < 450 ? 6 : 9}`}>
                                Merchandise Subtotal:
                            </p>
                            <p className={`d-flex justify-content-end col-${width > 1500 ? 4 : width > 1300 ? 5 : width > 991 ? 6 : width < 450 ? 6 : 3}`}>
                                {paymentType === "currency" && "RM "} {parseFloat(carts?.totalCartSubtotal ? carts.totalCartSubtotal : 0).toFixed(2)} {paymentType === "point" && "pts "}
                            </p>
                        </article>
                        <article className="d-flex mt-4 _label --order-total">
                            <p className={`col-${width > 1500 ? 8 : width > 1300 ? 7 : width > 991 ? 6 : width < 450 ? 6 : 9}`}>
                                Shipping Total:
                            </p>
                            <p className={`d-flex justify-content-end col-${width > 1500 ? 4 : width > 1300 ? 5 : width > 991 ? 6 : width < 450 ? 6 : 3}`}>
                                {paymentType === "currency" && "RM "} {parseFloat(carts?.totalCartShipping ? carts.totalCartShipping : 0).toFixed(2)} {paymentType === "point" && "pts "}
                            </p>
                        </article>
                        <article className="d-flex mt-4 align-items-center">
                            <p className={`col-${width > 1500 ? 8 : width > 1300 ? 7 : width > 991 ? 6 : width < 450 ? 6 : 9} _label --order-total`}>
                                Total Payment:
                            </p>
                            <p className={`d-flex justify-content-end col-${width > 1500 ? 4 : width > 1300 ? 5 : width > 991 ? 6 : width < 450 ? 6 : 3}`}>
                                {paymentType === "currency" && "RM "} {parseFloat(carts?.totalCartTotal ? carts.totalCartTotal : 0).toFixed(2)} {paymentType === "point" && "pts "}
                            </p>
                        </article>
                        <article className="mt-5"><MDBBtn type="submit" className="place-order-btn" disabled={paymentStatus}>Place Order</MDBBtn></article>
                    </article>
                </article>
            </section>
        </article>
    </section>               
  );
}
