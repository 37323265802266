//lib
import _ from "lodash";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import { Formik, Form } from "formik";
import {
  MDBBtn,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
} from "mdb-react-ui-kit";

//redux
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedSection,
  setShippingMethod,
  setShowPurchaseModal,
} from "../../../redux/reducer/stockReducer";
import { setIsEditedInfo } from "../../../redux/reducer/cartReducer";
import { setDeliveryOrPickup } from "../../../redux/reducer/checkoutReducer";

//component
import ToggleSwitch from "../../../components/element/toggleSwitch";

export default function ChooseQuantity({  }) {
  const dispatch = useDispatch();

  const { shippingMethod } = useSelector((state) => state.stock);
  const { shippingInfo, isEditedInfo } = useSelector((state) => state.cart);
  const { selectedMerchantId, deliveryFee, deliveryMerchantId, pickupMerchantId, pickupLocation } = useSelector((state) => state.checkout);
  
  const initialValues = {
    properties: shippingInfo ? shippingInfo : '',
  };

  let useSchema;

  // if (shippingMethod === "Delivery") {
  //   useSchema = Yup.object({
  //     properties: Yup.object().required("Please add shipping info"),
  //   });
  // } else {
  // }

  function dispatchSetShippingMethod(type, shipping) {
    dispatch(setShippingMethod(type));
  }

  const handleSubmit = async ({ values, setFieldError, errors }) => {
    if (shippingMethod === "Delivery") {
      if (values.properties.name) {
        dispatch(setDeliveryOrPickup(true));
        dispatch(setShowPurchaseModal(false));
      } else {
        setFieldError('properties', "Please add shipping info")
      }
    } else {
      dispatch(setDeliveryOrPickup(false));
      dispatch(setShowPurchaseModal(false));
    }
  };

  return (
    <article>
      <Formik
        initialValues={initialValues}
        validationSchema={useSchema}
        enableReinitialize={true}
        validateOnChange={true}
        onSubmit={(values, { errors, setFieldError }) => {
          handleSubmit({ values, errors, setFieldError });
        }}
      >
        {({
          setFieldValue,
          isSubmitting,
          handleReset,
          isValid,
          errors,
          values,
          handleSubmit,
        }) => (
          <Form>
            <MDBModalBody
              className="purchase-modal-body"
              style={{ height: "auto" }}
            >
              <header>
                <ToggleSwitch
                  optionOne="Delivery"
                  optionTwo="Self Pickup"
                  setShippingMethod={dispatchSetShippingMethod}
                  shippingMethod={shippingMethod}
                />
              </header>
              {shippingMethod === "Self Pickup" ? (
                <section className="row mt-5">
                  <article className="d-flex align-items-center">
                    <article className="short-line" />
                    <h3 className="_label --short-title">Self Pick Up</h3>
                  </article>
                  <article className="info-div mt-4">
                    <article className="_label --short-subtitle">Pick up location at
                      <br/>
                      <article className='d-flex mt-2'>
                        <figure className="d-flex align-items-center justify-content-center p-0">
                          <Icon icon="ion:location-outline" color="#3db8ed" width="18" height="18" />
                        </figure>
                        <article className="ms-2">
                          <span>{pickupLocation}</span>
                        </article>
                      </article>
                    </article>
                  </article>
                </section>
              ) : (
                ""
              )}
              {shippingMethod === "Delivery" ? (
                <section className="row mt-4">
                  <article className="d-flex align-items-center mt-4">
                    <article className="short-line" />
                    <h3 className="_label --short-title">Shipping info</h3>
                  </article>
                  <p className="_label --short-subtitle ms-3">
                    we'll only contact you about updates to your order
                  </p>
                  <article
                    className={
                      _.isEmpty(shippingInfo) 
                        ? "info-div mt-4"
                        : "info-div --blue mt-4"
                    }
                  >
                    <header> 
                      <p className="_label --cost ms-2 mb-4">
                        <Icon
                          icon="iconoir:delivery-truck"
                          color="#707070"
                          width={20}
                          height={20}
                          className="me-3 mb-1"
                        />
                        {deliveryFee !== 0 ? 
                          'Cost: RM ' + parseFloat(deliveryFee).toFixed(2) 
                        : 'Free shipping'
                        } 
                      </p>
                    </header>
                    {_.isEmpty(shippingInfo) && _.isEmpty(isEditedInfo) ? (
                      <section
                        className="d-flex add-div"
                        onClick={() => {
                          dispatch(setSelectedSection("contact"));
                          dispatch(setIsEditedInfo(true));
                        }}
                      >
                        <Icon
                          icon="carbon:add-alt"
                          color="#707070"
                          width={15}
                          height={15}
                        />
                        <p className="_label --add ms-2">Add</p>
                      </section>
                    ) : (
                      <>
                        <section className="d-flex">
                          <section className="d-flex username-div me-2">
                            <p className="_label --username ms-2">
                              Username
                            </p>
                          </section>
                          <section
                            className="d-flex add-div"
                            onClick={() => {
                              dispatch(setSelectedSection("contact"));
                              dispatch(setIsEditedInfo(true));
                            }}
                          >
                            <p className="_label --add ms-2">Edit</p>
                          </section>
                        </section>
                        <section className="mt-2">
                          {shippingInfo && Object.entries(shippingInfo.length > 0 ? shippingInfo[0] !== null ? shippingInfo[0] : shippingInfo : shippingInfo).map(([key, value]) => (
                            <article key={key} className="d-flex mt-3">
                              <p className="col-4 _label --form-data">
                                {key}
                              </p>
                              <p className="col-8 _label --form-data">
                                {value}
                              </p>
                            </article>
                          ))}
                        </section>
                      </>
                    )}
                    {errors && errors.properties && (
                      <section
                        className="_label --error-msg no-padding mt-3"
                      >
                        <p className="d-flex gap-2"><Icon icon="jam:alert" color="#dc4c64"/>{errors.properties}</p>
                      </section>
                    )}
                  </article>
                </section>
              ) : (
                ""
              )}
            </MDBModalBody>
            <MDBModalFooter className="purchase-modal-footer mt-5">
              <footer className="row d-flex justify-content-center mt-5" style={{ marginBottom: '4em'}}>
                {shippingMethod === 'Delivery' &&
                  <MDBBtn
                    type="submit"
                    className="_button-group --pay-btn"
                    disabled={errors.properties || deliveryMerchantId.includes(selectedMerchantId)}
                  >
                    {deliveryMerchantId.includes(selectedMerchantId) ? 'Selected' : `Select ${shippingMethod} Option`}
                  </MDBBtn>
                }
                {shippingMethod === 'Self Pickup' &&
                  <MDBBtn
                    type="submit"
                    className="_button-group --pay-btn"
                    disabled={pickupMerchantId.includes(selectedMerchantId)}
                  >
                    {pickupMerchantId.includes(selectedMerchantId) ? 'Selected' : `Select ${shippingMethod} Option`}
                  </MDBBtn>
                }
              </footer>
            </MDBModalFooter>
          </Form>
        )}
      </Formik>
    </article>
  );
}
