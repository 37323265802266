import React, { useState, useEffect } from 'react'

//css
import '../../assets/css/toggleSwitch.css'

export default function ToggleSwitch({ optionOne = '', optionTwo = '', setShippingMethod, shippingMethod }) {
  
  const [toggle, setToggle] = useState(shippingMethod === 'Self Pickup' ? false : true)
  const [toggleChanged, setToggleChanged] = useState(false);

  useEffect(() => {
    if (toggle === false) {
        setShippingMethod('Self Pickup')
    } else if (toggle === true) {
        setShippingMethod('Delivery')
    }
  }, [toggle])

  return (
    <section className="toggleSwitchContainer">
      <article className="toggleSwitch">
        <section className={toggle ? 'toggle' : 'toggle-optionTwo'}>
          <article className={toggleChanged ? "switch" : "switch --no-animation" } id={toggleChanged ? toggle ? 'active-left-toggled' : 'active-toggled' : ''}></article> 
          <article
            className={toggleChanged ? toggle ? 'optionOne' : 'optionOne-white' : toggle ? 'optionOne --no-animation' : 'optionOne-white'}
            onClick={() => {
              setToggleChanged(true);
              if(!toggle) {
                setToggle(!toggle)
              }
            }}>
            {optionOne}
          </article>
          <article
            className={toggleChanged ? !toggle ? 'optionTwo' : 'optionTwo-white' : !toggle ? 'optionTwo --no-animation' : 'optionTwo-white'}
            onClick={() => {
              setToggleChanged(true);
              if(toggle) {
                setToggle(!toggle)
              }
            }}>
            {optionTwo}
          </article>
        </section>
      </article>
    </section>
  )
}
