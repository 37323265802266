//lib
import moment from "moment";

//hook
import useWindowDimensions from "../common/window-dimension";

//redux
import { useSelector } from "react-redux";
import { useEffect } from "react";

export default function useDatePicker(
  selectedHistoryDate,
  userSelectedBookingDate
) {
  const { width } = useWindowDimensions();
  const { disableCalendar, calendar_loading } = useSelector((state) => state.car);
  const { booked_at } = useSelector((state) => state.car.report);

  const length = width >= 991 ? 4 : 3;

  const today = new Date();
  const todayNumber = moment(new Date()).format("D");
  const currentMonth = moment(new Date()).format("MM");
  const selectedMonth = moment(selectedHistoryDate).format("MM");

  const calculateDifferentDate =
    todayNumber - moment(selectedHistoryDate).format("DD");

  let label = "";
  if (currentMonth === selectedMonth && calculateDifferentDate === 0) {
    label = "Today, ";
  } else if (currentMonth === selectedMonth && calculateDifferentDate === -1) {
    label = "Tomorrow, ";
  } else if (currentMonth === selectedMonth && calculateDifferentDate === 1) {
    label = "Yesterday, ";
  }

  const result = [];
  for (let i = -length; i <= length; i++) {
    const currentDate = new Date(selectedHistoryDate);
    currentDate.setDate(selectedHistoryDate.getDate() + i);
    let object = "";
    if (i === 0) {
      object = {
        value: currentDate,
        formatDate: moment(currentDate).format("DD-MM-YYYY"),
        label: label + moment(currentDate).format("DD MMM"),
        default: true,
      };
    } else {
      if (
        moment(booked_at).format("DD MMM") >
        moment(currentDate).format("DD MMM")
      ) {
        object = {
          value: currentDate,
          formatDate: moment(currentDate).format("DD-MM-YYYY"),
          label: moment(currentDate).format("D"),
          history: true,
        };
      } else {
        object = {
          value: currentDate,
          formatDate: moment(currentDate).format("DD-MM-YYYY"),
          label: moment(currentDate).format("D"),
        };
      }
    }

    const objectVal = object;

    result.push(objectVal);
  }

  var timeOption = [];
  const startTime = 10;
  const endTime = 15    ;
  const currentHour = moment(today).format("H");

  const formatedDateUserSelectedBookingDate = moment(
    userSelectedBookingDate
  ).format("YYYY-MM-DD");

  const disabledTimes = disableCalendar
    .filter((item) => item.date === formatedDateUserSelectedBookingDate)
    .map((item) => parseInt(item.time));

  for (let i = startTime; i <= endTime; i++) {
    let shouldAddTimeOption = true;

    if(disableCalendar.length > 0) {
      const matchingData = disableCalendar.find(
        (item) =>
          formatedDateUserSelectedBookingDate === item.date &&
          parseInt(item.time) === i
      );
    
      if (matchingData) {
        shouldAddTimeOption = false;
      }
    }

    if (shouldAddTimeOption) {
      timeOption.push({
        value: i,
        label: `${i}:00`,
      });
    }
  }

  if(!calendar_loading) {
    return { result, disableCalendar, timeOption };
  } else {
    return { result };
  }
}
