//lib
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MDBCol, MDBModalBody, MDBRow } from "mdb-react-ui-kit";

//assets
import BankImg from "../../../assets/images/bank_img.webp"

//reducer
import { useDispatch } from "react-redux";
import {
  setBankCode,
  setShowPurchaseModal,
} from "../../../redux/reducer/stockReducer";
import {
  setPaymentMethod,
} from "../../../redux/reducer/cartReducer";
import { useEffect } from "react";
import { toast } from "react-toastify";

export default function SelectPaymentMethod({ data, bankData, loading }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if(!loading && bankData.length === 0) {
      dispatch(setShowPurchaseModal(false));
      toast.error("No available banks due to third party PG issue, please contact IT support.", {
        autoClose: 3000,
        theme: "light",
        hideProgressBar: true,
        closeOnClick: true,
        closeButton: false,
      });
    }
  }, [bankData])

  return (
    <article>
      <MDBModalBody
        className="purchase-modal-body"
        style={{ height: "auto" }}
      >
        <ul className="p-0">
          {bankData?.length > 0 &&
            bankData.map((item, index) => (
              item.name &&
              <li 
                key={index}
                className="row mt-4 d-flex align-items-center p-2" 
                onClick={() => {
                  dispatch(setPaymentMethod(item));
                  dispatch(setBankCode(item.code));
                  dispatch(setShowPurchaseModal(false));
                }}
              >
                <article className="col-2">
                  <section className="method-div">
                    <LazyLoadImage src={item.image ? item.image : BankImg} alt={item.name ? item.name : 'reunion-bank'} />
                  </section>
                </article>
                <article className="col-8 text-start">
                  <p className="_label --method_name ps-3">{item.name ? item.name : 'Unknown Bank'}</p>
                </article>
                <article className="col-2 text-end">
                  <section>
                    <input
                      type="radio"
                      name="paymentMethod"
                      onChange={() => {
                        dispatch(setPaymentMethod(item));
                        dispatch(setBankCode(item.code));
                        dispatch(setShowPurchaseModal(false));
                      }}
                    />
                  </section>
                </article>
              </li>
          ))}
        </ul>
      </MDBModalBody>
    </article>
  );
}
