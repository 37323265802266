//lib
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";

export default function Notify({ item }) {

  return (
    <section className="notify-div mt-1 mb-1">
      {item.map((notify_item, index) => {
        return (
          <section className={`row d-flex ${item.length > 1 ? 'align-items-start' : 'align-items-center'}`} key={index}>
            <article className="col-1 text-center">
              <Icon icon="icon-park-outline:volume-notice" className="icon" />
            </article>
            <article className="col-11">
              <p className="_label">{notify_item}</p>
            </article>
          </section>
        );
      })}
    </section>
  );
}
