import { useEffect, useRef, useState } from "react";

//redux
import { useDispatch, useSelector } from "react-redux";

//reducer
import { getNotification } from "../../redux/reducer/forumReducer";
import { openNotificationButton } from "../../redux/reducer/commonReducer";

//assets
import NotificationIcon from "../../assets/json/notifications.json";

//lib
import moment from "moment";
import Lottie from "lottie-react";
import { MDBRow } from "mdb-react-ui-kit";
import { Link, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

//hook
import useWindowDimensions from "../common/window-dimension";
import { DefaultAvatar } from "../../assets/images";

export default function NotificationModal() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [length, setLength] = useState(10);
  const [data, setData] = useState([]);
  const [start, setStart] = useState(0);
  const token = localStorage.getItem("token");
  const [loadingStatus, setLoadingStatus] = useState(false);
  const { notification_total_count } = useSelector((state) => state.forum);
  const { notificationButton } = useSelector((state) => state.common);

  const notificationModalRef = useRef(null);

  const renderText = (type) => {
    switch (type) {
      case "like comment":
        return `like your comment.`;
      case "like post":
        return `like your post.`;
      case "comment on post":
        return `comment on your post.`;
      case "reply on comment":
        return `reply your comment.`;
      default:
        return "like your comment.";
    }
  };

  useEffect(() => {
    if (token && notificationButton) {
      setLoadingStatus(true);
      dispatch(getNotification({ length: length, start: start }))
        .unwrap()
        .then((res) => {
          setLoadingStatus(false);
          localStorage.setItem("new_notification", false);
          if (start >= 10) { 
            setData([...data, ...res.data.aaData]);
          } else {
            setData(res.data.aaData);
          }
        });
    }
  }, [notificationButton, length, start]);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (
  //       notificationButton &&
  //       notificationModalRef.current &&
  //       !notificationModalRef.current.contains(event.target)
  //     ) {
  //       dispatch(openNotificationButton(false));
  //     }
  //   };

  //   document.addEventListener("click", handleClickOutside);
  
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };

  // }, [notificationButton]);

  return (
    <>
      {notificationButton &&
        <article
          ref={notificationModalRef}
          className={`notification-modal ${
            notificationButton
              ? window.location.pathname === "/forum"
                ? "--forum-show"
                : "--show"
              : ""
          }`}
          style={{
            top:
              window.location.pathname === "/forum"
                ? "5em"
                : width >= 991
                ? "6em"
                : "8em",
            right:
              window.location.pathname === "/forum"
                ? "1em"
                : width >= 991
                ? "2.5em"
                : "1em",
          }}
        >
          <header>
            <h2 className="_label --notification">Notification</h2>
          </header>
          <section
            className="notification-content-row"
            style={{
              paddingBottom: data.length === 0 ? "0em" : "2em",
            }}
          >
            {data.length === 0 && (
              <section className="m-0 justify-content-center lottie-container">
                <Lottie animationData={NotificationIcon} />
                <p>You have no notifications right now.</p>
                <p>Come back later.</p>
              </section >
            )}
            {data.length > 0 &&
              data.map((item, index) => (
                <Link
                  key={index}
                  className="row content-box m-0"
                  onClick={(e) => {
                    e.preventDefault();

                    navigate(`${window.location.pathname}?post=${item.post_id}`);
                    dispatch(openNotificationButton(false));
                  }}
                >
                  <article className="icon" style={{ cursor: "pointer" }}>
                    <LazyLoadImage 
                      className="avatar-icon" 
                      src={item.avatar ? item.avatar : DefaultAvatar} 
                      alt="reunion-avatar"
                      title="reunion-avatar" 
                    />
                  </article>
                  <article
                    style={{
                      padding: 0,
                      display: "grid",
                      lineHeight: "14px",
                      width: "auto",
                    }}
                  >
                    <p
                      className="_label --content"
                      style={{ cursor: "pointer" }}
                    >
                      {item.username} <span>{renderText(item.action)}</span>
                    </p>
                    <p className="_label --time">{item.created_at}</p>
                  </article>
                </Link>
              ))}
            {notification_total_count > data.length && (
              <section className="d-flex justify-content-center mt-5">
                <p
                  className="_label --content"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (!loadingStatus) {
                      setStart(start + 10);
                    }
                  }}
                >
                  see more
                </p>
              </section>
            )}
          </section>
        </article>
      }
    </>
  );
}
