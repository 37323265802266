import http from './http.services'

http.setToken(getToken())
// process.env.REACT_APP_API_URL

async function login(payload) {
  const { data } = await http.post(process.env.REACT_APP_API_URL + '/login', payload)
  return data
}

async function register(payload) {
  const { data } = await http.post(process.env.REACT_APP_API_URL + '/register', payload)
  return data
}

async function sendVerificationCode(payload) {
  const { data } = await http.post(process.env.REACT_APP_API_URL + `/send_verification_code`, payload)
  return data
}

function getToken() {
  return localStorage.getItem('token')
}

async function logout() {
  localStorage.clear()
}

function getCurrentUser() {
  const token = localStorage.getItem('token')
  if (token) return { token }
  else return ''
}

const authService = {
  login,
  register,
  sendVerificationCode,
  logout,
  getToken,
  getCurrentUser,
};

export default authService