import { useEffect } from "react";

//lib
import { Icon } from "@iconify/react";
import { EffectCreative } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

//assets
import NoImage from "../../../assets/images/no_image.webp"

//hook
import useWindowDimensions from "../../../components/common/window-dimension";

//redux
import { useDispatch, useSelector } from "react-redux";
import { openModalCarReport } from "../../../redux/reducer/modalReducer";

export function CarReport() {
  const { report } = useSelector((state) => state.car);

  const dispatch = useDispatch()
  const { width } = useWindowDimensions();
  const { id } = useParams()
  const navigate = useNavigate();
  const params = useParams();

  const autoOpenModal = {
    report: openModalCarReport(id)
  };

  useEffect(() => {
    if (params.state) {
      dispatch(autoOpenModal[params.state]);
    }
  }, []);

  const renderReportCard = (item) => {
    return (
      <Link
        className="card"
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          e.preventDefault();

          dispatch(autoOpenModal['report']);
          navigate(`/car/${item.id}/report`);
        }}
      >
        <section className="image-div">
          <LazyLoadImage
            src={item.brand_image ? item.brand_image : NoImage}
            title={item.brand}
            alt={item.brand}
            style={{ 
              width: item.brand_image ? '100%' : '100%',
              height: item.brand_image ? '100%' : '',
            }}
          />
        </section>
        <section className="card-body">
          <section style={{ minHeight: '138px' }}>
          <article className="row m-0 detail mt-2">
            <article className="col">
              <p className="report-label">Car Plate No</p>
            </article>
            <article className="col text-end">
              <p className="value-label">{item.car_plate}</p>
            </article>
          </article>
          <article className="row m-0 detail">
            <article className="col">
              <p className="report-label">Chassis No</p>
            </article>
            <article className="col text-end">
              <p className="value-label">{item.chassis_no}</p>
            </article>
          </article>
          <article className="row m-0 detail d-flex" style={{ alignItem: 'baseline' }}>
            <article className="col">
              <p className="report-label">Brand & Model</p>
            </article>
            <article className="col text-end" style={{ overflow: 'hidden' }}>
              <p className="model-label">{item.brand}</p>
            </article>
          </article>
          <article className="row m-0 detail">
            <article className="col">
              <p className="report-label">Model Year</p>
            </article>
            <article className="col text-end">
              <p className="value-label">{item.year}</p>
            </article>
          </article>
          </section>
          <footer className="title-box">
            <h3 className="title mb-2">My Diagnose Report</h3>
            <p className="detail"><Icon icon="ant-design:field-time-outlined" className="pe-2"/>Update at {item.updated_at}</p>
          </footer>
        </section>
      </Link>
    );
  };

  if (report.inspection_history.length > 0) {
    return (
      <article className="car-report-container">
        <header className="row home-title-row --car-inspection">
          <h2>My Car Report</h2>
        </header>
        {width >= 450 ? (
          <section>
            <Swiper
              slidesPerView={
                width >= 1400
                  ? 4
                  : width >= 1200
                  ? 3
                  : width >= 800
                  ? 3
                  : width >= 700
                  ? 2.8
                  : width >= 620
                  ? 2.5
                  : width >= 400
                  ? 2
                  : 1.5
              }
              spaceBetween={15}
              grabCursor={true}
              creativeEffect={{
                prev: {
                  shadow: false,
                  translate: ["-120%", 0, -500],
                },
                next: {
                  shadow: false,
                  translate: ["120%", 0, -500],
                },
              }}
              modules={[EffectCreative]}
            >
              {report.inspection_history.map((item, index) => (
                <SwiperSlide key={index}>{renderReportCard(item)}</SwiperSlide>
              ))}
            </Swiper>
          </section>
        ) : (
          <section className="row report-car-row-mobile">
            {report.inspection_history.map((item, index) => (
              <article className="col-12 p-0" key={index}>
                <SwiperSlide key={index}>{renderReportCard(item)}</SwiperSlide>
              </article>
            ))}
          </section>
        )}
      </article>
    );
  }
}
