import { useEffect } from "react";

//lib
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { MDBModalBody, MDBRow, MDBCol } from "mdb-react-ui-kit";

//hook
import useWindowDimensions from "../../../components/common/window-dimension";

//redux
import { useDispatch, useSelector } from "react-redux";
import { getOrderHistory } from "../../../redux/reducer/orderReducer";
import { closeAllModal } from "../../../redux/reducer/modalReducer";
import { setAnimationModal } from "../../../redux/reducer/commonReducer";

//component
import EmptyPage from "./emptyPage";
import OredrCard from "./orderCard";
import ModalBox from "../../../components/modal/full.modal.box";
import InfoHeader from "../../../components/header/info-header";
import Notify from "../../../components/common/notify-component";


export default function PurchaseModal() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { isOpenOrder } = useSelector((state) => state.modal);
  const { orderHistory, loading } = useSelector((state) => state.order);

  const data = [
    "Every Purchase you made can earn 1 Point : RM 1",
    "Some info only allowed limited change attempt. Please make sure to fill in the correct information.",
  ];

  const backButton = () => {
    dispatch(closeAllModal());
    dispatch(setAnimationModal(true));
    navigate("/profile");
  };

  useEffect(() => {
    dispatch(getOrderHistory({ order_type: "product" }));
  }, []);
  
  return (
    <ModalBox
      className={"modal-container"}
      show={isOpenOrder}
      centered
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      scrollable
      backButton={backButton}
      content={
        <article>
          <InfoHeader
            showBackButton={true}
            title="Purchase History"
            backButton={backButton}
          />
          <MDBModalBody className="p-0">
            <section className={width >= 991 ? "order-div desktop" : "order-div"}>
              <Notify item={data} />
              <header className="row tag-row">
                <section className="col tag d-flex">
                  <article className="short-div" />
                  <h3 className="_label --title">
                    Your Item is on the way under with our care
                  </h3>
                </section>
              </header>
              {loading ? (
                <Icon
                  icon="line-md:loading-twotone-loop"
                  height={"4em"}
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    marginLeft: "auto",
                    marginRight : "auto",
                    marginTop: "10em",
                    width: '100%'
                  }}
                />
              ) : orderHistory && orderHistory.length > 0 ? (
                orderHistory.map((item, index) => {
                  return <OredrCard item={item} key={index} />;
                })
              ) : (
                <EmptyPage name="purchase" />
              )}
            </section>
          </MDBModalBody>
        </article>
      }
    />
  );
}
