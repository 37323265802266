import { useEffect, useState } from "react";

//lib
import { Icon } from "@iconify/react";
import { MDBBtn } from "mdb-react-ui-kit";

//redux
import { useDispatch, useSelector } from "react-redux";
import { openNotificationButton } from "../../redux/reducer/commonReducer";

//socket
import Pusher from "pusher-js";

// Pusher.logToConsole = true;

export function ButtonNotification() {
  const [show, setShow] = useState(false);
  const newNotification = localStorage.getItem("new_notification");
  const username = localStorage.getItem("username");
  const user_id = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");
  const { notificationButton } = useSelector((state) => state.common);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user_id) {
      const pusher = new Pusher(process.env.REACT_APP_SOCKET_API_KEY, {
        cluster: "ap1",
        authEndpoint: process.env.REACT_APP_SOCKET_API_URL,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            username: username,
          },
        },
      });

      const channel = pusher.subscribe("presence-user-" + user_id);

      var socketId = null;
      pusher.connection.bind("connected", () => {
        socketId = pusher.connection.socket_id;
      });

      channel.bind("notification-event", (data) => {
        if (data) {
          setShow(!show);
          localStorage.setItem("new_notification", "true");
        }
      });

      return () => {
        channel.unbind("notification-event");
      };
    }
  }, [user_id]);

  useEffect(() => {}, [show]);

  return (
    <section style={{ position: "relative" }}>
      <MDBBtn
        className="button-group --notification-button"
        aria-label="notification"
        onClick={() => {
          dispatch(openNotificationButton(!notificationButton));
        }}
      >
        {newNotification === "true" && !notificationButton && (
          <article className="red-dot"></article>
        )}
        <Icon
          icon={
            notificationButton
              ? "charm:cross"
              : "mdi:message-notification-outline"
          }
        />
      </MDBBtn>
    </section>
  );
}
