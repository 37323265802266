import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "../../services/http.services";
import apiServices from "../../services/api.services";

export const checkout = createAsyncThunk('checkout', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.checkout(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const getCheckoutItem = createAsyncThunk('getCheckoutItem', async (payload, thunkAPI) => {
    try {
        const response = await apiServices.getCheckoutItem(payload)
        return response
    } catch (error) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const setShippingOption = createAsyncThunk('setShippingOption', async (payload, thunkAPI) => {
  try {
      const response = await apiServices.setShippingOption(payload)
      return response
  } catch (error) {
      return thunkAPI.rejectWithValue(error)
  }
})

export const initialData = {
    checkout_items: [],
    checkout_summary: {},
    deliveryMerchantId: [],
    pickupMerchantId: [],
    selectedMerchantId: '',
    deliveryFee: 0,
    paymentStatus: false,
    pickupLocation: ''
};

const dataSlice = createSlice({
  name: "checkout",
  initialState: initialData,
  reducers: {
    setDeliveryOrPickup: (state, action) => {
        const isDelivery = action.payload;
        const merchantId = state.selectedMerchantId;
      
        const deliveryArray = [...state.deliveryMerchantId]; 
        const pickupArray = [...state.pickupMerchantId]; 
      
        if (isDelivery) {
          const indexInPickup = pickupArray.indexOf(merchantId);
          if (indexInPickup !== -1) {
            pickupArray.splice(indexInPickup, 1); 
          }
      
          if (!deliveryArray.includes(merchantId)) {
            deliveryArray.push(merchantId); 
          }
        } else {
          const indexInDelivery = deliveryArray.indexOf(merchantId);
          if (indexInDelivery !== -1) {
            deliveryArray.splice(indexInDelivery, 1); 
          }
      
          if (!pickupArray.includes(merchantId)) {
            pickupArray.push(merchantId); 
          }
        }
      
        state.deliveryMerchantId = isDelivery !== null ? deliveryArray : [];
        state.pickupMerchantId = isDelivery !== null ? pickupArray : [];
    },
    setSelectedMerchantId: (state, action) => {
        state.selectedMerchantId = action.payload;
    },
    setDeliveryFee: (state, action) => {
        state.deliveryFee = action.payload;
    },
    setPickupLocation: (state, action) => {
      state.pickupLocation = action.payload;
    },
    setPaymentStatus: (state, action) => {
      state.paymentStatus = action.payload;
    },
  },
  extraReducers: {
    [getCheckoutItem.pending]: (state, action) => {
        state.loading = true
    },
    [getCheckoutItem.fulfilled]: (state, action) => {
        state.loading = false
        state.checkout_summary = action.payload.data
        state.checkout_items = action.payload.data.aaData
    },
  },
});

export const { setDeliveryOrPickup, setSelectedMerchantId, setDeliveryFee, setPaymentStatus, setPickupLocation } = dataSlice.actions;

export default dataSlice.reducer;

