import { useState } from "react";

//component
import LoginContent from "./login-content";

//lib
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";

//redux
import { useDispatch, useSelector } from "react-redux";
import { setAnimationModal, setShowFullModal, setToken } from "../../../redux/reducer/commonReducer";
import { openModalPurchase } from "../../../redux/reducer/modalReducer";
import { setShowLoginModal } from "../../../redux/reducer/settingReducer";
import { getProfile, setUserInformation } from "../../../redux/reducer/profileReducer";

//service
import authServices from "../../../services/auth.services";

export default function LoginForm() {
  const dispatch = useDispatch();

  const { reopenPurchase } = useSelector((state) => state.order);

  const [loginLoading, setLoginLoading] = useState(false);

  const [initialValues, setInitialValues] = useState(
      {
        username: "",
        verification_code: "",
        terms_condition: false,
      }
  );

  let useSchema;

  useSchema = Yup.object({
      username: Yup.string()
        .required("Phone number is required")
        .matches(
          /^(01)[02-9]-*[0-9]{7}$|^(01)[1]-*[0-9]{8}$/g,
          "Invalid phone number format"
          ),
      verification_code: Yup.string()
        .min(6, "OTP must be at least 6 characters")
        .required("OTP is required"),
      terms_condition: Yup.bool()
        .required()
        .oneOf([true], "Please agree with the terms and condition"),
  });
  
  const handleSubmit = async ({ values, setFieldError }) => {

      setLoginLoading(true)
      try {
        const response = await authServices.login({
          username: values.username,
          verification_code: values.verification_code,
        });
        if (response) {
          dispatch(setShowFullModal(false))
          setTimeout(() => {
            dispatch(setAnimationModal(true))
            dispatch(setShowLoginModal(false))
            setLoginLoading(false)
            localStorage.setItem("username", response.data.username);
            localStorage.setItem("user_id", response.data.id);
            localStorage.setItem("token", response.data.token);
            
            dispatch(getProfile());
            dispatch(setToken(response.data.token));
            dispatch(setUserInformation(response.data));
            toast.success("Login successful.", {
              autoClose: 3000,
              theme: "light",
              hideProgressBar: true,
              closeOnClick: true,
              closeButton: false,
            });
  
            if (reopenPurchase && response.data.token) {
              dispatch(openModalPurchase());
            }
          }, 500);
        }
      } catch (ex) {
        setLoginLoading(false)
        if (ex && ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) =>
              setFieldError(item, errors[item])
            );
          }
        }
      }
  };

  return (
    <section>
      <Formik
        initialValues={initialValues}
        validationSchema={useSchema}
        validateOnChange={true}
        onSubmit={(values, { setFieldError }) => {
          handleSubmit({ values, setFieldError });
        }}
      >
          <Form>
             <LoginContent loading={loginLoading} />
          </Form>
      </Formik>
    </section>
  );
}

