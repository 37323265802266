import { useEffect, useState } from "react";

//lib
import { Icon } from "@iconify/react";
import { MDBRow } from "mdb-react-ui-kit";

export default function SearchBar({ setSearch, setStart }) {
  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setSearch(displayText);
    }, 1000);
    return () => clearTimeout(timeOutId);
  }, [displayText]);
  
  return (
    <section className="row search-bar-forum">
      <article className="col-2 col-md-1 col-lg-2 icon-search justify-content-start">
        <Icon icon="eva:search-outline" width={24} />
      </article>
      <article className="col-10 col-md-11 col-lg-10">
        <input
          className="search-input"
          placeholder="Search topic"
          onChange={(e) => {
            // setStart(0);
            setDisplayText(e.target.value);
          }}
        />
      </article>
    </section>
  );
}
