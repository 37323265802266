import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import httpService from "../../services/http.services"
import auth from "../../services/auth.services"

export const initialData = {
  loading : false ,
  eventListing : [
  ],
  selectedEventId : {} , 
  //api
  ticketLing : [],
  ticket_payment_loading: false,
}

export const postTicketListing = createAsyncThunk('post/ticket_listing', async(payload, thunkAPI) => {
  try{
      httpService.setToken(auth.getToken());
      const { data } = await httpService.post(process.env.REACT_APP_API_URL + `/ticket_listing`, payload)
      return data
  }catch(error){
      return thunkAPI.rejectWithValue(error)
  }
})

export const postPurchaseTicket = createAsyncThunk('post/purchase_ticket', async(payload, thunkAPI) => {
  try{
      httpService.setToken(auth.getToken());
      const { data } = await httpService.post(process.env.REACT_APP_API_URL + `/purchase_ticket`, payload)
      return data
  }catch(error){
      return thunkAPI.rejectWithValue(error)
  }
})


const dataSlice = createSlice({
  name: "event",
  initialState: initialData,
  reducers: {
    setSelectedEventId: (state,action) => {
        state.selectedEventId = action.payload
    },
    setTicketPaymentLoading: (state,action) => {
      state.ticket_payment_loading = action.payload
    },
  },
  extraReducers: {
    [postTicketListing.pending]: (state, action) => {
      state.loading = true
    },
    [postTicketListing.fulfilled]: (state, action) => {
      state.loading = false
      state.ticketLing = action.payload.data.aaData
    },
    [postTicketListing.rejected]: (state, action) => {
      state.loading = false
    },
  },
})

export const { setSelectedEventId, setTicketPaymentLoading } = dataSlice.actions

export default dataSlice.reducer

// example for fetching API