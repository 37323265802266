import { useEffect } from "react";

//assest
import NoImage from "../../../assets/images/no_image.webp"

//lib
import { Icon } from "@iconify/react";
import { EffectCreative } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Link, useNavigate, useParams } from "react-router-dom";

//hook
import useWindowDimensions from "../../../components/common/window-dimension";

//redux
import { useDispatch, useSelector } from "react-redux";
import { openModalEventListing } from "../../../redux/reducer/modalReducer";
import { setSelectedEventId } from "../../../redux/reducer/eventReducer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Skeleton } from "primereact/skeleton";

export default function EventCarousell() {
  const navigate = useNavigate();
  const params = useParams();
  const { notificationButton } = useSelector((state) => state.common);
  const { home, home_loading } = useSelector((state) => state.home);

  const today = new Date();

  const { width } = useWindowDimensions();
  const dispatch = useDispatch();

  const autoOpenModal = {
    event: openModalEventListing()
  };

  useEffect(() => {
    if (params.state) {
      dispatch(autoOpenModal[params.state]);
    }
  }, []);

  const swiperOptions = {
    allowSlideNext: !home_loading,
    allowSlidePrev: !home_loading,
    allowTouchMove: !home_loading,
  };

  return (
    <article
      className="event-carousell-container"
      style={{ position: 'relative' }}
    >
      {!home_loading && home.ticket.length === 0 ? ''
      : 
        <header className="row home-title-row">
          <h2>Our Event</h2>
          <Link>
            {!home_loading && <Icon
              style={{ cursor: "pointer" }}
              icon="solar:menu-dots-bold"
              width={25}
              color="#222A39"
              onClick={(e) => {
                e.preventDefault();

                dispatch(autoOpenModal['event']);
                navigate(`/event`);
              }}
            />}
          </Link>
        </header>
      }
      <section>
        <Swiper
          style={{ zIndex: notificationButton ? "-1" : "1" }}
          spaceBetween={0}
          grabCursor={!home_loading ? true : false}
          creativeEffect={{
            prev: {
              shadow: false,
              translate: ["-120%", 0, -500],
            },
            next: {
              shadow: false,
              translate: ["120%", 0, -500],
            },
          }}
          modules={[EffectCreative]}
          breakpoints={{
            320: {
              slidesPerView: 2.5,
              spaceBetween: 20,
            },
            1000: {
              slidesPerView: 6,
              spaceBetween: 20,
            },
          }}
          {...swiperOptions}
        >
          {!home_loading ? 
            home.ticket &&
            home.ticket.length > 0 &&
            home.ticket.map((item, index) => {
              const startDate = new Date(item.start_date);
              const endDate = new Date(item.end_date);

              return (
                <SwiperSlide
                  key={index}
                  className={width >= 991 ? "event-card desktop" : "event-card"}
                >
                  <Link
                    style={{
                      // backgroundImage:
                      //   item.image.length > 0
                      //     ? `url("${item.image[0].url}")`
                      //     : "silver",
                      // backgroundSize: "cover",
                      // backgroundPosition: "center",
                    }}
                    className="event-image"
                    onClick={(e) => {
                      e.preventDefault();

                      dispatch(autoOpenModal['event']);
                      dispatch(setSelectedEventId(item.id));
                      navigate(`/event`);
                    }}
                    >
                    <figure className="event-image-frame">
                      {/* alt and title both need to return event name on home api */}
                      <LazyLoadImage fetchpriority="high" src={item.image.length > 0 ? item.image[0].url : NoImage} alt="reunion-event" /> 
                    </figure>
                    <article className="row label-row">
                      <p className="p-0 _label">
                        {today.getTime() > endDate.getTime()
                          ? "EVENT END"
                          : startDate.getTime() > today.getTime()
                          ? "COMING SOON"
                          : "JOIN NOW"}
                      </p>
                    </article>
                  </Link>
                </SwiperSlide>
              );
            })
          : 
            Array.from({ length: 3 }, (_, eventIndex) => (
              <SwiperSlide className={width >= 991 ? "event-card desktop" : "event-card"}>
                <article className="container event-image">
                  <section className="event-image-frame">
                    <Skeleton width="100%" height="100%" borderRadius="0.9em" className="event-skeleton"/>
                  </section>
                </article>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </section>
    </article>
  );
}
