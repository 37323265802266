import { useEffect, useState } from "react";

//lib
import { useNavigate } from "react-router-dom";
import { MDBContainer, MDBModalBody, MDBRow, MDBCol } from "mdb-react-ui-kit";

//hook
import useWindowDimensions from "../../../components/common/window-dimension";

//redux
import { useDispatch, useSelector } from "react-redux";
import { closeAllModal } from "../../../redux/reducer/modalReducer";
import {
  postWalletHistory,
  getUserWallet
} from "../../../redux/reducer/profileReducer";

//component
import EmptyPage from "./emptyPage";
import PointHistory from "./pointHistory";
import ModalBox from "../../../components/modal/full.modal.box";
import InfoHeader from "../../../components/header/info-header";
import Notify from "../../../components/common/notify-component";

export default function PointModal() {
  const navigate = useNavigate();

  const [start, setStart] = useState(0);
  const [length, setLength] = useState(10);
  const [pointData, setPointData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loadingStatus, setLoadingStatus] = useState(false);

  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const user_id = localStorage.getItem("user_id");
  const { isOpenRedeem } = useSelector((state) => state.modal);
  const { userWallet } = useSelector(
    (state) => state.profile
  );

  const data = [
    "Point can be use to redeem Item at our reunion store page, choose the item and pay with points.",
    "Point have no expired time.",
  ];

  const backButton = () => {
    dispatch(closeAllModal());
    navigate("/profile");
  };

  useEffect(() => {
    dispatch(getUserWallet());
    dispatch(
      postWalletHistory({
        id: Number(user_id),
        start: start,
        length: length,
      })
    )
      .unwrap()
      .then((res) => {
        let newData = [];
        for (var key in res.data.aaData) {
          newData.push({
            ...res.data.aaData[key],
            ...res.data.aaData[key],
          });
        }

        setPointData(newData);
        if (newData.length !== res.data.iTotalRecords) {
          setHasMore(true);
        }
      })
      .catch((ex) => {});
  }, []);

  const getMoreData = () => {
    setLoadingStatus(true);
    setStart(start + 10);
    dispatch(
      postWalletHistory({
        id: Number(user_id),
        start: start + 10,
        length: length,
      })
    )
      .unwrap()
      .then((res) => {
        setLoadingStatus(false);
        let newData = [];
        for (var key in res.data.aaData) {
          newData.push({
            ...res.data.aaData[key],
            ...res.data.aaData[key],
          });
        }
        if (pointData.length + newData.length >= res.data.iTotalRecords) {
          setHasMore(false);
        }
        setPointData([...pointData, ...newData]);
      });
  };

  return (
    <ModalBox
      className={"modal-container"}
      show={isOpenRedeem}
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      backButton={backButton}
      scrollable
      centered
      content={
        <article>
          <InfoHeader
            showBackButton={true}
            title="Point History"
            backButton={backButton}
          />
          <MDBModalBody className="p-0">
            <section className={width >= 991 ? "order-div desktop" : "order-div"}>
              <Notify item={data} />
              <section className="row tag-row">
                <article className="col tag d-flex">
                  <section className="short-div" />
                  <h3 className="_label --title">
                    Can't buy reunion points; participation is important
                  </h3>
                </article>
              </section>
              <section className="container point-container">
                <section className="row point-row">
                  <p className="_label --name">Reunion Point</p>
                </section>
                <section className="row">
                  <h2 className="_label --point">
                    {userWallet.point_balance
                      ? userWallet.point_balance
                      : 0}
                  </h2>
                </section>
              </section>
              <section className="row point-history-header">
                <article className="col-9 tag d-flex align-items-center">
                  <section className="short-div" />
                  <h3 className="_label --listing">History</h3>
                </article>
                <article className="col-3">
                  <h3 className="_label --text">Point</h3>
                </article>
              </section>
              {pointData && pointData.length > 0 ? (
                <section>
                  {pointData.map((item, index) => {
                    return <PointHistory key={index} item={item} />;
                  })}
                  {hasMore ? (
                    <section className="row">
                      <p
                        className="event-see-more pointer"
                        onClick={() => {
                          if (!loadingStatus) {
                            getMoreData();
                          }
                        }}
                      >
                        See more
                      </p>
                    </section>
                  ) : (
                    <section className="row">
                      <p className="event-see-more">History Is Empty</p>
                    </section>
                  )}
                </section>
              ) : (
                <EmptyPage name="point" />
              )}
            </section>
          </MDBModalBody>
        </article>
      }
    />
  );
}
