//lib
import { Icon } from "@iconify/react";
import { Link, useNavigate } from "react-router-dom";
import { MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

//redux
import { useDispatch, useSelector } from "react-redux";
import { setCreatePostModal } from "../../redux/reducer/forumReducer";
import { setShowLoginModal } from "../../redux/reducer/settingReducer";

//assets
import Logo from "../../assets/images/logo.webp";

//hook
import useWindowDimensions from "../common/window-dimension";

//component
import VideoCard from "../../pages/Forum/component/video.card";
import { closeModalPostPreview } from "../../redux/reducer/modalReducer";

export default function SideNavbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const width = useWindowDimensions();
  const token = localStorage.getItem("token");
  const { navigation_item } = useSelector((state) => state.common);

  return (
    <nav className="side-navbar-container">
      <section className="row justify-content-center">
        <LazyLoadImage className="logo-icon" src={Logo} alt="reunion-logo" title="reunion-logo" />
      </section>
      <section className="row justify-content-center m-0">
        <MDBBtn
          className="button-group --notification-button --create-post p-0"
          type="button"
          onClick={() => {
            if (token) {
              dispatch(closeModalPostPreview());
              dispatch(setCreatePostModal("create-post"));
            } else {
              dispatch(setShowLoginModal(true));
            }
          }}
        >
          <p style={{ fontFamily: 'inter-regular', fontSize: '16px' }} className="pointer">
            Post <Icon icon="mingcute:add-line" color="#ffffff" style={{ width: width < 991 ? '16px' : '19px' }} className="ps-1"/>
          </p>
        </MDBBtn>
      </section>
      {navigation_item.map((item, index) => (
        <section className="row m-0 justify-content-center" key={index}>
          <Link
            className={
              window.location.pathname === item.navigate
                ? "tab-bar --active pointer"
                : "tab-bar pointer"
            }
            onClick={(e) => {
              e.preventDefault();

              navigate(item.navigate);
            }}
          >
            <section className="w-auto">
              {window.location.pathname === item.navigate ? (
                // <VideoCard video={item.webM} videoMov={item.mov} type='side-gif' />
                <LazyLoadImage src={item.animation} width={35} height={35} alt="reunion-nav" title="reunion-nav" style={{ cursor: "pointer" }}/>
              ) : (
                <LazyLoadImage src={item.image} width={35} height={35} title="reunion-nav" alt="reunion-nav" style={{ cursor: "pointer" }}/>
              )}
            </section>
            <h4 className="navbar-label pointer">{item.label}</h4>
          </Link>
        </section>
      ))}
    </nav>
  );
}
