import { useEffect, useState } from "react";

//hook
import useWindowDimensions from "../../../components/common/window-dimension";

//lib
import _ from "lodash";
import Lottie from "lottie-react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

//redux
import { useDispatch, useSelector } from "react-redux";
import { setAddCartQuantity } from "../../../redux/reducer/cartReducer";

//component
import { CustomNumberInput } from "../../../components/element/quantity.input";

export default function CartTable({
  paymentType,
  editQuantity,
  deleteQuantity,
  cartData,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const [editProductId, setEditProductId] = useState(null);

  const { carts, cart_loading, edit_loading } = useSelector(
    (state) => state.cart
  );

  useEffect(() => {
    if(!cart_loading) {
      dispatch(setAddCartQuantity({ product_id: null, quantity: 0 }))
    }
  }, [cart_loading])

  const merchantNames = cartData !== undefined && cartData.length !== 0 ?  Object.keys(cartData) : [];

  return (
    <article>
      {width > 991 ? (
        <table>
          <thead>
            <tr>
              <th className="_th --product">Product</th>
              <th>Unit Price</th>
              <th>Quantity</th>
              <th className="blue-color">Total Price</th>
              <th></th>
            </tr>
          </thead>
          <section className="mb-5" />
          {merchantNames.length > 0 ? merchantNames.map((item , index) => {
            return (
              <section key={index} style={{display : "contents"}}>
                <article className="mb-5" />
                <tbody>
                  <tr className="merchant-row">
                    <td colSpan="6">
                      <p className="d-flex">
                        <Icon
                          icon="carbon:store"
                          color="#414141"
                          width="20"
                          height="20"
                          className="pointer me-3"
                        />
                        {item}
                      </p>
                    </td>
                  </tr>
                  {cartData[item] && cartData[item].items.map((item, itemIndex) => (
                      <tr key={itemIndex}>
                        <td
                          className="_td --product pointer"
                          onClick={() => {
                            navigate(`/product/${item.product_id}`);
                          }}
                        >
                          <section className="d-flex">
                            <article className="image-div pointer">
                              <LazyLoadImage
                                title={item.name}
                                alt={item.name}
                                src={
                                  _.find(item.image, {
                                    image_type: "product_image",
                                  })?.url
                                }
                              />
                            </article>
                            <article>
                              <p className="pointer">{item.name}</p>
                              <p className="pointer _label --brand-name mt-">
                                {item.brand}
                              </p>
                            </article>
                          </section>
                        </td>
                        <td>
                          {paymentType === "currency" && "RM"}{" "}
                          {item.unit_price.toFixed(2)}{" "}
                          {paymentType === "point" && "pts"}
                        </td>
                        <td className="_td --quantity">
                          <article className="d-flex justify-content-center">
                            <CustomNumberInput 
                              aria-label="Quantity Input" 
                              min={item.balance <= 0 ? 0 : 1}
                              max={item.balance <= 0 ? 0 : item.balance}
                              value={item.quantity}
                              onChange={(event, val) => {
                                if (val === item.balance) {
                                  toast.error("Maximum quantity reached!", {
                                    autoClose: 3000,
                                    theme: "light",
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    closeButton: false,
                                  });
                                }
                                editQuantity(item.id, val);
                                setEditProductId(item.id)
                              }}
                            />
                          </article>
                        </td>
                        <td>
                          {!edit_loading ? (
                            <>
                              {paymentType === "currency" && "RM"}{" "}
                              {item.total_price.toFixed(2)}{" "}
                              {paymentType === "point" && "pts"}
                            </>
                          ) : (
                              editProductId === item.id ?
                                <Icon
                                  icon="line-md:loading-twotone-loop"
                                  width="25px"
                                  height="25px"
                                  color="#3db8eb"
                                />
                            : 
                              <>
                                {paymentType === "currency" && "RM"}{" "}
                                {item.total_price.toFixed(2)}{" "}
                                {paymentType === "point" && "pts"}
                              </>
                          )}
                        </td>
                        <td className="p-3">
                          <article
                            onClick={() => {
                              deleteQuantity(item.id);
                            }}
                          >
                            <Icon
                              icon="openmoji:delete"
                              color="#dc4c64"
                              width="30"
                              height="30"
                              className="pointer"
                            />
                          </article>
                        </td>
                      </tr>
                  ))}
                </tbody>
              </section>
            )
          }) : 
          <tbody style={{ border: "none" }}>
          <tr>
            <td colSpan="6">
              <section
                className="d-flex justify-content-center"
                style={{ minHeight: "550px" }}
              >
                <article
                  style={{
                    width: "18em",
                    position: "absolute",
                    top: "45%",
                  }}
                >
                  <section className="row">
                    <Icon icon="mdi:cart-variant" height={"107.88px"} width={"142px"} color="#c1ecff"/>
                  </section>
                  <p className="_label --empty-cart">
                    Add something inside..
                  </p>
                </article>
              </section>
            </td>
          </tr>
        </tbody>
          }
        </table>
      ) : (
        <section className="p-0">
          {merchantNames.length > 0 ? 
            merchantNames.map((item , index) => (
              <article>
                {cartData[item] && cartData[item].items.length > 0 && (
                  <>
                    <header className="merchant-row">
                      <p className="d-flex align-items-center">
                        <Icon
                          icon="carbon:store"
                          color="#414141"
                          width="20"
                          height="20"
                          className="pointer me-3"
                        />
                        {item}
                      </p>
                    </header>
                  </>
                )}
                {cartData[item] && cartData[item].items.length > 0&&  cartData[item].items?.map((item, itemIndex) => (
                  <>
                    <section className="row" style={{ padding: "1em 1em 0em 1em" }}>
                      <article className="col-4 p-2 d-flex justify-content-center align-items-center">
                        <section className="image-div pointer">
                          <LazyLoadImage
                            alt={item.name}
                            src={
                              _.find(item.image, {
                                image_type: "product_image",
                              })?.url
                            }
                          />
                        </section>
                      </article>
                      <article className="col-7 p-3 cart-grid">
                        <p className="_mobile-label --product-name">
                          {item.name}
                        </p>
                        <p className="_mobile-label --brand-name">
                          {item.brand}
                        </p>
                        <article className="pt-4 pb-4">
                          <CustomNumberInput 
                            aria-label="Quantity Input" 
                            min={item.balance <= 0 ? 0 : 1}
                            max={item.balance <= 0 ? 0 : item.balance}
                            value={item.quantity}
                            onChange={(event, val) => {
                              if (val === item.balance) {
                                toast.error("Maximum quantity reached!", {
                                  autoClose: 3000,
                                  theme: "light",
                                  hideProgressBar: true,
                                  closeOnClick: true,
                                  closeButton: false,
                                });
                              }
                              editQuantity(item.id, val);
                              setEditProductId(item.id)
                            }}
                          />
                        </article>
                        <p className="_mobile-label --price">
                          {!edit_loading ? (
                            <>
                              {paymentType === "currency" && "RM"}{" "}
                              {item.total_price.toFixed(2)}{" "}
                              {paymentType === "point" && "pts"}
                            </>
                          ) : (
                              editProductId === item.id ?
                                <Icon
                                  icon="line-md:loading-twotone-loop"
                                  width="25px"
                                  height="25px"
                                  color="#3db8eb"
                                />
                            : 
                              <>
                                {paymentType === "currency" && "RM"}{" "}
                                {item.total_price.toFixed(2)}{" "}
                                {paymentType === "point" && "pts"}
                              </>
                           )}
                        </p>
                      </article>
                      <article className="col-1">
                        <section
                          onClick={() => {
                            deleteQuantity(item.id);
                          }}
                        >
                          <Icon
                            icon="openmoji:delete"
                            color="#dc4c64"
                            width="25"
                            height="25"
                            className="pointer"
                          />
                        </section>
                      </article>
                    </section>
                    {itemIndex !== merchantNames.length - 1 && (
                      <section className="long-line" />
                    )}
                  </>
                ))}
                {cartData[item] && cartData[item].items.length > 0 && <section className="grey-space" />}
              </article>
            ))
          : (
            <article
              className="d-flex align-items-center justify-content-center"
              style={{ minHeight: "500px" }}
            >
              <section style={{ width: "16em" }} className="text-center">
                {/* <Lottie animationData={EmptyCart} /> */}
                <section className="row">
                  <Icon icon="mdi:cart-variant" height={"107.88px"} width={"142px"} color="#c1ecff"/>
                </section>
                <p className="_label --empty-cart">
                  Add something inside..
                </p>
              </section>
            </article>
          )}
        </section>
      )}
    </article>
  );
}
