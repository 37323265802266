import { useState } from "react";

//lib 
import { Icon } from "@iconify/react";
import { Skeleton } from "primereact/skeleton";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { LazyLoadImage } from "react-lazy-load-image-component";

//redux
import { useDispatch, useSelector } from "react-redux";
import { setSeletedAvatar } from "../../../redux/reducer/profileReducer";

export default function AvatarList({ item }) {
  const dispatch = useDispatch();
  const { seletedAvatar, avatar_list_loading } = useSelector((state) => state.profile);
  const [tooltipShown, setTooltipShown] = useState(false);

  return (
    <article className="col-3 avatar-col">
      <section
        className={"row avatar-div pointer"}
        style={{ opacity: item.type === "locked" ? 0.5 : 1 }}
      >
        {item.type === "locked" && (
          <article>
            <section
              className="lock-icon"
              data-tooltip-id="avatar-tooltip" 
              onClick={() => setTooltipShown(!tooltipShown)}
            >
              <Icon icon="icon-park-outline:lock" width="30" height="30" />
            </section>
            <section>
              <ReactTooltip
                id='avatar-tooltip'
                // type="info"
                // effect="solid"
                // delayHide={100000000000}
                place="bottom"
                // globalEventOff="click"
                // isCapture={false}
                // disable={false}
                className="custom-tooltip"
              >
                <article className="d-flex align-items-center">
                  <section className="d-flex align-items-start">
                    <Icon icon="ic:outline-info" color="#fafafa" width="16" height="16" />
                  </section>
                  <p className="d-flex align-items-center ps-2">Please get the avatar through the task</p>
                </article>
              </ReactTooltip>
            </section>
          </article>
        )}
        {!avatar_list_loading ?
          <LazyLoadImage
            src={item.url}
            alt="reunion-avatar"
            className={
              seletedAvatar === item.url ? "avatar-image active" : "avatar-image"
            }
            onClick={() => {
              if (item.type !== "locked") {
                dispatch(setSeletedAvatar(item.url));
              }
            }}
          />
        : 
          <Skeleton shape="circle" size="7rem" className="p-0" />
        }
      </section>
    </article>
  );
}
