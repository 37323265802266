//lib
import { init } from "ityped";
import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import { Skeleton } from "primereact/skeleton";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

//hook
import useWindowDimensions from "../../../components/common/window-dimension";

//redux
import { useDispatch, useSelector } from "react-redux";
import { openModalAvatarList } from "../../../redux/reducer/modalReducer";
import {
  getProfile,
  setNickname,
  setSelectedAvatarBackground,
  setSeletedAvatar,
} from "../../../redux/reducer/profileReducer";
import { setShowLoginModal } from "../../../redux/reducer/settingReducer";

//assets
import defaultBg from "../../../assets/images/default_avatar_background.webp"

export default function UserProfile() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const { width } = useWindowDimensions();
  const { guest, profile, profile_loading, seletedAvatar, selectedAvatarBackground, loading , userWallet} = useSelector((state) => state.profile);
  const [userName, setUserName] = useState(
    token && profile.user_nickname ? profile.user_nickname : ""
  );
  const [isEditing, setIsEditing] = useState(false);

  const profileWidget1 = profile.occupation;
  const profileWidget2 = profile.car_model;
  const profileWidget3 = profile.city;

  const profileWidgetArray = [];

  if (profileWidget1 !== undefined && profileWidget1 !== null) {
    profileWidgetArray.push({ data: profileWidget1, color: "yellow" });
  }

  if (profileWidget2 !== undefined && profileWidget2 !== null) {
    profileWidgetArray.push({ data: profileWidget2, color: "blue" });
  }

  if (profileWidget3 !== undefined && profileWidget3 !== null) {
    profileWidgetArray.push({ data: profileWidget3, color: "yellow" });
  }

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value.slice(0, 12);
    setUserName(inputValue);
  };

  const handleInputBlur = () => {
    setIsEditing(false);
    if (userName.length > 0) {
      dispatch(setNickname({ nickname: userName }))
        .unwrap()
        .then((res) => {
          dispatch(getProfile());
        });
    }
  };

  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleInputBlur();
    }
  };

  useEffect(() => {
    if(profile){
      setUserName(profile.user_nickname)
      dispatch(setSeletedAvatar(profile.user_avatar))
      dispatch(setSelectedAvatarBackground(profile.user_avatar_background))
    }
  },[profile])

  useEffect(() => {
    init(document.querySelector(".detail"), {
      strings: ["Car Lover", "Car Owner", "Car Expert", "REUNION"],
      loop: true,
      typeSpeed: 500,
      backSpeed: 300,
    });
  }, []);

  return (
    <section 
      className={width >= 991 ? "user-div --desktop" : "user-div --mobile"}
      style={{
        backgroundImage: `url(${
          selectedAvatarBackground ? selectedAvatarBackground 
          : profile_loading || loading ?  defaultBg 
          : token && profile && profile.user_avatar_background !== "" ? profile.user_avatar_background 
          : defaultBg})`, 
        backgroundSize: "cover", 
        backgroundPosition: "center", 
      }}
    >
      <section className="row" style={{ display: width >= 991 ? "" : "none" }}>
        <article className="col">
          <h1 className="_label --baner-title">
            Welcome{" "}
            {token && userName !== "" ? userName : profile.user_nickname}, Share
            about your car to&nbsp;
            <span className="detail"></span>
          </h1>
        </article>
        <article
          className="d-flex justify-content-end col-3"
          style={{ position: "relative" }}
        >
        </article>
      </section>

      <section className="row user-data-row">
        <article className={`avatar-col col-12 col-md-6`}>
          <section className="row avatar-row pointer">
            {profile_loading ? (
              seletedAvatar ?
              <LazyLoadImage
                style={{
                  width: width < 991 ? "75px" : "100px",
                  height: width < 991 ? "75px" : "100px",
                  padding: 0,
                  borderRadius: "50%",
                }}
                src={seletedAvatar}
                alt="reunion-avatar"
                className="user-avatar"
                onClick={() => {
                  if(token){
                    dispatch(openModalAvatarList());
                    dispatch(setSeletedAvatar(profile.user_avatar));
                  }
                }}
              />
              :
              <Skeleton shape="circle" size={ width > 991 ? '100px' : '75px' } className="p-0"></Skeleton>
            ) : (
              <LazyLoadImage
                style={{
                  width: width < 991 ? "75px" : "100px",
                  height: token ? width < 991 ? "75px" : "100px" : width < 991 ? "75px" : "100px",
                  padding: 0,
                  borderRadius: "50%",
                }}
                src={
                  token && profile && profile.user_avatar !== ""
                    ? profile.user_avatar
                    : guest.avatar
                }
                alt="reunion-avatar"
                className="user-avatar"
                onClick={() => {
                  if(token){
                    dispatch(openModalAvatarList());
                    dispatch(setSeletedAvatar(profile.user_avatar));
                  }
                }}
              />
            )}
            {token && !loading && (
              <Icon
                icon="material-symbols:change-circle-outline"
                color="#fafafa"
                height="20"
                className="change-icon"
                onClick={() => {
                  dispatch(openModalAvatarList());
                  dispatch(setSeletedAvatar(profile.user_avatar));
                }}
              />
            )}
          </section>
        </article>
        <article className="data-col col-12 col-md-6" style={{ display: !token ? 'flex' : '', justifyContent: width < 770 ? 'center' : 'start' }}>
          {token && (
            <section className="row name-row">
              <label className="_label --name">
                {isEditing ? (
                  <input
                    type="text"
                    value={userName}
                    onKeyPress={handleInputKeyPress}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    className="text-center"
                    style={{
                      fontSize: "16px",
                      background: "transparent",
                      color: "#fafafa",
                    }}
                  />
                ) : (
                  <h3 
                    className="pointer" 
                    onClick={() => handleEditClick()}
                  >
                    {token && userName !== ""
                      ? userName
                      : profile.user_nickname}
                    &nbsp;
                    {token && !loading && (
                      <Icon
                        icon="mingcute:edit-line"
                        height={"11px"}
                        color="fafafa"
                      />
                    )}
                  </h3>
                )}
              </label>
            </section>
          )}
          {!token && (
            <section className="row login-row">
               <article
                className="d-flex align-items-center pointer"
                onClick={() => dispatch(setShowLoginModal(true))}
              >
                <h5 className={`ms-${width > 991 ? 5 : 0} button-group --login-button mb-0`} style={{ color: '#f4fbff', letterSpacing: '0.05em', fontWeight: 'unset' }}>
                  Login | Register
                  <Icon
                    width="22"
                    icon="ic:twotone-login"
                    color="#fafafa"
                    className="ms-3"
                  />
                  <span />
                </h5>
              </article>
            </section>
          )}
          {token  && (
            <section className="row name-row">
              <article className="col">
                <label className="_label --point">
                 {(profile.point_balance || profile.point_balance === 0) && profile.point_balance} point
                </label>
              </article>
            </section>
          )}
          <section className="row name-row">
            <article className="col text-end">
              {token && profileWidgetArray.length > 0 && (
                <label
                  className={
                    profileWidgetArray[0].color === "yellow"
                      ? "_label --info"
                      : "_label --blue"
                  }
                >
                  {profileWidgetArray[0].data}
                </label>
              )}
            </article>
            <article className="col text-start">
              {token && profileWidgetArray.length > 1 && (
                <label
                  className={
                    profileWidgetArray[1].color === "yellow"
                      ? "_label --info"
                      : "_label --blue"
                  }
                >
                  {profileWidgetArray[1].data}
                </label>
              )}
            </article>
          </section>
          {token && profileWidgetArray.length > 2 && (
            <section className="row name-row">
              <article className="col">
                <label className="_label --info" style={{ marginTop: '-0.4em'}}>
                  {profileWidgetArray[2].data}
                </label>
              </article>
            </section>
          )}
        </article>
      </section>
    </section>
  );
}
