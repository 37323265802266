import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import apiServices from "../../services/api.services"

export const getProductListing = createAsyncThunk('getProductListing', async (payload, thunkAPI) => {
    try {
      const response = await apiServices.getProductListing(payload)
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
})

export const getProductInfo = createAsyncThunk('getProductInfo', async (payload, thunkAPI) => {
    try {
      const response = await apiServices.getProductInfo(payload)
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
})

export const getUserAddressInfo = createAsyncThunk('getUserAddressInfo', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.getUserAddressInfo(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const getUserWalletBalance = createAsyncThunk('getUserWalletBalance', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.getUserWalletBalance(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const getUserBanks = createAsyncThunk('getUserBanks', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.getUserBanks(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const getUserOrderDetails = createAsyncThunk('getUserOrderDetails', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.getUserOrderDetails(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})


export const initialData = {
  selectedSection: "quantity",
  showPurchaseModal: false,
  showShareModal: false,
  showConfirmModal: false,
  surePay: {},
  productListing: [],
  bankListing: [],
  productInfo: {},
  contactInfo: {},
  paymentMethod: "",
  userAddressInfo: [],
  editInfo: false,
  shippingMethod: "Delivery",
  walletBalance: "",
  skeletonData : [1,2,3, 4,5,6],
  bankCode: "",
  productInfo_loading: false,
  quantity: 1,
  payment_loading: false,
}

const stockSlice = createSlice({
  name: "stock",
  initialState: initialData,
  reducers: {
    setShowPurchaseModal: (state,action) => {
      state.showPurchaseModal = action.payload
    },
    setShowShareModal: (state,action) => {
      state.showShareModal = action.payload
    },
    setShowConfirmModal: (state,action) => {
      state.showConfirmModal = action.payload
    },
    setSurePay: (state,action) => {
      state.surePay = action.payload
    },
    setSelectedSection: (state,action) => {
      state.selectedSection = action.payload
    },
    setContactInfo: (state,action) => {
      state.contactInfo = action.payload
    },
    setPaymentMethod: (state,action) => {
        state.paymentMethod = action.payload
    },
    setBankCode: (state,action) => {
      state.bankCode = action.payload
    },
    setEditInfo: (state,action) => {
      state.editInfo = action.payload
    },
    setShippingMethod: (state,action) => {
      state.shippingMethod = action.payload
    },
    setProductInfo: (state,action) => {
      state.productInfo = action.payload
    },
    setQuantity: (state,action) => {
      state.quantity = action.payload
    },
    setPaymentLoading: (state,action) => {
      state.payment_loading = action.payload
    },
  },
  extraReducers: {
    [getProductListing.pending]: (state, action) => {
        state.loading = true
    },
    [getProductListing.fulfilled]: (state, action) => {
        const allProductData = action.payload.data.aaData
        const groupedWithMerchantName = allProductData.reduce((acc, item) => {
            const key = item.merchant_name
            if (!acc[key]) acc[key] = []
            acc[key].push(item)
            return acc
        }, {})

        state.productListing = groupedWithMerchantName
        state.loading = false

    },
    [getUserBanks.pending]: (state, action) => {
      state.bank_loading = true
    },
    [getUserBanks.fulfilled]: (state, action) => {
      state.bank_loading = false
      state.bankListing = action.payload.data
    },
    [getUserBanks.rejected]: (state, action) => {
      state.bank_loading = false
    },
    [getUserOrderDetails.fulfilled]: (state, action) => {
        state.loading = false
        const orderDetails = action.payload.data

        state.shippingMethod = orderDetails.shipping_method
        state.paymentMethod = orderDetails.payment_method
        state.bankCode = orderDetails.payment_code
        state.quantity = orderDetails.quantity
    },
    [getProductInfo.pending]: (state, action) => {
        state.productInfo_loading = true
    },
    [getProductInfo.fulfilled]: (state, action) => {
        state.productInfo_loading = false
        state.productInfo = action.payload.data
    },
    [getUserAddressInfo.pending]: (state, action) => {
      state.loading = true
    },
    [getUserAddressInfo.fulfilled]: (state, action) => {
        state.loading = false
        state.userAddressInfo = action.payload.data
    },
    [getUserWalletBalance.pending]: (state, action) => {
      state.loading = true
    },
    [getUserWalletBalance.fulfilled]: (state, action) => {
        state.loading = false
        state.walletBalance = action.payload.data
    },
  },
})

export const { setShowPurchaseModal, setShowShareModal, setQuantity, setShowConfirmModal, setSurePay, setBankCode, setSelectedSection, setContactInfo, setProductInfo, setPaymentMethod, setEditInfo, setShippingMethod, setPaymentLoading} = stockSlice.actions;
const { reducer } = stockSlice;
export default reducer;
