import { useEffect } from 'react'

//lib
import { MDBContainer } from 'mdb-react-ui-kit'

//component
import TermsData from './component/terms.and.conditions'

export default function TermsAndConditions() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <section className="container common-main-container" breakpoint="none">
      <TermsData />
    </section>
  )
}
