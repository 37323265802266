import { useState } from "react";

//lib
import * as Yup from "yup";
import moment from "moment";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { MDBInput } from "mdb-react-ui-kit";

//redux
import { useDispatch, useSelector } from "react-redux";
import {
  putUserInformation,
  getProfile,
} from "../../../redux/reducer/profileReducer";

//component
import Text from "../../../components/element/text";
import Select from "../../../components/element/select";
import Button from "../../../components/element/button";

export default function InformationForm({ item }) {
  const dispatch = useDispatch();
  const { response, profile } = useSelector((state) => state.profile);
  const [loading, setLoading] = useState(false);

  const today = new Date();

  const initialValues = {
    full_name: profile.full_name ? profile.full_name : "",
    mobile_number: profile.mobile_number ? profile.mobile_number : "",
    dob:
      profile.dob !== undefined
        ? moment(profile.dob, "DD-MM-YYYY").format("YYYY-MM-DD")
        : moment(today).format("YYYY-MM-DD"),
    gender: profile.gender ? profile.gender : "male",
    city: profile.city ? profile.city : "",
    car_model: profile.car_model ? profile.car_model : "",
  };

  const useSchema = Yup.object({
    full_name: Yup.string().required("User name is required"),
    mobile_number: Yup.string()
      .required("Required")
      .matches(
        /^(01)[02-9]-*[0-9]{7}$|^(01)[1]-*[0-9]{8}$/g,
        "Invalid phone number format."
      ),
    gender: Yup.string().required("Required"),
    dob: Yup.date()
      .max(new Date(), "Date of birth cannot be a future date.")
      .required("Required"),
  });

  const genderList = [
    { label: "male", id: "male" },
    { label: "female", id: "female" },
  ];

  const ErrorMessage = ({ errors, name, touched }) => {
    return (
      <p
        className="text-danger  error-message mt-2"
        style={{ fontSize: "1.1rem" }}
      >
        {errors[name]}
      </p>
    );
  };

  const handleSubmit = async ({ values, setFieldError }) => {
    const valuesData = {
      full_name: values.full_name,
      mobile_number: values.mobile_number,
      dob: moment(values.dob).format("DD-MM-YYYY"),
      gender: values.gender,
      city: values.city,
      car_model: values.car_model,
    };
    setLoading(true);
    dispatch(putUserInformation(valuesData))
      .unwrap()
      .then((res) => {
        setLoading(false);

        dispatch(getProfile());
        toast.success("Update profile successful.", {
          autoClose: 3000,
          theme: "light",
          hideProgressBar: true,
          closeOnClick: true,
          closeButton: false,
        });
      })
      .catch((ex) => {
        setLoading(false);
        if (response && Object.keys(response).length > 0) {
          let errorMsg = "";
          if (response.response.status === 422) {
            const errors = response.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                setFieldError(item, errors[item]);
                errorMsg = errors[item];
              });
            }
          }
        }
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={useSchema}
      enableReinitialize={true}
      validateOnChange={true}
      onSubmit={(values, { setFieldError, resetForm, setValues }) => {
        handleSubmit({ values, setFieldError });
      }}
    >
      {({
        setFieldValue,
        setFieldError,
        isSubmitting,
        isValid,
        values,
        errors,
        touched,
      }) => (
        <Form>
          <Text
            name="full_name"
            placeholder={values.username !== null ? "Full Name" : ""}
            as={MDBInput}
            className="mdb-field"
          />

          <ErrorMessage errors={errors} name={"full_name"} />

          <Text
            name="mobile_number"
            placeholder={values.mobile_number !== null ? "Phone Number" : ""}
            as={MDBInput}
            className="mdb-field disable"
            disabled={true}
          />

          <ErrorMessage errors={errors} name={"mobile_number"} />

          <Text
            name="dob"
            placeholder="Birthday Month"
            as={MDBInput}
            className={
              profile.dob !== undefined ? "mdb-field disable" : "mdb-field"
            }
            type={"date"}
            disabled={profile.dob !== undefined ? true : false}
          />
          <ErrorMessage errors={errors} name={"dob"} />

          <Select
            name="gender"
            label={values.gender !== null ? "Gender" : ""}
            className="gender"
            options={genderList}
            placeholder="Please select your gender"
          />

          <ErrorMessage errors={errors} name={"gender"} />

          <Text
            name="city"
            placeholder={values.city !== null ? "City you stay" : ""}
            as={MDBInput}
            className="mdb-field"
          />
          <ErrorMessage errors={errors} name={"city"} />

          <Text
            name="car_model"
            placeholder={values.car_model !== null ? "Car Model" : ""}
            as={MDBInput}
            className="mdb-field"
          />
          <ErrorMessage errors={errors} name={"car_model"} />

          <Button
            type={"submit"}
            name={
              profile.dob !== undefined ? "Save" : "Complete & Get 500 Points"
            }
            className="form_button"
            disabled={loading || !isValid || isSubmitting}
          />
        </Form>
      )}
    </Formik>
  ); 
}
