//lib
import { MDBModalBody, MDBRow, MDBContainer } from "mdb-react-ui-kit";
import useWindowDimensions from "../../../components/common/window-dimension";

//redux
import { useDispatch, useSelector } from "react-redux";

//component
import ModalBox from "../../../components/modal/full.modal.box";
import InfoHeader from "../../../components/header/info-header";
import { closeModalAttachment, openModalCarReport } from "../../../redux/reducer/modalReducer";
import { setAnimationModal } from "../../../redux/reducer/commonReducer";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function AttachmentModal() {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { isOpenAttachment } = useSelector((state) => state.modal);
  const { attachmentInfo } = useSelector((state) => state.car);

  const backButton = () => {
    dispatch(closeModalAttachment());
    dispatch(openModalCarReport());
    dispatch(setAnimationModal(false));
  };

  return (
    <ModalBox
    className="report-container-modal"
    screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
    centered
    show={isOpenAttachment}
    backButton={backButton}
    content={
      <article>
        <InfoHeader
          title={attachmentInfo.booking_no}
          backButton={backButton}
          backButtonNoAnimation={backButton}
        />
        <MDBModalBody className="p-0">
          <section
            className="container"
            style={{
              paddingTop: width >= 991 ? "1em" : "6em",
              paddingLeft: width >= 991 ? "2em" : "1em",
              paddingRight: width >= 991 ? "2em" : "1em",
            }}
          >
            <article>
              <header className="row" style={{ marginBottom: "0.5em" }}>
                <p className="d-flex">
                  <section className="blue-tag"></section>The Report is generate by a
                  professional maintenance tool.
                </p>
              </header>
              {attachmentInfo.data?.length > 0 && attachmentInfo.data.map((attachment, attachmentIndex) => {
                return(
                  <section className="attachment-border mt-5" key={attachmentIndex}>
                      <h4 className='attachment-title mb-5'>Report Attachment {attachmentIndex + 1}</h4>
                      <article className='flex'>
                          <section className='attachment-grid'>
                              <LazyLoadImage
                                  src={attachment.attachment_image}
                                  title={attachment.attachment_desc}
                                  alt={attachment.attachment_desc}
                                  style={{ objectFit: 'cover' }}
                              />
                              <article className='justify-start'>
                                <p className="description">Description: </p>
                                <h5 className='description mt-3'>{attachment.attachment_desc ? attachment.attachment_desc : '..'}</h5>
                              </article>
                          </section>
                      </article>
                  </section>
                )
              })}
            </article>
          </section>
        </MDBModalBody>
      </article>
    }
  />
  )
}
