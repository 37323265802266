import React from 'react'
import { useRef } from 'react'

//redux
import { useSelector } from 'react-redux'

//lib
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit'

//assets
import useWindowDimensions from '../../../components/common/window-dimension'

//component
import TandCScroll from './t_and_c.scroll'
import TandCDropdown from './t_and_c.dropdown'

export default function TermsData() {
  const { width } = useWindowDimensions()

  const { t_and_c_Listing, privacyListing ,refundListing} = useSelector((state) => state.common)

  const section1 = useRef(null)
  const section2 = useRef(null)
  const section3 = useRef(null)
  const containerRef = useRef(null)

  return (
    <article className="container tnc-container p-0">
      <section className="row" style={{marginTop : width > 991 ? "4em" : "" , textAlign : "center"}}>
        <h1 className="_label">
          <span className="_text --title">Terms of Use</span>
        </h1>
      </section>
      <section className="row d-flex justify-content-center text-center">
        <p className="_label mt-2 mb-5">
          <span className="_text --subtitle">
            We believe service the best, values the customer and honestly always the best policy at
            all time
          </span>
        </p>
      </section>
      
      <TandCDropdown section1={section1} section2={section2} section3={section3}/>

      <section className="row" style={{justifyContent : "center"}}>
        <article className={width > 991 ? 'col-10' : 'col-12'}>
          <section ref={containerRef}>
            <section id="section1">
              <header className="d-flex">
                <h2 className="_label --tc-title mt-3 mb-3" ref={section1}>
                  Terms of Use
                </h2>
              </header>
              {t_and_c_Listing.map((item, index) => {
                return (
                  <article key={index} className="t_and_c-div">
                    <section className='row'>
                      <h3 className="_text --t_c_title">{item.title}</h3>
                    </section>
                    <section className='row'>
                      <p className="_text --t_c_content pt-1 pb-3" style={{ paddingLeft: '1.85em' }}>
                        {item.content}
                        <span className="_text --t_c_detail" >{item.phone_no ? item.phone_no : ''}</span>
                      </p>
                    </section>
                    {item.company && (
                      <section className='row'>
                        <p className="_text --t_c_detail">{item.company}</p>
                      </section>
                    )}
                    {item.address && (
                      <section className='row'>
                        <p className="_text --t_c_detail">{item.address}</p>
                      </section>
                    )}
                    {item.email && (
                      <section className='row'>
                        <p className="_text --t_c_detail">{item.email}</p>
                      </section>
                    )}
                  </article>
                )
              })}
            </section>
            <section id="section2">
              <header className="d-flex">
                <h2 className="_label --tc-title mt-5 mb-3" ref={section2}>
                  Privacy Policy
                </h2>
              </header>
              {privacyListing.map((item, index) => {
                return (
                  <article key={index} className="t_and_c-div">
                    <section className='row'> 
                      <h3 className="_text --t_c_title">{item.title}</h3>
                    </section>
                    <section className='row'> 
                      <p className="_text --t_c_content pt-1 pb-3" style={{ paddingLeft: '1.85em' }}>
                        {item.content}
                        <span className="_text --t_c_detail" >{item.phone_no ? item.phone_no : ''}</span>
                      </p>
                    </section>
                    {item.company && (
                      <section className='row'> 
                        <p className="_text --t_c_detail pt-1 pb-0" style={{ paddingLeft: '1.85em'}}>{item.company}</p>
                      </section>
                    )}
                    {item.address && (
                      <section className='row'> 
                        <p className="_text --t_c_detail pb-1" style={{ paddingLeft: '1.85em' }}>{item.address}</p>
                      </section>
                    )}
                    {item.email && (
                      <section className='row'> 
                        <p className="_text --t_c_detail pb-3" style={{ paddingLeft: '1.85em' }}>{item.email}</p>
                      </section>
                    )}
                  </article>
                )
              })}
            </section>

            <section className="row" style={{marginTop : width > 991 ? "4em" : "" , textAlign : "center"}}>
              <h1 className="_label">
                <span className="_text --title">Refund Policy</span>
              </h1>
            </section>
            <section className="row d-flex justify-content-center text-center">
              <p className="_label mt-2 mb-5">
                <span className="_text --subtitle">
                  Our refund policy is designed to protect your interests, ensuring that you have a clear understanding
                  of how refunds work. We are committed to addressing any issues promptly and fairly to maintain your
                  trust.
                </span>
              </p>
            </section>
            <section id="section3 mt-1">
              <header className="d-flex">
                <h2 className="_label --tc-title mt-5 mb-3" ref={section3}>
                Refund Policy
                </h2>
              </header>
              {refundListing.map((item, index) => {
                return (
                  <article key={index} className="t_and_c-div">
                    <section className='row'>
                      <h3 className="_text --t_c_title">{item.title}</h3>
                    </section>
                   
                    <section className='row'>
                      <p className="_text --t_c_content pt-1 pb-3" style={{ paddingLeft: '1.85em' }}>
                        {item.content}
                        <span className="_text --t_c_detail" >{item.phone_no ? item.phone_no : ''}</span>
                      </p>
                    </section>
                    {item.list && item.list.length > 0 && item.list.map((list , index) => {
                      return (
                        <section className='row' key={index}>
                          <p className="_text --t_c_content pt-1 pb-3" style={{ paddingLeft: '1.85em' , lineHeight : "14px" }}>
                            -&nbsp;{list.list}
                          </p>
                        </section>
                      )
                    })}
                    {item.phone && (
                      <section className='row'>
                        <p className="_text --t_c_detail pb-1" style={{ paddingLeft: '1.85em' }}>{item.phone}</p>
                      </section>
                    )}
                    {item.company && (
                      <section className='row'>
                        <p className="_text --t_c_detail pt-1 pb-0" style={{ paddingLeft: '1.85em'}}>{item.company}</p>
                      </section>
                    )}
                    {item.address && (
                      <section className='row'>
                        <p className="_text --t_c_detail pb-1" style={{ paddingLeft: '1.85em' }}>{item.address}</p>
                      </section>
                    )}
                    {item.email && (
                      <section className='row'>
                        <p className="_text --t_c_detail pb-3" style={{ paddingLeft: '1.85em' }}>{item.email}</p>
                      </section>
                    )}
                  </article>
                )
              })}
            </section>
          </section>
        </article>
      </section>
    </article>
  )
}
