//lib
import { Icon } from "@iconify/react";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

//component
import AttendeeUser from "./attendeeUser";

export default function EventCard({ item }) {
  const today = new Date();

  const TicketBtn = ({ startDate, endDate, eventDate }) => {
    return (
      <article
        className={
          endDate.getTime() > today.getTime() &&
          today.getTime() > startDate.getTime()
            ? "status-div"
            : "status-div"
        }
      >
        <p className="_label --status">
          {today.getTime() > eventDate.getTime()
            ? "Thanks For Joining Us"
            : "Joined"}
        </p>
      </article>
    );
  };

  return (
    <article className="container event-card">
      <header className="row title-row">
        <article className="col-1">
          <Icon
            icon="heroicons:ticket-solid"
            height={"1.5em"}
            color="#1d71bf"
          />
        </article>
        <article className="col">
          <p className="_label --title mt-1">{item.title}</p>
        </article>
      </header>
      <section className="row ticket-row">
        <article className="col-4 image-col">
          {item.mainMediaUrl && (
            <LazyLoadImage
              title={item.title}
              src={item.mainMediaUrl[0].url}
              alt={item.description}
              className="event-image"
            />
          )}
        </article>
        <article className="col p-0 col-8">
          <section className="row" style={{ minHeight: "6em" }}>
            <p className="_p --description break-all">{item.description}</p>
          </section>
          <section className="row status-row">
            <TicketBtn
              startDate={new Date(item.start_date)}
              endDate={new Date(item.end_date)}
              eventDate={new Date(item.event_date)}
            />
          </section>
          <section className="row date-row">
            <p className="_label --date">
              <Icon icon="ant-design:field-time-outlined" height={"1.3em"} className="mb-1" />{" "}
              &nbsp; Event Date : {item.event_date}
            </p>
          </section>
        </article>
      </section>
      <section className="row detail-div">
        <article className="row detail-row">
          <p className="_label --detail">Event Details</p>
        </article>
        <article className="detail-data">
          <p className="_p --description2">{item.content}</p>
        </article>
      </section>
      <footer className="row detail-div">
        <section className="row detail-row">
          <p className="_label --detail">Ticket Details</p>
        </section>
        <section className="ticket-detail d-grid">
          <p>
            Purchase Date : <span className="_span">{item.purchase_date}</span>
          </p>
          <p>
            Order No : <span className="_span">{item.order_no}</span>
          </p>
          <p>
            Ticket Price : <span className="_span">{item.ticket_price} {item.payment_method === "point" ? "PTS" : "MYR"}</span>
          </p>
          <p>
            Event Date : <span className="_span">{item.event_date}</span>
          </p>
          <p className="text-danger">
            Maximum Attendee Per Ticket: <span className="_span">{item.attendee_number}</span>
          </p>
        </section>
        <section className="detail-data border-0">
          {item.properties &&
            item.properties.length > 0 &&
            item.properties.map((data, index) => {
              return (
                <section key={index} className="row mb-3">
                  <p className="pb-2" style={{ fontWeight: '500' }}>Ticket #{index + 1}</p>
                  {item.properties[index] &&
                    item.properties[index].length > 0 &&
                    item.properties[index].map((item, index) => {
                      return <AttendeeUser key={index} item={item} />;
                    })}
                </section>
              );
            })}
        </section>
      </footer>
    </article>
  );
}
