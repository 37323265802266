import { configureStore } from "@reduxjs/toolkit";

//reducer
import forumReducer from "./reducer/forumReducer";
import commonReducer from "./reducer/commonReducer";
import orderReducer from "./reducer/orderReducer";
import settingReducer from "./reducer/settingReducer";
import modalReducer from "./reducer/modalReducer";
import stockReducer from "./reducer/stockReducer";
import profileReducer from "./reducer/profileReducer";
import eventReducer from "./reducer/eventReducer";
import paymentReducer from "./reducer/paymentReducer";
import carReducer from "./reducer/carReducer";
import homeReducer from "./reducer/homeReducer";
import cartReducer from "./reducer/cartReducer";
import checkoutReducer from "./reducer/checkoutReducer";

const reducer = {
    common: commonReducer,
    order: orderReducer,
    forum: forumReducer,
    setting: settingReducer,
    stock: stockReducer,
    modal : modalReducer,
    payment : paymentReducer,
    profile: profileReducer,
    event: eventReducer,
    car: carReducer,
    home: homeReducer,
    cart: cartReducer,
    checkout: checkoutReducer,
}

const store = configureStore({
    reducer: reducer,
    devTools: process.env.REACT_APP_DEV_TOOL,
})

export default store