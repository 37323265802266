import { useState } from "react";

//lib
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { MDBInput } from "mdb-react-ui-kit";

//redux
import { useDispatch, useSelector } from "react-redux";
import {
  putUserInformationTwo,
  getProfile,
} from "../../../redux/reducer/profileReducer";

//component
import Text from "../../../components/element/text";
import Button from "../../../components/element/button";
import TagInput from "../../../components/element/tagsInput";

export default function OccupationForm() {
  const dispatch = useDispatch();
  const { response, profile } = useSelector((state) => state.profile);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    occupation: profile.occupation ? profile.occupation : "",
    industry: profile.industry ? profile.industry : "",
    three_sport: profile.three_sport ? profile.three_sport : [],
    three_chill: profile.three_chill ? profile.three_chill : [],
  };

  const formSchema = Yup.object({
    three_sport: Yup.array()
      .of(Yup.string())
      .min(3, "3 sport activities are required")
      .max(3, "Maximum of 3 sport activities allowed"),
    three_chill: Yup.array()
      .of(Yup.string())
      .min(3, "3 chill activities are required")
      .max(3, "Maximum of 3 chill activities allowed"),
  });

  const ErrorMessage = ({ errors, name }) => {
    return (
      <p
        className="text-danger  error-message"
        style={{ fontSize: "1.1rem" }}
      >
        {errors[name]}
      </p>
    );
  };

  const handleSubmit = async ({ values, setFieldError }) => {
    setLoading(true);

    dispatch(putUserInformationTwo(values))
      .unwrap()
      .then((res) => {
        setLoading(false);

        dispatch(getProfile());
        toast.success("Update profile successful.", {
          autoClose: 3000,
          theme: "light",
          hideProgressBar: true,
          closeOnClick: true,
          closeButton: false,
        });
      })
      .catch((ex) => {
        setLoading(false);

        if (response && Object.keys(response).length > 0) {
          let errorMsg = "";
          if (response.response.status === 422) {
            const errors = response.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                setFieldError(item, errors[item]);
                errorMsg = errors[item];
              });
            }
          }
        }
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      enableReinitialize={true}
      validateOnChange={true}
      onSubmit={(values, { setFieldError, resetForm }) => {
        handleSubmit({ values, setFieldError });
      }}
    >
      {({
        setFieldValue,
        setFieldError,
        isSubmitting,
        isValid,
        values,
        errors,
      }) => (
        <Form>
          <Text
            name="occupation"
            maxLength={15}
            placeholder={
              values.occupation !== null ? "Name your Occupation" : ""
            }
            as={MDBInput}
            className="mdb-field"
          />
          <Text
            name="industry"
            placeholder={
              values.industry !== null ? "Name your industry of expertise" : ""
            }
            as={MDBInput}
            className="mdb-field"
          />

          <TagInput
            className={"mdb-field"}
            name="three_sport"
            placeholder="Please write your recent 3 sport activity"
            max={3}
          />

          <ErrorMessage errors={errors} name={"three_sport"} />

          <TagInput
            className={"mdb-field"}
            name="three_chill"
            placeholder="Please write your recent 3 chill activity"
            max={3}
          />

          <ErrorMessage errors={errors} name={"three_chill"} />

          <Button
            type={"submit"}
            name={profile.three_chill ? "Save" : "Complete & Get 500 Points"}
            className="form_button"
            disabled={loading || !isValid || isSubmitting}
          />
        </Form>
      )}
    </Formik> 
  );
}
